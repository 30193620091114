import { Fragment, useRef, useState, useEffect } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Storage } from "aws-amplify";
import { isEmpty } from "lodash";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgRotate from "lightgallery/plugins/rotate";
import lgHash from "lightgallery/plugins/hash";
import Slider from "react-slick";
import lgVideo from "lightgallery/plugins/video";

// Import required CSS
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-rotate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const initialTabs = [
  { name: "Teach Me", href: "#Teach", current: true },
  { name: "Show Me", href: "#Show", current: false },
  { name: "Coach Me", href: "#Coach", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ContentAccordion({
  directoryName,
  outcome,
  contentTitle,
  contentBody,
  videoUrl,
  tableObject,
  contentOpen,
  Title,
  keyQuestion,
  showForm,
  setShowForm,
}) {
  const getTabFromHash = (hash) => {
    switch (hash) {
      case "#Teach":
        return "Teach Me";
      case "#Show":
        return "Show Me";
      case "#Coach":
        return "Coach Me";
      default:
        return "Teach Me";
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    const tabName = getTabFromHash(hash);
    handleTabClick(tabName);

    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const [isOpen, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [imagesSet, setImagesSet] = useState(false);
  const [images, setImages] = useState([
    "https://opskit.app/wp-content/uploads/2023/11/ipad-in-field-1024x578-1-600x400.jpg",
    "https://opskit.app/wp-content/uploads/2023/11/Image-uploaded-from-iOS-11-600x400.jpg",
    "https://opskit.app/wp-content/uploads/2023/11/cropped-sic-truck-operators-600x400.jpg",
    "https://opskit.app/wp-content/uploads/2023/11/Supervisor-600x400.jpg",
  ]);

  const [videos, setVideos] = useState([]);
  const [videosLoaded, setVideosLoaded] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const result = await Storage.list(`fallback/${directoryName}`);
        if (!isEmpty(result.results)) {
          const fileResults = result.results.filter(
            (item) => !item.key.endsWith("/"),
          );
          let urls = [];
          for (const file of fileResults) {
            let url = await Storage.get(file.key);
            urls.push(url);
          }
          setImages(urls);
        }
      } catch (err) {
        console.error("Error fetching images:", err);
      }
      setImagesSet(true);
    };

    const fetchVideos = async () => {
      try {
        // Replace this with your actual video fetching logic
        const videoData = [
          {
            youtubeId: "",
            thumbnail: ``,
          },
        ];
        setVideos(videoData);
      } catch (err) {
        console.error("Error fetching videos:", err);
      }
      setVideosLoaded(false);
    };

    fetchImages();
    fetchVideos();
  }, []);

  const lightGallery = useRef(null);
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "0",
  };

  const scrollCarouselComponent = () => {
    return (
      <div>
        {imagesSet ? (
          <LightGallery
            plugins={[
              lgZoom,
              lgAutoplay,
              lgFullscreen,
              lgThumbnail,
              lgRotate,
              lgHash,
            ]}
            licenseKey="1234-43432fd-dsfdsfsdf-34324"
            selector=".slick__slide"
            speed={500}
            onInit={(detail) => {
              lightGallery.current = detail.instance;
            }}
          >
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <div key={index} className="px-2">
                  <a
                    className="slick__slide block"
                    data-lg-size="1600-1067"
                    data-src={image}
                  >
                    <img
                      src={image}
                      alt={`Image #${index + 1}`}
                      className="w-full h-56 object-cover rounded cursor-pointer"
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </LightGallery>
        ) : (
          <div
            role="status"
            className="flex items-center justify-center h-56 w-full bg-gray-300 rounded-lg animate-pulse"
          >
            <svg
              className="w-10 h-10 text-gray-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
    );
  };

  const VideoCarousel = () => {
    const videoLightGallery = useRef(null);

    if (!videosLoaded) {
      return (
        <div
          role="status"
          className="flex flex-col items-center justify-center h-56 w-full bg-gray-300 rounded-lg"
        >
          <svg
            className="w-10 h-10 text-gray-200"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 20"
          >
            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
          </svg>
          <span className="">Video Coming Soon</span>
        </div>
      );
    }

    return (
      <LightGallery
        plugins={[lgVideo, lgZoom, lgThumbnail, lgFullscreen]}
        licenseKey="1234-43432fd-dsfdsfsdf-34324"
        selector=".video-item"
        speed={500}
        onInit={(detail) => {
          videoLightGallery.current = detail.instance;
        }}
      >
        <Slider {...sliderSettings}>
          {videos.map((video, index) => (
            <div key={index} className="px-2">
              <a
                className="video-item"
                data-src={`https://www.youtube.com/watch?v=${video.youtubeId}`}
                data-poster={video.thumbnail}
              >
                <img
                  src={video.thumbnail}
                  alt={`Video thumbnail #${index + 1}`}
                  className="w-full h-56 object-cover rounded cursor-pointer"
                />
              </a>
            </div>
          ))}
        </Slider>
      </LightGallery>
    );
  };

  const [selectedTab, setSelectedTab] = useState(initialTabs[0].name);
  const [tabs, setTabs] = useState(initialTabs);

  const handleTabClick = (tabName) => {
    if (tabName === "Teach Me" || tabName === "Show Me") {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
    setSelectedTab(tabName);
    updateCurrentTab(tabName);
  };

  const updateCurrentTab = (tabName) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        current: tab.name === tabName,
      })),
    );
  };

  return (
    <div id="Content" className="mx-auto rounded-2xl m-6 shadow-lg bg-white">
      <div className="block">
        <div
          className="isolate flex divide-x-8 divide-gray-50"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? "bg-white hover:bg-gray-50 focus-visible:outline-thiess-blue-200 text-black"
                  : "bg-thiess-blue-600 hover:bg-thiess-blue-500 focus-visible:outline-thiess-blue-600 text-white",
                "group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center sm:text-lg text-sm font-medium focus:z-10 rounded-t-2xl shadow-sm",
              )}
              aria-current={tab.current ? "page" : undefined}
              onClick={() => handleTabClick(tab.name)}
            >
              {tab.name}
            </a>
          ))}
        </div>
      </div>
      <div>
        {selectedTab === "Teach Me" && (
          <div className="m-6 grid grid-cols-1 gap-x-12 gap-y-8 sm:grid-cols-6 bg-white">
            <h7
              className="sm:text-xl text-base col-span-3 text-thiess-blue-700  mx-auto mb-5"
              dangerouslySetInnerHTML={{ __html: keyQuestion }}
            />
            <h7
              className="sm:text-xl text-base col-span-3  mx-auto mb-5 text-black"
              dangerouslySetInnerHTML={{ __html: outcome }}
            />
            <div className="col-span-3">
              <h7 className="sm:text-xl text-base pb-6 mx-auto">
                {contentTitle}
              </h7>
              {contentBody ? (
                <p
                  className="sm:text-base text-sm leading-7 text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: contentBody,
                  }}
                />
              ) : (
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[330px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
            <div className="col-span-3">
              <h7 className="sm:text-xl text-base pb-6 mx-auto">
                What Information and Tools Do I Use To Do a Great {Title}?
              </h7>
              {tableObject ? (
                <Markdown
                  className="markdown py-6 sm:text-base text-sm"
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {tableObject}
                </Markdown>
              ) : (
                <div role="status" className="max-w-sm animate-pulse">
                  <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[330px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[300px] mb-2.5"></div>
                  <div className="h-2 bg-gray-200 rounded-full max-w-[360px]"></div>
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        )}
        {selectedTab === "Show Me" && (
          <div className="m-6 mx-auto divide-black/10">
            <dl className="space-y-6 divide-black/10 m-6">
              <div className="grid grid-cols-1 gap-x-16 gap-y-8 sm:grid-cols-6 bg-white">
                <div className="col-span-3">
                  <h7 className="sm:text-xl text-base pb-6 mx-auto text-black">
                    Images of tools & what good looks like?
                  </h7>
                  <div className="py-5">{scrollCarouselComponent()}</div>
                </div>

                <div className="col-span-3">
                  <h6 className="sm:text-xl text-base pb-6 text-left leading-7 text-black">
                    Watch videos to see examples of how it's done.
                  </h6>
                  <VideoCarousel />
                </div>
              </div>
              <dt></dt>
            </dl>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState } from "react";

const tabs = [
  {
    name: "Thiess",
    description: `People buy our services because our process, procedures and people allow us to mine faster, safer and more profitably whilst continuing to deliver a better-quality product to our customers. Thiess has grown considerably, and we will continue to do so. That means we are adding more sites and employing more talented people from outside of Thiess. Given the complexities of our sites, the geographies we work in and the variances in our contracts we need a way to ensure we don't lose the Thiess way of doing things.
        The MO@T has been developed to ensure we can teach newcomers what makes Thiess - Thiess, whilst providing a way to learn from our people and continually develop and enhance how we operate.`,
    subtitle: "Thiess is a service business",
  },
  {
    name: "MO@T",
    description: `We expect the implementation of the MO@T to result in safer, more efficient work by:
1. Driving Clarity of "What is Work" for me and "What does Good look like?"
2. Giving leaders a set of solid rhythms and routines for leading their team.
3. Improving the quality of planning.
4. Helping everyone to believe that they can achieve their plan each month, week, day or shift.
5. Assisting people to effectively schedule, coordinate and communicate work so that all supervisors and their crews go to work with an achievable plan every shift.
6. Ensuring we learn from experience through structured reviews of performance vs the plan.
7. Turning opportunities for improvement into actions and projects that deliver a more stable, safe & productive operation.
8. A single plan which is visible to everyone on site that balances the needs of production, maintenance, technical services, training and all our support functions.
9. A way of Diagnosing our Performance.
10. A tool that helps us teach our people practically, how to improve their operational disciple.`,
    subtitle:
      "The MO@T is a collection of tools and behaviors that align the work and focus of frontline teams with the expectations of their leaders.",
  },
  {
    name: "Your Site",
    description: `The MO@T has been developed so that you can diagnose your team's maturity and teach your people the skills they need to deliver projects the Thiess way. The MO@T project is NOT prescriptive. It is descriptive. Our MO@T has been designed as a:

1. Diagnostic Tool
2. Teaching Tool

It has been specifically developed to be used within the workflow as a practical way to ensure we all know:

1. What is work for me?
2. What does good look like?
3. How we execute work at Thiess?`,
    subtitle: "ADAPT THE MO@T FOR YOUR SITE.",
  },
];

const Tabs = () => {
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (name) => {
    setExpandedItems((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const truncateText = (text, maxLength = 200) => {
    if (text.length <= maxLength) return text;
    const truncated = text
      .slice(0, maxLength)
      .split(" ")
      .slice(0, -1)
      .join(" ");
    return truncated + "...";
  };

  return (
    <>
      <div className="py-12 sm:py-14">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-center">
            <h2 className="sm:text-6xl text-4xl  tracking-tight text-black">
              Thiess, MO@T and your site
            </h2>
            <p className="mt-8 max-w-2xl mx-auto text-gray-700 sm:text-lg text-sm">
              Read more about Thiess, MO@T and your site and learn how they
              could enhance your work process.
            </p>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tabs.map((item) => (
              <div key={item.name}>
                <h6 className="text-black sm:text-2xl text-lg mt-6  leading-8 tracking-tight">
                  {item.name}
                </h6>
                <div
                  className="absolute h-1 w-screen -translate-x-full bg-black/10 sm:-ml-4 lg:static lg:w-auto lg:flex-auto lg:translate-x-0"
                  aria-hidden="true"
                />
                <div className="flex my-4 items-center sm:text-lg text-sm leading-6 text-thiess-blue-600">
                  {item.subtitle}
                </div>
                <div className="mt-1 sm:text-lg text-sm leading-7 text-gray-600">
                  {expandedItems[item.name]
                    ? item.description
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)
                    : truncateText(item.description)
                        .split("\n")
                        .map((line, index) => <p key={index}>{line}</p>)}
                  {item.description.length > 200 && (
                    <button
                      onClick={() => toggleExpand(item.name)}
                      className="text-thiess-blue-600 hover:underline mt-2"
                    >
                      {expandedItems[item.name] ? "Read less" : "Read more"}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export { Tabs };

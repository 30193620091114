import { useState, useRef } from "react";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import { Storage } from "aws-amplify";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Import the plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginValidateType,
  FilePondPluginFileValidateSize,
);

export default function SummarySection({
  formData,
  handleInputChange,
  isSubmitting,
  resetStateValues,
  directoryName,
  nextSection,
  prevSection,
  handleSubmitReset,
}) {
  const [files, setFiles] = useState([]);
  const pond = useRef(null);

  const handleInit = () => {
    console.log("FilePond instance has initialised", pond.current);
  };
  const handleProcessFile = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
  ) => {
    const fileName = `${directoryName}/${file.name}`; // Use the directoryName state to create a path

    Storage.put(fileName, file, {
      progressCallback(progressEvent) {
        progress(true, progressEvent.loaded, progressEvent.total);
      },
      contentType: file.type,
    })
      .then((storageResponse) => {
        load(storageResponse.key); // Calls the load method with the file key if upload is successful
      })
      .catch((err) => {
        error(err.message);
      });

    // Return a function to abort the upload process
    return {
      abort: () => {
        abort();
      },
    };
  };
  const uploadFiles = () => {
    if (pond.current) {
      pond.current.processFiles(); // This initiates the upload of all files
    }
  };
  return (
    <section
      id="Summary"
      key="Summary"
      className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10"
    >
      <div className="bg-white shadow-sm ring-1 ring-black/5 sm:rounded-xl">
        <div className="px-4 py-6 sm:p-8">
          <div className="px-4 sm:px-0">
            <h7 className="sm:text-xl text-base font-semibold leading-7 text-black">
              Summary
            </h7>
          </div>
          <div className="max-w-7xl space-y-10">
            <div className="col-span-full">
              <label
                htmlFor="comments-notes"
                className="block text-md font-medium leading-6 text-black"
              >
                Overall Comments/Notes
              </label>
              <div className="mt-2">
                <textarea
                  id="comments-notes"
                  name="CommentsNotes"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-lg text-sm sm:leading-6"
                  value={formData.CommentsNotes}
                  onChange={handleInputChange}
                  disabled={isSubmitting}
                />
              </div>
              <p className="mt-3 sm:text-lg text-sm leading-6 text-gray-600">
                Please provide any observations or opportunities for improvement
                you have for this element.
              </p>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="coaching-notes"
                className="block text-md font-medium leading-6 text-black"
              >
                Coaching Comments/Notes
              </label>
              <div className="mt-2">
                <textarea
                  id="coaching-notes"
                  name="CoachingNotes"
                  rows={3}
                  className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-lg text-sm sm:leading-6"
                  value={formData.CoachingNotes}
                  onChange={handleInputChange}
                  disabled={isSubmitting}
                />
              </div>
              <p className="mt-3 sm:text-lg text-sm leading-6 text-gray-600">
                Please record any suggestions or work that the coachee should
                focus on to improve their maturity.
              </p>
            </div>
          </div>
          <div className="col-span-full pt-6">
            <label
              htmlFor="Image Upload"
              className="block text-md font-medium leading-6 text-black"
            >
              Upload Images Below:
            </label>
            <FilePond
              ref={pond}
              files={files}
              allowReorder={true}
              acceptedFileTypes={[
                "image/jpg",
                "image/jpeg",
                "image/png",
                "image/gif",
              ]}
              instantUpload={false}
              allowMultiple={true}
              allowImagePreview={true}
              allowFileTypeValidation={true}
              allowImageExifOrientation={true}
              allowFileSizeValidation={true}
              maxFileSize="10MB"
              maxTotalFileSize="50MB"
              labelMaxTotalFileSizeExceeded="Max total file size exceeded"
              labelMaxFileSizeExceeded="File is too large"
              oninit={() => handleInit()}
              onupdatefiles={setFiles}
              server={{ process: handleProcessFile }}
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-6 border-t border-black/10 px-4 py-4 sm:px-8">
          <button
            type="reset"
            className="sm:text-lg text-sm  leading-6 text-black"
            disabled={isSubmitting}
            onClick={(event) => {
              event.preventDefault();
              resetStateValues();
            }}
          >
            Clear All
          </button>
          <a
            href="#Psych"
            className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
            onClick={prevSection}
          >
            Back
          </a>
          <button
            disabled={isSubmitting}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              uploadFiles();
              handleSubmitReset(e);
            }}
            className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
          >
            {isSubmitting ? (
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-200 animate-spin fill-thiess-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              "Save & Clear"
            )}
          </button>
          <button
            disabled={isSubmitting}
            type="submit"
            onClick={uploadFiles}
            className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
          >
            {isSubmitting ? (
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-200 animate-spin fill-thiess-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              "Save & Review"
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

import React, { forwardRef } from "react";
import classNames from "classnames";
import themeConfig from "../../config/theme.config";
import useColorIntensity from "../../hooks/useColorIntensity";
import Icon from "../icon/Icon";

const Button = forwardRef((props, ref) => {
  const {
    borderWidth,
    children,
    className,
    color,
    colorIntensity,
    icon,
    isActive,
    isDisable,
    isLoading,
    rightIcon,
    rounded,
    size,
    variant,
    ...rest
  } = props;
  const HAS_CHILDREN = typeof children !== "undefined";

  const { textColor, shadeColorIntensity } = useColorIntensity(colorIntensity);

  /**
   * Variant & Color & Status
   */
  const btnVariants = {
    solid: classNames(
      // Default
      {
        [`bg-${color}-${colorIntensity}`]: !isActive,
      },
      [`${borderWidth} border-${color}-${colorIntensity}`],
      [`${textColor}`],
      // Hover
      [`hover:bg-${color}-${shadeColorIntensity}`],
      [`hover:border-${color}-${shadeColorIntensity}`],
      // Active
      [`active:bg-${color}-${shadeColorIntensity}`],
      [`active:border-${color}-${shadeColorIntensity}`],
      {
        [`bg-${color}-${shadeColorIntensity}`]: isActive,
        [`border-${color}-${shadeColorIntensity}`]: isActive,
      },
    ),
    outline: classNames(
      // Default
      "bg-transparent",
      [`${borderWidth}`],
      {
        [`border-${color}-${colorIntensity}/50`]: !isActive,
      },
      "text-black dark:text-white",
      // Hover
      [`hover:border-${color}-${colorIntensity}`],
      // Active
      [`active:border-${color}-${colorIntensity}`],
      {
        [`border-${color}-${colorIntensity}`]: isActive,
      },
    ),
    default: classNames(
      // Default
      "bg-transparent",
      { "text-zinc-600 dark:text-zinc-400": !isActive },
      [`${borderWidth}`],
      "border-transparent",
      // Hover
      [
        `hover:text-${color}-${colorIntensity} dark:hover:text-${color}-${colorIntensity}`,
      ],
      // Active
      [`active:text-${color}-${colorIntensity}`],
      {
        [`text-${color}-${colorIntensity}`]: isActive,
      },
    ),
  };
  const btnVariantClasses = btnVariants[variant];

  /**
   * Padding & Font Size & Icon Margin
   */
  const btnSizes = {
    xs: {
      general: classNames(
        {
          "px-3": HAS_CHILDREN,
          "px-0.5": !HAS_CHILDREN,
        },
        "py-0.5",
        "text-xs",
      ),
      icon: classNames(
        { "ltr:mr-1 rtl:ml-1": HAS_CHILDREN },
        "text-[1.125rem]",
      ),
      rightIcon: classNames("ltr:ml-1", "rtl:mr-1", "text-[1.125rem]"),
    },
    sm: {
      general: classNames(
        {
          "px-4": HAS_CHILDREN,
          "px-1": !HAS_CHILDREN,
        },
        "py-1",
        "text-base",
      ),
      icon: classNames({ "ltr:mr-1 rtl:ml-1": HAS_CHILDREN }, "text-[1.25rem]"),
      rightIcon: classNames("ltr:ml-1", "rtl:mr-1", "text-[1.25rem]"),
    },
    default: {
      general: classNames(
        {
          "px-5": HAS_CHILDREN,
          "px-1.5": !HAS_CHILDREN,
        },
        "py-1.5",
        "text-base",
      ),
      icon: classNames(
        { "ltr:mr-1.5 rtl:ml-1.5": HAS_CHILDREN },
        "text-[1.5rem]",
      ),
      rightIcon: classNames("ltr:ml-1.5", "rtl:mr-1.5", "text-[1.5rem]"),
    },
    lg: {
      general: classNames(
        {
          "px-6": HAS_CHILDREN,
          "px-2": !HAS_CHILDREN,
        },
        "py-2",
        "text-lg",
      ),
      icon: classNames({ "ltr:mr-2 rtl:ml-2": HAS_CHILDREN }, "text-[1.75rem]"),
      rightIcon: classNames("ltr:ml-2", "rtl:mr-2", "text-[1.75rem]"),
    },
    xl: {
      general: classNames(
        {
          "px-7": HAS_CHILDREN,
          "px-2.5": !HAS_CHILDREN,
        },
        "py-2.5",
        "text-xl",
      ),
      icon: classNames(
        { "ltr:mr-2.5 rtl:ml-2.5": HAS_CHILDREN },
        "text-[1.75rem]",
      ),
      rightIcon: classNames("ltr:ml-2.5", "rtl:mr-2.5"),
    },
  };
  const btnSizeClasses = btnSizes[size].general;
  const btnIconClasses = btnSizes[size].icon;
  const btnRightIconClasses = HAS_CHILDREN
    ? btnSizes[size].rightIcon
    : undefined;

  /**
   * Disable
   */
  const btnDisabledClasses = "opacity-50 pointer-events-none";

  const classes = classNames(
    "inline-flex items-center justify-center",
    btnVariantClasses,
    btnSizeClasses,
    rounded,
    themeConfig.transition,
    { [`${btnDisabledClasses}`]: isDisable || isLoading },
    className,
  );

  return (
    <button
      ref={ref}
      data-component-name="Button"
      type="button"
      className={classes}
      {...rest}
    >
      {(!!icon || isLoading) && (
        <Icon
          icon={isLoading ? "DuoLoading" : icon}
          className={classNames({ "animate-spin": isLoading }, btnIconClasses)}
        />
      )}
      {children}
      {!!rightIcon && (
        <Icon icon={rightIcon} className={classNames(btnRightIconClasses)} />
      )}
    </button>
  );
});
Button.defaultProps = {
  borderWidth: themeConfig.borderWidth,
  children: undefined,
  className: undefined,
  color: themeConfig.themeColor,
  colorIntensity: themeConfig.themeColorShade,
  icon: undefined,
  isActive: false,
  isDisable: false,
  isLoading: false,
  rightIcon: undefined,
  rounded: themeConfig.rounded,
  size: "default",
  variant: "default",
};
Button.displayName = "Button";

export default Button;

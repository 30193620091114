import React, { useState, useEffect } from "react";
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterChild,
} from "../../fyr-theme/components/ui/Modal";
import Button from "../../fyr-theme/components/ui/Button";
import Label from "../../fyr-theme/components/form/Label";
import SelectReact from "../../fyr-theme/components/form/SelectReact";
import Input from "../../fyr-theme/components/form/Input";
import RichText from "../../fyr-theme/components/RichText";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API, graphqlOperation } from "aws-amplify";
import { createTask } from "../../graphql/mutations";
import {
  listBoards,
  listCoachNames,
  getCoachName,
} from "../../graphql/queries";
import toast from "react-hot-toast";
import { fetchAllPaginatedResults } from "../../utils/pagination";

const TaskCreatePartial = (props) => {
  const {
    isOpen,
    setIsOpen,
    boardKey,
    onTaskCreated,
    currentUser,
    createColumnKey,
  } = props;
  const [coaches, setCoaches] = useState([]);
  const [coachDetails, setCoachDetails] = useState({});
  const [boardId, setBoardId] = useState(null);

  useEffect(() => {
    fetchBoard(boardKey);
    fetchCoaches();
  }, [boardKey]);

  const fetchBoard = async (key) => {
    try {
      const boardData = await fetchAllPaginatedResults(listBoards, {
        filter: { key: { eq: key } },
      });
      const board = boardData[0];
      if (board) {
        setBoardId(board.id);
      } else {
        toast.error("Board not found");
        setIsOpen(false);
      }
    } catch (error) {
      console.error("Error fetching board:", error);
      toast.error("Failed to fetch board");
      setIsOpen(false);
    }
  };

  const fetchCoaches = async () => {
    try {
      const coachData = await fetchAllPaginatedResults(listCoachNames);
      const coachDetailsMap = {};
      for (const coach of coachData) {
        const coachNameData = await API.graphql(
          graphqlOperation(getCoachName, { id: coach.id }),
        );
        console.log("coachNameData", coachNameData);
        coachDetailsMap[coach.id] = {
          name: coachNameData.data.getCoachName.CoachName,
          email: coachNameData.data.getCoachName.userEmail,
        };
      }
      setCoachDetails(coachDetailsMap);
      setCoaches(coachData);
    } catch (error) {
      console.error("Error fetching coaches:", error);
      toast.error("Failed to fetch coaches");
    }
  };

  const statusOptions = [
    { label: "Backlog", value: "BACKLOG" },
    { label: "To Do", value: "TODO" },
    { label: "Pending", value: "PENDING" },
    { label: "Run", value: "RUN" },
    { label: "Done", value: "DONE" },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    status: Yup.object().required("Status is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      status:
        statusOptions.find((option) => option.value === createColumnKey) ||
        statusOptions[0],
      label: "",
      assignedTo: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!boardId) {
        toast.error("Board not found");
        return;
      }
      try {
        const input = {
          title: values.title,
          description: JSON.stringify(values.description),
          status: values.status.value,
          label: values.label,
          boardTasksId: boardId,
          assignedTo: values.assignedTo ? values.assignedTo : null,
          creator: currentUser.id,
        };
        const result = await API.graphql(
          graphqlOperation(createTask, { input }),
        );

        const createdTask = {
          ...result.data.createTask,
          assignedTo: input.assignedTo,
          creator: input.creator,
        };

        onTaskCreated(createdTask);
        setIsOpen(false);
        toast.success("Task created successfully");
      } catch (error) {
        console.error("Error creating task:", error);
        if (error.errors) {
          error.errors.forEach((err) => toast.error(err.message));
        } else {
          toast.error("Failed to create task");
        }
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isCentered={true}
      isScrollable={true}
    >
      <ModalHeader>Create a new task</ModalHeader>
      <ModalBody>
        <div className="flex flex-col gap-y-2">
          <div>
            <Label htmlFor="taskStatus" description="Task status">
              Status
            </Label>
            <div className="w-60">
              <SelectReact
                options={statusOptions}
                menuPlacement="auto"
                menuPosition="fixed"
                name="status"
                value={formik.values.status}
                onChange={(option) => formik.setFieldValue("status", option)}
                onBlur={formik.handleBlur}
                isSearchable={false}
              />
            </div>
            {formik.touched.status && formik.errors.status && (
              <div className="text-red-500">{formik.errors.status}</div>
            )}
          </div>
          <div>
            <Label htmlFor="taskTitle">Title</Label>
            <Input
              type="text"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              placeholder="Enter Task Title ..."
              autoComplete="off"
            />
            {formik.touched.title && formik.errors.title && (
              <div className="text-red-500">{formik.errors.title}</div>
            )}
          </div>
          <div>
            <Label htmlFor="taskDescription">Description</Label>
            <RichText
              id="description"
              value={formik.values.description}
              handleChange={(event) => {
                formik.setFieldValue("description", event);
              }}
            />
          </div>
          <div>
            <Label
              htmlFor="taskResponsible"
              description="Assign responsible person for the task"
            >
              Responsible
            </Label>
            <div className="w-60">
              <SelectReact
                options={coaches.map((coach) => ({
                  label: coachDetails[coach.id]?.name || coach.id,
                  value: coach.id,
                }))}
                menuPlacement="auto"
                menuPosition="fixed"
                name="assignedTo"
                value={
                  formik.values.assignedTo
                    ? {
                        label:
                          coachDetails[formik.values.assignedTo]?.name ||
                          formik.values.assignedTo,
                        value: formik.values.assignedTo,
                      }
                    : null
                }
                onChange={(option) =>
                  formik.setFieldValue(
                    "assignedTo",
                    option ? option.value : null,
                  )
                }
                isSearchable={true}
              />
            </div>
          </div>
          <div>
            <Label htmlFor="taskLabel">Label</Label>
            <Input
              type="text"
              name="label"
              onChange={formik.handleChange}
              value={formik.values.label}
              placeholder="Enter Task Label ..."
              autoComplete="off"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="[&&]:justify-end">
        <ModalFooterChild>
          <Button color="red" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? "Creating..." : "Create"}
          </Button>
        </ModalFooterChild>
      </ModalFooter>
    </Modal>
  );
};

export default TaskCreatePartial;

import { API } from "aws-amplify";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { getAllForms } from "../graphql/queries";
import Chart from "react-apexcharts";
import {
  listFormScores,
  listOperationNames,
  listCrewNames,
  listDepartmentNames,
} from "../graphql/queries";
import Stats from "./Stats";
// import Moskit from "../Moskit";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Loader } from "@aws-amplify/ui-react";

// CSS imports
import "react-datepicker/dist/react-datepicker.css";
import "../ReportScreen.css";
import { fetchAllPaginatedResults } from "../utils/pagination";
import InteractiveSvg from "./InteractiveSvg";

import React, { useEffect, useState } from "react";

const InteractiveMap = (props) => {
  const {
    selectedCoach,
    selectedOperation,
    selectedDepartment,
    selectedCrew,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setOperations,
    setDepartments,
    setCrews,
    operations,
    departments,
    crews,
  } = props;
  const [fillArr, setFillArr] = useState({
    preStart: "white",
    shiftHandover: "white",
    preShiftAlignment: "white",
    quarterlyMonthlyPlan: "white",
    monthlyPlanningMeeting: "white",
    weeklyPlanSchedule: "white",
    weeklyPrePlanning: "white",
    weeklySitePlanningMeeting: "white",
    weeklyCommitmentMeeting: "white",
    dailyCommitmentMeeting: "white",
    shiftPlan: "white",
    shortIntervalControl: "white",
    shiftReport: "white",
    shiftDebrief: "white",
    performanceReports: "white",
    departmentDailyReview: "white",
    dailySiteReview: "white",
    performanceAnalysis: "white",
    weeklyDepartmentReview: "white",
    weeklySiteReview: "white",
    managementReports: "white",
    monthlyReview: "white",
    quarterlyForecastReview: "white",
    actionLog: "white",
    businessImprovementCapitalProjects: "white",
  });
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedForm, setSelectedForm] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const [FormScoresData, setFormScoresData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  function transformValuetoColor(num) {
    num = Number(num);
    if (num >= 0 && num < 0.5) {
      return "red";
    } else if (num >= 0.5 && num < 1.5) {
      return "orange";
    } else if (num >= 1.5 && num <= 2.5) {
      return "lightyellow";
    } else if (num >= 2.5 && num <= 3) {
      return "lightgreen";
    } else {
      return "white"; // For 'Missing' or any unrecognized num
    }
  }

  const [preStartWeight, setPreStartWeight] = useState(null);
  const [shiftHandoverWeight, setShiftHandoverWeight] = useState(null);
  const [preShiftAlignmentWeight, setPreShiftAlignmentWeight] = useState(null);
  const [quarterlyMonthlyPlanWeight, setQuarterlyMonthlyPlanWeight] =
    useState(null);
  const [monthlyPlanningMeetingWeight, setMonthlyPlanningMeetingWeight] =
    useState(null);
  const [weeklyPlanScheduleWeight, setWeeklyPlanScheduleWeight] =
    useState(null);
  const [weeklyPrePlanningWeight, setWeeklyPrePlanningWeight] = useState(null);
  const [weeklySitePlanningMeetingWeight, setWeeklySitePlanningMeetingWeight] =
    useState(null);
  const [weeklyCommitmentMeetingWeight, setWeeklyCommitmentMeetingWeight] =
    useState(null);
  const [dailyCommitmentMeetingWeight, setDailyCommitmentMeetingWeight] =
    useState(null);
  const [shiftplanWeight, setShiftplanWeight] = useState(null);
  const [shortIntervalControlWeight, setShortIntervalControlWeight] =
    useState(null);
  const [shiftReportWeight, setShiftReportWeight] = useState(null);
  const [shiftDebriefWeight, setShiftDebriefWeight] = useState(null);
  const [performanceReportsWeight, setPerformanceReportsWeight] =
    useState(null);
  const [departmentDailyReviewWeight, setDepartmentDailyReviewWeight] =
    useState(null);
  const [dailySiteReviewWeight, setDailySiteReviewWeight] = useState(null);
  const [performanceAnalysisWeight, setPerformanceAnalysisWeight] =
    useState(null);
  const [weeklyDepartmentReviewWeight, setWeeklyDepartmentReviewWeight] =
    useState(null);
  const [weeklySiteReviewWeight, setWeeklySiteReviewWeight] = useState(null);
  const [managementReportsWeight, setManagementReportsWeight] = useState(null);
  const [monthlyReviewWeight, setMonthlyReviewWeight] = useState(null);
  const [quarterlyForecastReviewWeight, setQuarterlyForecastReviewWeight] =
    useState(null);
  const [actionLogWeight, setActionLogWeight] = useState(null);
  const [
    businessImprovementCapitalProjectsWeight,
    setBusinessImprovementCapitalProjectsWeight,
  ] = useState(null);

  const [preStartLength, setPreStartLength] = useState(null);
  const [shiftHandoverLength, setShiftHandoverLength] = useState(null);
  const [preShiftAlignmentLength, setPreShiftAlignmentLength] = useState(null);
  const [quarterlyMonthlyPlanLength, setQuarterlyMonthlyPlanLength] =
    useState(null);
  const [monthlyPlanningMeetingLength, setMonthlyPlanningMeetingLength] =
    useState(null);
  const [weeklyPlanScheduleLength, setWeeklyPlanScheduleLength] =
    useState(null);
  const [weeklyPrePlanningLength, setWeeklyPrePlanningLength] = useState(null);
  const [weeklySitePlanningMeetingLength, setWeeklySitePlanningMeetingLength] =
    useState(null);
  const [weeklyCommitmentMeetingLength, setWeeklyCommitmentMeetingLength] =
    useState(null);
  const [dailyCommitmentMeetingLength, setDailyCommitmentMeetingLength] =
    useState(null);
  const [shiftplanLength, setShiftplanLength] = useState(null);
  const [shortIntervalControlLength, setShortIntervalControlLength] =
    useState(null);
  const [shiftReportLength, setShiftReportLength] = useState(null);
  const [shiftDebriefLength, setShiftDebriefLength] = useState(null);
  const [performanceReportsLength, setPerformanceReportsLength] =
    useState(null);
  const [departmentDailyReviewLength, setDepartmentDailyReviewLength] =
    useState(null);
  const [dailySiteReviewLength, setDailySiteReviewLength] = useState(null);
  const [performanceAnalysisLength, setPerformanceAnalysisLength] =
    useState(null);
  const [weeklyDepartmentReviewLength, setWeeklyDepartmentReviewLength] =
    useState(null);
  const [weeklySiteReviewLength, setWeeklySiteReviewLength] = useState(null);
  const [managementReportsLength, setManagementReportsLength] = useState(null);
  const [monthlyReviewLength, setMonthlyReviewLength] = useState(null);
  const [quarterlyForecastReviewLength, setQuarterlyForecastReviewLength] =
    useState(null);
  const [actionLogLength, setActionLogLength] = useState(null);
  const [
    businessImprovementCapitalProjectsLength,
    setBusinessImprovementCapitalProjectsLength,
  ] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState(0);
  const [hasFetchedData, setHasFetchedData] = useState(false); // New state variable

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      toast.loading("Loading Colors...");
      const currentSelectedCoach = selectedCoach;
      const currentSelectedOperation = selectedOperation;
      const currentSelectedDepartment = selectedDepartment;
      const currentSelectedCrew = selectedCrew;
      const currentStartDate = startDate;
      const currentEndDate = endDate;
      try {
        // Initialize an empty filter object
        let filter = {};
        // Conditionally add filters
        if (selectedCrew && selectedCrew.trim() !== "") {
          filter.CrewName = { contains: selectedCrew };
        }
        if (selectedOperation && selectedOperation.trim() !== "") {
          filter.OperationName = {
            contains: selectedOperation,
          };
        }
        if (selectedDepartment && selectedDepartment.trim() !== "") {
          filter.DepartmentName = {
            contains: selectedDepartment,
          };
        }
        if (startDate && !endDate) {
          filter.FormDate = { ge: startDate }; // dates greater than or equal to startDate
        }
        if (!startDate && endDate) {
          filter.FormDate = { le: endDate }; // dates less than or equal to endDate
        }
        if (startDate && endDate) {
          filter.FormDate = { between: [startDate, endDate] }; // dates between startDate and endDate
        }
        const InfoPromise = API.graphql({
          query: getAllForms,
          variables: { id: "aa703c84-de86-4898-bcb3-0d7bd68f0886" },
        });
        const FormScoresPromise = await fetchAllPaginatedResults(
          listFormScores,
          {
            filter: filter,
          },
        );
        const operationsPromise = fetchAllPaginatedResults(listOperationNames);
        const crewsPromise = fetchAllPaginatedResults(listCrewNames);
        const departmentsPromise =
          fetchAllPaginatedResults(listDepartmentNames);
        const [
          InfoResponse,
          FormScoresResponse,
          operationsResponse,
          crewsResponse,
          departmentsResponse,
        ] = await Promise.all([
          InfoPromise,
          FormScoresPromise,
          operationsPromise,
          crewsPromise,
          departmentsPromise,
        ]);
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field0
        ) {
          let weights = InfoResponse.data.getAllForms.Field0.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setQuarterlyMonthlyPlanWeight(totalWeight);
          setQuarterlyMonthlyPlanLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field1
        ) {
          let weights = InfoResponse.data.getAllForms.Field1.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setMonthlyPlanningMeetingWeight(totalWeight);
          setMonthlyPlanningMeetingLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field2
        ) {
          let weights = InfoResponse.data.getAllForms.Field2.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setWeeklyPlanScheduleWeight(totalWeight);
          setWeeklyPlanScheduleLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field3
        ) {
          let weights = InfoResponse.data.getAllForms.Field3.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setWeeklyPrePlanningWeight(totalWeight);
          setWeeklyPrePlanningLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field4
        ) {
          let weights = InfoResponse.data.getAllForms.Field4.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setWeeklySitePlanningMeetingWeight(totalWeight);
          setWeeklySitePlanningMeetingLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field5
        ) {
          let weights = InfoResponse.data.getAllForms.Field5.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setWeeklyCommitmentMeetingWeight(totalWeight);
          setWeeklyCommitmentMeetingLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field6
        ) {
          let weights = InfoResponse.data.getAllForms.Field6.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setDailyCommitmentMeetingWeight(totalWeight);
          setDailyCommitmentMeetingLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field7
        ) {
          let weights = InfoResponse.data.getAllForms.Field7.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setShiftplanWeight(totalWeight);
          setShiftplanLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field8
        ) {
          let weights = InfoResponse.data.getAllForms.Field8.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setShiftHandoverWeight(totalWeight);
          setShiftHandoverLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field9
        ) {
          let weights = InfoResponse.data.getAllForms.Field9.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setPreStartWeight(totalWeight);
          setPreStartLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field10
        ) {
          let weights = InfoResponse.data.getAllForms.Field10.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setShortIntervalControlWeight(totalWeight);
          setShortIntervalControlLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field11
        ) {
          let weights = InfoResponse.data.getAllForms.Field11.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setShiftReportWeight(totalWeight);
          setShiftReportLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field12
        ) {
          let weights = InfoResponse.data.getAllForms.Field12.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setShiftDebriefWeight(totalWeight);
          setShiftDebriefLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field13
        ) {
          let weights = InfoResponse.data.getAllForms.Field13.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setPerformanceReportsWeight(totalWeight);
          setPerformanceReportsLength(weights.length);
        }

        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field14
        ) {
          let weights = InfoResponse.data.getAllForms.Field14.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setDepartmentDailyReviewWeight(totalWeight);
          setDepartmentDailyReviewLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field15
        ) {
          let weights = InfoResponse.data.getAllForms.Field15.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setDailySiteReviewWeight(totalWeight);
          setDailySiteReviewLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field16
        ) {
          let weights = InfoResponse.data.getAllForms.Field16.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setPerformanceAnalysisWeight(totalWeight);
          setPerformanceAnalysisLength(weights.length);
        }

        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field17
        ) {
          let weights = InfoResponse.data.getAllForms.Field17.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setWeeklyDepartmentReviewWeight(totalWeight);
          setWeeklyDepartmentReviewLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field18
        ) {
          let weights = InfoResponse.data.getAllForms.Field18.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setWeeklySiteReviewWeight(totalWeight);
          setWeeklySiteReviewLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field19
        ) {
          let weights = InfoResponse.data.getAllForms.Field19.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setManagementReportsWeight(totalWeight);
          setManagementReportsLength(weights.length);
        }

        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field20
        ) {
          let weights = InfoResponse.data.getAllForms.Field20.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setMonthlyReviewWeight(totalWeight);
          setMonthlyReviewLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field21
        ) {
          let weights = InfoResponse.data.getAllForms.Field21.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setQuarterlyForecastReviewWeight(totalWeight);
          setQuarterlyForecastReviewLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field22
        ) {
          let weights = InfoResponse.data.getAllForms.Field22.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setActionLogWeight(totalWeight);
          setActionLogLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field23
        ) {
          let weights = InfoResponse.data.getAllForms.Field23.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setBusinessImprovementCapitalProjectsWeight(totalWeight);
          setBusinessImprovementCapitalProjectsLength(weights.length);
        }
        if (
          InfoResponse &&
          InfoResponse.data &&
          InfoResponse.data.getAllForms &&
          InfoResponse.data.getAllForms.Field24
        ) {
          let weights = InfoResponse.data.getAllForms.Field24.weights || [];
          let totalWeight = weights.reduce(
            (total, weight) => total + weight,
            0,
          );
          setPreShiftAlignmentWeight(totalWeight);
          setPreShiftAlignmentLength(weights.length);
        }
        setDepartments(departmentsResponse);
        setOperations(operationsResponse);
        setCrews(crewsResponse);
        setFormScoresData(FormScoresResponse);
        setHasFetchedData(true);
        setIsLoading(false);
        if (FormScoresResponse) {
          let preStartData = FormScoresResponse.filter(
            (item) => item.FormName === "preStart",
          );
          let preStartSum = preStartData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let preStartAverage = transformValuetoColor(
            preStartSum / preStartData.length,
          );

          let shiftHandoverData = FormScoresResponse.filter(
            (item) => item.FormName === "shiftHandover",
          );
          let shiftHandoverSum = shiftHandoverData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let shiftHandoverAverage = transformValuetoColor(
            shiftHandoverSum / shiftHandoverData.length,
          );
          let preShiftAlignmentData = FormScoresResponse.filter(
            (item) => item.FormName === "preShiftAlignment",
          );
          let preShiftAlignmentSum = preShiftAlignmentData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let preShiftAlignmentAverage = transformValuetoColor(
            preShiftAlignmentSum / preShiftAlignmentData.length,
          );
          let quarterlyMonthlyPlanData = FormScoresResponse.filter(
            (item) => item.FormName === "quarterlyMonthlyPlan",
          );
          let quarterlyMonthlyPlanSum = quarterlyMonthlyPlanData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let quarterlyMonthlyPlanAverage = transformValuetoColor(
            quarterlyMonthlyPlanSum / quarterlyMonthlyPlanData.length,
          );
          let monthlyPlanningMeetingData = FormScoresResponse.filter(
            (item) => item.FormName === "monthlyPlanningMeeting",
          );
          let monthlyPlanningMeetingSum = monthlyPlanningMeetingData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let monthlyPlanningMeetingAverage = transformValuetoColor(
            monthlyPlanningMeetingSum / monthlyPlanningMeetingData.length,
          );
          let weeklyPlanScheduleData = FormScoresResponse.filter(
            (item) => item.FormName === "weeklyPlanSchedule",
          );
          let weeklyPlanScheduleSum = weeklyPlanScheduleData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let weeklyPlanScheduleAverage = transformValuetoColor(
            weeklyPlanScheduleSum / weeklyPlanScheduleData.length,
          );
          let weeklyPrePlanningData = FormScoresResponse.filter(
            (item) => item.FormName === "weeklyPrePlanning",
          );
          let weeklyPrePlanningSum = weeklyPrePlanningData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let weeklyPrePlanningAverage = transformValuetoColor(
            weeklyPrePlanningSum / weeklyPrePlanningData.length,
          );
          let weeklySitePlanningMeetingData = FormScoresResponse.filter(
            (item) => item.FormName === "weeklySitePlanningMeeting",
          );
          let weeklySitePlanningMeetingSum =
            weeklySitePlanningMeetingData.reduce(
              (total, item) => total + item.WeightedScore,
              0,
            );
          let weeklySitePlanningMeetingAverage = transformValuetoColor(
            weeklySitePlanningMeetingSum / weeklySitePlanningMeetingData.length,
          );
          let weeklyCommitmentMeetingData = FormScoresResponse.filter(
            (item) => item.FormName === "weeklyCommitmentMeeting",
          );
          let weeklyCommitmentMeetingSum = weeklyCommitmentMeetingData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let weeklyCommitmentMeetingAverage = transformValuetoColor(
            weeklyCommitmentMeetingSum / weeklyCommitmentMeetingData.length,
          );
          let dailyCommitmentMeetingData = FormScoresResponse.filter(
            (item) => item.FormName === "dailyCommitmentMeeting",
          );
          let dailyCommitmentMeetingSum = dailyCommitmentMeetingData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let dailyCommitmentMeetingAverage = transformValuetoColor(
            dailyCommitmentMeetingSum / dailyCommitmentMeetingData.length,
          );
          let shiftPlanData = FormScoresResponse.filter(
            (item) => item.FormName === "shiftPlan",
          );
          let shiftPlanSum = shiftPlanData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let shiftPlanAverage = transformValuetoColor(
            shiftPlanSum / shiftPlanData.length,
          );
          let shortIntervalControlData = FormScoresResponse.filter(
            (item) => item.FormName === "shortIntervalControl",
          );
          let shortIntervalControlSum = shortIntervalControlData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let shortIntervalControlAverage = transformValuetoColor(
            shortIntervalControlSum / shortIntervalControlData.length,
          );
          let shiftReportData = FormScoresResponse.filter(
            (item) => item.FormName === "shiftReport",
          );
          let shiftReportSum = shiftReportData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let shiftReportAverage = transformValuetoColor(
            shiftReportSum / shiftReportData.length,
          );
          let shiftDebriefData = FormScoresResponse.filter(
            (item) => item.FormName === "shiftDebrief",
          );
          let shiftDebriefSum = shiftDebriefData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let shiftDebriefAverage = transformValuetoColor(
            shiftDebriefSum / shiftDebriefData.length,
          );
          let performanceReportsData = FormScoresResponse.filter(
            (item) => item.FormName === "performanceReports",
          );
          let performanceReportsSum = performanceReportsData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let performanceReportsAverage = transformValuetoColor(
            performanceReportsSum / performanceReportsData.length,
          );
          let departmentDailyReviewData = FormScoresResponse.filter(
            (item) => item.FormName === "departmentDailyReview",
          );
          let departmentDailyReviewSum = departmentDailyReviewData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let departmentDailyReviewAverage = transformValuetoColor(
            departmentDailyReviewSum / departmentDailyReviewData.length,
          );
          let dailySiteReviewData = FormScoresResponse.filter(
            (item) => item.FormName === "dailySiteReview",
          );
          let dailySiteReviewSum = dailySiteReviewData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let dailySiteReviewAverage = transformValuetoColor(
            dailySiteReviewSum / dailySiteReviewData.length,
          );
          let performanceAnalysisData = FormScoresResponse.filter(
            (item) => item.FormName === "performanceAnalysis",
          );
          let performanceAnalysisSum = performanceAnalysisData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let performanceAnalysisAverage = transformValuetoColor(
            performanceAnalysisSum / performanceAnalysisData.length,
          );
          let weeklyDepartmentReviewData = FormScoresResponse.filter(
            (item) => item.FormName === "weeklyDepartmentReview",
          );
          let weeklyDepartmentReviewSum = weeklyDepartmentReviewData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let weeklyDepartmentReviewAverage = transformValuetoColor(
            weeklyDepartmentReviewSum / weeklyDepartmentReviewData.length,
          );
          let weeklySiteReviewData = FormScoresResponse.filter(
            (item) => item.FormName === "weeklySiteReview",
          );
          let weeklySiteReviewSum = weeklySiteReviewData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let weeklySiteReviewAverage = transformValuetoColor(
            weeklySiteReviewSum / weeklySiteReviewData.length,
          );
          let managementReportsData = FormScoresResponse.filter(
            (item) => item.FormName === "managementReports",
          );
          let managementReportsSum = managementReportsData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let managementReportsAverage = transformValuetoColor(
            managementReportsSum / managementReportsData.length,
          );
          let monthlyReviewData = FormScoresResponse.filter(
            (item) => item.FormName === "monthlyReview",
          );
          let monthlyReviewSum = monthlyReviewData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let monthlyReviewAverage = transformValuetoColor(
            monthlyReviewSum / monthlyReviewData.length,
          );
          let quarterlyForecastReviewData = FormScoresResponse.filter(
            (item) => item.FormName === "quarterlyForecastReview",
          );
          let quarterlyForecastReviewSum = quarterlyForecastReviewData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let quarterlyForecastReviewAverage = transformValuetoColor(
            quarterlyForecastReviewSum / quarterlyForecastReviewData.length,
          );
          let actionLogData = FormScoresResponse.filter(
            (item) => item.FormName === "actionLog",
          );
          let actionLogSum = actionLogData.reduce(
            (total, item) => total + item.WeightedScore,
            0,
          );
          let actionLogAverage = transformValuetoColor(
            actionLogSum / actionLogData.length,
          );
          let businessImprovementCapitalProjectsData =
            FormScoresResponse.filter(
              (item) => item.FormName === "businessImprovementCapitalProjects",
            );
          let businessImprovementCapitalProjectsSum =
            businessImprovementCapitalProjectsData.reduce(
              (total, item) => total + item.WeightedScore,
              0,
            );
          let businessImprovementCapitalProjectsAverage = transformValuetoColor(
            businessImprovementCapitalProjectsSum /
              businessImprovementCapitalProjectsData.length,
          );

          setFillArr((prevState) => ({
            ...prevState,
            preStart: preStartAverage,
            shiftHandover: shiftHandoverAverage,
            preShiftAlignment: preShiftAlignmentAverage,
            quarterlyMonthlyPlan: quarterlyMonthlyPlanAverage,
            monthlyPlanningMeeting: monthlyPlanningMeetingAverage,
            weeklyPlanSchedule: weeklyPlanScheduleAverage,
            weeklyPrePlanning: weeklyPrePlanningAverage,
            weeklySitePlanningMeeting: weeklySitePlanningMeetingAverage,
            weeklyCommitmentMeeting: weeklyCommitmentMeetingAverage,
            dailyCommitmentMeeting: dailyCommitmentMeetingAverage,
            shiftPlan: shiftPlanAverage,
            shortIntervalControl: shortIntervalControlAverage,
            shiftReport: shiftReportAverage,
            shiftDebrief: shiftDebriefAverage,
            performanceReports: performanceReportsAverage,
            departmentDailyReview: departmentDailyReviewAverage,
            dailySiteReview: dailySiteReviewAverage,
            performanceAnalysis: performanceAnalysisAverage,
            weeklyDepartmentReview: weeklyDepartmentReviewAverage,
            weeklySiteReview: weeklySiteReviewAverage,
            managementReports: managementReportsAverage,
            monthlyReview: monthlyReviewAverage,
            quarterlyForecastReview: quarterlyForecastReviewAverage,
            actionLog: actionLogAverage,
            businessImprovementCapitalProjects:
              businessImprovementCapitalProjectsAverage,
          }));
        }
        if (
          selectedCoach === currentSelectedCoach &&
          selectedOperation === currentSelectedOperation &&
          selectedDepartment === currentSelectedDepartment &&
          startDate === currentStartDate &&
          selectedCrew === currentSelectedCrew &&
          endDate === currentEndDate
        ) {
          setFormScoresData((prevState) => ({
            ...prevState,
            ...FormScoresResponse,
          }));
        }
        toast.dismiss();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [
    endDate,
    selectedCoach,
    selectedOperation,
    selectedDepartment,
    selectedCrew,
    startDate,
    hasFetchedData,
  ]);

  useEffect(() => {
    let path = location.pathname;
    // Set selectedForm based on URL path
    const validPaths = [
      "shiftHandover",
      "preStart",
      "preShiftAlignment",
      "quarterlyMonthlyPlan",
      "monthlyPlanningMeeting",
      "weeklyPlanSchedule",
      "weeklyPrePlanning",
      "weeklySitePlanningMeeting",
      "weeklyCommitmentMeeting",
      "dailyCommitmentMeeting",
      "shiftPlan",
      "shortIntervalControl",
      "shiftReport",
      "shiftDebrief",
      "performanceReports",
      "departmentDailyReview",
      "dailySiteReview",
      "performanceAnalysis",
      "weeklyDepartmentReview",
      "weeklySiteReview",
      "managementReports",
      "monthlyReview",
      "quarterlyForecastReview",
      "actionLog",
      "businessImprovementCapitalProjects",
    ];
    if (path.includes("/reports")) {
      path = location.pathname.replace("/reports/", "");
      if (!validPaths.includes(path)) {
        navigate("/", { replace: true });
      }
    }
    if (path.includes("/forms")) {
      path = location.pathname.replace("/forms/", "");
      if (!validPaths.includes(path)) {
        navigate("/", { replace: true });
      }
    }
  }, [location.pathname, navigate]);

  const handleReportSelection = (report) => {
    setSelectedReport(reports[report].slug);
    navigate(`/reports/${reports[report].slug}`);
    setTimeout(() => {
      const element = document.getElementById("reports");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const handleFormSelection = (form) => {
    setSelectedForm(forms[form].slug);
    navigate(`/forms/${forms[form].slug}`);
  };

  let reports = {
    _1link: {
      id: "1",
      name: "Quarterly/Monthly Plan",
      slug: "quarterlyMonthlyPlan",
      fill: fillArr.quarterlyMonthlyPlan
        ? fillArr.quarterlyMonthlyPlan
        : "white",
      weight: quarterlyMonthlyPlanWeight,
      length: quarterlyMonthlyPlanLength,
    },
    _1alink: {
      id: "1a",
      name: "Monthly Planning Meeting",
      slug: "monthlyPlanningMeeting",
      fill: fillArr.monthlyPlanningMeeting
        ? fillArr.monthlyPlanningMeeting
        : "white",
      weight: monthlyPlanningMeetingWeight,
      length: monthlyPlanningMeetingLength,
    },
    _2link: {
      id: "2",
      name: "Weekly Plan/Schedule",
      slug: "weeklyPlanSchedule",
      fill: fillArr.weeklyPlanSchedule ? fillArr.weeklyPlanSchedule : "white",
      weight: weeklyPlanScheduleWeight,
      length: weeklyPlanScheduleLength,
    },
    _2alink: {
      id: "2a",
      name: "Weekly Pre-Planning",
      slug: "weeklyPrePlanning",
      fill: fillArr.weeklyPrePlanning ? fillArr.weeklyPrePlanning : "white",
      weight: weeklyPrePlanningWeight,
      length: weeklyPrePlanningLength,
    },
    _2blink: {
      id: "2b",
      name: "Weekly Site Planning Meeting",
      slug: "weeklySitePlanningMeeting",
      fill: fillArr.weeklySitePlanningMeeting
        ? fillArr.weeklySitePlanningMeeting
        : "white",
      weight: weeklySitePlanningMeetingWeight,
      length: weeklySitePlanningMeetingLength,
    },
    _2clink: {
      id: "2c",
      name: "Weekly Commitment Meeting",
      slug: "weeklyCommitmentMeeting",
      fill: fillArr.weeklyCommitmentMeeting
        ? fillArr.weeklyCommitmentMeeting
        : "white",
      weight: weeklyCommitmentMeetingWeight,
      length: weeklyCommitmentMeetingLength,
    },
    _3link: {
      id: "3",
      name: "Shift Plan",
      slug: "shiftPlan",
      fill: fillArr.shiftPlan ? fillArr.shiftPlan : "white",
      weight: shiftplanWeight,
      length: shiftplanLength,
    },
    _3alink: {
      id: "3a",
      name: "Daily Commitment Meeting",
      slug: "dailyCommitmentMeeting",
      fill: fillArr.dailyCommitmentMeeting
        ? fillArr.dailyCommitmentMeeting
        : "white",
      weight: dailyCommitmentMeetingWeight,
      length: dailyCommitmentMeetingLength,
    },
    _3blink: {
      id: "3b",
      name: "Shift Handover",
      slug: "shiftHandover",
      fill: fillArr.shiftHandover ? fillArr.shiftHandover : "white",
      weight: shiftHandoverWeight,
      length: shiftHandoverLength,
    },
    _3clink: {
      id: "3c",
      name: "Pre-Shift Alignment",
      slug: "preShiftAlignment",
      fill: fillArr.preShiftAlignment ? fillArr.preShiftAlignment : "white",
      weight: preShiftAlignmentWeight,
      length: preShiftAlignmentLength,
    },
    _4link: {
      id: "4",
      name: "Shift Report",
      slug: "shiftReport",
      fill: fillArr.shiftReport ? fillArr.shiftReport : "white",
      weight: shiftReportWeight,
      length: shiftReportLength,
    },
    _4alink: {
      id: "4a",
      name: "Pre-Start Meeting",
      slug: "preStart",
      fill: fillArr.preStart ? fillArr.preStart : "white",
      weight: preStartWeight,
      length: preStartLength,
    },
    _4blink: {
      id: "4b",
      name: "Short Interval Control",
      slug: "shortIntervalControl",
      fill: fillArr.shortIntervalControl
        ? fillArr.shortIntervalControl
        : "white",
      weight: shortIntervalControlWeight,
      length: shortIntervalControlLength,
    },
    //doesnt exist _4c and 6c
    _4dlink: {
      id: "4d",
      name: "Shift Debrief",
      slug: "shiftDebrief",
      fill: fillArr.shiftDebrief ? fillArr.shiftDebrief : "white",
      weight: shiftDebriefWeight,
      length: shiftDebriefLength,
    },
    _5link: {
      id: "5",
      name: "Performance Reports",
      slug: "performanceReports",
      fill: fillArr.performanceReports ? fillArr.performanceReports : "white",
      weight: performanceReportsWeight,
      length: performanceReportsLength,
    },
    _5alink: {
      id: "5a",
      name: "Department Daily Review",
      slug: "departmentDailyReview",
      fill: fillArr.departmentDailyReview
        ? fillArr.departmentDailyReview
        : "white",
      weight: departmentDailyReviewWeight,
      length: departmentDailyReviewLength,
    },
    _5blink: {
      id: "5b",
      name: "Daily Site Review",
      slug: "dailySiteReview",
      fill: fillArr.dailySiteReview ? fillArr.dailySiteReview : "white",
      weight: dailySiteReviewWeight,
      length: dailySiteReviewLength,
    },
    _5clink: {
      id: "5e",
      name: "Performance Analysis",
      slug: "performanceAnalysis",
      fill: fillArr.performanceAnalysis ? fillArr.performanceAnalysis : "white",
      weight: performanceAnalysisWeight,
      length: performanceAnalysisLength,
    },
    _5elink: {
      id: "5d",
      name: "Weekly Site Review",
      slug: "weeklySiteReview",
      fill: fillArr.weeklySiteReview ? fillArr.weeklySiteReview : "white",
      weight: weeklySiteReviewWeight,
      length: weeklySiteReviewLength,
    },
    _5dlink: {
      id: "5c",
      name: "Weekly Department Review",
      slug: "weeklyDepartmentReview",
      fill: fillArr.weeklyDepartmentReview
        ? fillArr.weeklyDepartmentReview
        : "white",
      weight: weeklyDepartmentReviewWeight,
      length: weeklyDepartmentReviewLength,
    },
    _6link: {
      id: "6",
      name: "Management Reports",
      slug: "managementReports",
      fill: fillArr.managementReports ? fillArr.managementReports : "white",
      weight: managementReportsWeight,
      length: managementReportsLength,
    },
    _6alink: {
      id: "6a",
      name: "Monthly Review",
      slug: "monthlyReview",
      fill: fillArr.monthlyReview ? fillArr.monthlyReview : "white",
      weight: monthlyReviewWeight,
      length: monthlyReviewLength,
    },
    _6blink: {
      id: "6b",
      name: "Quarterly Forecast /  Review",
      slug: "quarterlyForecastReview",
      fill: fillArr.quarterlyForecastReview
        ? fillArr.quarterlyForecastReview
        : "white",
      weight: quarterlyForecastReviewWeight,
      length: quarterlyForecastReviewLength,
    },
    _7link: {
      id: "7",
      name: "Action Log",
      slug: "actionLog",
      fill: fillArr.actionLog ? fillArr.actionLog : "white",
      weight: actionLogWeight,
      length: actionLogLength,
    },
    _8link: {
      id: "8",
      name: "Business Improvement & Capital Projects",
      slug: "businessImprovementCapitalProjects",
      fill: fillArr.businessImprovementCapitalProjects
        ? fillArr.businessImprovementCapitalProjects
        : "white",
      weight: businessImprovementCapitalProjectsWeight,
      length: businessImprovementCapitalProjectsLength,
    },
  };

  const forms = {
    _1: {
      id: "1",
      name: "Quarterly/Monthly Plan",
      slug: "quarterlyMonthlyPlan",
    },
    _1a: {
      id: "1a",
      name: "Monthly Planning Meeting",
      slug: "monthlyPlanningMeeting",
    },
    _2: {
      id: "2",
      name: "Weekly Plan/Schedule",
      slug: "weeklyPlanSchedule",
    },
    _2a: {
      id: "2a",
      name: "Weekly Pre-Planning",
      slug: "weeklyPrePlanning",
    },
    _2b: {
      id: "2b",
      name: "Weekly Site Planning Meeting",
      slug: "weeklySitePlanningMeeting",
    },
    _2c: {
      id: "2c",
      name: "Weekly Commitment Meeting",
      slug: "weeklyCommitmentMeeting",
    },
    _3: {
      id: "3",
      name: "Shift Plan",
      slug: "shiftPlan",
    },
    _3a: {
      id: "3a",
      name: "Daily Commitment Meeting",
      slug: "dailyCommitmentMeeting",
    },
    _3b: {
      id: "3b",
      name: "Shift Handover",
      slug: "shiftHandover",
    },
    _3c: {
      id: "3c",
      name: "Pre-Shift Alignment",
      slug: "preShiftAlignment",
    },
    _4a: {
      id: "4a",
      name: "Pre-Start Meeting",
      slug: "preStart",
    },
    _4: {
      id: "4",
      name: "Shift Report",
      slug: "shiftReport",
    },
    _4b: {
      id: "4b",
      name: "Short Interval Control",
      slug: "shortIntervalControl",
    },
    _4d: {
      id: "4d",
      name: "Shift Debrief",
      slug: "shiftDebrief",
    },
    _5: {
      id: "5",
      name: "Performance Reports",
      slug: "performanceReports",
    },
    _5a: {
      id: "5a",
      name: "Department Daily Review",
      slug: "departmentDailyReview",
    },
    _5b: {
      id: "5b",
      name: "Daily Site Review",
      slug: "dailySiteReview",
    },
    _5c: {
      id: "5c",
      name: "Weekly Department Review",
      slug: "weeklyDepartmentReview",
    },
    _5d: {
      id: "5d",
      name: "Weekly Site Review",
      slug: "weeklySiteReview",
    },
    _5e: {
      id: "5e",
      name: "Performance Analysis",
      slug: "performanceAnalysis",
    },
    _6: {
      id: "6",
      name: "Management Reports",
      slug: "managementReports",
    },
    _6a: {
      id: "6a",
      name: "Monthly Review",
      slug: "monthlyReview",
    },
    _6b: {
      id: "6b",
      name: "Quarterly Forecast /  Review",
      slug: "quarterlyForecastReview",
    },
    _7: {
      id: "7",
      name: "Action Log",
      slug: "actionLog",
    },
    _8: {
      id: "8",
      name: "Business Improvement & Capital Projects",
      slug: "businessImprovementCapitalProjects",
    },
  };
  // Define the links and fill colors
  useEffect(() => {
    // Update each element with new link and fill color
    for (const id in reports) {
      const element = document.getElementById(id);
      if (element && reports) {
        if (reports[id]) {
          if (reports[id].fill) {
            element.style.fill = reports[id].fill; // Set fill color
          }
        }
      }
    }
  }, []);

  // Function to parse the data into a format suitable for the chart
  const parseDataForChart = (data) => {
    if (typeof data !== "object" || Array.isArray(data) || data === null)
      return [];

    return Object.values(data).map((item) => ({
      x: new Date(item.FormDate).getTime(), // Use timestamp for calculations
      label: new Date(item.FormDate).toLocaleDateString(), // Keep formatted date string for display
      y: item.WeightedScore,
    }));
  };

  // Function to calculate monthly averages and counts from data points.
  const calculateMonthlyAverageAndCount = (data) => {
    const monthlyData = {};

    data.forEach((point) => {
      const date = new Date(point.x);
      const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (!monthlyData[yearMonth]) {
        monthlyData[yearMonth] = { sum: 0, count: 0 };
      }

      monthlyData[yearMonth].sum += point.y;
      monthlyData[yearMonth].count += 1;
    });

    const averageData = [];
    const countData = [];

    const allMonths = Object.keys(monthlyData).sort();
    const firstMonth = new Date(allMonths[0]);
    const lastMonth = new Date(allMonths[allMonths.length - 1]);

    for (
      let date = new Date(firstMonth);
      date <= lastMonth;
      date.setMonth(date.getMonth() + 1)
    ) {
      const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const average =
        monthlyData[yearMonth] && monthlyData[yearMonth].sum !== 0
          ? monthlyData[yearMonth].sum / monthlyData[yearMonth].count
          : null;

      if (average !== null) {
        averageData.push({
          x: Date.UTC(date.getFullYear(), date.getMonth(), 15),
          y: average,
        });
        countData.push({
          x: Date.UTC(date.getFullYear(), date.getMonth(), 15),
          y: monthlyData[yearMonth].count,
        });
      }
    }

    return { averageData, countData };
  };

  // Prepare Scatter, Trendline, and Count Data
  const prepareScatterTrendAndCountData = (data) => {
    const scatterData = parseDataForChart(data);

    const { averageData, countData } =
      calculateMonthlyAverageAndCount(scatterData);

    const trendlineData = averageData.map((point) => ({
      x: point.x,
      y: point.y,
    }));

    return [
      {
        name: "Trendline",
        type: "line",
        data: trendlineData,
      },
      {
        name: "Monthly Count",
        type: "scatter",
        data: countData,
      },
    ];
  };

  const TimeSeriesChart = ({ scatterTrendData }) => (
    <div className="flex flex-col flex-grow bg-white px-7.5 col-span-12 xl:col-span-6 my-6 xl:my-0 rounded-2xl shadow xl:overflow-y-scroll">
      <div className="xl:w-full">
        <h7 className="text-black sm:text-xl text-base  mb-5 mx-6">
          MO@T Maturity Trend
        </h7>
        <div id="timeSeries" class="max-w-2xl overflow-hidden mx-auto">
          <Chart
            type="line"
            series={[scatterTrendData[0]]}
            options={{
              chart: {
                height: 800,
                type: "line",
                toolbar: {
                  tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: false,
                    customIcons: [
                      {
                        icon: '<i class="fa fa-sync fa-sm"></i>',
                        index: -1,
                        title: "Reset Zoom",
                        class: "custom-icon",
                        click: function () {
                          setStartDate("");
                          setEndDate("");
                        },
                      },
                    ],
                  },
                },
                events: {
                  zoomed: function (chartContext, zoomedData) {
                    if (zoomedData && zoomedData.xaxis) {
                      const { min, max } = zoomedData.xaxis;

                      const startDate =
                        min !== undefined && !isNaN(min) ? new Date(min) : "";
                      const endDate =
                        max !== undefined && !isNaN(max) ? new Date(max) : "";

                      if (startDate) {
                        setStartDate(startDate);
                      } else {
                        console.warn("Invalid start date:", min);
                      }

                      if (endDate) {
                        setEndDate(endDate);
                      } else {
                        console.warn("Invalid end date:", max);
                      }
                    } else {
                      console.warn("Invalid zoomedData:", zoomedData);
                    }
                  },
                },
              },
              fill: {
                type: "solid",
              },
              markers: {
                size: [6, 0],
              },
              stroke: {
                curve: "smooth",
              },
              tooltip: {
                shared: true,
                intersect: false,
                y: {
                  formatter: function (val) {
                    return val ? val.toFixed(1) : 0;
                  },
                },
                x: {
                  format: "MMM yyyy",
                },
              },
              legend: {
                show: false,
              },
              xaxis: {
                type: "datetime",
                title: {
                  text: "Date", // Add X-axis label here
                },
              },
              yaxis: {
                min: 0,
                max: 3,
                tickAmount: 3,
                title: {
                  text: "Maturity Score", // Add Y-axis label here
                },
                labels: {
                  minWidth: 30,
                  formatter: function (val) {
                    return val.toFixed(1);
                  },
                },
              },
            }}
            height="325"
          />
        </div>
      </div>
    </div>
  );

  const [data, setData] = useState({});
  const [scatterTrendData, setScatterTrendData] = useState([]);

  useEffect(() => {
    setData(FormScoresData);
    setScatterTrendData(prepareScatterTrendAndCountData(FormScoresData));
  }, [FormScoresData]);

  return (
    <div>
      {scatterTrendData.length ? (
        <div className="grid grid-cols-12">
          <Stats scatterTrendData={scatterTrendData} />
          <TimeSeriesChart scatterTrendData={scatterTrendData} />
        </div>
      ) : (
        <Loader />
      )}
      <InteractiveSvg
        key={key}
        handleFormSelection={handleFormSelection}
        handleReportSelection={handleReportSelection}
        reports={reports}
        forms={forms}
      />
    </div>
  );
};
export default InteractiveMap;

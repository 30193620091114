import Question from "./Question";
import React from "react";

export default function QuestionSection({
  questions,
  questionsText,
  comments,
  errors,
  isSubmitting,
  handleQuestionChange,
  handleCommentChange,
  sections,
  viewState,
  weights,
  total,
  nextSection,
  prevSection,
  currentQuestionSection,
  setCurrentQuestionSection,
}) {
  const nextQuestionSection = () => {
    setCurrentQuestionSection(currentQuestionSection + 1);
  };

  const prevQuestionSection = () => {
    if (currentQuestionSection > 0) {
      setCurrentQuestionSection(currentQuestionSection - 1);
    }
  };
  return (
    <div>
      {sections.map((section, index) => {
        if (index === currentQuestionSection) {
          return (
            <section
              key={section.id}
              id={section.id}
              className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10"
            >
              <div className="bg-white shadow-sm ring-1 ring-black/5 rounded-xl">
                <div className="px-4 py-6 sm:p-8">
                  <div className="max-w-7xl space-y-10">
                    <div className="px-4 sm:px-0">
                      <h7 className="sm:text-xl text-base font-semibold leading-7 text-black">
                        {section.title}
                      </h7>
                      <p className="mt-1 sm:text-base text-sm leading-6 text-gray-600">
                        {section.description}
                      </p>
                    </div>

                    <div>
                      {section.questionIds.length === 0 && (
                        <div>
                          There are no questions for this section. Please
                          navigate to either the previous or next sections
                        </div>
                      )}
                      {section.questionIds.map((qId) => (
                        <div key={qId}>
                          <Question
                            value={questions[qId]}
                            weight={weights[qId]}
                            total={total}
                            id={qId}
                            question={questionsText[qId]}
                            name={qId}
                            onChange={handleQuestionChange}
                            viewState={viewState}
                            disabled={isSubmitting}
                            comment={comments[qId]}
                            onCommentChange={handleCommentChange}
                          />
                          {errors[qId] && (
                            <p className="text-red-500 sm:text-lg text-sm italic">
                              {errors[qId]}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end gap-x-6 border-t border-black/10 px-4 py-4 sm:px-8">
                  <a
                    href={`#${section.prevSection}`}
                    className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
                    onClick={
                      section.prevSection === "Company"
                        ? prevSection
                        : prevQuestionSection
                    }
                  >
                    Back
                  </a>
                  <a
                    href={`#${section.nextSection}`}
                    className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
                    onClick={
                      section.nextSection === "Summary"
                        ? nextSection
                        : nextQuestionSection
                    }
                  >
                    Next
                  </a>
                </div>
              </div>
            </section>
          );
        }
        return null;
      })}
    </div>
  );
}

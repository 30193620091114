import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { createActionLog, createFormScore } from "../graphql/mutations";
import toast from "react-hot-toast";

import CompanyInfoSection from "../tailwind-ui-components/CompanyInfoSection";
import QuestionSection from "../tailwind-ui-components/QuestionSection";
import SummarySection from "../tailwind-ui-components/SummarySection";
import UpdateProfileNotice from "../tailwind-ui-components/UpdateProfileNotice";
import ContentAccordion from "../tailwind-ui-components/ContentAccordion";
import { v4 as uuidv4 } from "uuid";
import FormTabSteps from "../tailwind-ui-components/FormTabSteps";
import { graphqlOperation } from "aws-amplify";
import { updateAllForms } from "../graphql/mutations";

export default function ActionLogUI({
  user,
  weights,
  viewState,
  setViewState,
  coachees,
  selectedCoachee,
  setSelectedCoachee,
  operations,
  departments,
  crews,
  questionsText,
  formName,
}) {
  // const updateAllFormsWithId = async () => {
  //   const input = {
  //     id: "7716e5fc-5b05-42ac-be6e-0ae160efb13b",
  //     Field0: {
  //       formName: "quarterlyMonthlyPlan",
  //       questionsText: JSON.stringify({
  //         q1: "A procedure and checklist exists and is used to monitor all the agreed Quarterly / Monthly planning inputs with responsibilities (RACI) and planned dates for completion",
  //         q2: "All planning assumptions and standards for the 3MP have been signed off.",
  //         q3: "The 3MP aligns to an updated production forecast, signed off and agreed by head office.",
  //         q4: "The 3MP clearly outlines the requirements of all ancillary work / projects with responsibilities and timeframes.",
  //         q5: "There is a detailed reconciliation of the previous months performance versus plan and against agreed indicators.",
  //         q6: "The mining 3MP aligns to the plant forecast and requirements, including volumes, grades.",
  //         q7: "Quarterly Plan (3MP) includes all the detailed outputs as per signed off procedure for the following 3 months with Month 1 divided into 4 weeks.",
  //         q8: "The Monthly meeting takes place at the agreed time and follows the agreed agenda with all the required people present.",
  //         q9: "The Quarterly plan addresses all identified risks through a risk mitigation plan.",
  //         q10: "The Quarterly plan is committed to and signed off by all key stakeholders.",
  //         q11: "Contributors to the plan demonstrate that they were safe to take risks around other team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [2, 3, 3, 2, 3, 4, 4, 2, 4, 3, 3],
  //     },
  //     Field1: {
  //       formName: "monthlyPlanningMeeting",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time and all MO@T Meeting Behaviours observed.",
  //         q2: "Progress against 3MP plan and previous months compliance to plan is reviewed and critical areas of focus / issues discussed to ensure future targets are met.",
  //         q3: "Draft plans and agreed inputs are up to date with good accuracy for all areas before the meeting. (see detailed input checklist).",
  //         q4: "Contingencies prepared in advance for critical equipment. (e.g. prepped patterns exist for drillers, contingency excavators exist for breakdowns, ore on ROM for issues with live feed to plant).",
  //         q5: "Equipment required for maintenance during the month is included in plans and understood by production before the meeting, and supported with a detailed maintenance and mobilzation schedule.",
  //         q6: "Progress of and attainment to plan of projects and ancillary work, e.g. construction, machine shut downs, water management etc, that may hold up production, is reviewed and risks addressed.",
  //         q7: "The leader of each area (or their delegate) clearly describes how their plan is achievable, describes key risks and what they will do to ensure they achieve the plan.",
  //         q8: "Final Quarterly / monthly plan is issued and signed off by agreed parties within 24 hours of the planning meeting being completed and complies to the pre-determined content and standards.",
  //         q9: "Planning Standards & Assumptions are formally agreed and align to monthly plan.",
  //         q10: "Agreed actions and risks (with mitigation plans) are recorded in the Action Database.",
  //         q11: "Attendees demonstrated that they were safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [2, 3, 3, 3, 3, 3, 4, 4, 3, 3, 3],
  //     },
  //     Field2: {
  //       formName: "weeklyPlanSchedule",
  //       questionsText: JSON.stringify({
  //         q1: "A procedure and checklist exists and is used to monitor all the agreed Weekly planning inputs with responsibilities (RACI) and planned dates for completion",
  //         q2: "All planning assumptions and standards for the Weekly plan / schedule have been reviewed and agreed.",
  //         q3: "The Weekly plan / schedule looks out at least two weeks in detail and aligns with the monthly plan and production targets.",
  //         q4: "The Weekly plan clearly outlines the requirements of all ancillary work / projects with responsibilities and timeframes.",
  //         q5: "There is a detailed reconciliation of the previous week performance versus plan and against agreed indicators.",
  //         q6: "The mining weekly plan aligns to the maintenance plan including major equipment availability and scheduled maintenance for the coming weeks. ",
  //         q7: "The mining weekly plan aligns to the plant’s requirements, including volumes, grades maintenance windows etc. for the coming weeks.",
  //         q8: "The weekly plan includes all identified risks and a mitigation plan that is documented in actions and tasks for the week.",
  //         q9: "The Weekly plan / schedule is committed to and signed off by all key stakeholders as P80 (80% likely) to be achieved for the week.",
  //         q10: "The committed weekly plan / schedule is made available to the execution team before the start of the planning week.",
  //         q11: "Contributors to the plan demonstrate that they were safe to take risks around other team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //         q12: 'When asked, contributors to the plan agree with the statement "my input into the plan this week was heard and is reflected in the plan"',
  //       }),
  //       weights: [3, 3, 3, 3, 2, 3, 3, 3, 4, 3, 2, 3],
  //     },
  //     Field3: {
  //       formName: "weeklyPrePlanning",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time and all MO@T Meeting Behaviours observed.",
  //         q2: "Progress against Monthly plan spatial compliance and previous week's compliance to plan is reviewed and critical areas of focus / issues discussed to ensure targets are met.",
  //         q3: "An hypothesis sequence plan has been completed before the meeting.",
  //         q4: "Planning inputs from the planning input checklist have been received prior to the meeting, e.g. maintenance and plant requirements are known.",
  //         q5: "Planning standards & assumptions are agreed and align to realistic machine capability in current and planned digs.",
  //         q6: "Strong cross functional collaboration and knowledge of the progress of projects and ancillary work, e.g. construction, shutdowns, power, ventilation, water management etc, that may affect the plan is reviewed, risks addressed with actions.",
  //         q7: "The team visit each specific workplace and discuss the reality of executing the work and any issues that might arise.",
  //         q8: "Contingencies are developed for critical equipment. (e.g. prepped patterns exist for drillers, contingency excavators exist for breakdowns, ore on ROM for issues with live feed to plant)",
  //         q9: "Meeting / process is owned by a senior planner (or their delegate) and includes planning, execution, technical and maintenance representatives.",
  //         q10: "Any actions or tasks needed to mitigate potential problems are recorded as scheduled tasks or in the action log and assigned to specific people for resolution.",
  //         q11: "Attendees demonstrate that they are safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [3, 3, 4, 3, 3, 3, 4, 3, 4, 3, 3],
  //     },
  //     Field4: {
  //       formName: "weeklySitePlanningMeeting",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time (includes planning, execution, technical and maintenance representatives) and all MO@T Meeting Behaviours observed.",
  //         q2: "Progress against Monthly plan, spatial compliance and previous week's compliance to plan is reviewed and critical areas of focus / issues are discussed to ensure targets are met.",
  //         q3: "Sequence meeting has been held and draft plans and agreed inputs are up to date with good accuracy  for all areas before the meeting.",
  //         q4: "Contingencies prepared in advance for critical equipment. (e.g. prepped patterns exist for drillers, contingency excavators exist for breakdowns, ore on ROM for issues with live feed to plant)",
  //         q5: "Equipment required for maintenance during the week (supported by a detailed maintenance schedule) is included in plans and understood by production before the meeting.",
  //         q6: "Progress of and attainment to plan of projects and ancillary work, e.g. construction, machine shut downs, water management etc, that may hold up production, is reviewed and risks addressed.",
  //         q7: "Each superintendent (or their delegate) clearly describe how / if their plan is achievable, describes key risks and what they will do to ensure they achieve the plan.",
  //         q8: "Meeting run by senior planner (or their delegate), speaks clearly, with energy and follows predefined agenda.",
  //         q9: "The team confirms that planning standards & assumptions are agreed and align to machine capability and monthly plan.",
  //         q10: "Any actions or tasks needed to mitigate potential problems are recorded as scheduled tasks or in the action log and assigned to specific people for resolution.",
  //         q11: "Attendees demonstrated that they were safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [2, 3, 4, 3, 3, 2, 4, 2, 3, 2, 3],
  //     },
  //     Field5: {
  //       formName: "weeklyCommitmentMeeting",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time (includes planning, execution, technical and maintenance representatives) and all MO@T Meeting Behaviours observed.",
  //         q2: "Updated Plans are distributed prior to the meeting with adequate time for attendees to review.(2-3 hours)",
  //         q3: "Plans are up to date for all work areas before meeting starts including location targets, key tasks, manning, pad prep, blasted inventory in front of machine.(as per planning checklist)",
  //         q4: "Updated contingencies prepared in advance for critical equipment. (e.g. prepped patterns exist for drillers, contingency excavators exist for breakdowns, Ore on ROM for issues with live feed to plant)",
  //         q5: "Pieces of equipment required for maintenance are included in plans with specified timing (to the shift) and target availability %",
  //         q6: "Each Superintendent (or their delegate) clearly describes how they will achieve their Shift Targets for each work area and each production unit.",
  //         q7: "Labour plans are reviewed to ascertain that there are adequate operators and personnel to support all the planned activities and tasks.",
  //         q8: "Superintendents publicly commit and agree to achieve targets and plans for the Weekly plan/schedule.",
  //         q9: "Final Weekly plan/schedule is signed off by agreed parties and issued within 2 hours of the commitment meeting being completed.",
  //         q10: "Any actions or tasks needed to mitigate potential problems are recorded as scheduled tasks or in the action log and assigned to specific people for resolution.",
  //         q11: "Attendees demonstrated that they were safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [2, 3, 3, 2, 3, 4, 3, 4, 3, 3, 3],
  //     },
  //     Field6: {
  //       formName: "dailyCommitmentMeeting",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time (includes planning, execution, technical and maintenance representatives) and all MO@T Meeting Behaviours observed.",
  //         q2: "Attendees understand the current reality in the pit, having inspected the workplace with a drive around, by looking from the highwall and by reviewing tasks that are already completed. ",
  //         q3: "The 24 hour plan / schedule for all production targets, plant feed, auxiliary work, interactions, breakdowns, potential issues and maintenance is up to date and thoroughly reviewed.",
  //         q4: "The roster, leave and equipment plans are up to date so that the resources needed to do the work are available.",
  //         q5: "All work that impacts other teams is understood, accepted, declined or replanned prior to the meeting.",
  //         q6: "All work orders, maps or work instructions are attached to tasks and shift plans to ensure that the work is well understood by supervisors and crew.",
  //         q7: "Any risks or resource constraints are resolved.",
  //         q8: "Each process leader confirms that all of their work & targets can be completed by the available resources and that no blockages exist.",
  //         q9: "Any actions or tasks needed to mitigate potential problems are recorded as scheduled tasks or in the action log and assigned to specific people for resolution.",
  //         q10: "Everyone publicly agrees that the updated schedule for the next 24 hours is achievable, based on all known information.",
  //         q11: "Attendees of the meeting demonstrate that they were safe to take risks around their team mates. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [2, 4, 3, 3, 3, 4, 3, 4, 2, 4, 3],
  //     },
  //     Field7: {
  //       formName: "shiftPlan",
  //       questionsText: JSON.stringify({
  //         q1: "A shift plan that describes exactly what will be delivered (e.g. tonnes, metres, work orders and tasks) by which machines in which locations using which people exists.",
  //         q2: "Dispatch and/or the off-going supervisor sets up the new shift plan for the oncoming supervisor to pick up at the start of shift.",
  //         q3: 'Targets in the shift plan represent "good performance" for each machine based on the expected availability, resources & utilization on this specific shift. (i.e. does not use budgeted or long term averages).',
  //         q4: "Oncoming supervisor uses their shift handover to identify any risks to their shift plan and either plans work to mitigate or escalates these risks.",
  //         q5: "Oncoming supervisor adapts shift plan with crew and equipment availability and handover information to set shift targets, schedule tasks and assign people to work.",
  //         q6: "Oncoming supervisor uses the shift plan to update the prestart presentation & targets for their crew.",
  //         q7: "Supervisors briefly commit to their shift plans in front of their crew during prestart meetings.",
  //         q8: "Supervisor uses the shift plan throughout the shift to allocate, clarify and follow up on work. ",
  //         q9: "Up to date performance data for machines is recorded throughout the shift and visible to everyone on site.",
  //         q10: "Supervisor ticks off work as it is completed and/or enters actuals into app, onto printed shift plan or via a touchscreen. ",
  //         q11: "Supervisor regularly communicates with dispatch to update completed tasks or update them on work completed during the shift.",
  //         q12: "The supervisor feels safe to challenge and adapt the work and targets in the shift plan so that they can feel confident it is achievable before committing to it.",
  //       }),
  //       weights: [5, 4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
  //     },
  //     Field8: {
  //       formName: "shiftHandover",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time and all MO@T Meeting Behaviours observed.",
  //         q2: "Shift Plan developed for oncoming shift by offgoing supervisor and/or dispatch prior to shift handover.",
  //         q3: "Shift Plan is referred to throughout the handover.",
  //         q4: "Offgoing supervisor clearly describes current reality, i.e dig location, rate, hazards, any escalations made during the previous shift and checks for understanding.",
  //         q5: "Plan for each work area reviewed by both supervisors using tools (e.g. handover tool, whiteboard). Understand issues and hazards that exist, the plan for the next shift, incomplete tasks and any actions or tasks necessary to have a successful shift.",
  //         q6: "Oncoming supervisor clearly understands what is required and makes notes of key actions in their shift plan and plans work for allocation to the team.",
  //         q7: "Any issues that may affect the plan but can't be resolved by supervisors are assigned to a person or escalated to superintendent.",
  //         q8: "Oncoming supervisor uses the information from the handover to update the prestart presentation for their crew.",
  //         q9: "For the next 24 hours supervisor considers all realities, sequence tasks, maintenance, known issues, supplies, machine availability, planned work and interactions.",
  //         q10: "Prepares to discuss any necessary changes at the pre-shift alignment.",
  //         q11: "Attendees demonstrated that they were safe to take risks around their team members (e.g. by asking clarifying questions or by suggesting new ideas). No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [2, 4, 4, 3, 3, 4, 3, 3, 3, 3, 3],
  //     },
  //     Field9: {
  //       formName: "preStart",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all leaders and crew attend on time and all MO@T Meeting Behaviours observed.",
  //         q2: "Meeting uses the up to date shift plan (safety, production & maintenance work is reviewed and / or edited by supervisor prior to meeting). Usually in a visual, standard format powerpoint show.",
  //         q3: "Safety slides / topics are reviewed in relevant level of detail (i.e., when changed/if new crew).",
  //         q4: "Past performance is briefly reviewed for each work area.",
  //         q5: "Each work area and the key work to be completed for the shift is described to the team.",
  //         q6: "Shift Targets and TTF / TTL expectations set for each work area.",
  //         q7: "Supervisor checks for understanding with key operators. Setting expectation of a safe and productive shift.",
  //         q8: "Supervisor communicates clearly, with pace, authority and enthusiasm.",
  //         q9: "Crew leave prestart room within 30 seconds of prestart completion and go straight to vehicles.",
  //         q10: "Time to First Bucket & first short interval control targets are met by the crew.",
  //         q11: "Attendees demonstrated that they were safe to take risks around their team members (e.g. by asking clarifying questions). No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea",
  //       }),
  //       weights: [2, 5, 3, 3, 4, 3, 4, 3, 3, 3, 3],
  //     },
  //     Field10: {
  //       formName: "shortIntervalControl",
  //       questionsText: JSON.stringify({
  //         q1: "The agreed shift plan i.e. production targets and key tasks/actions for the shift are entered into the SIC tool in time for the prestart and start of shift.",
  //         q2: "The supervisor goes into the field straight after prestart to ensure a safe shift startup and that first load targets are met.",
  //         q3: "The supervisor uses the workplace inspection tool/forms to ensure the workplace is safe & to standard.",
  //         q4: "The supervisor reviews their work areas at regular intervals (every 2 hours) to be aware of varaiances / risks to the plan (production and tasks) and the causes of these variances.",
  //         q5: "The supervisor receives regular notifications of the performance vs plan of their work areas throughout the shift so that they are aware of potential problems.",
  //         q6: "Dispatch proactively contacts the supervisor when they identify there is a risk that a machine or  work area will not meet their plan for the shift. They should assist the supervisor to coordinate an effective recovery response and escalate where appropriate.",
  //         q7: "Supervisor takes corrective action as soon as practical when they become aware of risks to their plan and record these actions on the SIC tool progressively during the shift.",
  //         q8: "Superintendents and managers are made aware of major variances and risks to their plan through the escalation protocol / before they find out through their reports.",
  //         q9: "Supervisor is able to justify the way they way they responded to problems on their shift to ensure safe production. Notes are made in their shift report to support this.",
  //         q10: 'SIC data is used to demonstrate the facts in a scientific way without fear of these "facts" being rejected or dismissed as wrong or unimportant because they are uncomfortable to the supervisor or other leaders.',
  //       }),
  //       weights: [4, 3, 2, 4, 3, 3, 4, 3, 3, 3],
  //     },
  //     Field11: {
  //       formName: "shiftReport",
  //       questionsText: JSON.stringify({
  //         q1: "A shift report template or tool exists and is used by supervisors and / or dispatch to close out the shift.",
  //         q2: "Shift reports are written by all supervisors for each shift.",
  //         q3: "Supervisors complete the shift report to compare plan with actual for production targets throughout and at the end of shift.",
  //         q4: "Supervisor uses the shift report to record any scheduled tasks or actions that were incomplete in the shift.",
  //         q5: "Shift report makes it easy to see how the supervisor and / or dispatch responded to challenges or variances from the plan throughout the shift. E.g. slow production rates or missed auxiliary work.",
  //         q6: "Shift report makes it easy to see where the production variances are and encourages the supervisor and / or dispatch to raise actions for these variances.",
  //         q7: "Supervisor uses the shift report to identify any risks to the next shift and documents any tasks that need to be completed. ",
  //         q8: "Supervisor refers to the shift report (e.g. the rates they experienced and any potential problems) during the shift handover with the oncoming shift.",
  //         q9: "Supervisor delivers the shift report to their direct leader and discusses it during the Shift Debrief.",
  //         q10: 'Shift report data is used to demonstrate the facts in a scientific way without fear of these "facts" being rejected or dismissed as wrong or unimportant because they are inconvenient or uncomfortable to the supervisor or other leaders.',
  //       }),
  //       weights: [4, 3, 3, 3, 4, 3, 3, 3, 3, 3],
  //     },
  //     Field12: {
  //       formName: "shiftDebrief",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time and all MO@T Meeting Behaviours observed.",
  //         q2: "Debriefs each shift supervisor either as a group or individually after they have completed their shift report.",
  //         q3: "Uses shift reports and statutory reports to review plan vs actual.",
  //         q4: "Probes to understand major variances and actions taken to limit impact (i.e. were we aware and did we take action / escalate as soon as reasonably possible?)",
  //         q5: "Asks if there are any issues that are emerging or work that needs to be done to ensure the next shifts are successful",
  //         q6: "Identifies and captures all actions/jobs for scheduling",
  //         q7: "Reviews and confirms all actions recorded in this meeting",
  //         q8: "Attendees demonstrated that they were safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea. ",
  //         q9: "Actions come from the debrief that show leaders are raising and facing into important problems on the site.",
  //       }),
  //       weights: [1, 3, 4, 4, 3, 3, 3, 3, 3],
  //     },
  //     Field13: {
  //       formName: "performanceReports",
  //       questionsText: JSON.stringify({
  //         q1: "In shift performance data is collected and available in time for Short Interval Control.",
  //         q2: "Shift performance data is collected and reports are available in time for use at start & end of shift processes. ",
  //         q3: "Performance data is collected and reports are available in time for use at department and daily review meetings.",
  //         q4: "Task completion, schedule compliance & spatial compliance are measured to ensure that the work that leads to high performance is focussed on as much as the performance itself.",
  //         q5: "Performance reports make it easy for users to see the Plan vs Actual, to identify the Variance and clearly see whether it is good or bad.",
  //         q6: "Performance reports are visible in public places on the mine so that everyone can see how the operation and its processes are performing. ",
  //         q7: "Leaders on the site can explain how the site is performing against its targets and what the focus is for improving the site and their area.",
  //         q8: "Leaders on the site can explain what the focus is for improving the site and their area.",
  //         q9: "Causes of variance such as delays, waste etc. are visible in effective reports e.g. waterfall & pareto charts to enable the team to target improvement actions.",
  //         q10: "Corrective Actions / Projects to improve performance are developed and recorded in action log and communicated during meetings.",
  //         q11: 'Data is used to demonstrate the facts in a scientific way without fear of these "facts" being rejected or dismissed as wrong or unimportant because they are inconvenient to leaders.',
  //       }),
  //       weights: [3, 3, 3, 3, 4, 4, 3, 3, 4, 3, 3],
  //     },
  //     Field14: {
  //       formName: "departmentDailyReview",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend and all MO@T meeting behaviours observed. Structured agenda and run sheet exists and is used to guide the meeting.",
  //         q2: "Incidents, Safety, Workplace standards, People, Training and Absenteeism are reviewed and actions raised for any risks or issues.",
  //         q3: "Progress against the weekly plan & monthly budget are quickly reviewed.",
  //         q4: "Daily performance targets for each KPI are based on the targets from the committed shift plans for the last 24 hour period.",
  //         q5: "Performance results are presented for each Area in the Department by the Area leader using the PAVA (Plan, Actual, Variance and Action) format.",
  //         q6: "Reports account for and show major causes of variance, including lost time, lost meters, tonnes, missed work etc.",
  //         q7: "Any causes of variance are explained by the leader of each area with recommended actions to prevent the variance occurring again.",
  //         q8: "Major risks to the plan for the next 48 hours raised and any actions recorded.",
  //         q9: "Action Database reviewed and key actions probed for completion / outcome. All actions completed or rescheduled with prior approval from manager.",
  //         q10: "Attendees demonstrated that they felt safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //         q11: "Actions exist that show leaders are raising and facing into important problems on the site.",
  //       }),
  //       weights: [1, 3, 3, 3, 5, 4, 4, 3, 4, 3, 3],
  //     },
  //     Field15: {
  //       formName: "dailySiteReview",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend and all MO@T meeting behaviours observed. Structured agenda and run sheet exists and is used to guide the meeting.",
  //         q2: "Incidents, Safety, Workplace standards, People, Training and Absernteeism are reviewed and actions raised for any risks or issues.",
  //         q3: "Progress against the weekly plan & monthly budget are quickly reviewed.",
  //         q4: "Daily performance targets for each KPI are based on the targets from the committed shift plans for the last 24 hour period.",
  //         q5: "Performance results are presented for each area by the area leader using the PAVA (Plan, Actual, Variance and Action) format.",
  //         q6: "Reports account for and show major causes of variance, including lost time, lost metres, tonnes, missed work etc.",
  //         q7: "Any causes of variance are explained by the leader of each area with recommended actions to prevent the variance occurring again.",
  //         q8: "Plan for next 48 hours briefly presented by each superintendent separately to the performance review. ",
  //         q9: "Major risks to the plan for the next 48 hours raised and any actions recorded.",
  //         q10: "Action Database reviewed and key actions probed for completion / outcome. All actions completed or rescheduled with prior approval from manager.",
  //         q11: "Attendees demonstrated that they were safe to take risks around other team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //         q12: "Actions exist that show leaders are facing into important problems on the site.",
  //       }),
  //       weights: [1, 3, 3, 3, 5, 4, 4, 3, 3, 4, 3, 3],
  //     },
  //     Field16: {
  //       formName: "performanceAnalysis",
  //       questionsText: JSON.stringify({
  //         q1: "Data is recorded and can be used to understand delays and the performance of the operation. Effective time usage model is used.",
  //         q2: "Delay analysis has been completed and top 3 issues are identified from Pareto charts. The impact of these delays on production is quantified and understood.",
  //         q3: "At least one corrective action is recorded in the Action Log per top 3 delay.",
  //         q4: "Collaborative, cross functional teams are formed to develop and implement solutions to major causes of variance",
  //         q5: "Dig & delay analysis reports i.e. weekly waterfall charts, short interval control documents and intrashift reports are used by the contractor & coordinators to help understand variances.",
  //         q6: "Five whys and / or RCA process used to establish root causes and actions for top 3 actionable delays.",
  //         q7: "Corrective Actions / Projects to prevent recurrence of delays are developed and recorded in the action log and progress is reviewed in Weekly Review Meeting.",
  //         q8: "Managers and their teams clearly understand their numbers and can describe the projects and actions they are taking to improve results.",
  //         q9: 'Data is used to demonstrate the facts in a scientific way without fear of these "facts" being rejected or dismissed as unimportant because they are inconvenient to leaders.',
  //       }),
  //       weights: [5, 4, 3, 3, 3, 4, 4, 4, 3],
  //     },
  //     Field17: {
  //       formName: "weeklyDepartmentReview",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend and all MO@T meeting behaviours observed. Structured agenda and run sheet exists and is used to guide the meeting.",
  //         q2: "Set context by reviewing overall performance of the operation against the business plan / budget. SVC - Safety, volume, costs.",
  //         q3: "Each process leader reviews the performance vs plan for each process from planning to feeding the crusher. Use delay data and waterfall charts to provide causes of variance. Follow PAVA process.",
  //         q4: "Attendees know the root causes of variance and have plans / actions in place to reduce the impact and ensure these issues are resolved. Or have an action to do an RCA or 5 whys.",
  //         q5: "Manager enquires into causes of variance to ensure root causes are identified and that the actions and projects needed to remove these causes are documented.",
  //         q6: "Critical projects are reviewed, on track for completion and likely to deliver intended outcome on time.",
  //         q7: "Quality actions are recorded for variances in the Action Log, actions reviewed and probed for completion / outcome.",
  //         q8: "Attendees demonstrate that they are safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //         q9: "Business Improvement/Capital Projects exist that show leaders are facing into important problems on the site.",
  //       }),
  //       weights: [1, 3, 5, 4, 5, 3, 4, 3, 3],
  //     },
  //     Field18: {
  //       formName: "weeklySiteReview",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend and all MO@T meeting behaviours observed. Structured agenda and run sheet exists and is used to guide the meeting.",
  //         q2: "Set context by reviewing overall performance of the operation against the business plan / budget. SVC - Safety, volume, costs. ",
  //         q3: "Mining team review headline performance vs plan on safety, volume & cost for their major processes and equipment and compliance to plan. Use delay data to provide causes of variance, understand major variances & have actions to resolve them.",
  //         q4: "Maintenance team review headline performance vs plan on schedule compliance, availability, backlog & mean time to failure for major equipment. Use delay data to provide causes of variance, understand major variances & have actions to resolve them.",
  //         q5: "Supporting teams review headline performance vs plan on safety, volume & cost. Discussing Availability, Util, Rate & product quality. Use delay data to provide causes of variance, understand major variances & have actions to resolve them.",
  //         q6: "Manager enquires into causes of variance to ensure root causes are identified and that the actions and projects needed to remove these causes are documented.",
  //         q7: "Critical projects are reviewed, on track for completion and likely to deliver intended outcome on time.",
  //         q8: "All actions from the meeting are reviewed and recorded in the Action Log. Due actions from previous meetings are followed up.",
  //         q9: "Attendees demonstrate that they are safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea. ",
  //         q10: "Business Improvement/Capital Projects exist that show leaders are identifying and facing into important problems on the site.",
  //       }),
  //       weights: [2, 3, 5, 5, 4, 5, 3, 3, 3, 3],
  //     },
  //     Field19: {
  //       formName: "managementReports",
  //       questionsText: JSON.stringify({
  //         q1: "Management data is collected, and reports are available in time for use at monthly review meetings.",
  //         q2: "Management reports make it easy for users to see the Plan vs Actual for the Month and year to date, to identify the Variance and clearly see whether it is good or bad.",
  //         q3: "Management reports are used by managers to prepare for monthly review meetings. ",
  //         q4: "Standardized management reports support the team to have an insightful discussion about performance in a logical and coherent way.",
  //         q5: "Leaders on the site can explain how the site is performing against its budget and forecast.",
  //         q6: "Leaders on the site can explain what the focus is for improving the site and their area.",
  //         q7: "The team uses logical and objective approaches that are supported by the data to understand the causes of success or failure.",
  //         q8: "Projects to improve performance are developed and recorded in action log and communicated during meetings.",
  //         q9: 'Data is used to demonstrate the facts in a scientific way without fear of these "facts" being rejected or dismissed as wrong or unimportant because they are inconvenient or upsetting to the leaders responsible.',
  //       }),
  //       weights: [4, 5, 4, 4, 4, 4, 4, 4, 4],
  //     },
  //     Field20: {
  //       formName: "monthlyReview",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend and all MO@T meeting behaviours observed. Structured agenda and run sheet exists and is used to guide the meeting.",
  //         q2: "Set context by reviewing overall performance of the operation against the business plan / budget. SVC - Safety, volume, costs. ",
  //         q3: "Mining team review headline performance vs plan on safety, volume & cost for their major processes and equipment and compliance to plan. Use delay data to provide causes of variance, understand major variances & have actions to resolve them.",
  //         q4: "Maintenance team review headline performance vs plan on schedule compliance, availability, backlog & mean time to failure for major equipment. Use delay data to provide causes of variance, understand major variances & have actions to resolve them.",
  //         q5: "Supporting teams review headline performance vs plan on safety, volume & cost. Discussing Availability, Util, Rate & product quality. Use delay data to provide causes of variance, understand major variances & have actions to resolve them.",
  //         q6: "Manager enquires into causes of variance to ensure root causes are identified and that the actions and projects needed to remove these causes are documented.",
  //         q7: "Critical projects are reviewed, on track for completion and likely to deliver intended outcome on time.",
  //         q8: "All actions from the meeting are reviewed and recorded in the Action Log. Due actions from previous meetings are followed up.",
  //         q9: "Attendees demonstrated that they were safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //         q10: "Business Improvement/Capital Projects exist that show leaders are facing into important problems on the site.",
  //       }),
  //       weights: [2, 3, 5, 5, 5, 5, 3, 3, 3, 3],
  //     },
  //     Field21: {
  //       formName: "quarterlyForecastReview",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend and all MO@T meeting behaviours observed. Structured agenda and run sheet exists and is used to guide the meeting.",
  //         q2: "Set context by reviewing overall performance of the operation against the business plan / budget. SVC - Safety, volume, costs. ",
  //         q3: "Mining team review headline performance vs plan on safety, volume & cost for their major processes and equipment and compliance to plan. Use reliable data and quality analysis to understand major variances & have actions to resolve them.",
  //         q4: "Maintenance team review headline performance vs plan on schedule compliance, availability, backlog & mean time to failure for major equipment. Use reliable data and quality analysis to understand major variances & have actions to resolve them.",
  //         q5: "Supporting teams review headline performance vs plan on safety, volume & cost. Discussing Availability, Util, Rate & product quality. Use reliable data and quality analysis to understand major variances & have actions to resolve them.",
  //         q6: "Manager enquires into causes of variance to ensure root causes are identified and that the actions and projects needed to remove these causes are documented.",
  //         q7: "Critical projects are reviewed, on track for completion and likely to deliver intended outcome on time.",
  //         q8: "All actions from the meeting are reviewed and recorded in the Action Log. Due actions from previous meetings are followed up.",
  //         q9: "Attendees demonstrated that they were safe to take risks around their team members. No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //         q10: "Business Improvement/Capital Projects exist that show leaders are facing into important problems on the site.",
  //       }),
  //       weights: [2, 3, 5, 5, 4, 5, 5, 3, 3, 3],
  //     },
  //     Field22: {
  //       formName: "actionLog",
  //       questionsText: JSON.stringify({
  //         q1: "Action Log exists on site.",
  //         q2: "Action Log is used to record actions in all review meetings observed.",
  //         q3: "Action Log is reviewed during all review meetings.",
  //         q4: "All actions are completed by the due date or rescheduled with prior approval from manager.",
  //         q5: 'Where actions remain incomplete a private "performance conversation" is had with the leader responsible for the incomplete actions.',
  //         q6: "Large business improvement, risks or capital projects are managed in separate project management tools.",
  //         q7: "Users demonstrate that they are safe to take risks around other team members. Actions exist that show leaders are facing into and fixing the issues that arise in review meetings.",
  //       }),
  //       weights: [5, 4, 4, 3, 3, 3, 3],
  //     },
  //     Field23: {
  //       formName: "businessImprovementCapitalProjects",
  //       questionsText: JSON.stringify({
  //         q1: "Business Improvement & Capital Projects log / plan exists on site.",
  //         q2: 'A "Plan on a Page" or similar process is used at least annually to identify initiatives to improve the performance of the operation.',
  //         q3: "Progress of Business Improvement & Capital Projects Log is reviewed during weekly, monthly, quarterly & annual review meetings.",
  //         q4: 'A formal "business case" tool / process is used to approve or decline projects that require capital expenditure.',
  //         q5: "Where appropriate the expected impact of these initiatives are included in future P&L / Budgets.",
  //         q6: 'An "after action review" process is used to measure the impact of these projects to identify if they delivered the expected benefits.',
  //         q7: "Risks to the success of these projects are understood, documented and being actively mitigated to be ALARA.",
  //         q8: "Users demonstrate that they are safe to take risks around other team members. BI/Capital Projects exist that show leaders are facing into and fixing important problems on the site.",
  //       }),
  //       weights: [5, 4, 3, 3, 3, 3, 3, 3],
  //     },
  //     Field24: {
  //       formName: "preShiftAlignment",
  //       questionsText: JSON.stringify({
  //         q1: "Meeting starts and finishes on time, all required people attend on time and all MO@T Meeting Behaviours observed.",
  //         q2: "Supervisors and Shift Coordinator  come prepared with any recommended changes to shift plans after a handover with their off-going shift counterpart.",
  //         q3: "Uses Whole-of-Mine Shift Plan / Schedule to understand the interactions between processes.",
  //         q4: "For the next 24 hours, considers in detail all realities, sequence tasks, maintenance, known issues, supplies, machine availability, planned work and interactions.",
  //         q5: "Makes trade-offs to balance resources and equipment against production priorities.",
  //         q6: "Identifies and captures in The plan any new actions or jobs for supervisors and production areas.",
  //         q7: "Gains commitment from attendees that proposed changes to plans are optimum and achievable, then follows the approval process to revise plans.",
  //         q8: "Agrees with all attendees that the updated schedule is possible based on all known information. Asks for commitment from the team.",
  //         q9: "Adapts shift plans for any processes that have had their plan changed during this meeting.",
  //         q10: "Attendees demonstrated that they were safe to take risks around their team members (e.g. by asking clarifying questions). No one on the team publicly embarrassed or punished anyone else for admitting a mistake, asking a question, or offering a new idea.",
  //       }),
  //       weights: [5, 4, 3, 3, 3, 3, 3, 3, 3, 2],
  //     },
  //   };
  //   try {
  //     const result = await API.graphql(
  //       graphqlOperation(updateAllForms, { input }),
  //     );
  //     console.log("updated successfully: ", result);
  //   } catch (error) {
  //     console.error("Error updating: ", error);
  //   }
  // };
  const [errors, setErrors] = useState({});
  const [toggleState, setToggleState] = useState(false);
  const handleView = () => {
    // updateAllFormsWithId();
    setViewState(!viewState);
  };
  const [formattedWeights, setFormattedWeights] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (weights) {
      const newFormattedWeights = {
        q1: weights[0],
        q2: weights[1],
        q3: weights[2],
        q4: weights[3],
        q5: weights[4],
        q6: weights[5],
        q7: weights[6],
      };
      let newTotal = 0;
      for (let i = 0; i < weights.length; i++) {
        newTotal += Number(weights[i]);
      }

      // Update state variables
      setFormattedWeights(newFormattedWeights);
      setTotal(newTotal);
    }
  }, [weights]); // Run this effect whenever `weights` changes

  const [questions, setQuestions] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
  });

  const sections = [
    {
      id: "Behaviours",
      title: "Behaviours",
      description: "Behaviours observed during the assessment.",
      prevSection: "Company",
      nextSection: "Tools",
      questionIds: ["q5"],
    },
    {
      id: "Tools",
      title: "Tools / Systems",
      description: "Tools / Systems that are currently being used.",
      prevSection: "Behaviours",
      nextSection: "Psych",
      questionIds: ["q1", "q2", "q3", "q4", "q6"],
    },
    {
      id: "Psych",
      title: "Psych Safety",
      description:
        "Psychological safety is the belief that you won’t be punished when you make a mistake. It’s the belief that you can ask a question without being ridiculed. It’s the belief that you can offer a new idea without being shot down.",
      prevSection: "Tools",
      nextSection: "Summary",
      questionIds: ["q7"],
    },
  ];

  const [comments, setComments] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    operation: "",
    department: "",
    FirstName: user.name ? user.name : "",
    LastName: user.family_name ? user.family_name : "",
    date: "",
    CommentsNotes: "",
    CoachingNotes: "",
    coachee: "",
    crew: "",
  });

  const validateForm = () => {
    let newErrors = {};

    if (!formData.operation || !formData.operation.trim()) {
      newErrors.operation = "Operation is required";
    }

    if (!formData.department || !formData.department.trim()) {
      newErrors.department = "Department is required";
    }

    if (!formData.date || !formData.date.trim()) {
      newErrors.date = "Date is required";
    } else {
      const selectedDate = new Date(formData.date);
      const validDate = new Date("2023-01-01");
      const oneDayAhead = new Date();
      oneDayAhead.setDate(oneDayAhead.getDate() + 1);

      if (selectedDate < validDate) {
        newErrors.date = "Date is older than 2023";
      } else if (selectedDate > oneDayAhead) {
        newErrors.date =
          "Date cannot be more than one day ahead of the current time";
      }
    }

    // Validating all questions
    Object.keys(questions).forEach((key) => {
      if (!questions[key].trim() || questions[key] === "-1") {
        newErrors[key] = "This field is required";
      }
    });

    setErrors(newErrors);

    // Return the error object
    return newErrors;
  };

  const handleToggle = (value) => {
    setToggleState(value);
  };

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setQuestions((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  const handleCommentChange = (e) => {
    const { name, value } = e.target;
    setComments((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleDateChange = (e) => {
    let { name, value } = e.target;
    value = new Date(value).toISOString();
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  useEffect(() => {
    if (user.name && user.family_name) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        FirstName: user.name,
        LastName: user.family_name,
      }));
    }
  }, [user]);
  const handleSubmitReset = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const newErrors = validateForm();

    // Check for the specific date error
    if (newErrors.date === "Date is older than 2023") {
      toast.error("Error: The date must be on or after January 1, 2023");
      setIsSubmitting(false);
      return;
    }

    // If there are any other errors
    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      const result = await API.graphql({
        query: createActionLog,
        variables: {
          input: {
            Operation: formData.operation,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            Department: formData.department,
            FormDate: formData.date,
            MosElement: toggleState ? "Yes" : "No",
            Crew: formData.crew,
            Q1: questions.q1,
            Q2: questions.q2,
            Q3: questions.q3,
            Q4: questions.q4,
            Q5: questions.q5,
            Q6: questions.q6,
            Q7: questions.q7,
            C1: comments.q1,
            C2: comments.q2,
            C3: comments.q3,
            C4: comments.q4,
            C5: comments.q5,
            C6: comments.q6,
            C7: comments.q7,
            CommentsNotes: formData.CommentsNotes,
            CoachingNotes: formData.CoachingNotes,
            CoachName: formData.coachee,
          },
        },
      });
      function transformStringToValue(str) {
        str = str.toLowerCase();
        switch (str) {
          case "nothing":
            return 0;
          case "0":
            return 0;
          case "poor":
            return 1;
          case "1":
            return 1;
          case "ok":
            return 2;
          case "2":
            return 2;
          case "good":
            return 3;
          case "3":
            return 3;
          default:
            return 0; // For 'Missing' or any unrecognized string
        }
      }

      function calculateWeightedAverage(weights, questions) {
        let sumOfWeightedQuestions = 0;
        let sumOfWeights = weights.reduce((a, b) => a + b, 0);
        for (let i = 0; i < weights.length; i++) {
          let question = transformStringToValue(questions[`q${i + 1}`]);
          sumOfWeightedQuestions += question * weights[i];
        }
        let result = sumOfWeightedQuestions / sumOfWeights;
        return result.toFixed(1);
      }

      let averageScore = calculateWeightedAverage(weights, questions);

      const FormScore = await API.graphql({
        query: createFormScore,
        variables: {
          input: {
            // Error with amplify autogenerating id: https://github.com/aws-amplify/amplify-cli/issues/4502
            id: uuidv4(),
            FormName: formName,
            WeightedScore: averageScore,
            OperationName: formData.operation,
            DepartmentName: formData.department,
            CrewName: formData.crew,
            CoachName: formData.coachee,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            FormDate: formData.date,
          },
        },
      });
      console.log("Score Form Input Result: ", FormScore);
      console.log("Result: ", result);
      toast.success("Successfully submitted Form!");
      setIsSubmitting(false);
      resetStateValues();
    } catch (error) {
      console.error("Error submitting form: ", error);
      toast.error("Error submitting Form! " + error.errors[0].message);
      setIsSubmitting(false);
      resetStateValues();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const newErrors = validateForm();

    // Check for the specific date error
    if (newErrors.date === "Date is older than 2023") {
      toast.error("Error: The date must be on or after January 1, 2023");
      setIsSubmitting(false);
      return;
    }

    // If there are any other errors
    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      const result = await API.graphql({
        query: createActionLog,
        variables: {
          input: {
            Operation: formData.operation,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            Department: formData.department,
            FormDate: formData.date,
            MosElement: toggleState ? "Yes" : "No",
            Crew: formData.crew,
            Q1: questions.q1,
            Q2: questions.q2,
            Q3: questions.q3,
            Q4: questions.q4,
            Q5: questions.q5,
            Q6: questions.q6,
            Q7: questions.q7,
            C1: comments.q1,
            C2: comments.q2,
            C3: comments.q3,
            C4: comments.q4,
            C5: comments.q5,
            C6: comments.q6,
            C7: comments.q7,
            CommentsNotes: formData.CommentsNotes,
            CoachingNotes: formData.CoachingNotes,
            CoachName: formData.coachee,
          },
        },
      });
      function transformStringToValue(str) {
        str = str.toLowerCase();
        switch (str) {
          case "nothing":
            return 0;
          case "0":
            return 0;
          case "poor":
            return 1;
          case "1":
            return 1;
          case "ok":
            return 2;
          case "2":
            return 2;
          case "good":
            return 3;
          case "3":
            return 3;
          default:
            return 0; // For 'Missing' or any unrecognized string
        }
      }

      function calculateWeightedAverage(weights, questions) {
        let sumOfWeightedQuestions = 0;
        let sumOfWeights = weights.reduce((a, b) => a + b, 0);
        for (let i = 0; i < weights.length; i++) {
          let question = transformStringToValue(questions[`q${i + 1}`]);
          sumOfWeightedQuestions += question * weights[i];
        }
        let result = sumOfWeightedQuestions / sumOfWeights;
        return result.toFixed(1);
      }

      let averageScore = calculateWeightedAverage(weights, questions);

      const FormScore = await API.graphql({
        query: createFormScore,
        variables: {
          input: {
            // Error with amplify autogenerating id: https://github.com/aws-amplify/amplify-cli/issues/4502
            id: uuidv4(),
            FormName: formName,
            WeightedScore: averageScore,
            OperationName: formData.operation,
            DepartmentName: formData.department,
            CrewName: formData.crew,
            CoachName: formData.coachee,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            FormDate: formData.date,
          },
        },
      });
      console.log("Score Form Input Result: ", FormScore);
      console.log("Result: ", result);
      toast.success("Successfully submitted Form!");
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error submitting form: ", error);
      toast.error("Error submitting Form! " + error.errors[0].message);
      setIsSubmitting(false);
    }
  };
  const resetStateValues = () => {
    setFormData({
      operation: formData.operation,
      department: formData.department,
      FirstName: user.name ? user.name : "",
      LastName: user.family_name ? user.family_name : "",
      CommentsNotes: "",
      CoachingNotes: "",
      coachee: formData.coachee,
      crew: formData.crew,
    });
    setQuestions({
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
    });
    setComments({
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
    });
    setErrors({});
  };

  //   const contentBody = `
  // #### Objectives:
  // - Every variance to plan should have an action assigned to permanently remove the variance, or to limit its impact if it is unable to be removed. The Action Log is used to capture and track all site actions and accountabilities for completion by the due date.
  // - It is the sole source of actions on site and is administered by the Mine Managers Executive Assistant or a suitable delegate.
  // - Actions are typically able to be completed in less than a month and don’t require capital approval for implementation. Actions bigger than this should be raised as business improvement or capital projects.
  // `;

  const keyQuestion =
    "KEY QUESTION: Are we capturing and completing all the actions we need to take to improve our performance?";

  const outcome =
    "OUTCOME: Actions for each variance get raised and completed so that variability reduces and the performance of the operation steadily improves.";
  // const tableObject = {
  //   "All actions recorded in all meetings and captured centrally":
  //     "Standard action log book, Web based Action Log / Database",
  //   "Issues & Actions Log used at all review meetings on site ":
  //     "Spreadsheet, JotForm, whiteboard",
  //   "Protocol for issuing actions to those not in meetings where actions assigned and for extension of time where actions not completed on due date.":
  //     "Web based action log system.",
  //   "Reports on the actions opened, completed and rescheduled by department":
  //     "Web based action log system.",
  // };
  const [parsedContent, setParsedContent] = useState({});
  const [data, setData] = useState(null);
  const splitContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = Array.from(doc.body.childNodes);

    let tableObject, contentBody;
    let contentStartIndex, contentEndIndex;
    let tableStartIndex = null;
    let tableHeading = "";

    elements.forEach((element, index) => {
      if (
        /^H[1-6]$/i.test(element.nodeName) &&
        element.textContent.includes("Description")
      ) {
        contentStartIndex = index + 1;
      } else if (/^H[1-6]$/i.test(element.nodeName)) {
        tableHeading = element.textContent;
        tableStartIndex = index;
      }
    });

    if (tableStartIndex !== null) {
      tableObject = elements
        .slice(tableStartIndex + 1)
        .map((element) => element.outerHTML)
        .join("");
      contentEndIndex = tableStartIndex - 1;
    }

    contentBody = elements
      .slice(contentStartIndex, contentEndIndex + 1)
      .map((element) => {
        if (element.nodeName === "UL") {
          element.classList.add("list-disc");
          element.classList.add("pl-8");
        } else if (element.nodeName === "OL") {
          element.classList.add("list-decimal");
          element.classList.add("pl-8");
        }
        return element.outerHTML;
      })
      .join("");

    return {
      tableHeading,
      tableObject,
      contentBody: `<div>${contentBody}</div>`,
    };
  };
  useEffect(() => {
    fetch("https://opskit.app/wp-json/wp/v2/posts?slug=thiess-7-action-log", {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEyLCJuYW1lIjoib3Brc2tpdF9jb3JzIiwiaWF0IjoxNzExNTE1ODE4LCJleHAiOjE4NjkxOTU4MTh9.V15EPNXDoR7UeKGa-rAVXtXXokG3bzy9gQsqnm7wVkY",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        if (data && data[0] && data[0].content) {
          setParsedContent(splitContent(data[0].content.rendered));
        }
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  let contentBody = parsedContent.contentBody;
  if (contentBody) {
    contentBody = contentBody.replace(/\[vc_.*?\]/g, "");
  }
  let tableObject = parsedContent.tableObject;
  if (tableObject) {
    tableObject = tableObject.replace(/\[vc_.*?\]/g, "");
  }
  let tableHeading = parsedContent.tableHeading;
  if (tableHeading) {
    tableHeading = tableHeading.replace(/\[vc_.*?\]/g, "");
  }
  const [showForm, setShowForm] = useState(false);
  const [currentQuestionSection, setCurrentQuestionSection] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  const nextSection = () => {
    setCurrentSection(currentSection + 1);
  };

  const prevSection = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
    }
  };
  return (
    <>
      <h5 className="sm:text-3xl text-xl mx-auto  mb-5 text-black break-words">
        7. Action Log
      </h5>
      <ContentAccordion
        directoryName={formName}
        contentTitle="What is a Action Log?"
        contentBody={contentBody}
        videoUrl="https://www.youtube.com/embed/84fuJJgVPP0?si=_rinmaDP8dhCxWih"
        tableObject={tableObject}
        contentOpen="Click here to open the content & show info"
        Title="Action Log"
        keyQuestion={keyQuestion}
        outcome={outcome}
        showForm={showForm}
        setShowForm={setShowForm}
      />
      {showForm && (
        <div>
          <h6 className="text-black font-semibold sm:text-2xl text-lg mx-auto  my-5">
            Maturity Assessment
          </h6>
          <p className="text-black sm:text-base text-sm mx-auto my-5">
            Use this form to understand and assess the maturity of this element
            as you observe it on the site
          </p>
          <FormTabSteps
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            currentQuestionSection={currentQuestionSection}
            setCurrentQuestionSection={setCurrentQuestionSection}
          />
          <div className="space-y-10 divide-y divide-black/10">
            {user.name &&
            user.family_name &&
            user.userHasInfo &&
            !user.guest &&
            !user.temp ? (
              <form onSubmit={handleSubmit}>
                {/* <PersonalInfoSection
              user={user}
              viewState={viewState}
              handleView={handleView}
            /> */}
                {currentSection === 0 && (
                  <CompanyInfoSection
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleDateChange={handleDateChange}
                    handleToggle={handleToggle}
                    isSubmitting={isSubmitting}
                    errors={errors}
                    operations={operations}
                    departments={departments}
                    crews={crews}
                    toggleState={toggleState}
                    coachees={coachees}
                    nextSection={nextSection}
                    prevSection={prevSection}
                  />
                )}
                {currentSection === 1 && (
                  <QuestionSection
                    questions={questions}
                    questionsText={questionsText}
                    comments={comments}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    handleQuestionChange={handleQuestionChange}
                    handleCommentChange={handleCommentChange}
                    sections={sections}
                    viewState={viewState}
                    weights={formattedWeights}
                    total={total}
                    nextSection={nextSection}
                    prevSection={prevSection}
                    currentQuestionSection={currentQuestionSection}
                    setCurrentQuestionSection={setCurrentQuestionSection}
                  />
                )}
                {currentSection === 2 && (
                  <SummarySection
                    handleSubmitReset={handleSubmitReset}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    isSubmitting={isSubmitting}
                    resetStateValues={resetStateValues}
                    directoryName={formName}
                    nextSection={nextSection}
                    prevSection={prevSection}
                  />
                )}
              </form>
            ) : user.userHasInfo && user.guest ? (
              <div className="text-center text-2xl">
                You are not allowed to view this page, ask your coach or admin
                to elevate your permissions.
              </div>
            ) : (
              <UpdateProfileNotice />
            )}
          </div>
        </div>
      )}
    </>
  );
}

import * as React from "react";
const SvgCustomApple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    className="svg-icon"
    {...props}
  >
    <path
      fill="currentColor"
      d="M248.644 123.476c-5.45-29.71 8.598-60.285 25.516-80.89C292.805 19.851 324.802 2.416 352.146.5c4.619 31.149-8.093 61.498-24.826 82.965-17.95 23.062-48.812 40.946-78.676 40.011zm160.39 107.655c8.461-23.606 25.223-44.845 51.227-59.175-26.278-32.792-63.173-51.83-97.99-51.83-46.065 0-65.542 21.947-97.538 21.947-32.96 0-57.965-21.947-97.866-21.947-39.127 0-80.776 23.848-107.19 64.577-9.712 15.055-16.291 33.758-19.879 54.59-9.956 58.439 4.916 134.557 49.279 202.144 21.57 32.796 50.321 69.737 87.881 70.059 33.459.327 42.951-21.392 88.246-21.616 45.362-.258 53.959 21.841 87.372 21.522 37.571-.317 67.906-41.199 89.476-73.991 15.359-23.532 21.167-35.418 33.11-62.023-60.727-22.901-85.703-89.817-66.128-144.257z"
    />
  </svg>
);
export default SvgCustomApple;

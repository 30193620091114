import { API, graphqlOperation } from "aws-amplify";

/**
 * Fetches all paginated results for a given GraphQL query.
 * @param {Object} query - The GraphQL query object.
 * @param {Object} variables - Variables to be passed to the query.
 * @returns {Promise<Array>} - Returns a Promise that resolves with all results.
 */
async function fetchAllPaginatedResults(query, variables = {}) {
  let allResults = [];
  let nextToken = null;
  const limit = 1000;

  try {
    do {
      const response = await API.graphql(
        graphqlOperation(query, {
          ...variables,
          limit,
          nextToken,
        }),
      );

      const queryName = Object.keys(response.data)[0];
      const newResults = response.data[queryName].items;
      allResults = allResults.concat(newResults);
      nextToken = response.data[queryName].nextToken;
    } while (nextToken);

    return allResults;
  } catch (error) {
    console.error(`Error fetching paginated results:`, error);
    throw error;
  }
}

export { fetchAllPaginatedResults };

import * as React from "react";
const SvgCustomNpm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 2500 2500"
    className="svg-icon"
    {...props}
  >
    <path fill="#c00" d="M0 0h2500v2500H0z" />
    <path
      fill="#fffff1"
      d="M1241.5 268.5h-973v1962.9h972.9V763.5h495v1467.9h495V268.5z"
    />
  </svg>
);
export default SvgCustomNpm;

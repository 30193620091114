import React, {
  cloneElement,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from "react";
import { Manager, Popper, Reference } from "react-popper";
import classNames from "classnames";
import { useMatch, useNavigate } from "react-router-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import themeConfig from "../../config/theme.config";
import Icon from "../icon/Icon";

const Dropdown = (props) => {
  const { children, className, isOpen, setIsOpen, tag: Tag } = props;

  const [state, setState] = useState(
    !!(isOpen !== null && !!setIsOpen ? isOpen : false),
  );

  const dropdownRef = useRef(null);

  const classes = classNames("inline-flex");

  // Clicking outside to close
  const closeMenu = useCallback(() => {
    if (isOpen !== null && !!setIsOpen) {
      setIsOpen(false);
    } else {
      setState(false);
    }
  }, [isOpen, setIsOpen]);
  useOnClickOutside(dropdownRef, closeMenu);

  return (
    <Manager>
      {/* @ts-ignore */}
      <Tag
        data-component-name="Dropdown"
        ref={dropdownRef}
        className={classNames(classes, className)}
      >
        {children.map((child, index) =>
          // @ts-ignore
          ["DropdownMenu", "DropdownToggle"].includes(child.type.displayName)
            ? cloneElement(child, {
                isOpen: isOpen !== null && !!setIsOpen ? isOpen : state,
                setIsOpen:
                  isOpen !== null && !!setIsOpen ? setIsOpen : setState,
                // eslint-disable-next-line react/no-array-index-key
                key: index,
              })
            : child,
        )}
      </Tag>
    </Manager>
  );
};
Dropdown.displayName = "Dropdown";
Dropdown.defaultProps = {
  className: undefined,
  isOpen: null,
  setIsOpen: undefined,
  tag: "div",
};

export const DropdownToggle = (props) => {
  const { children, isOpen, setIsOpen, hasIcon } = props;

  const dropdownButtonRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setButtonRef = useCallback((node, ref) => {
    dropdownButtonRef.current = node;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return ref(node);
  }, []);

  return (
    <Reference>
      {({ ref }) =>
        cloneElement(children, {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          "data-component-name": `Dropdown/DropdownToggle [${children.type.displayName}]`,
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          ref: (node) => setButtonRef(node, ref),
          onClick: () => {
            // @ts-ignore
            // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
            children?.props?.onClick ? children.props.onClick() : null;
            if (setIsOpen) {
              setIsOpen(!isOpen);
            }
          },
          rightIcon: hasIcon
            ? // @ts-ignore
              (children.type.displayName === "Button" && "HeroChevronDown") ||
              "HeroChevronRight"
            : undefined,
          isActive: isOpen,
          className: classNames(
            {
              // Only presentation
              show: isOpen,
            },
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
            children?.props?.className,
          ),
          "aria-expanded": isOpen,
        })
      }
    </Reference>
  );
};
DropdownToggle.displayName = "DropdownToggle";
DropdownToggle.defaultProps = {
  hasIcon: true,
  isOpen: false,
  setIsOpen: () => {},
};

export const DropdownMenu = (props) => {
  const {
    isOpen,
    setIsOpen,
    children,
    className,
    placement,
    isCloseAfterLeave,
    borderWidth,
    rounded,
    fallbackPlacements,
    ...rest
  } = props;

  const dropdownListRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setListRef = useCallback((node, ref) => {
    dropdownListRef.current = node;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return ref(node);
  }, []);

  const modifiers = [
    {
      name: "flip",
      options: {
        fallbackPlacements,
      },
    },
  ];

  const onMouseLeave =
    isCloseAfterLeave && setIsOpen ? () => setIsOpen(false) : undefined;

  if (isOpen) {
    return (
      <Popper placement={placement} modifiers={modifiers}>
        {({ ref, style }) => (
          <ul
            data-component-name="Dropdown/DropdownMenu"
            role="presentation"
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            ref={(node) => setListRef(node, ref)}
            // dynamic positioning must be disabled for responsive alignment
            style={style}
            data-placement={placement}
            className={classNames(
              classNames(
                "py-2",
                "z-[9999]",
                "border-zinc-300/25 bg-white shadow-lg dark:border-zinc-800/50 dark:bg-zinc-900",
                [`${borderWidth}`, `${rounded}`],
              ),
              className,
            )}
            onMouseLeave={onMouseLeave}
            {...rest}
          >
            {children}
          </ul>
        )}
      </Popper>
    );
  }
  return null;
};
DropdownMenu.defaultProps = {
  borderWidth: themeConfig.borderWidth,
  className: undefined,
  fallbackPlacements: [`top-start`, `bottom-start`],
  isCloseAfterLeave: true,
  isOpen: false,
  placement: "bottom-start",
  rounded: themeConfig.rounded,
  setIsOpen: () => {},
};
DropdownMenu.displayName = "DropdownMenu";

export const DropdownItem = forwardRef((props, ref) => {
  const {
    children,
    className,
    color,
    colorIntensity,
    isActive,
    icon,
    rightIcon,
    ...rest
  } = props;
  const classes = classNames(
    "px-4 py-2",
    "flex items-center",
    "whitespace-nowrap",
    "cursor-pointer",
    "border-zinc-300/25 dark:border-zinc-800/50",
    {
      [`text-${color}-${colorIntensity}`]: isActive,
      "text-zinc-500 hover:text-zinc-950 dark:hover:text-zinc-100": !isActive,
    },
    themeConfig.transition,
  );
  return (
    <li
      data-component-name="Dropdown/DropdownItem"
      ref={ref}
      className={classNames(classes, className)}
      {...rest}
    >
      {icon && (
        <Icon
          icon={icon}
          className="inline-flex text-xl ltr:mr-1.5 rtl:ml-1.5"
        />
      )}
      {children}
      {rightIcon && (
        <Icon
          icon={rightIcon}
          className="inline-flex text-xl ltr:ml-1.5 rtl:mr-1.5"
        />
      )}
    </li>
  );
});
DropdownItem.defaultProps = {
  className: undefined,
  color: themeConfig.themeColor,
  colorIntensity: themeConfig.themeColorShade,
  isActive: false,
  icon: undefined,
  rightIcon: undefined,
};
DropdownItem.displayName = "DropdownItem";

export const DropdownNavLinkItem = (props) => {
  const { to, children, ...rest } = props;

  const navigate = useNavigate();
  const match = useMatch({ path: to });

  return (
    <DropdownItem {...rest} onClick={() => navigate(to)} isActive={!!match}>
      {children}
    </DropdownItem>
  );
};
DropdownNavLinkItem.defaultProps = {
  ...DropdownItem.defaultProps,
};

export default Dropdown;

import { useState } from "react";

export default function CompanyInfoSection({
  formData,
  handleInputChange,
  handleDateChange,
  handleToggle,
  isSubmitting,
  errors,
  operations,
  departments,
  crews,
  toggleState,
  coachees,
  nextSection,
  prevSection,
}) {
  const [selectValue, setSelectValue] = useState(formData.coachee);

  // Filter Coachees based on selected operation, department and crew
  let filteredCoachees = coachees.sort((a, b) =>
    a.CoachName.localeCompare(b.CoachName),
  );
  if (formData.operation) {
    filteredCoachees = filteredCoachees.filter(
      (coachee) => coachee.OperationName === formData.operation,
    );
  }
  if (formData.department) {
    filteredCoachees = filteredCoachees.filter(
      (coachee) => coachee.DepartmentName === formData.department,
    );
  }
  if (formData.crew) {
    filteredCoachees = filteredCoachees.filter(
      (coachee) => coachee.AltCrewName === formData.crew,
    );
  }
  return (
    <section
      key="Company"
      id="Company"
      className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10"
    >
      <div className="bg-white shadow-sm ring-1 ring-black/5 rounded-xl">
        <div className="px-4 py-6 sm:p-8">
          <div className="max-w-7xl space-y-10">
            <div className="px-4 sm:px-0">
              <h7 className="sm:text-xl text-base font-semibold leading-7 text-black">
                Assessment Detail
              </h7>
              <p className="mt-1 sm:text-base text-sm leading-6 text-gray-600">
                Add assessment info
              </p>
            </div>
            <div className="grid max-w-7xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-2">
                <label
                  htmlFor="operation"
                  className="block text-md font-medium leading-6 text-black"
                >
                  Operation
                </label>
                <div className="mt-2">
                  <select
                    id="operation"
                    name="operation"
                    value={formData.operation}
                    autoComplete="operation-name"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-lg text-sm sm:leading-6"
                    required
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                  >
                    <optgroup label="Operations">
                      <option key="Select-Option" value="">
                        -- Please select from below --
                      </option>
                      {operations
                        .sort((a, b) =>
                          a.OperationName.localeCompare(b.OperationName),
                        )
                        .map((operation) => (
                          <option key={operation.id} value={operation.id}>
                            {operation.OperationName}
                          </option>
                        ))}
                    </optgroup>
                  </select>
                  {errors.operation && (
                    <p className="text-red-500 sm:text-lg text-sm italic">
                      {errors.operation}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="department"
                  className="block text-md font-medium leading-6 text-black"
                >
                  Department
                </label>
                <div className="mt-2">
                  <select
                    id="department"
                    name="department"
                    value={formData.department}
                    autoComplete="department-name"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-lg text-sm sm:leading-6"
                    required
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                  >
                    <optgroup label="Departments">
                      <option key="Select-Option" value="">
                        -- Please select from below --
                      </option>
                      {departments
                        .sort((a, b) =>
                          a.DepartmentName.localeCompare(b.DepartmentName),
                        )
                        .map((department) => (
                          <option key={department.id} value={department.id}>
                            {department.DepartmentName}
                          </option>
                        ))}
                    </optgroup>
                  </select>
                  {errors.department && (
                    <p className="text-red-500 sm:text-lg text-sm italic">
                      {errors.department}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="crew"
                  className="block text-md font-medium leading-6 text-black"
                >
                  Crew
                </label>
                <div className="mt-2">
                  <select
                    id="crew"
                    name="crew"
                    value={formData.crew}
                    autoComplete="crew-name"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-lg text-sm sm:leading-6"
                    required
                    onChange={handleInputChange}
                    disabled={isSubmitting}
                  >
                    <optgroup label="Crews">
                      <option key="Select-Option" value="">
                        -- Please select from below --
                      </option>
                      {crews
                        .sort((a, b) => a.CrewName.localeCompare(b.CrewName))
                        .map((crew) => (
                          <option key={crew.id} value={crew.id}>
                            {crew.CrewName}
                          </option>
                        ))}
                    </optgroup>
                  </select>
                  {errors.crew && (
                    <p className="text-red-500 sm:text-lg text-sm italic">
                      {errors.crew}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="crew"
                  className="block text-md font-medium leading-6 text-black"
                >
                  Coachee
                </label>
                <div className="mt-2">
                  <select
                    id="coachee"
                    name="coachee"
                    value={selectValue}
                    autoComplete="crew-name"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-lg text-sm sm:leading-6"
                    required
                    onChange={(event) => {
                      handleInputChange(event);
                      const { name, value } = event.target;
                      if (
                        name === "department" ||
                        name === "crew" ||
                        name === "operation"
                      ) {
                        setSelectValue("");
                      } else {
                        setSelectValue(value);
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    <optgroup label="Coachees">
                      <option key="Select-Option" value="">
                        -- Please select from below --
                      </option>
                      {filteredCoachees.map((coach) => (
                        <option key={coach.id} value={coach.id}>
                          {coach.CoachName}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                  {errors.coachee && (
                    <p className="text-red-500 sm:text-lg text-sm italic">
                      {errors.coachee}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-span-2 sm:col-span-4">
                <label
                  htmlFor="date"
                  className="block text-md font-medium leading-6 text-black"
                >
                  Date & Time
                </label>
                <div className="mt-2">
                  <input
                    id="date"
                    name="date"
                    type="datetime-local"
                    // value={formData.date}
                    autoComplete="date"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-lg text-sm sm:leading-6"
                    onChange={handleDateChange}
                    disabled={isSubmitting}
                  />
                  {errors.date && (
                    <p className="text-red-500 sm:text-lg text-sm italic">
                      {errors.date}
                    </p>
                  )}
                </div>
              </div>
              {/* 
            <div className="col-span-2">
              <label
                htmlFor="postal-code"
                className="block text-md font-medium leading-6 text-black"
              >
                MO@T Element exists
              </label>
              <div className="mt-2">
                <Toggle enabled={toggleState} setEnabled={handleToggle} />
                {errors.toggle && (
                  <p className="text-red-500 sm:text-lg text-sm italic">{errors.toggle}</p>
                )}
              </div>
            </div> */}
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-black/10 px-4 py-4 sm:px-8">
              <a
                href="#Behaviours"
                className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
                onClick={nextSection}
              >
                Next
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

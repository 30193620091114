/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const invokeChatBot = /* GraphQL */ `
  mutation InvokeChatBot($message: String!) {
    invokeChatBot(message: $message) {
      message
      __typename
    }
  }
`;
export const createOPADataEventsTimeline = /* GraphQL */ `
  mutation CreateOPADataEventsTimeline(
    $input: CreateOPADataEventsTimelineInput!
    $condition: ModelOPADataEventsTimelineConditionInput
  ) {
    createOPADataEventsTimeline(input: $input, condition: $condition) {
      id
      Site
      State
      ShiftDate
      Week
      Month
      Day
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      Unit
      MachineType
      UnitType
      EventOrigin
      EventDescription
      EventValue
      EventSeverity
      EventStart
      EventEnd
      Duration
      __typename
    }
  }
`;
export const updateOPADataEventsTimeline = /* GraphQL */ `
  mutation UpdateOPADataEventsTimeline(
    $input: UpdateOPADataEventsTimelineInput!
    $condition: ModelOPADataEventsTimelineConditionInput
  ) {
    updateOPADataEventsTimeline(input: $input, condition: $condition) {
      id
      Site
      State
      ShiftDate
      Week
      Month
      Day
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      Unit
      MachineType
      UnitType
      EventOrigin
      EventDescription
      EventValue
      EventSeverity
      EventStart
      EventEnd
      Duration
      __typename
    }
  }
`;
export const deleteOPADataEventsTimeline = /* GraphQL */ `
  mutation DeleteOPADataEventsTimeline(
    $input: DeleteOPADataEventsTimelineInput!
    $condition: ModelOPADataEventsTimelineConditionInput
  ) {
    deleteOPADataEventsTimeline(input: $input, condition: $condition) {
      id
      Site
      State
      ShiftDate
      Week
      Month
      Day
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      Unit
      MachineType
      UnitType
      EventOrigin
      EventDescription
      EventValue
      EventSeverity
      EventStart
      EventEnd
      Duration
      __typename
    }
  }
`;
export const createOPADataOperatorTimeline = /* GraphQL */ `
  mutation CreateOPADataOperatorTimeline(
    $input: CreateOPADataOperatorTimelineInput!
    $condition: ModelOPADataOperatorTimelineConditionInput
  ) {
    createOPADataOperatorTimeline(input: $input, condition: $condition) {
      id
      Site
      ShiftDate
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      EquipmentCategory
      EquipmentCode
      Model
      Starttime
      Endtime
      Duration
      Activity
      Category
      EngineStatus
      assignedTrucks {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateOPADataOperatorTimeline = /* GraphQL */ `
  mutation UpdateOPADataOperatorTimeline(
    $input: UpdateOPADataOperatorTimelineInput!
    $condition: ModelOPADataOperatorTimelineConditionInput
  ) {
    updateOPADataOperatorTimeline(input: $input, condition: $condition) {
      id
      Site
      ShiftDate
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      EquipmentCategory
      EquipmentCode
      Model
      Starttime
      Endtime
      Duration
      Activity
      Category
      EngineStatus
      assignedTrucks {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteOPADataOperatorTimeline = /* GraphQL */ `
  mutation DeleteOPADataOperatorTimeline(
    $input: DeleteOPADataOperatorTimelineInput!
    $condition: ModelOPADataOperatorTimelineConditionInput
  ) {
    deleteOPADataOperatorTimeline(input: $input, condition: $condition) {
      id
      Site
      ShiftDate
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      EquipmentCategory
      EquipmentCode
      Model
      Starttime
      Endtime
      Duration
      Activity
      Category
      EngineStatus
      assignedTrucks {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createOPAData = /* GraphQL */ `
  mutation CreateOPAData(
    $input: CreateOPADataInput!
    $condition: ModelOPADataConditionInput
  ) {
    createOPAData(input: $input, condition: $condition) {
      id
      Site
      Crew
      CycleDuration
      CycleEnabled
      CycleEnd
      CycleId
      CycleStart
      DiggerOperatorId
      DigUnitOperatorName
      Dumping
      DumpPosGeo
      InstantaneousGiggerRate
      LoadDate
      LoaderDesc
      LoaderName
      Loading
      LoadPosGeo
      MaterialName
      PassCount
      PayloadBCM
      PayloadTon
      QueuingEmpty
      Respotting
      ShiftCode
      ShiftDate
      ShiftId
      SpottingEmpty
      StopLoaded
      StoppedEmpty
      TotalActiveCycletime
      TotalCycleTime
      TravelEmptyForwards
      TravelEmptyReverse
      TravelLoadedDistance
      TravelLoadedForwards
      TravelLoadedReverse
      TravlEmptyDistance
      TruckDesc
      TruckName
      TruckOperatorId
      TruckOperatorName
      WaitingOnLoader
      waste
      WOT
      oPADataOperatorTimelineAssignedTrucksId
      __typename
    }
  }
`;
export const updateOPAData = /* GraphQL */ `
  mutation UpdateOPAData(
    $input: UpdateOPADataInput!
    $condition: ModelOPADataConditionInput
  ) {
    updateOPAData(input: $input, condition: $condition) {
      id
      Site
      Crew
      CycleDuration
      CycleEnabled
      CycleEnd
      CycleId
      CycleStart
      DiggerOperatorId
      DigUnitOperatorName
      Dumping
      DumpPosGeo
      InstantaneousGiggerRate
      LoadDate
      LoaderDesc
      LoaderName
      Loading
      LoadPosGeo
      MaterialName
      PassCount
      PayloadBCM
      PayloadTon
      QueuingEmpty
      Respotting
      ShiftCode
      ShiftDate
      ShiftId
      SpottingEmpty
      StopLoaded
      StoppedEmpty
      TotalActiveCycletime
      TotalCycleTime
      TravelEmptyForwards
      TravelEmptyReverse
      TravelLoadedDistance
      TravelLoadedForwards
      TravelLoadedReverse
      TravlEmptyDistance
      TruckDesc
      TruckName
      TruckOperatorId
      TruckOperatorName
      WaitingOnLoader
      waste
      WOT
      oPADataOperatorTimelineAssignedTrucksId
      __typename
    }
  }
`;
export const deleteOPAData = /* GraphQL */ `
  mutation DeleteOPAData(
    $input: DeleteOPADataInput!
    $condition: ModelOPADataConditionInput
  ) {
    deleteOPAData(input: $input, condition: $condition) {
      id
      Site
      Crew
      CycleDuration
      CycleEnabled
      CycleEnd
      CycleId
      CycleStart
      DiggerOperatorId
      DigUnitOperatorName
      Dumping
      DumpPosGeo
      InstantaneousGiggerRate
      LoadDate
      LoaderDesc
      LoaderName
      Loading
      LoadPosGeo
      MaterialName
      PassCount
      PayloadBCM
      PayloadTon
      QueuingEmpty
      Respotting
      ShiftCode
      ShiftDate
      ShiftId
      SpottingEmpty
      StopLoaded
      StoppedEmpty
      TotalActiveCycletime
      TotalCycleTime
      TravelEmptyForwards
      TravelEmptyReverse
      TravelLoadedDistance
      TravelLoadedForwards
      TravelLoadedReverse
      TravlEmptyDistance
      TruckDesc
      TruckName
      TruckOperatorId
      TruckOperatorName
      WaitingOnLoader
      waste
      WOT
      oPADataOperatorTimelineAssignedTrucksId
      __typename
    }
  }
`;
export const createDepartmentName = /* GraphQL */ `
  mutation CreateDepartmentName(
    $input: CreateDepartmentNameInput!
    $condition: ModelDepartmentNameConditionInput
  ) {
    createDepartmentName(input: $input, condition: $condition) {
      id
      DepartmentName
      CrewNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      operationNameDepartmentNamesId
      __typename
    }
  }
`;
export const updateDepartmentName = /* GraphQL */ `
  mutation UpdateDepartmentName(
    $input: UpdateDepartmentNameInput!
    $condition: ModelDepartmentNameConditionInput
  ) {
    updateDepartmentName(input: $input, condition: $condition) {
      id
      DepartmentName
      CrewNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      operationNameDepartmentNamesId
      __typename
    }
  }
`;
export const deleteDepartmentName = /* GraphQL */ `
  mutation DeleteDepartmentName(
    $input: DeleteDepartmentNameInput!
    $condition: ModelDepartmentNameConditionInput
  ) {
    deleteDepartmentName(input: $input, condition: $condition) {
      id
      DepartmentName
      CrewNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      operationNameDepartmentNamesId
      __typename
    }
  }
`;
export const createBoard = /* GraphQL */ `
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
      id
      name
      key
      description
      site
      department
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBoard = /* GraphQL */ `
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      id
      name
      key
      description
      site
      department
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBoard = /* GraphQL */ `
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      id
      name
      key
      description
      site
      department
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      label
      board {
        id
        name
        key
        description
        site
        department
        createdAt
        updatedAt
        __typename
      }
      assignedTo
      creator
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      boardTasksId
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      label
      board {
        id
        name
        key
        description
        site
        department
        createdAt
        updatedAt
        __typename
      }
      assignedTo
      creator
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      boardTasksId
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      label
      board {
        id
        name
        key
        description
        site
        department
        createdAt
        updatedAt
        __typename
      }
      assignedTo
      creator
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      boardTasksId
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      text
      task {
        id
        title
        description
        status
        label
        assignedTo
        creator
        createdAt
        updatedAt
        boardTasksId
        __typename
      }
      user
      createdAt
      updatedAt
      taskCommentsId
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      text
      task {
        id
        title
        description
        status
        label
        assignedTo
        creator
        createdAt
        updatedAt
        boardTasksId
        __typename
      }
      user
      createdAt
      updatedAt
      taskCommentsId
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      text
      task {
        id
        title
        description
        status
        label
        assignedTo
        creator
        createdAt
        updatedAt
        boardTasksId
        __typename
      }
      user
      createdAt
      updatedAt
      taskCommentsId
      __typename
    }
  }
`;
export const createOperationName = /* GraphQL */ `
  mutation CreateOperationName(
    $input: CreateOperationNameInput!
    $condition: ModelOperationNameConditionInput
  ) {
    createOperationName(input: $input, condition: $condition) {
      id
      OperationName
      DepartmentNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOperationName = /* GraphQL */ `
  mutation UpdateOperationName(
    $input: UpdateOperationNameInput!
    $condition: ModelOperationNameConditionInput
  ) {
    updateOperationName(input: $input, condition: $condition) {
      id
      OperationName
      DepartmentNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOperationName = /* GraphQL */ `
  mutation DeleteOperationName(
    $input: DeleteOperationNameInput!
    $condition: ModelOperationNameConditionInput
  ) {
    deleteOperationName(input: $input, condition: $condition) {
      id
      OperationName
      DepartmentNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrewName = /* GraphQL */ `
  mutation CreateCrewName(
    $input: CreateCrewNameInput!
    $condition: ModelCrewNameConditionInput
  ) {
    createCrewName(input: $input, condition: $condition) {
      id
      CrewName
      CoachNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      departmentNameCrewNamesId
      __typename
    }
  }
`;
export const updateCrewName = /* GraphQL */ `
  mutation UpdateCrewName(
    $input: UpdateCrewNameInput!
    $condition: ModelCrewNameConditionInput
  ) {
    updateCrewName(input: $input, condition: $condition) {
      id
      CrewName
      CoachNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      departmentNameCrewNamesId
      __typename
    }
  }
`;
export const deleteCrewName = /* GraphQL */ `
  mutation DeleteCrewName(
    $input: DeleteCrewNameInput!
    $condition: ModelCrewNameConditionInput
  ) {
    deleteCrewName(input: $input, condition: $condition) {
      id
      CrewName
      CoachNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      departmentNameCrewNamesId
      __typename
    }
  }
`;
export const createPreStart = /* GraphQL */ `
  mutation CreatePreStart(
    $input: CreatePreStartInput!
    $condition: ModelPreStartConditionInput
  ) {
    createPreStart(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePreStart = /* GraphQL */ `
  mutation UpdatePreStart(
    $input: UpdatePreStartInput!
    $condition: ModelPreStartConditionInput
  ) {
    updatePreStart(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePreStart = /* GraphQL */ `
  mutation DeletePreStart(
    $input: DeletePreStartInput!
    $condition: ModelPreStartConditionInput
  ) {
    deletePreStart(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShiftHandover = /* GraphQL */ `
  mutation CreateShiftHandover(
    $input: CreateShiftHandoverInput!
    $condition: ModelShiftHandoverConditionInput
  ) {
    createShiftHandover(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShiftHandover = /* GraphQL */ `
  mutation UpdateShiftHandover(
    $input: UpdateShiftHandoverInput!
    $condition: ModelShiftHandoverConditionInput
  ) {
    updateShiftHandover(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShiftHandover = /* GraphQL */ `
  mutation DeleteShiftHandover(
    $input: DeleteShiftHandoverInput!
    $condition: ModelShiftHandoverConditionInput
  ) {
    deleteShiftHandover(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPreShiftAlignment = /* GraphQL */ `
  mutation CreatePreShiftAlignment(
    $input: CreatePreShiftAlignmentInput!
    $condition: ModelPreShiftAlignmentConditionInput
  ) {
    createPreShiftAlignment(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePreShiftAlignment = /* GraphQL */ `
  mutation UpdatePreShiftAlignment(
    $input: UpdatePreShiftAlignmentInput!
    $condition: ModelPreShiftAlignmentConditionInput
  ) {
    updatePreShiftAlignment(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePreShiftAlignment = /* GraphQL */ `
  mutation DeletePreShiftAlignment(
    $input: DeletePreShiftAlignmentInput!
    $condition: ModelPreShiftAlignmentConditionInput
  ) {
    deletePreShiftAlignment(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuarterlyMonthlyPlan = /* GraphQL */ `
  mutation CreateQuarterlyMonthlyPlan(
    $input: CreateQuarterlyMonthlyPlanInput!
    $condition: ModelQuarterlyMonthlyPlanConditionInput
  ) {
    createQuarterlyMonthlyPlan(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuarterlyMonthlyPlan = /* GraphQL */ `
  mutation UpdateQuarterlyMonthlyPlan(
    $input: UpdateQuarterlyMonthlyPlanInput!
    $condition: ModelQuarterlyMonthlyPlanConditionInput
  ) {
    updateQuarterlyMonthlyPlan(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuarterlyMonthlyPlan = /* GraphQL */ `
  mutation DeleteQuarterlyMonthlyPlan(
    $input: DeleteQuarterlyMonthlyPlanInput!
    $condition: ModelQuarterlyMonthlyPlanConditionInput
  ) {
    deleteQuarterlyMonthlyPlan(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMonthlyPlanningMeeting = /* GraphQL */ `
  mutation CreateMonthlyPlanningMeeting(
    $input: CreateMonthlyPlanningMeetingInput!
    $condition: ModelMonthlyPlanningMeetingConditionInput
  ) {
    createMonthlyPlanningMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMonthlyPlanningMeeting = /* GraphQL */ `
  mutation UpdateMonthlyPlanningMeeting(
    $input: UpdateMonthlyPlanningMeetingInput!
    $condition: ModelMonthlyPlanningMeetingConditionInput
  ) {
    updateMonthlyPlanningMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMonthlyPlanningMeeting = /* GraphQL */ `
  mutation DeleteMonthlyPlanningMeeting(
    $input: DeleteMonthlyPlanningMeetingInput!
    $condition: ModelMonthlyPlanningMeetingConditionInput
  ) {
    deleteMonthlyPlanningMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeeklyPlanSchedule = /* GraphQL */ `
  mutation CreateWeeklyPlanSchedule(
    $input: CreateWeeklyPlanScheduleInput!
    $condition: ModelWeeklyPlanScheduleConditionInput
  ) {
    createWeeklyPlanSchedule(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeeklyPlanSchedule = /* GraphQL */ `
  mutation UpdateWeeklyPlanSchedule(
    $input: UpdateWeeklyPlanScheduleInput!
    $condition: ModelWeeklyPlanScheduleConditionInput
  ) {
    updateWeeklyPlanSchedule(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeeklyPlanSchedule = /* GraphQL */ `
  mutation DeleteWeeklyPlanSchedule(
    $input: DeleteWeeklyPlanScheduleInput!
    $condition: ModelWeeklyPlanScheduleConditionInput
  ) {
    deleteWeeklyPlanSchedule(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeeklyPrePlanning = /* GraphQL */ `
  mutation CreateWeeklyPrePlanning(
    $input: CreateWeeklyPrePlanningInput!
    $condition: ModelWeeklyPrePlanningConditionInput
  ) {
    createWeeklyPrePlanning(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeeklyPrePlanning = /* GraphQL */ `
  mutation UpdateWeeklyPrePlanning(
    $input: UpdateWeeklyPrePlanningInput!
    $condition: ModelWeeklyPrePlanningConditionInput
  ) {
    updateWeeklyPrePlanning(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeeklyPrePlanning = /* GraphQL */ `
  mutation DeleteWeeklyPrePlanning(
    $input: DeleteWeeklyPrePlanningInput!
    $condition: ModelWeeklyPrePlanningConditionInput
  ) {
    deleteWeeklyPrePlanning(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeeklySitePlanningMeeting = /* GraphQL */ `
  mutation CreateWeeklySitePlanningMeeting(
    $input: CreateWeeklySitePlanningMeetingInput!
    $condition: ModelWeeklySitePlanningMeetingConditionInput
  ) {
    createWeeklySitePlanningMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeeklySitePlanningMeeting = /* GraphQL */ `
  mutation UpdateWeeklySitePlanningMeeting(
    $input: UpdateWeeklySitePlanningMeetingInput!
    $condition: ModelWeeklySitePlanningMeetingConditionInput
  ) {
    updateWeeklySitePlanningMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeeklySitePlanningMeeting = /* GraphQL */ `
  mutation DeleteWeeklySitePlanningMeeting(
    $input: DeleteWeeklySitePlanningMeetingInput!
    $condition: ModelWeeklySitePlanningMeetingConditionInput
  ) {
    deleteWeeklySitePlanningMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeeklyCommitmentMeeting = /* GraphQL */ `
  mutation CreateWeeklyCommitmentMeeting(
    $input: CreateWeeklyCommitmentMeetingInput!
    $condition: ModelWeeklyCommitmentMeetingConditionInput
  ) {
    createWeeklyCommitmentMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeeklyCommitmentMeeting = /* GraphQL */ `
  mutation UpdateWeeklyCommitmentMeeting(
    $input: UpdateWeeklyCommitmentMeetingInput!
    $condition: ModelWeeklyCommitmentMeetingConditionInput
  ) {
    updateWeeklyCommitmentMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeeklyCommitmentMeeting = /* GraphQL */ `
  mutation DeleteWeeklyCommitmentMeeting(
    $input: DeleteWeeklyCommitmentMeetingInput!
    $condition: ModelWeeklyCommitmentMeetingConditionInput
  ) {
    deleteWeeklyCommitmentMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDailyCommitmentMeeting = /* GraphQL */ `
  mutation CreateDailyCommitmentMeeting(
    $input: CreateDailyCommitmentMeetingInput!
    $condition: ModelDailyCommitmentMeetingConditionInput
  ) {
    createDailyCommitmentMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDailyCommitmentMeeting = /* GraphQL */ `
  mutation UpdateDailyCommitmentMeeting(
    $input: UpdateDailyCommitmentMeetingInput!
    $condition: ModelDailyCommitmentMeetingConditionInput
  ) {
    updateDailyCommitmentMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDailyCommitmentMeeting = /* GraphQL */ `
  mutation DeleteDailyCommitmentMeeting(
    $input: DeleteDailyCommitmentMeetingInput!
    $condition: ModelDailyCommitmentMeetingConditionInput
  ) {
    deleteDailyCommitmentMeeting(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShiftPlan = /* GraphQL */ `
  mutation CreateShiftPlan(
    $input: CreateShiftPlanInput!
    $condition: ModelShiftPlanConditionInput
  ) {
    createShiftPlan(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShiftPlan = /* GraphQL */ `
  mutation UpdateShiftPlan(
    $input: UpdateShiftPlanInput!
    $condition: ModelShiftPlanConditionInput
  ) {
    updateShiftPlan(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShiftPlan = /* GraphQL */ `
  mutation DeleteShiftPlan(
    $input: DeleteShiftPlanInput!
    $condition: ModelShiftPlanConditionInput
  ) {
    deleteShiftPlan(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShortIntervalControl = /* GraphQL */ `
  mutation CreateShortIntervalControl(
    $input: CreateShortIntervalControlInput!
    $condition: ModelShortIntervalControlConditionInput
  ) {
    createShortIntervalControl(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShortIntervalControl = /* GraphQL */ `
  mutation UpdateShortIntervalControl(
    $input: UpdateShortIntervalControlInput!
    $condition: ModelShortIntervalControlConditionInput
  ) {
    updateShortIntervalControl(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShortIntervalControl = /* GraphQL */ `
  mutation DeleteShortIntervalControl(
    $input: DeleteShortIntervalControlInput!
    $condition: ModelShortIntervalControlConditionInput
  ) {
    deleteShortIntervalControl(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShiftReport = /* GraphQL */ `
  mutation CreateShiftReport(
    $input: CreateShiftReportInput!
    $condition: ModelShiftReportConditionInput
  ) {
    createShiftReport(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShiftReport = /* GraphQL */ `
  mutation UpdateShiftReport(
    $input: UpdateShiftReportInput!
    $condition: ModelShiftReportConditionInput
  ) {
    updateShiftReport(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShiftReport = /* GraphQL */ `
  mutation DeleteShiftReport(
    $input: DeleteShiftReportInput!
    $condition: ModelShiftReportConditionInput
  ) {
    deleteShiftReport(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShiftDebrief = /* GraphQL */ `
  mutation CreateShiftDebrief(
    $input: CreateShiftDebriefInput!
    $condition: ModelShiftDebriefConditionInput
  ) {
    createShiftDebrief(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShiftDebrief = /* GraphQL */ `
  mutation UpdateShiftDebrief(
    $input: UpdateShiftDebriefInput!
    $condition: ModelShiftDebriefConditionInput
  ) {
    updateShiftDebrief(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShiftDebrief = /* GraphQL */ `
  mutation DeleteShiftDebrief(
    $input: DeleteShiftDebriefInput!
    $condition: ModelShiftDebriefConditionInput
  ) {
    deleteShiftDebrief(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPerformanceReports = /* GraphQL */ `
  mutation CreatePerformanceReports(
    $input: CreatePerformanceReportsInput!
    $condition: ModelPerformanceReportsConditionInput
  ) {
    createPerformanceReports(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePerformanceReports = /* GraphQL */ `
  mutation UpdatePerformanceReports(
    $input: UpdatePerformanceReportsInput!
    $condition: ModelPerformanceReportsConditionInput
  ) {
    updatePerformanceReports(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePerformanceReports = /* GraphQL */ `
  mutation DeletePerformanceReports(
    $input: DeletePerformanceReportsInput!
    $condition: ModelPerformanceReportsConditionInput
  ) {
    deletePerformanceReports(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDepartmentDailyReview = /* GraphQL */ `
  mutation CreateDepartmentDailyReview(
    $input: CreateDepartmentDailyReviewInput!
    $condition: ModelDepartmentDailyReviewConditionInput
  ) {
    createDepartmentDailyReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDepartmentDailyReview = /* GraphQL */ `
  mutation UpdateDepartmentDailyReview(
    $input: UpdateDepartmentDailyReviewInput!
    $condition: ModelDepartmentDailyReviewConditionInput
  ) {
    updateDepartmentDailyReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDepartmentDailyReview = /* GraphQL */ `
  mutation DeleteDepartmentDailyReview(
    $input: DeleteDepartmentDailyReviewInput!
    $condition: ModelDepartmentDailyReviewConditionInput
  ) {
    deleteDepartmentDailyReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDailySiteReview = /* GraphQL */ `
  mutation CreateDailySiteReview(
    $input: CreateDailySiteReviewInput!
    $condition: ModelDailySiteReviewConditionInput
  ) {
    createDailySiteReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDailySiteReview = /* GraphQL */ `
  mutation UpdateDailySiteReview(
    $input: UpdateDailySiteReviewInput!
    $condition: ModelDailySiteReviewConditionInput
  ) {
    updateDailySiteReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDailySiteReview = /* GraphQL */ `
  mutation DeleteDailySiteReview(
    $input: DeleteDailySiteReviewInput!
    $condition: ModelDailySiteReviewConditionInput
  ) {
    deleteDailySiteReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPerformanceAnalysis = /* GraphQL */ `
  mutation CreatePerformanceAnalysis(
    $input: CreatePerformanceAnalysisInput!
    $condition: ModelPerformanceAnalysisConditionInput
  ) {
    createPerformanceAnalysis(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePerformanceAnalysis = /* GraphQL */ `
  mutation UpdatePerformanceAnalysis(
    $input: UpdatePerformanceAnalysisInput!
    $condition: ModelPerformanceAnalysisConditionInput
  ) {
    updatePerformanceAnalysis(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePerformanceAnalysis = /* GraphQL */ `
  mutation DeletePerformanceAnalysis(
    $input: DeletePerformanceAnalysisInput!
    $condition: ModelPerformanceAnalysisConditionInput
  ) {
    deletePerformanceAnalysis(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeeklyDepartmentReview = /* GraphQL */ `
  mutation CreateWeeklyDepartmentReview(
    $input: CreateWeeklyDepartmentReviewInput!
    $condition: ModelWeeklyDepartmentReviewConditionInput
  ) {
    createWeeklyDepartmentReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeeklyDepartmentReview = /* GraphQL */ `
  mutation UpdateWeeklyDepartmentReview(
    $input: UpdateWeeklyDepartmentReviewInput!
    $condition: ModelWeeklyDepartmentReviewConditionInput
  ) {
    updateWeeklyDepartmentReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeeklyDepartmentReview = /* GraphQL */ `
  mutation DeleteWeeklyDepartmentReview(
    $input: DeleteWeeklyDepartmentReviewInput!
    $condition: ModelWeeklyDepartmentReviewConditionInput
  ) {
    deleteWeeklyDepartmentReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWeeklySiteReview = /* GraphQL */ `
  mutation CreateWeeklySiteReview(
    $input: CreateWeeklySiteReviewInput!
    $condition: ModelWeeklySiteReviewConditionInput
  ) {
    createWeeklySiteReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWeeklySiteReview = /* GraphQL */ `
  mutation UpdateWeeklySiteReview(
    $input: UpdateWeeklySiteReviewInput!
    $condition: ModelWeeklySiteReviewConditionInput
  ) {
    updateWeeklySiteReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWeeklySiteReview = /* GraphQL */ `
  mutation DeleteWeeklySiteReview(
    $input: DeleteWeeklySiteReviewInput!
    $condition: ModelWeeklySiteReviewConditionInput
  ) {
    deleteWeeklySiteReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManagementReports = /* GraphQL */ `
  mutation CreateManagementReports(
    $input: CreateManagementReportsInput!
    $condition: ModelManagementReportsConditionInput
  ) {
    createManagementReports(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManagementReports = /* GraphQL */ `
  mutation UpdateManagementReports(
    $input: UpdateManagementReportsInput!
    $condition: ModelManagementReportsConditionInput
  ) {
    updateManagementReports(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManagementReports = /* GraphQL */ `
  mutation DeleteManagementReports(
    $input: DeleteManagementReportsInput!
    $condition: ModelManagementReportsConditionInput
  ) {
    deleteManagementReports(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMonthlyReview = /* GraphQL */ `
  mutation CreateMonthlyReview(
    $input: CreateMonthlyReviewInput!
    $condition: ModelMonthlyReviewConditionInput
  ) {
    createMonthlyReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMonthlyReview = /* GraphQL */ `
  mutation UpdateMonthlyReview(
    $input: UpdateMonthlyReviewInput!
    $condition: ModelMonthlyReviewConditionInput
  ) {
    updateMonthlyReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMonthlyReview = /* GraphQL */ `
  mutation DeleteMonthlyReview(
    $input: DeleteMonthlyReviewInput!
    $condition: ModelMonthlyReviewConditionInput
  ) {
    deleteMonthlyReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuarterlyForecastReview = /* GraphQL */ `
  mutation CreateQuarterlyForecastReview(
    $input: CreateQuarterlyForecastReviewInput!
    $condition: ModelQuarterlyForecastReviewConditionInput
  ) {
    createQuarterlyForecastReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuarterlyForecastReview = /* GraphQL */ `
  mutation UpdateQuarterlyForecastReview(
    $input: UpdateQuarterlyForecastReviewInput!
    $condition: ModelQuarterlyForecastReviewConditionInput
  ) {
    updateQuarterlyForecastReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuarterlyForecastReview = /* GraphQL */ `
  mutation DeleteQuarterlyForecastReview(
    $input: DeleteQuarterlyForecastReviewInput!
    $condition: ModelQuarterlyForecastReviewConditionInput
  ) {
    deleteQuarterlyForecastReview(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActionLog = /* GraphQL */ `
  mutation CreateActionLog(
    $input: CreateActionLogInput!
    $condition: ModelActionLogConditionInput
  ) {
    createActionLog(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActionLog = /* GraphQL */ `
  mutation UpdateActionLog(
    $input: UpdateActionLogInput!
    $condition: ModelActionLogConditionInput
  ) {
    updateActionLog(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActionLog = /* GraphQL */ `
  mutation DeleteActionLog(
    $input: DeleteActionLogInput!
    $condition: ModelActionLogConditionInput
  ) {
    deleteActionLog(input: $input, condition: $condition) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBusinessImprovementCapitalProjects = /* GraphQL */ `
  mutation CreateBusinessImprovementCapitalProjects(
    $input: CreateBusinessImprovementCapitalProjectsInput!
    $condition: ModelBusinessImprovementCapitalProjectsConditionInput
  ) {
    createBusinessImprovementCapitalProjects(
      input: $input
      condition: $condition
    ) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBusinessImprovementCapitalProjects = /* GraphQL */ `
  mutation UpdateBusinessImprovementCapitalProjects(
    $input: UpdateBusinessImprovementCapitalProjectsInput!
    $condition: ModelBusinessImprovementCapitalProjectsConditionInput
  ) {
    updateBusinessImprovementCapitalProjects(
      input: $input
      condition: $condition
    ) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBusinessImprovementCapitalProjects = /* GraphQL */ `
  mutation DeleteBusinessImprovementCapitalProjects(
    $input: DeleteBusinessImprovementCapitalProjectsInput!
    $condition: ModelBusinessImprovementCapitalProjectsConditionInput
  ) {
    deleteBusinessImprovementCapitalProjects(
      input: $input
      condition: $condition
    ) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAllForms = /* GraphQL */ `
  mutation CreateAllForms(
    $input: CreateAllFormsInput!
    $condition: ModelAllFormsConditionInput
  ) {
    createAllForms(input: $input, condition: $condition) {
      id
      Field0 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field1 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field2 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field3 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field4 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field5 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field6 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field7 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field8 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field9 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field10 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field11 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field12 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field13 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field14 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field15 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field16 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field17 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field18 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field19 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field20 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field21 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field22 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field23 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field24 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAllForms = /* GraphQL */ `
  mutation UpdateAllForms(
    $input: UpdateAllFormsInput!
    $condition: ModelAllFormsConditionInput
  ) {
    updateAllForms(input: $input, condition: $condition) {
      id
      Field0 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field1 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field2 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field3 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field4 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field5 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field6 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field7 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field8 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field9 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field10 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field11 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field12 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field13 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field14 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field15 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field16 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field17 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field18 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field19 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field20 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field21 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field22 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field23 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field24 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAllForms = /* GraphQL */ `
  mutation DeleteAllForms(
    $input: DeleteAllFormsInput!
    $condition: ModelAllFormsConditionInput
  ) {
    deleteAllForms(input: $input, condition: $condition) {
      id
      Field0 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field1 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field2 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field3 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field4 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field5 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field6 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field7 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field8 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field9 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field10 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field11 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field12 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field13 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field14 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field15 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field16 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field17 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field18 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field19 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field20 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field21 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field22 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field23 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field24 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFormScore = /* GraphQL */ `
  mutation CreateFormScore(
    $input: CreateFormScoreInput!
    $condition: ModelFormScoreConditionInput
  ) {
    createFormScore(input: $input, condition: $condition) {
      id
      FormName
      WeightedScore
      OperationName
      DepartmentName
      CrewName
      CoachName
      FirstName
      LastName
      FormDate
      __typename
    }
  }
`;
export const updateFormScore = /* GraphQL */ `
  mutation UpdateFormScore(
    $input: UpdateFormScoreInput!
    $condition: ModelFormScoreConditionInput
  ) {
    updateFormScore(input: $input, condition: $condition) {
      id
      FormName
      WeightedScore
      OperationName
      DepartmentName
      CrewName
      CoachName
      FirstName
      LastName
      FormDate
      __typename
    }
  }
`;
export const deleteFormScore = /* GraphQL */ `
  mutation DeleteFormScore(
    $input: DeleteFormScoreInput!
    $condition: ModelFormScoreConditionInput
  ) {
    deleteFormScore(input: $input, condition: $condition) {
      id
      FormName
      WeightedScore
      OperationName
      DepartmentName
      CrewName
      CoachName
      FirstName
      LastName
      FormDate
      __typename
    }
  }
`;
export const createCoachName = /* GraphQL */ `
  mutation CreateCoachName(
    $input: CreateCoachNameInput!
    $condition: ModelCoachNameConditionInput
  ) {
    createCoachName(input: $input, condition: $condition) {
      id
      userId
      CoachName
      CrewName
      coach
      admin
      guest
      deleted
      altEmail
      AltCrewName
      DepartmentName
      OperationName
      temp
      userEmail
      createdAt
      updatedAt
      crewNameCoachNamesId
      __typename
    }
  }
`;
export const updateCoachName = /* GraphQL */ `
  mutation UpdateCoachName(
    $input: UpdateCoachNameInput!
    $condition: ModelCoachNameConditionInput
  ) {
    updateCoachName(input: $input, condition: $condition) {
      id
      userId
      CoachName
      CrewName
      coach
      admin
      guest
      deleted
      altEmail
      AltCrewName
      DepartmentName
      OperationName
      temp
      userEmail
      createdAt
      updatedAt
      crewNameCoachNamesId
      __typename
    }
  }
`;
export const deleteCoachName = /* GraphQL */ `
  mutation DeleteCoachName(
    $input: DeleteCoachNameInput!
    $condition: ModelCoachNameConditionInput
  ) {
    deleteCoachName(input: $input, condition: $condition) {
      id
      userId
      CoachName
      CrewName
      coach
      admin
      guest
      deleted
      altEmail
      AltCrewName
      DepartmentName
      OperationName
      temp
      userEmail
      createdAt
      updatedAt
      crewNameCoachNamesId
      __typename
    }
  }
`;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOPADataEventsTimeline = /* GraphQL */ `
  query GetOPADataEventsTimeline($id: ID!) {
    getOPADataEventsTimeline(id: $id) {
      id
      Site
      State
      ShiftDate
      Week
      Month
      Day
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      Unit
      MachineType
      UnitType
      EventOrigin
      EventDescription
      EventValue
      EventSeverity
      EventStart
      EventEnd
      Duration
      __typename
    }
  }
`;
export const listOPADataEventsTimelines = /* GraphQL */ `
  query ListOPADataEventsTimelines(
    $id: ID
    $filter: ModelOPADataEventsTimelineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOPADataEventsTimelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Site
        State
        ShiftDate
        Week
        Month
        Day
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        Unit
        MachineType
        UnitType
        EventOrigin
        EventDescription
        EventValue
        EventSeverity
        EventStart
        EventEnd
        Duration
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOPADataOperatorTimeline = /* GraphQL */ `
  query GetOPADataOperatorTimeline($id: ID!) {
    getOPADataOperatorTimeline(id: $id) {
      id
      Site
      ShiftDate
      ShiftCode
      EmployeeID
      GivenNames
      LastName
      FullName
      Crew
      EquipmentCategory
      EquipmentCode
      Model
      Starttime
      Endtime
      Duration
      Activity
      Category
      EngineStatus
      assignedTrucks {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listOPADataOperatorTimelines = /* GraphQL */ `
  query ListOPADataOperatorTimelines(
    $id: ID
    $filter: ModelOPADataOperatorTimelineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOPADataOperatorTimelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Site
        ShiftDate
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        EquipmentCategory
        EquipmentCode
        Model
        Starttime
        Endtime
        Duration
        Activity
        Category
        EngineStatus
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOPAData = /* GraphQL */ `
  query GetOPAData($id: ID!) {
    getOPAData(id: $id) {
      id
      Site
      Crew
      CycleDuration
      CycleEnabled
      CycleEnd
      CycleId
      CycleStart
      DiggerOperatorId
      DigUnitOperatorName
      Dumping
      DumpPosGeo
      InstantaneousGiggerRate
      LoadDate
      LoaderDesc
      LoaderName
      Loading
      LoadPosGeo
      MaterialName
      PassCount
      PayloadBCM
      PayloadTon
      QueuingEmpty
      Respotting
      ShiftCode
      ShiftDate
      ShiftId
      SpottingEmpty
      StopLoaded
      StoppedEmpty
      TotalActiveCycletime
      TotalCycleTime
      TravelEmptyForwards
      TravelEmptyReverse
      TravelLoadedDistance
      TravelLoadedForwards
      TravelLoadedReverse
      TravlEmptyDistance
      TruckDesc
      TruckName
      TruckOperatorId
      TruckOperatorName
      WaitingOnLoader
      waste
      WOT
      oPADataOperatorTimelineAssignedTrucksId
      __typename
    }
  }
`;
export const listOPAData = /* GraphQL */ `
  query ListOPAData(
    $id: ID
    $filter: ModelOPADataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOPAData(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Site
        Crew
        CycleDuration
        CycleEnabled
        CycleEnd
        CycleId
        CycleStart
        DiggerOperatorId
        DigUnitOperatorName
        Dumping
        DumpPosGeo
        InstantaneousGiggerRate
        LoadDate
        LoaderDesc
        LoaderName
        Loading
        LoadPosGeo
        MaterialName
        PassCount
        PayloadBCM
        PayloadTon
        QueuingEmpty
        Respotting
        ShiftCode
        ShiftDate
        ShiftId
        SpottingEmpty
        StopLoaded
        StoppedEmpty
        TotalActiveCycletime
        TotalCycleTime
        TravelEmptyForwards
        TravelEmptyReverse
        TravelLoadedDistance
        TravelLoadedForwards
        TravelLoadedReverse
        TravlEmptyDistance
        TruckDesc
        TruckName
        TruckOperatorId
        TruckOperatorName
        WaitingOnLoader
        waste
        WOT
        oPADataOperatorTimelineAssignedTrucksId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepartmentName = /* GraphQL */ `
  query GetDepartmentName($id: ID!) {
    getDepartmentName(id: $id) {
      id
      DepartmentName
      CrewNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      operationNameDepartmentNamesId
      __typename
    }
  }
`;
export const listDepartmentNames = /* GraphQL */ `
  query ListDepartmentNames(
    $filter: ModelDepartmentNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartmentNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        DepartmentName
        createdAt
        updatedAt
        operationNameDepartmentNamesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      name
      key
      description
      site
      department
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        description
        site
        department
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      title
      description
      status
      label
      board {
        id
        name
        key
        description
        site
        department
        createdAt
        updatedAt
        __typename
      }
      assignedTo
      creator
      comments {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      boardTasksId
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        status
        label
        assignedTo
        creator
        createdAt
        updatedAt
        boardTasksId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      text
      task {
        id
        title
        description
        status
        label
        assignedTo
        creator
        createdAt
        updatedAt
        boardTasksId
        __typename
      }
      user
      createdAt
      updatedAt
      taskCommentsId
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        user
        createdAt
        updatedAt
        taskCommentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataEventsTimelinesBySite = /* GraphQL */ `
  query OPADataEventsTimelinesBySite(
    $Site: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataEventsTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataEventsTimelinesBySite(
      Site: $Site
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        State
        ShiftDate
        Week
        Month
        Day
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        Unit
        MachineType
        UnitType
        EventOrigin
        EventDescription
        EventValue
        EventSeverity
        EventStart
        EventEnd
        Duration
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataEventsTimelinesByShiftDate = /* GraphQL */ `
  query OPADataEventsTimelinesByShiftDate(
    $ShiftDate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataEventsTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataEventsTimelinesByShiftDate(
      ShiftDate: $ShiftDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        State
        ShiftDate
        Week
        Month
        Day
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        Unit
        MachineType
        UnitType
        EventOrigin
        EventDescription
        EventValue
        EventSeverity
        EventStart
        EventEnd
        Duration
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataEventsTimelinesByShiftCode = /* GraphQL */ `
  query OPADataEventsTimelinesByShiftCode(
    $ShiftCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataEventsTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataEventsTimelinesByShiftCode(
      ShiftCode: $ShiftCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        State
        ShiftDate
        Week
        Month
        Day
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        Unit
        MachineType
        UnitType
        EventOrigin
        EventDescription
        EventValue
        EventSeverity
        EventStart
        EventEnd
        Duration
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataEventsTimelinesByEmployeeID = /* GraphQL */ `
  query OPADataEventsTimelinesByEmployeeID(
    $EmployeeID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataEventsTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataEventsTimelinesByEmployeeID(
      EmployeeID: $EmployeeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        State
        ShiftDate
        Week
        Month
        Day
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        Unit
        MachineType
        UnitType
        EventOrigin
        EventDescription
        EventValue
        EventSeverity
        EventStart
        EventEnd
        Duration
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataEventsTimelinesByCrew = /* GraphQL */ `
  query OPADataEventsTimelinesByCrew(
    $Crew: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataEventsTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataEventsTimelinesByCrew(
      Crew: $Crew
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        State
        ShiftDate
        Week
        Month
        Day
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        Unit
        MachineType
        UnitType
        EventOrigin
        EventDescription
        EventValue
        EventSeverity
        EventStart
        EventEnd
        Duration
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataOperatorTimelinesBySite = /* GraphQL */ `
  query OPADataOperatorTimelinesBySite(
    $Site: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataOperatorTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataOperatorTimelinesBySite(
      Site: $Site
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        ShiftDate
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        EquipmentCategory
        EquipmentCode
        Model
        Starttime
        Endtime
        Duration
        Activity
        Category
        EngineStatus
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataOperatorTimelinesByShiftDate = /* GraphQL */ `
  query OPADataOperatorTimelinesByShiftDate(
    $ShiftDate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataOperatorTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataOperatorTimelinesByShiftDate(
      ShiftDate: $ShiftDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        ShiftDate
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        EquipmentCategory
        EquipmentCode
        Model
        Starttime
        Endtime
        Duration
        Activity
        Category
        EngineStatus
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataOperatorTimelinesByShiftCode = /* GraphQL */ `
  query OPADataOperatorTimelinesByShiftCode(
    $ShiftCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataOperatorTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataOperatorTimelinesByShiftCode(
      ShiftCode: $ShiftCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        ShiftDate
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        EquipmentCategory
        EquipmentCode
        Model
        Starttime
        Endtime
        Duration
        Activity
        Category
        EngineStatus
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataOperatorTimelinesByEmployeeID = /* GraphQL */ `
  query OPADataOperatorTimelinesByEmployeeID(
    $EmployeeID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataOperatorTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataOperatorTimelinesByEmployeeID(
      EmployeeID: $EmployeeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        ShiftDate
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        EquipmentCategory
        EquipmentCode
        Model
        Starttime
        Endtime
        Duration
        Activity
        Category
        EngineStatus
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataOperatorTimelinesByCrew = /* GraphQL */ `
  query OPADataOperatorTimelinesByCrew(
    $Crew: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataOperatorTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataOperatorTimelinesByCrew(
      Crew: $Crew
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        ShiftDate
        ShiftCode
        EmployeeID
        GivenNames
        LastName
        FullName
        Crew
        EquipmentCategory
        EquipmentCode
        Model
        Starttime
        Endtime
        Duration
        Activity
        Category
        EngineStatus
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataBySite = /* GraphQL */ `
  query OPADataBySite(
    $Site: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataBySite(
      Site: $Site
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        Crew
        CycleDuration
        CycleEnabled
        CycleEnd
        CycleId
        CycleStart
        DiggerOperatorId
        DigUnitOperatorName
        Dumping
        DumpPosGeo
        InstantaneousGiggerRate
        LoadDate
        LoaderDesc
        LoaderName
        Loading
        LoadPosGeo
        MaterialName
        PassCount
        PayloadBCM
        PayloadTon
        QueuingEmpty
        Respotting
        ShiftCode
        ShiftDate
        ShiftId
        SpottingEmpty
        StopLoaded
        StoppedEmpty
        TotalActiveCycletime
        TotalCycleTime
        TravelEmptyForwards
        TravelEmptyReverse
        TravelLoadedDistance
        TravelLoadedForwards
        TravelLoadedReverse
        TravlEmptyDistance
        TruckDesc
        TruckName
        TruckOperatorId
        TruckOperatorName
        WaitingOnLoader
        waste
        WOT
        oPADataOperatorTimelineAssignedTrucksId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataByCrew = /* GraphQL */ `
  query OPADataByCrew(
    $Crew: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataByCrew(
      Crew: $Crew
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        Crew
        CycleDuration
        CycleEnabled
        CycleEnd
        CycleId
        CycleStart
        DiggerOperatorId
        DigUnitOperatorName
        Dumping
        DumpPosGeo
        InstantaneousGiggerRate
        LoadDate
        LoaderDesc
        LoaderName
        Loading
        LoadPosGeo
        MaterialName
        PassCount
        PayloadBCM
        PayloadTon
        QueuingEmpty
        Respotting
        ShiftCode
        ShiftDate
        ShiftId
        SpottingEmpty
        StopLoaded
        StoppedEmpty
        TotalActiveCycletime
        TotalCycleTime
        TravelEmptyForwards
        TravelEmptyReverse
        TravelLoadedDistance
        TravelLoadedForwards
        TravelLoadedReverse
        TravlEmptyDistance
        TruckDesc
        TruckName
        TruckOperatorId
        TruckOperatorName
        WaitingOnLoader
        waste
        WOT
        oPADataOperatorTimelineAssignedTrucksId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataByShiftCode = /* GraphQL */ `
  query OPADataByShiftCode(
    $ShiftCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataByShiftCode(
      ShiftCode: $ShiftCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        Crew
        CycleDuration
        CycleEnabled
        CycleEnd
        CycleId
        CycleStart
        DiggerOperatorId
        DigUnitOperatorName
        Dumping
        DumpPosGeo
        InstantaneousGiggerRate
        LoadDate
        LoaderDesc
        LoaderName
        Loading
        LoadPosGeo
        MaterialName
        PassCount
        PayloadBCM
        PayloadTon
        QueuingEmpty
        Respotting
        ShiftCode
        ShiftDate
        ShiftId
        SpottingEmpty
        StopLoaded
        StoppedEmpty
        TotalActiveCycletime
        TotalCycleTime
        TravelEmptyForwards
        TravelEmptyReverse
        TravelLoadedDistance
        TravelLoadedForwards
        TravelLoadedReverse
        TravlEmptyDistance
        TruckDesc
        TruckName
        TruckOperatorId
        TruckOperatorName
        WaitingOnLoader
        waste
        WOT
        oPADataOperatorTimelineAssignedTrucksId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const oPADataByShiftDate = /* GraphQL */ `
  query OPADataByShiftDate(
    $ShiftDate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOPADataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    oPADataByShiftDate(
      ShiftDate: $ShiftDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Site
        Crew
        CycleDuration
        CycleEnabled
        CycleEnd
        CycleId
        CycleStart
        DiggerOperatorId
        DigUnitOperatorName
        Dumping
        DumpPosGeo
        InstantaneousGiggerRate
        LoadDate
        LoaderDesc
        LoaderName
        Loading
        LoadPosGeo
        MaterialName
        PassCount
        PayloadBCM
        PayloadTon
        QueuingEmpty
        Respotting
        ShiftCode
        ShiftDate
        ShiftId
        SpottingEmpty
        StopLoaded
        StoppedEmpty
        TotalActiveCycletime
        TotalCycleTime
        TravelEmptyForwards
        TravelEmptyReverse
        TravelLoadedDistance
        TravelLoadedForwards
        TravelLoadedReverse
        TravlEmptyDistance
        TruckDesc
        TruckName
        TruckOperatorId
        TruckOperatorName
        WaitingOnLoader
        waste
        WOT
        oPADataOperatorTimelineAssignedTrucksId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOperationName = /* GraphQL */ `
  query GetOperationName($id: ID!) {
    getOperationName(id: $id) {
      id
      OperationName
      DepartmentNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOperationNames = /* GraphQL */ `
  query ListOperationNames(
    $filter: ModelOperationNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperationNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OperationName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrewName = /* GraphQL */ `
  query GetCrewName($id: ID!) {
    getCrewName(id: $id) {
      id
      CrewName
      CoachNames {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      departmentNameCrewNamesId
      __typename
    }
  }
`;
export const listCrewNames = /* GraphQL */ `
  query ListCrewNames(
    $filter: ModelCrewNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrewNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CrewName
        createdAt
        updatedAt
        departmentNameCrewNamesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPreStart = /* GraphQL */ `
  query GetPreStart($id: ID!) {
    getPreStart(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPreStarts = /* GraphQL */ `
  query ListPreStarts(
    $filter: ModelPreStartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreStarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShiftHandover = /* GraphQL */ `
  query GetShiftHandover($id: ID!) {
    getShiftHandover(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShiftHandovers = /* GraphQL */ `
  query ListShiftHandovers(
    $filter: ModelShiftHandoverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShiftHandovers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPreShiftAlignment = /* GraphQL */ `
  query GetPreShiftAlignment($id: ID!) {
    getPreShiftAlignment(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPreShiftAlignments = /* GraphQL */ `
  query ListPreShiftAlignments(
    $filter: ModelPreShiftAlignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreShiftAlignments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuarterlyMonthlyPlan = /* GraphQL */ `
  query GetQuarterlyMonthlyPlan($id: ID!) {
    getQuarterlyMonthlyPlan(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuarterlyMonthlyPlans = /* GraphQL */ `
  query ListQuarterlyMonthlyPlans(
    $filter: ModelQuarterlyMonthlyPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuarterlyMonthlyPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMonthlyPlanningMeeting = /* GraphQL */ `
  query GetMonthlyPlanningMeeting($id: ID!) {
    getMonthlyPlanningMeeting(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMonthlyPlanningMeetings = /* GraphQL */ `
  query ListMonthlyPlanningMeetings(
    $filter: ModelMonthlyPlanningMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonthlyPlanningMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklyPlanSchedule = /* GraphQL */ `
  query GetWeeklyPlanSchedule($id: ID!) {
    getWeeklyPlanSchedule(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeklyPlanSchedules = /* GraphQL */ `
  query ListWeeklyPlanSchedules(
    $filter: ModelWeeklyPlanScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklyPlanSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        Q12
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        C12
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklyPrePlanning = /* GraphQL */ `
  query GetWeeklyPrePlanning($id: ID!) {
    getWeeklyPrePlanning(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeklyPrePlannings = /* GraphQL */ `
  query ListWeeklyPrePlannings(
    $filter: ModelWeeklyPrePlanningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklyPrePlannings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklySitePlanningMeeting = /* GraphQL */ `
  query GetWeeklySitePlanningMeeting($id: ID!) {
    getWeeklySitePlanningMeeting(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeklySitePlanningMeetings = /* GraphQL */ `
  query ListWeeklySitePlanningMeetings(
    $filter: ModelWeeklySitePlanningMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklySitePlanningMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklyCommitmentMeeting = /* GraphQL */ `
  query GetWeeklyCommitmentMeeting($id: ID!) {
    getWeeklyCommitmentMeeting(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeklyCommitmentMeetings = /* GraphQL */ `
  query ListWeeklyCommitmentMeetings(
    $filter: ModelWeeklyCommitmentMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklyCommitmentMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDailyCommitmentMeeting = /* GraphQL */ `
  query GetDailyCommitmentMeeting($id: ID!) {
    getDailyCommitmentMeeting(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDailyCommitmentMeetings = /* GraphQL */ `
  query ListDailyCommitmentMeetings(
    $filter: ModelDailyCommitmentMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyCommitmentMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShiftPlan = /* GraphQL */ `
  query GetShiftPlan($id: ID!) {
    getShiftPlan(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShiftPlans = /* GraphQL */ `
  query ListShiftPlans(
    $filter: ModelShiftPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShiftPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        Q12
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        C12
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShortIntervalControl = /* GraphQL */ `
  query GetShortIntervalControl($id: ID!) {
    getShortIntervalControl(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShortIntervalControls = /* GraphQL */ `
  query ListShortIntervalControls(
    $filter: ModelShortIntervalControlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShortIntervalControls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShiftReport = /* GraphQL */ `
  query GetShiftReport($id: ID!) {
    getShiftReport(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShiftReports = /* GraphQL */ `
  query ListShiftReports(
    $filter: ModelShiftReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShiftReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShiftDebrief = /* GraphQL */ `
  query GetShiftDebrief($id: ID!) {
    getShiftDebrief(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShiftDebriefs = /* GraphQL */ `
  query ListShiftDebriefs(
    $filter: ModelShiftDebriefFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShiftDebriefs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPerformanceReports = /* GraphQL */ `
  query GetPerformanceReports($id: ID!) {
    getPerformanceReports(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPerformanceReports = /* GraphQL */ `
  query ListPerformanceReports(
    $filter: ModelPerformanceReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerformanceReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepartmentDailyReview = /* GraphQL */ `
  query GetDepartmentDailyReview($id: ID!) {
    getDepartmentDailyReview(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDepartmentDailyReviews = /* GraphQL */ `
  query ListDepartmentDailyReviews(
    $filter: ModelDepartmentDailyReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartmentDailyReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDailySiteReview = /* GraphQL */ `
  query GetDailySiteReview($id: ID!) {
    getDailySiteReview(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      Q11
      Q12
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      C11
      C12
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDailySiteReviews = /* GraphQL */ `
  query ListDailySiteReviews(
    $filter: ModelDailySiteReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailySiteReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        Q11
        Q12
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        C11
        C12
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPerformanceAnalysis = /* GraphQL */ `
  query GetPerformanceAnalysis($id: ID!) {
    getPerformanceAnalysis(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPerformanceAnalyses = /* GraphQL */ `
  query ListPerformanceAnalyses(
    $filter: ModelPerformanceAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerformanceAnalyses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklyDepartmentReview = /* GraphQL */ `
  query GetWeeklyDepartmentReview($id: ID!) {
    getWeeklyDepartmentReview(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeklyDepartmentReviews = /* GraphQL */ `
  query ListWeeklyDepartmentReviews(
    $filter: ModelWeeklyDepartmentReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklyDepartmentReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWeeklySiteReview = /* GraphQL */ `
  query GetWeeklySiteReview($id: ID!) {
    getWeeklySiteReview(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWeeklySiteReviews = /* GraphQL */ `
  query ListWeeklySiteReviews(
    $filter: ModelWeeklySiteReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeeklySiteReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManagementReports = /* GraphQL */ `
  query GetManagementReports($id: ID!) {
    getManagementReports(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManagementReports = /* GraphQL */ `
  query ListManagementReports(
    $filter: ModelManagementReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManagementReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMonthlyReview = /* GraphQL */ `
  query GetMonthlyReview($id: ID!) {
    getMonthlyReview(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMonthlyReviews = /* GraphQL */ `
  query ListMonthlyReviews(
    $filter: ModelMonthlyReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonthlyReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuarterlyForecastReview = /* GraphQL */ `
  query GetQuarterlyForecastReview($id: ID!) {
    getQuarterlyForecastReview(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      Q9
      Q10
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      C9
      C10
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuarterlyForecastReviews = /* GraphQL */ `
  query ListQuarterlyForecastReviews(
    $filter: ModelQuarterlyForecastReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuarterlyForecastReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        Q9
        Q10
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        C9
        C10
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActionLog = /* GraphQL */ `
  query GetActionLog($id: ID!) {
    getActionLog(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActionLogs = /* GraphQL */ `
  query ListActionLogs(
    $filter: ModelActionLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusinessImprovementCapitalProjects = /* GraphQL */ `
  query GetBusinessImprovementCapitalProjects($id: ID!) {
    getBusinessImprovementCapitalProjects(id: $id) {
      id
      Operation
      FirstName
      LastName
      Department
      FormDate
      MosElement
      Q1
      Q2
      Q3
      Q4
      Q5
      Q6
      Q7
      Q8
      C1
      C2
      C3
      C4
      C5
      C6
      C7
      C8
      CommentsNotes
      CoachingNotes
      CoachName
      Crew
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBusinessImprovementCapitalProjects = /* GraphQL */ `
  query ListBusinessImprovementCapitalProjects(
    $filter: ModelBusinessImprovementCapitalProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessImprovementCapitalProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Operation
        FirstName
        LastName
        Department
        FormDate
        MosElement
        Q1
        Q2
        Q3
        Q4
        Q5
        Q6
        Q7
        Q8
        C1
        C2
        C3
        C4
        C5
        C6
        C7
        C8
        CommentsNotes
        CoachingNotes
        CoachName
        Crew
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAllForms = /* GraphQL */ `
  query GetAllForms($id: ID!) {
    getAllForms(id: $id) {
      id
      Field0 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field1 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field2 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field3 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field4 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field5 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field6 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field7 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field8 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field9 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field10 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field11 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field12 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field13 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field14 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field15 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field16 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field17 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field18 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field19 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field20 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field21 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field22 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field23 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      Field24 {
        formName
        questionsText
        youtubeLink
        weights
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAllForms = /* GraphQL */ `
  query ListAllForms(
    $filter: ModelAllFormsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFormScore = /* GraphQL */ `
  query GetFormScore($id: ID!) {
    getFormScore(id: $id) {
      id
      FormName
      WeightedScore
      OperationName
      DepartmentName
      CrewName
      CoachName
      FirstName
      LastName
      FormDate
      __typename
    }
  }
`;
export const listFormScores = /* GraphQL */ `
  query ListFormScores(
    $filter: ModelFormScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        FormName
        WeightedScore
        OperationName
        DepartmentName
        CrewName
        CoachName
        FirstName
        LastName
        FormDate
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoachName = /* GraphQL */ `
  query GetCoachName($id: ID!) {
    getCoachName(id: $id) {
      id
      userId
      CoachName
      CrewName
      coach
      admin
      guest
      deleted
      altEmail
      AltCrewName
      DepartmentName
      OperationName
      temp
      userEmail
      createdAt
      updatedAt
      crewNameCoachNamesId
      __typename
    }
  }
`;
export const listCoachNames = /* GraphQL */ `
  query ListCoachNames(
    $filter: ModelCoachNameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachNames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        CoachName
        CrewName
        coach
        admin
        guest
        deleted
        altEmail
        AltCrewName
        DepartmentName
        OperationName
        temp
        userEmail
        createdAt
        updatedAt
        crewNameCoachNamesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React from "react";
import classNames from "classnames";
import themeConfig from "../../config/theme.config";

export const THead = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(className);

  return (
    <thead data-component-name="Table/THead" className={classes} {...rest}>
      {children}
    </thead>
  );
};
THead.defaultProps = {
  className: undefined,
};
THead.displayName = "THead";

export const TBody = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(className);

  return (
    <tbody data-component-name="Table/TBody" className={classes} {...rest}>
      {children}
    </tbody>
  );
};
TBody.defaultProps = {
  className: undefined,
};
TBody.displayName = "TBody";

export const TFoot = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(className);

  return (
    <thead data-component-name="Table/TFoot" className={classes} {...rest}>
      {children}
    </thead>
  );
};
TFoot.defaultProps = {
  className: undefined,
};
TFoot.displayName = "TFoot";

export const Tr = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames("group/Tr", className);

  return (
    <tr data-component-name="Table/Tr" className={classes} {...rest}>
      {children}
    </tr>
  );
};
Tr.defaultProps = {
  className: undefined,
};
Tr.displayName = "Tr";

export const Th = (props) => {
  const { children, className, isResizable, isColumnBorder, ...rest } = props;

  const classes = classNames(
    "bg-zinc-950/10",
    "dark:bg-zinc-950/90",
    "p-4",
    "ltr:group-[&:first-child]/Tr:[&:first-child]:rounded-tl-lg ltr:group-[&:first-child]/Tr:[&:last-child]:rounded-tr-lg",
    "ltr:group-[&:last-child]/Tr:[&:first-child]:rounded-bl-lg ltr:group-[&:last-child]/Tr:[&:last-child]:rounded-br-lg",
    "rtl:group-[&:first-child]/Tr:[&:first-child]:rounded-tr-lg rtl:group-[&:first-child]/Tr:[&:last-child]:rounded-tl-lg",
    "rtl:group-[&:last-child]/Tr:[&:first-child]:rounded-br-lg rtl:group-[&:last-child]/Tr:[&:last-child]:rounded-bl-lg",
    { "shadow-[inset_0_0_0_0.5px_rgba(0,0,0,0.1)]": isColumnBorder },
    {
      "dark:shadow-[inset_0_0_0_0.5px_rgba(255,255,255,0.05)]": isColumnBorder,
    },
    { relative: isResizable },
    className,
  );

  return (
    <th data-component-name="Table/Th" className={classes} {...rest}>
      {children}
    </th>
  );
};
Th.defaultProps = {
  className: undefined,
  isResizable: false,
  isColumnBorder: true,
};
Th.displayName = "Th";

export const ThResizer = (props) => {
  const { isResizing, className, color, colorIntensity, ...rest } = props;

  return (
    <div
      data-component-name="Table/ThResizer"
      className={classNames(
        "absolute -right-0.5 top-0 z-10",
        "h-full w-1",
        "cursor-col-resize touch-none select-none",
        [`bg-${color}-${colorIntensity}`],
        "rounded-full",
        "[@media(hover:hover)]:opacity-0",
        "[@media(hover:hover){&:hover}]:opacity-100",
        { "bg-blue-500": isResizing, "opacity-100": isResizing },
        className,
      )}
      {...rest}
    />
  );
};
ThResizer.defaultProps = {
  className: undefined,
  color: themeConfig.themeColor,
  colorIntensity: themeConfig.themeColorShade,
};
ThResizer.displayName = "ThResizer";

export const Td = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(
    "ltr:[&:first-child]:rounded-l-lg ltr:[&:last-child]:rounded-r-lg",
    "rtl:[&:first-child]:rounded-r-lg rtl:[&:last-child]:rounded-l-lg",
    "group-even/Tr:bg-zinc-500/5",
    "group-hover/Tr:bg-zinc-500/10",
    "dark:group-even/Tr:bg-zinc-950/50",
    "dark:group-hover/Tr:bg-zinc-950/90",
    "p-4",
    themeConfig.transition,
    className,
  );

  return (
    <td data-component-name="Table/Td" className={classes} {...rest}>
      {children}
    </td>
  );
};
Td.defaultProps = {
  className: undefined,
};
Td.displayName = "Td";

const Table = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames("w-full", className);

  return (
    <table data-component-name="Table" className={classes} {...rest}>
      {children}
    </table>
  );
};
Table.defaultProps = {
  className: undefined,
};
Table.displayName = "Table";

export default Table;

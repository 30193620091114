import React, { useEffect, useState } from "react";
import { DragDropContext } from "@hello-pangea/dnd";
import PageWrapper from "../../fyr-theme/components/layouts/PageWrapper/PageWrapper";
import Container from "../../fyr-theme/components/layouts/Container/Container";
import ColumnsPartial from "../_partial/Columns.partial";
import BoardPartial from "../_partial/Board.partial";
import Subheader, {
  SubheaderLeft,
  SubheaderRight,
} from "../../fyr-theme/components/layouts/Subheader/Subheader";
import Icon from "../../fyr-theme/components/icon/Icon";
import Input from "../../fyr-theme/components/form/Input";
import Button from "../../fyr-theme/components/ui/Button";
import ButtonGroup from "../../fyr-theme/components/ui/ButtonGroup";
import FieldWrap from "../../fyr-theme/components/form/FieldWrap";
import TaskCreatePartial from "../_partial/TaskCreatePartial";
import TabularBoard from "../TabularBoard/TabularBoard";
import { useParams, useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Loader } from "@aws-amplify/ui-react";
import {
  listTasks,
  listCoachNames,
  getCoachName,
  listBoards,
} from "../../graphql/queries";
import { updateTask } from "../../graphql/mutations";
import toast from "react-hot-toast";
import { fetchAllPaginatedResults } from "../../utils/pagination";

const ProjectBoardPage = ({ user, onBack = null }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [tasks, setTasks] = useState({
    BACKLOG: [],
    TODO: [],
    PENDING: [],
    RUN: [],
    DONE: [],
  });
  const [isCardsOrTabularView, setIsCardsOrTabularView] = useState("cards");
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [createColumnKey, setCreateColumnKey] = useState(null);
  const [boardInfo, setBoardInfo] = useState(null);
  const { boardId } = useParams();
  const navigate = useNavigate();

  // New state for coaches
  const [coaches, setCoaches] = useState([]);
  const [coachDetails, setCoachDetails] = useState({});

  const columnsData = {
    BACKLOG: {
      id: "BACKLOG",
      title: "Backlog",
      icon: "HeroQueueList",
    },
    TODO: { id: "TODO", title: "To Do", icon: "HeroClipboard" },
    PENDING: {
      id: "PENDING",
      title: "Pending",
      icon: "HeroReceiptRefund",
    },
    RUN: { id: "RUN", title: "Run", icon: "HeroPlay" },
    DONE: { id: "DONE", title: "Done", icon: "HeroCheckCircle" },
  };

  useEffect(() => {
    const fetchData = async () => {
      const board = await fetchBoardInfo();
      if (board) {
        setBoardInfo(board);
        await fetchTasks(board.id);
      }
    };

    fetchData();
    fetchCoaches();
  }, [boardId]);

  const fetchBoardInfo = async () => {
    try {
      const boardData = await fetchAllPaginatedResults(listBoards, {
        filter: { key: { eq: boardId } },
      });
      const boards = boardData;
      if (boards.length > 0) {
        return boards[0];
      } else {
        console.error("Board not found");
        toast.error("Board not found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching board info:", error);
      toast.error("Failed to fetch board information");
      return null;
    }
  };

  const fetchTasks = async (boardId) => {
    try {
      const taskData = await fetchAllPaginatedResults(listTasks, {
        filter: { boardTasksId: { eq: boardId } },
      });
      const fetchedTasks = taskData;
      const groupedTasks = {
        BACKLOG: [],
        TODO: [],
        PENDING: [],
        RUN: [],
        DONE: [],
      };
      fetchedTasks.forEach((task) => {
        if (groupedTasks[task.status]) {
          groupedTasks[task.status].push(task);
        }
      });
      setTasks(groupedTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Failed to fetch tasks");
    }
  };

  // New function to fetch coaches
  const fetchCoaches = async () => {
    try {
      const coachData = await fetchAllPaginatedResults(listCoachNames);
      const coachItems = coachData;
      const coachDetailsMap = {};
      for (const coach of coachItems) {
        const coachNameData = await API.graphql(
          graphqlOperation(getCoachName, { id: coach.id }),
        );
        coachDetailsMap[coach.id] = {
          name: coachNameData.CoachName,
          email: coachNameData.userEmail,
        };
      }
      setCoachDetails(coachDetailsMap);
      setCoaches(coachItems);
    } catch (error) {
      console.error("Error fetching coaches:", error);
      toast.error("Failed to fetch coaches");
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // If there's no destination, we don't need to do anything
    if (!destination) {
      return;
    }

    let newTasks = { ...tasks };

    // Ensure that both source and destination columns exist
    if (!newTasks[source.droppableId]) {
      newTasks[source.droppableId] = [];
    }
    if (!newTasks[destination.droppableId]) {
      newTasks[destination.droppableId] = [];
    }

    if (source.droppableId === destination.droppableId) {
      // Moving within the same column
      const column = newTasks[source.droppableId];
      const [removed] = column.splice(source.index, 1);
      column.splice(destination.index, 0, removed);
    } else {
      // Moving from one column to another
      const sourceColumn = newTasks[source.droppableId];
      const destColumn = newTasks[destination.droppableId];
      const [removed] = sourceColumn.splice(source.index, 1);
      destColumn.splice(destination.index, 0, removed);
    }

    setTasks(newTasks);

    const taskId = result.draggableId;
    const newStatus = destination.droppableId;
    try {
      await API.graphql(
        graphqlOperation(updateTask, {
          input: { id: taskId, status: newStatus },
        }),
      );
    } catch (error) {
      console.error("Error updating task status:", error);
      toast.error("Failed to update task status");
      // Optionally, revert the state if the API call fails
      setTasks(tasks);
    }
  };

  const handleCreateTask = (newTask) => {
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks };
      if (!updatedTasks[newTask.status]) {
        updatedTasks[newTask.status] = [];
      }
      updatedTasks[newTask.status].push(newTask);
      return updatedTasks;
    });
  };

  const handleUpdateTask = (updatedTask) => {
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks };
      Object.keys(updatedTasks).forEach((status) => {
        updatedTasks[status] = updatedTasks[status].map((task) =>
          task.id === updatedTask.id ? updatedTask : task,
        );
      });
      return updatedTasks;
    });
  };

  const handleDeleteTask = (taskId) => {
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks };
      Object.keys(updatedTasks).forEach((status) => {
        updatedTasks[status] = updatedTasks[status].filter(
          (task) => task.id !== taskId,
        );
      });
      return updatedTasks;
    });
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate("/action-jackson");
    }
  };

  const filterTasks = (tasks, searchTerm) => {
    if (!searchTerm) return tasks;

    const lowercasedTerm = searchTerm.toLowerCase();

    const filteredTasks = {};
    Object.keys(tasks).forEach((status) => {
      filteredTasks[status] = tasks[status].filter(
        (task) =>
          task.title.toLowerCase().includes(lowercasedTerm) ||
          task.description.toLowerCase().includes(lowercasedTerm) ||
          (task.assignee &&
            task.assignee.toLowerCase().includes(lowercasedTerm)),
      );
    });

    return filteredTasks;
  };
  const filteredTasks = filterTasks(tasks, globalFilter);

  return (
    <PageWrapper
      name={boardInfo ? `Board Name: ${boardInfo.name}` : "Loading..."}
    >
      <Button onClick={handleBack} className="mb-4">
        Back to Boards
      </Button>
      <div className="flex justify-between">
        <h1 className="text-2xl my-2">
          {boardInfo ? (
            `Board: ${boardInfo.name}`
          ) : (
            <Loader className="w-12 h-12" />
          )}
        </h1>
        <Button
          variant="solid"
          icon="HeroPencil"
          className="max-w-fit my-2"
          onClick={() => setIsCreateTaskModalOpen(true)}
        >
          Create a new Task
        </Button>
      </div>
      <Subheader>
        <SubheaderLeft className="grow">
          <FieldWrap
            className="w-full"
            firstSuffix={<Icon className="mx-2" icon="HeroFunnel" />}
            lastSuffix={
              globalFilter && (
                <Icon
                  icon="HeroXMark"
                  color="red"
                  className="mx-2 cursor-pointer"
                  onClick={() => {
                    setGlobalFilter("");
                  }}
                />
              )
            }
          >
            <Input
              id="example"
              name="example"
              placeholder="Filter by keyword or by field"
              value={globalFilter ?? ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              className="w-full"
            />
          </FieldWrap>
        </SubheaderLeft>
      </Subheader>
      <Container
        breakpoint={null}
        className={`h-full max-w-full overflow-auto ${
          isCardsOrTabularView === "tabular" ? "mx-[initial]" : ""
        }`}
      >
        {isCardsOrTabularView === "cards" ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <BoardPartial>
              <ColumnsPartial
                columnsData={columnsData}
                tasksData={filteredTasks}
                setTasksData={setTasks}
                onUpdateTask={handleUpdateTask}
                onDeleteTask={handleDeleteTask}
                currentUser={user}
                setIsCreateTaskModalOpen={setIsCreateTaskModalOpen}
                setCreateColumnKey={setCreateColumnKey}
                coaches={coaches}
                coachDetails={coachDetails}
              />
            </BoardPartial>
          </DragDropContext>
        ) : (
          <TabularBoard
            columnsData={columnsData}
            tasksData={filteredTasks}
            coaches={coaches}
            coachDetails={coachDetails}
          />
        )}
      </Container>
      {isCreateTaskModalOpen && user && (
        <TaskCreatePartial
          isOpen={isCreateTaskModalOpen}
          setIsOpen={setIsCreateTaskModalOpen}
          boardId={boardId}
          onTaskCreated={handleCreateTask}
          currentUser={user}
          createColumnKey={createColumnKey}
          coaches={coaches}
          coachDetails={coachDetails}
        />
      )}
    </PageWrapper>
  );
};

export default ProjectBoardPage;

import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { updateCoachName, createCoachName } from "./graphql/mutations";
import {
  listCoachNames,
  listOperationNames,
  listDepartmentNames,
  listCrewNames,
} from "./graphql/queries";
import "./App.css";
import toast from "react-hot-toast";
import PageWrapper from "./fyr-theme/components/layouts/PageWrapper/PageWrapper.jsx";
import Container from "./fyr-theme/components/layouts/Container/Container.jsx";
import Table, {
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "./fyr-theme/components/ui/Table.jsx";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { fetchAllPaginatedResults } from "./utils/pagination";

export default function ManageCoachees({ user }) {
  const [newCoachFirstName, setNewCoachFirstName] = useState("");
  const [newCoachLastName, setNewCoachLastName] = useState("");
  const [allCoaches, setAllCoaches] = useState([]);
  const [editingCoach, setEditingCoach] = useState(null);
  const [operations, setOperations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [crews, setCrews] = useState([]);
  const isAdmin = user.admin;
  const [currentPage, setCurrentPage] = useState(1);
  const [coachesPerPage] = useState(10);

  useEffect(() => {
    fetchAllOperations();
    fetchAllCrews();
    fetchAllDepartments();
    fetchAllCoaches();
  }, []);

  const fetchAllOperations = async () => {
    try {
      const operationData = await fetchAllPaginatedResults(listOperationNames);
      setOperations(operationData);
    } catch (err) {
      console.error("Error fetching operations:", err);
    }
  };

  const fetchAllDepartments = async (operationId) => {
    try {
      const departmentData =
        await fetchAllPaginatedResults(listDepartmentNames);
      setDepartments(departmentData);
    } catch (err) {
      console.error("Error fetching departments:", err);
    }
  };

  const fetchAllCrews = async () => {
    try {
      const crewData = await fetchAllPaginatedResults(listCrewNames);
      setCrews(crewData);
    } catch (err) {
      console.error("Error fetching crews:", err);
    }
  };

  const fetchAllCoaches = async () => {
    try {
      let allCoaches = [];
      allCoaches = await fetchAllPaginatedResults(listCoachNames);
      setAllCoaches(allCoaches);
    } catch (err) {
      console.error("Error fetching users:", err);
      toast.error("Failed to fetch users");
    }
  };
  const handleAddCoach = async () => {
    if (!newCoachFirstName.trim() && !newCoachLastName.trim()) return;
    try {
      const combinedCoachName = `${newCoachFirstName} ${newCoachLastName}`;
      const existingCoach = allCoaches.find(
        (coach) =>
          coach.CoachName.toLowerCase().trim() ===
          combinedCoachName.toLowerCase().trim(),
      );
      if (existingCoach) {
        alert("A coach with the same name already exists.");
        return;
      }
      const input = {
        CoachName: combinedCoachName,
        temp: true,
        userId: "temp",
        coach: false,
        admin: false,
        guest: false,
      };
      const result = await API.graphql(
        graphqlOperation(createCoachName, { input }),
      );
      setAllCoaches([...allCoaches, result.data.createCoachName]);
      setNewCoachFirstName("");
      setNewCoachLastName("");
      toast.success("User added successfully");
    } catch (err) {
      console.error("Error adding coach:", err);
      toast.error("Failed to add user");
    }
  };

  const handleEditCoach = (coach) => {
    setEditingCoach(coach);
  };

  const handleUpdateCoach = async () => {
    if (!editingCoach) return;
    try {
      const input = {
        id: editingCoach.id,
        AltCrewName: editingCoach.CrewName,
        CoachName: editingCoach.CoachName,
        coach: editingCoach.coach,
        guest: editingCoach.guest,
        DepartmentName: editingCoach.DepartmentName,
        CrewName: editingCoach.CrewName,
        OperationName: editingCoach.OperationName,
        deleted: editingCoach.deleted,
        temp: editingCoach.temp,
      };
      if (isAdmin && editingCoach.id !== user.id) {
        input.admin = editingCoach.admin;
      }
      const result = await API.graphql(
        graphqlOperation(updateCoachName, { input }),
      );
      setAllCoaches(
        allCoaches
          .sort((a, b) => a.CoachName.localeCompare(b.CoachName))
          .map((coach) =>
            coach.id === editingCoach.id ? result.data.updateCoachName : coach,
          ),
      );
      setEditingCoach(null);
      toast.success("User updated successfully");
    } catch (err) {
      console.error("Error updating coach:", err);
      toast.error("Failed to update user");
    }
  };
  const sortedCoaches = allCoaches
  ? [...allCoaches].sort((a, b) => {
      const nameA = a?.CoachName ?? '';
      const nameB = b?.CoachName ?? '';
      return nameA.localeCompare(nameB);
    })
  : [];


  // Pagination logic
  const indexOfLastCoach = currentPage * coachesPerPage;
  const indexOfFirstCoach = indexOfLastCoach - coachesPerPage;
  const currentCoaches = sortedCoaches.slice(indexOfFirstCoach, indexOfLastCoach);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allCoaches.length / coachesPerPage); i++) {
    pageNumbers.push(i);
  }

  const checkboxes = ["coach", "guest", "temp", "deleted"];

  return (
    <PageWrapper name="Manage Coachees">
      <Container
        breakpoint={null}
        className="h-full w-full overflow-auto mb-12"
      >
        <div className="sm:flex sm:items-center mb-8">
          {/* UI for selecting operation, department, and crew */}
          <div className="sm:flex-auto">
            <h3 className="sm:text-5xl text-3xl  leading-6 text-black">
              Users
            </h3>
            <p className="mt-2 sm:text-lg text-sm text-gray-700">
              A list of all the users
            </p>
          </div>
          <div className="sm:flex sm:items-center mb-4">
            <input
              type="text"
              value={newCoachFirstName}
              onChange={(e) => setNewCoachFirstName(e.target.value)}
              placeholder="First Name"
              className="block w-64 rounded-md border-0 mx-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-base text-sm sm:leading-6"
            />

            <input
              type="text"
              value={newCoachLastName}
              onChange={(e) => setNewCoachLastName(e.target.value)}
              placeholder="Last Name"
              className="block w-64 rounded-md border-0 mx-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-base text-sm sm:leading-6"
            />
            <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-thiess-blue-600 px-3 py-2 text-center sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
                onClick={handleAddCoach}
              >
                Add User
              </button>
            </div>
          </div>
        </div>
        {allCoaches.length === 0 ? (
          <div
            role="status"
            className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse md:p-6 "
          >
            <div className="flex items-center justify-between">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <Table>
              <THead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Operation</Th>
                  <Th>Department</Th>
                  <Th>Crew</Th>
                  {isAdmin && <Th>Is Admin</Th>}
                  <Th>Is Coach</Th>
                  <Th>Is Guest</Th>
                  <Th>Is Temp</Th>
                  <Th>Is Deleted</Th>
                  <Th>Actions</Th>
                </Tr>
              </THead>
              <TBody>
                {currentCoaches
                  .sort((a, b) => a.CoachName.localeCompare(b.CoachName))
                  .map((coach) => (
                    <Tr key={coach.id}>
                      <Td>
                        {editingCoach && editingCoach.id === coach.id ? (
                          <input
                            type="text"
                            value={editingCoach.CoachName}
                            onChange={(e) =>
                              setEditingCoach({
                                ...editingCoach,
                                CoachName: e.target.value,
                              })
                            }
                            className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-base text-sm sm:leading-6"
                          />
                        ) : (
                          coach.CoachName
                        )}
                      </Td>

                      <Td>
                        {editingCoach && editingCoach.id === coach.id ? (
                          <select
                            className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-base text-sm sm:leading-6 cursor-pointer"
                            value={editingCoach.OperationName}
                            onChange={(e) =>
                              setEditingCoach({
                                ...editingCoach,
                                OperationName: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Operation</option>
                            {operations
                              .sort((a, b) =>
                                a.OperationName.localeCompare(b.OperationName),
                              )
                              .map((operation) => (
                                <option key={operation.id} value={operation.id}>
                                  {operation.OperationName}
                                </option>
                              ))}
                          </select>
                        ) : (
                          operations.find(
                            (operation) => operation.id === coach.OperationName,
                          )?.OperationName || "N/A"
                        )}
                      </Td>
                      <Td>
                        {editingCoach && editingCoach.id === coach.id ? (
                          <select
                            className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-base text-sm sm:leading-6 cursor-pointer"
                            value={editingCoach.DepartmentName}
                            onChange={(e) =>
                              setEditingCoach({
                                ...editingCoach,
                                DepartmentName: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Department</option>
                            {departments
                              .sort((a, b) =>
                                a.DepartmentName.localeCompare(
                                  b.DepartmentName,
                                ),
                              )
                              .map((department) => (
                                <option
                                  key={department.id}
                                  value={department.id}
                                >
                                  {department.DepartmentName}
                                </option>
                              ))}
                          </select>
                        ) : (
                          departments.find(
                            (department) =>
                              department.id === coach.DepartmentName,
                          )?.DepartmentName || "N/A"
                        )}
                      </Td>
                      <Td>
                        {editingCoach && editingCoach.id === coach.id ? (
                          <select
                            className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-base text-sm sm:leading-6 cursor-pointer"
                            value={editingCoach.CrewName}
                            onChange={(e) =>
                              setEditingCoach({
                                ...editingCoach,
                                CrewName: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Crew</option>
                            {crews
                              .sort((a, b) =>
                                a.CrewName.localeCompare(b.CrewName),
                              )
                              .map((crew) => (
                                <option key={crew.id} value={crew.id}>
                                  {crew.CrewName}
                                </option>
                              ))}
                          </select>
                        ) : (
                          crews.find((crew) => crew.id === coach.CrewName)
                            ?.CrewName || "N/A"
                        )}
                      </Td>
                      {isAdmin && (
                        <Td>
                          {editingCoach && editingCoach.id === coach.id ? (
                            coach.id === user.id ? (
                              coach.admin ? (
                                "Yes"
                              ) : (
                                "No"
                              )
                            ) : (
                              <input
                                type="checkbox"
                                checked={editingCoach.admin}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  setEditingCoach((prev) => ({
                                    ...prev,
                                    admin: isChecked,
                                  }));
                                }}
                                className="w-4 h-4 text-thiess-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-thiess-blue-500 dark:focus:ring-thiess-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600"
                              />
                            )
                          ) : coach.admin ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                        </Td>
                      )}
                      {checkboxes.map((checkbox) => (
                        <Td key={checkbox}>
                          {editingCoach && editingCoach.id === coach.id ? (
                            <input
                              type="checkbox"
                              checked={editingCoach[checkbox]}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setEditingCoach((prev) => ({
                                  ...prev,
                                  [checkbox]: isChecked,
                                  ...(isChecked
                                    ? Object.fromEntries(
                                        checkboxes
                                          .filter((c) => c !== checkbox)
                                          .map((c) => [c, false]),
                                      )
                                    : {}),
                                }));
                              }}
                              className="w-4 h-4 text-thiess-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-thiess-blue-500 dark:focus:ring-thiess-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600 cursor-pointer"
                            />
                          ) : coach[checkbox] ? (
                            "Yes"
                          ) : (
                            "No"
                          )}
                        </Td>
                      ))}

                      <Td>
                        {editingCoach && editingCoach.id === coach.id ? (
                          <div
                            variant="solid"
                            onClick={handleUpdateCoach}
                            className="cursor-pointer text-green-600 hover:text-green-700"
                          >
                            Save
                          </div>
                        ) : (
                          <div
                            variant="solid"
                            onClick={() => handleEditCoach(coach)}
                            className="cursor-pointer text-yellow-600 hover:text-yellow-700"
                          >
                            Edit
                          </div>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>

            {/* Pagination */}
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
              <div className="flex flex-1 justify-between sm:hidden">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </button>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(allCoaches.length / coachesPerPage)
                  }
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">{indexOfFirstCoach + 1}</span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {Math.min(indexOfLastCoach, allCoaches.length)}
                    </span>{" "}
                    of <span className="font-medium">{allCoaches.length}</span>{" "}
                    results
                  </p>
                </div>
                <div>
                  <div
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <button
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    {pageNumbers.map((number) => (
                      <button
                        key={number}
                        onClick={() => paginate(number)}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                          currentPage === number
                            ? "z-10 bg-thiess-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
                            : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                        }`}
                      >
                        {number}
                      </button>
                    ))}
                    <button
                      onClick={() => paginate(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(allCoaches.length / coachesPerPage)
                      }
                      className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </PageWrapper>
  );
}

import { Bars3Icon } from "@heroicons/react/24/outline";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Link } from "react-router-dom";

export default function Navbar({ signOut, setSidebarOpen, profilePictureURL }) {
  return (
    <div className="md:sticky top-0 z-40 flex items-center gap-x-6 px-4 py-2 bg-[rgba(0,0,0,0.1)] backdrop-blur-md">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-white lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 sm:text-lg text-sm  leading-6 text-white"></div>
      {/* Profile dropdown */}
      <div></div>
      <div>
        <Link
          to="/profile"
          className="flex rounded-full bg-white sm:text-lg text-sm focus:outline-none focus:ring-2 focus:ring-thiess-blue-500 focus:ring-offset-2"
        >
          <span className="sr-only">Open user menu</span>
          <img
            className="h-10 w-10 rounded-full"
            src={profilePictureURL}
            alt="Profile"
          />
        </Link>
      </div>
      <button
        onClick={signOut}
        className="flex px-4 py-2 sm:text-lg text-sm hover:text-gray-200 text-white"
      >
        <ArrowLeftEndOnRectangleIcon className="h-8 w-8" />
      </button>
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import { API } from "aws-amplify";
import { Loader, View } from "@aws-amplify/ui-react";
import BarchartHierarchyWrapper from "../tailwind-ui-components/BarchartHierarchyWrapper";
import Chart from "react-apexcharts";
import { toast } from "react-hot-toast";

// Import your GraphQL queries
import { listPerformanceReports } from "../graphql/queries";

// CSS imports
import "react-datepicker/dist/react-datepicker.css";
import "../ReportScreen.css";
import { fetchAllPaginatedResults } from "../utils/pagination";

const PerformanceReportsReportUI = ({
  questionsText,
  weights,
  selectedOperation,
  selectedDepartment,
  selectedCrew,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  // State declarations for data and filters
  const [data, setData] = useState(null);
  const [key, setKey] = useState(0);
  const [hasFetchedData, setHasFetchedData] = useState(false); // New state variable
  const [loading, setLoading] = useState(false); // new state for tracking loading status
  const [formattedWeights, setFormattedWeights] = useState({});
  const [total, setTotal] = useState(0);

  function transformStringToValue(str) {
    str = str.toLowerCase();
    switch (str) {
      case "nothing":
        return 0;
      case "0":
        return 0;
      case "poor":
        return 1;
      case "1":
        return 1;
      case "ok":
        return 2;
      case "2":
        return 2;
      case "good":
        return 3;
      case "3":
        return 3;
      default:
        return 0; // For 'Missing' or any unrecognized string
    }
  }

  useEffect(() => {
    if (weights) {
      const newFormattedWeights = {
        q1: weights[0],
        q2: weights[1],
        q3: weights[2],
        q4: weights[3],
        q5: weights[4],
        q6: weights[5],
        q7: weights[6],
        q8: weights[7],
        q9: weights[8],
        q10: weights[9],
        q11: weights[10],
      };
      let newTotal = 0;
      for (let i = 0; i < weights.length; i++) {
        newTotal += Number(weights[i]);
      }

      // Update state variables
      setFormattedWeights(newFormattedWeights);
      setTotal(newTotal);
    }
  }, [weights]); // Run this effect whenever `weights` changes

  function transformData(sourceData, header) {
    const transformed = {
      name: header ? header : "Performance Reports",
      children: [],
    };
    let items = sourceData;

    const barchartData = {};
    if (items && items.length === 0) {
      if (!hasFetchedData) {
        // Only show toast if data hasn't been fetched before
        toast.error(
          "No records found in the database, please notify the admin, or fill in the forms to add data",
        );
      }
      return;
    }
    if (questionsText === null || weights === null) {
      // Only show toast if data hasn't been fetched before
      toast.loading("Loading data...");
      return;
    } else {
      toast.dismiss();
      items.forEach((item) => {
        // Calculate total score for this entry
        for (let i = 1; i <= weights.length; i++) {
          const fieldName = `Q${i}`;
          const question = questionsText[`q${i}`];
          const weight = formattedWeights[`q${i}`];
          if (question === undefined) {
            continue;
          }
          // Find or create the child node for the current question
          let child = transformed.children.find((c) => c.name === question);
          if (!child) {
            child = { name: question, weight, children: [] };
            transformed.children.push(child);
          }

          // Add the value to the child node
          const value = transformStringToValue(item[fieldName]);
          child.children.push({
            name: item["FirstName"] + " " + item["LastName"],
            value,
            comment: item[`C${i}`] ? item[`C${i}`] : "No Comment",
          });
        }
      });

      // Group the children nodes based on their value
      transformed.children.forEach((child) => {
        const groups = {
          Nothing: [],
          Poor: [],
          OK: [],
          Good: [],
        };

        child.children.forEach((node) => {
          switch (node.value) {
            case 0:
              groups["Nothing"].push(node);
              break;
            case 1:
              groups["Poor"].push(node);
              break;
            case 2:
              groups["OK"].push(node);
              break;
            case 3:
              groups["Good"].push(node);
              break;
            default:
              groups["Nothing"].push(node);
          }
        });

        // Create a new array to hold the grouped children
        const newChildren = Object.entries(groups)
          .filter(([name, children]) => children.length > 0) // Only include groups with children
          .map(([name, children]) => ({ name, children }));

        // Assign the new array to child.children
        child.children = newChildren;
      });

      items.forEach((item) => {
        const siteName = "Total Average Score";
        const date = new Date(item.FormDate);

        // Initialize site level in transformed data
        if (!barchartData[siteName]) {
          barchartData[siteName] = {};
        }

        // Initialize date level under the site
        if (!barchartData[siteName][date]) {
          barchartData[siteName][date] = {
            monthlyTotal: 0,
            crews: [],
          };
        }

        // Calculate total score for this entry
        let totalWeightedScore = 0;
        for (let i = 1; i <= weights.length; i++) {
          const fieldName = `Q${i}`;
          const value = transformStringToValue(item[fieldName]);
          if (value !== null) {
            totalWeightedScore += value * formattedWeights[`q${i}`];
          }
        }
        // Calculate average score
        let averageScore = totalWeightedScore / total;
        averageScore = averageScore.toFixed(2);

        // Update monthly total
        barchartData[siteName][date].monthlyTotal = averageScore;

        // Check if crew entry already exists
        let crewEntry = barchartData[siteName][date].crews.find(
          (crew) => crew.crewName === siteName,
        );

        if (crewEntry) {
          // Update existing crew entry
          crewEntry.total = averageScore;
        } else {
          // Add new crew entry with total score
          barchartData[siteName][date].crews.push({
            crewName: siteName,
            total: averageScore,
          });
        }
      });
    }

    return [transformed, barchartData];
  }
  // Fetch data only once on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const currentSelectedCrew = selectedCrew;
      const currentSelectedOperation = selectedOperation;
      const currentSelectedDepartment = selectedDepartment;
      const currentStartDate = startDate;
      const currentEndDate = endDate;
      try {
        // Initialize an empty filter object
        let filter = {};

        // Conditionally add filters based on the captured state
        if (currentSelectedCrew) {
          filter.Crew = { contains: currentSelectedCrew };
        }
        if (currentSelectedOperation)
          filter.Operation = { contains: currentSelectedOperation };
        if (currentSelectedDepartment)
          filter.Department = { contains: currentSelectedDepartment };
        if (currentStartDate && !currentEndDate)
          filter.FormDate = { ge: currentStartDate };
        if (!currentStartDate && currentEndDate)
          filter.FormDate = { le: currentEndDate };
        if (currentStartDate && currentEndDate) {
          if (new Date(currentStartDate) <= new Date(currentEndDate)) {
            filter.FormDate = { between: [currentStartDate, currentEndDate] };
          } else {
            filter.FormDate = { between: [currentEndDate, currentStartDate] };
            console.error("currentStartDate is after currentEndDate");
          }
        }

        // Include the filters in the GraphQL query
        const response = await fetchAllPaginatedResults(
          listPerformanceReports,
          { filter: filter },
        );
        // After fetching data, check if the filters haven't changed during the data fetch.
        // If they have changed, don't update the data to avoid flickering in the UI.
        if (
          selectedCrew === currentSelectedCrew &&
          selectedOperation === currentSelectedOperation &&
          selectedDepartment === currentSelectedDepartment &&
          startDate === currentStartDate &&
          endDate === currentEndDate
        ) {
          setData(response);
          setHasFetchedData(true); // Set hasFetchedData to true after fetching data
          setKey((prevKey) => prevKey + 1);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Only call fetchData if data is not already set
    fetchData();
  }, [
    selectedCrew,
    selectedOperation,
    selectedDepartment,
    startDate,
    endDate,
    formattedWeights,
    questionsText,
  ]); // Dependency array includes filters to refetch when they change

  // Use useMemo to memoize the result of transformData
  const transformedData = useMemo(() => {
    if (data) {
      return transformData(data, "Performance Reports Maturity");
    }
    return null;
  }, [data]);

  /**
   * Function to calculate monthly averages from data points.
   * @param {Array} data - Array of data points. Each point should have 'x' (timestamp) and 'y' (score).
   * @returns {Array} - Array of monthly averages. Each point will have 'x' (timestamp) and 'y' (monthly average score).
   */
  function calculateMonthlyAverage(data) {
    const monthlyData = {};

    data.forEach((point) => {
      const date = new Date(point.x);
      const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;

      if (!monthlyData[yearMonth]) {
        monthlyData[yearMonth] = { sum: 0, count: 0 };
      }

      monthlyData[yearMonth].sum += point.y;
      monthlyData[yearMonth].count += 1;
    });

    const result = [];

    const allMonths = Object.keys(monthlyData).sort();
    const firstMonth = new Date(allMonths[0]);
    const lastMonth = new Date(allMonths[allMonths.length - 1]);

    for (
      let date = new Date(firstMonth);
      date <= lastMonth;
      date.setMonth(date.getMonth() + 1)
    ) {
      const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const average =
        monthlyData[yearMonth] && monthlyData[yearMonth].sum !== 0
          ? monthlyData[yearMonth].sum / monthlyData[yearMonth].count
          : null;

      result.push({
        x: Date.UTC(date.getFullYear(), date.getMonth(), 15),
        y: average,
      });
    }

    // Filter out months where the average is null
    return result.filter((point) => point.y !== null);
  }

  function prepareScatterTrendData(data) {
    const scatterData = [];

    Object.keys(data).forEach((key) => {
      const transformedItems = data[key];
      Object.keys(transformedItems).forEach((dateStr) => {
        const date = new Date(dateStr);
        const monthlyTotal = transformedItems[dateStr].monthlyTotal;

        scatterData.push({ x: date.getTime(), y: parseFloat(monthlyTotal) });
      });
    });

    const monthlyData = calculateMonthlyAverage(scatterData);

    const trendlineData = monthlyData.map((point) => ({
      x: point.x,
      y: point.y,
    }));

    return [
      {
        name: "Points",
        type: "scatter",
        data: scatterData,
      },
      {
        name: "Trendline",
        type: "line",
        data: trendlineData,
      },
    ];
  }

  const scatterTrendData = useMemo(() => {
    if (transformedData) {
      return prepareScatterTrendData(transformedData[1]);
    }
    return null;
  }, [transformedData]);

  return (
    <div className="scroll-smooth pb-10 m-4">
      <View>
        {transformedData ? (
          <>
            {loading ? (
              <Loader className="w-20 h-20" />
            ) : (
              <>
                {loading ? (
                  <Loader className="w-20 h-20" />
                ) : (
                  <>
                    <h5 className="sm:text-3xl text-xl mx-auto  mb-5 text-black break-words">
                      MO@T Element Maturity
                    </h5>
                    <h7 className="text-black sm:text-lg text-sm mx-auto my-5">
                      Use the graphs below to drill into the maturity of each
                      element through the scores for the “what good feels like”
                      questions and comments.
                    </h7>
                    {transformedData[0] &&
                    transformedData[1] &&
                    questionsText !== null &&
                    formattedWeights !== null ? (
                      <div className="backdrop:mt-4 grid grid-cols-12 gap-1 md:mt-6 md:gap-2 2xl:mt-7.5 2xl:gap-4">
                        <div className="flex flex-col col-span-12 bg-white py-6 px-7.5 xl:col-span-6 rounded-2xl shadow">
                          <h7 className="text-black sm:text-xl text-base  mb-5 mx-6">
                            Performance Reports Maturity Trend
                          </h7>
                          <div id="timeSeries">
                            <Chart
                              type="line"
                              series={scatterTrendData}
                              options={{
                                chart: {
                                  height: 800,
                                  type: "line",
                                  toolbar: {
                                    tools: {
                                      zoom: true,
                                      zoomin: true,
                                      zoomout: true,
                                      pan: true,
                                      reset: false,
                                      customIcons: [
                                        {
                                          icon: '<i class="fa fa-sync fa-sm"></i>',
                                          index: -1,
                                          title: "Reset Zoom",
                                          class: "custom-icon",
                                          click: function () {
                                            setStartDate("");
                                            setEndDate("");
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  events: {
                                    zoomed: function (
                                      chartContext,
                                      zoomedData,
                                    ) {
                                      if (zoomedData && zoomedData.xaxis) {
                                        const { min, max } = zoomedData.xaxis;

                                        const startDate =
                                          min !== undefined && !isNaN(min)
                                            ? new Date(min)
                                            : "";
                                        const endDate =
                                          max !== undefined && !isNaN(max)
                                            ? new Date(max)
                                            : "";

                                        if (startDate) {
                                          setStartDate(startDate);
                                        } else {
                                          console.warn(
                                            "Invalid start date:",
                                            min,
                                          );
                                        }

                                        if (endDate) {
                                          setEndDate(endDate);
                                        } else {
                                          console.warn(
                                            "Invalid end date:",
                                            max,
                                          );
                                        }
                                      } else {
                                        console.warn(
                                          "Invalid zoomedData:",
                                          zoomedData,
                                        );
                                      }
                                    },
                                  },
                                },
                                fill: {
                                  type: "solid",
                                },
                                markers: {
                                  size: [6, 0],
                                },
                                stroke: {
                                  curve: "smooth",
                                },
                                tooltip: {
                                  shared: true,
                                  intersect: false,
                                  y: {
                                    formatter: function (val) {
                                      return val ? val.toFixed(1) : 0;
                                    },
                                  },
                                  x: {
                                    format: "dd MMM yyyy",
                                  },
                                },
                                legend: {
                                  show: false,
                                },
                                xaxis: {
                                  type: "datetime",
                                  title: {
                                    text: "Date", // Add X-axis label here
                                    format: "dd MMM yyyy",
                                  },
                                },
                                yaxis: {
                                  min: 0,
                                  max: 3,
                                  title: {
                                    text: "Maturity Score", // Add Y-axis label here
                                  },
                                  labels: {
                                    minWidth: 30,
                                    formatter: function (val) {
                                      return val.toFixed(2);
                                    },
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col col-span-12 bg-white py-6 px-7.5 xl:col-span-6 rounded-2xl shadow">
                          <h7 className="text-black sm:text-xl text-base  mb-5 mx-6">
                            Performance Reports Maturity
                          </h7>
                          <BarchartHierarchyWrapper
                            key={key}
                            data={transformedData[0]}
                            isLarge={false}
                          />
                        </div>
                      </div>
                    ) : (
                      <Loader className="w-20 h-20" />
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : transformedData === null && hasFetchedData ? (
          <Loader className="w-20 h-20" />
        ) : (
          <div>There are no records matching your query.</div>
        )}
      </View>
    </div>
  );
};

export default PerformanceReportsReportUI;

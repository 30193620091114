// import React from 'react';
// import { UpdateProfileForm } from "./tailwind-ui-components";

// function ProfileUpdate({ combinedUser, setCombinedUser }) {
//   if (!combinedUser) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <UpdateProfileForm
//         combinedUser={combinedUser}
//         setCombinedUser={setCombinedUser}
//       />
//     </div>
//   );
// }

// export default ProfileUpdate;

import React from "react";
import { UpdateProfileForm } from "./tailwind-ui-components";

function ProfileUpdate({ combinedUser, setCombinedUser }) {
  return (
    <div>
      <UpdateProfileForm
        combinedUser={combinedUser}
        setCombinedUser={setCombinedUser}
      />
    </div>
  );
}

export default ProfileUpdate;
import { CheckIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

export default function FormTabSteps({
  currentSection,
  setCurrentSection,
  currentQuestionSection,
  setCurrentQuestionSection,
}) {
  const [steps, setSteps] = useState([
    {
      id: "01",
      name: "Assessment Detail",
      description: "",
      href: "#Company",
      stepId: 0,
      questionId: -1,
      displayId: "01",
      status: "current",
    },
    {
      id: "q1",
      name: "Behaviours",
      description: "",
      href: "#Behaviours",
      stepId: 1,
      questionId: 0,
      displayId: "02",
      status: "upcoming",
    },
    {
      id: "q2",
      name: "Tools / Systems",
      description: "",
      href: "#Tools",
      stepId: 1,
      questionId: 1,
      displayId: "03",
      status: "upcoming",
    },
    {
      id: "q3",
      name: "Psych Safety",
      description: "",
      href: "#Psych",
      stepId: 1,
      questionId: 2,
      displayId: "04",
      status: "upcoming",
    },
    {
      id: "03",
      name: "Summary",
      description: "",
      href: "#Summary",
      stepId: 2,
      displayId: "05",
      questionId: -1,
      status: "upcoming",
    },
  ]);

  useEffect(() => {
    const updatedSteps = steps.map((step) => {
      let status;
      if (step.stepId === currentSection) {
        if (step.id.startsWith("q")) {
          if (step.stepId === currentSection) {
            if (step.questionId === currentQuestionSection) {
              status = "current";
            } else if (step.questionId < currentQuestionSection) {
              status = "complete";
            } else {
              status = "upcoming";
            }
          }
        } else {
          status = "current";
        }
      } else if (step.stepId < currentSection) {
        status = "complete";
      } else {
        status = "upcoming";
      }
      return { ...step, status };
    });
    setSteps(updatedSteps);
  }, [currentSection, currentQuestionSection]);

  return (
    <div className="w-full py-6">
      <div aria-label="Progress" className="w-full">
        <ol className="flex items-center w-full">
          {steps.map((step, stepIdx) => (
            <li key={step.name} className="relative flex-1">
              <div className="group flex items-center justify-center">
                <span className="flex-shrink-0" aria-hidden="true">
                  {stepIdx !== 0 && (
                    <div className="absolute top-4 left-0 h-0.5 w-full -translate-x-1/2 bg-gray-200" />
                  )}
                  {step.status === "complete" ? (
                    <a
                      href={step.href}
                      className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-thiess-blue-600 hover:bg-thiess-blue-900"
                      onClick={(e) => {
                        if (step.id.startsWith("q")) {
                          setCurrentSection(step.stepId);
                          setCurrentQuestionSection(step.questionId);
                        } else {
                          setCurrentSection(step.stepId);
                        }
                      }}
                    >
                      <CheckIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </a>
                  ) : step.status === "current" ? (
                    <a
                      href={step.href}
                      className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-thiess-blue-600 bg-white"
                      aria-current="step"
                      onClick={(e) => {
                        if (step.id.startsWith("q")) {
                          setCurrentSection(step.stepId);
                          setCurrentQuestionSection(step.questionId);
                        } else {
                          setCurrentSection(step.stepId);
                        }
                      }}
                    >
                      <span
                        className="h-2.5 w-2.5 rounded-full bg-thiess-blue-600"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </a>
                  ) : (
                    <a
                      href={step.href}
                      className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                      onClick={(e) => {
                        if (step.id.startsWith("q")) {
                          setCurrentSection(step.stepId);
                          setCurrentQuestionSection(step.questionId);
                        } else {
                          setCurrentSection(step.stepId);
                        }
                      }}
                    >
                      <span
                        className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </a>
                  )}
                </span>
              </div>
              <div className="absolute top-14 left-1/2 -translate-x-1/2 w-full">
                <div className="text-xs font-medium text-black text-center whitespace-nowrap overflow-hidden text-ellipsis px-1">
                  {step.name}
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useState } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useNavigate, useLocation } from "react-router-dom";
import ManageOperations from "./ManageOperations";
import ManageDepartments from "./ManageDepartments";
import ManageCrews from "./ManageCrews";
import ManageCoachees from "./ManageCoachees";
function Manage({ user }) {
  const [statusArr, setStatusArr] = useState({
    operations: "complete",
    departments: "current",
    crews: "current",
    users: "complete",
  });
  const [selected, setSelected] = useState("users");
  const navigate = useNavigate();
  const location = useLocation();
  const steps = [
    {
      id: "1",
      name: "Operations",
      slug: "operations",
      status: statusArr.operations,
    },
    {
      id: "2",
      name: "Departments",
      slug: "departments",
      status: statusArr.departments,
    },
    {
      id: "3",
      name: "Crews",
      slug: "crews",
      status: statusArr.crews,
    },
    {
      id: "4",
      name: "Users",
      slug: "users",
      status: statusArr.users,
    },
  ];
  useEffect(() => {
    // Set selectedForm based on URL path
    const path = location.pathname.replace("/manage/", "");
    const validPaths = ["operations", "departments", "crews", "users"];
    if (validPaths.includes(path)) {
      setSelected(path);
      const updatedStatus = Object.keys(statusArr).reduce((acc, key) => {
        acc[key] = key === path ? "complete" : "current";
        if (path !== "users" && key === "users") {
          acc[key] = "current";
        }
        return acc;
      }, {});
      setStatusArr(updatedStatus);
    } else {
      handleSelection("users");
      navigate("/manage/users", { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleSelection = (slug) => {
    setSelected(slug);
    const updatedStatus = Object.keys(statusArr).reduce((acc, key) => {
      acc[key] = key === slug ? "complete" : "current";
      return acc;
    }, {});
    setStatusArr(updatedStatus);
    navigate(`/manage/${slug}`);
  };
  function PanelView({ steps }) {
    return (
      <div
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <div
            key={step.slug}
            className="relative md:flex md:flex-1"
            onClick={() => handleSelection(step.slug)}
          >
            {step.status === "complete" ? (
              <div className="group flex cursor-pointer w-full items-center">
                <span className="flex items-center px-6 py-4 sm:text-lg text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-thiess-blue-600 group-hover:bg-thiess-blue-800">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 sm:text-lg text-sm font-medium text-black">
                    {step.name}
                  </span>
                </span>
              </div>
            ) : step.status === "current" ? (
              <div
                className="flex cursor-pointer items-center px-6 py-4 sm:text-lg text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-thiess-blue-600">
                  <span className="text-thiess-blue-600">{step.id}</span>
                </span>
                <span className="ml-4 sm:text-lg text-sm font-medium text-thiess-blue-600">
                  {step.name}
                </span>
              </div>
            ) : (
              <div className="group cursor-pointer flex items-center">
                <span className="flex items-center px-6 py-4 sm:text-lg text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                    <span className="text-gray-700 group-hover:text-black">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 sm:text-lg text-sm font-medium text-gray-700 group-hover:text-black">
                    {step.name}
                  </span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </div>
        ))}
      </div>
    );
  }
  function chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }
  const stepChunks = chunkArray(steps, 5);
  return (
    <div className="scroll-smooth pb-10">
      <h2 className="sm:text-6xl text-4xl mx-auto  mb-5 text-black break-words">
        Management Screens
      </h2>
      {user.userHasInfo && (user.admin || user.coach) ? (
        <>
          <nav aria-label="Progress">
            {stepChunks.map((stepChunk, index) => (
              <PanelView key={index} steps={stepChunk} />
            ))}
          </nav>
          <div>
            {selected === "operations" && <ManageOperations />}
            {selected === "departments" && <ManageDepartments />}
            {selected === "crews" && <ManageCrews />}
            {selected === "users" && <ManageCoachees user={user} />}
          </div>
        </>
      ) : (
        <div className="text-center text-2xl">
          You are not allowed to view this page, ask your coach or admin to
          elevate your permissions.
        </div>
      )}
    </div>
  );
}

export default Manage;

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "../../fyr-theme/components/layouts/PageWrapper/PageWrapper.jsx";
import Container from "../../fyr-theme/components/layouts/Container/Container.jsx";
import Button from "../../fyr-theme/components/ui/Button.jsx";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Loader } from "@aws-amplify/ui-react";
import {
  listBoards,
  listOperationNames,
  listDepartmentNames,
} from "../../graphql/queries.js";
import { updateBoard, deleteBoard } from "../../graphql/mutations.js";
import Table, {
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "../../fyr-theme/components/ui/Table.jsx";
import BoardCreatePartial from "../_partial/BoardCreatePartial";
import { API, graphqlOperation } from "aws-amplify";
import toast from "react-hot-toast";
import ActionLogsBoard from "../ActionLogsBoardPage/ActionLogsBoard.page.js";
import { fetchAllPaginatedResults } from "../../utils/pagination.js";
const EditableCell = ({ initialValue, onSave }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    onSave(value);
  };

  return (
    <input
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-base text-sm sm:leading-6"
    />
  );
};

const BoardsDashboardPage = ({ user }) => {
  const [boardsData, setBoardsData] = useState([]);
  const [isCreateBoardModalOpen, setIsCreateBoardModalOpen] = useState(false);
  const [editingBoard, setEditingBoard] = useState(null);
  const [operations, setOperations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBoardKey, setSelectedBoardKey] = useState(null);
  const navigate = useNavigate();
  const { boardId } = useParams();

  useEffect(() => {
    if (boardId) {
      setSelectedBoardKey(boardId);
    }
  }, [boardId]);

  const fetchBoards = async () => {
    setIsLoading(true);
    try {
      const boardData = await fetchAllPaginatedResults(listBoards);
      setBoardsData(boardData);
    } catch (error) {
      console.error("Error fetching boards:", error);
      toast.error("Failed to fetch boards");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllOperationsAndDepartments = useCallback(async () => {
    try {
      const [operationData, departmentData] = await Promise.all([
        fetchAllPaginatedResults(listOperationNames),
        fetchAllPaginatedResults(listDepartmentNames),
      ]);
      setOperations(operationData);
      setDepartments(departmentData);
    } catch (err) {
      console.error("Error fetching operations and departments:", err);
      toast.error("Failed to fetch operations and departments");
    }
  }, []);

  useEffect(() => {
    fetchBoards();
    fetchAllOperationsAndDepartments();
  }, [fetchAllOperationsAndDepartments]);

  const handleEditBoard = (id) => {
    const boardToEdit = boardsData.find((board) => board.id === id);
    setEditingBoard({ ...boardToEdit });
  };

  const updateEditingBoard = (field, value) => {
    setEditingBoard((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const formatKey = (key) => {
    return key
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const handleSaveBoard = async () => {
    if (!editingBoard) return;

    const { id, name, key, description, site, department } = editingBoard;

    const formattedKey = formatKey(key);

    if (formattedKey === "") {
      toast.error("Key cannot be empty");
      return;
    }

    const isKeyUnique = boardsData.every(
      (board) => board.id === id || formatKey(board.key) !== formattedKey,
    );
    if (!isKeyUnique) {
      toast.error("Board key must be unique");
      return;
    }

    try {
      const input = {
        id,
        name,
        key: formattedKey,
        description,
        site,
        department,
      };
      const result = await API.graphql(
        graphqlOperation(updateBoard, { input }),
      );
      setBoardsData(
        boardsData.map((board) =>
          board.id === id ? result.data.updateBoard : board,
        ),
      );
      setEditingBoard(null);
      toast.success("Board updated successfully");
    } catch (error) {
      console.error("Error updating board:", error);
      toast.error("Failed to update board");
    }
  };

  const handleDeleteBoard = async (id) => {
    if (window.confirm("Are you sure you want to delete this board?")) {
      try {
        await API.graphql(graphqlOperation(deleteBoard, { input: { id } }));
        setBoardsData(boardsData.filter((board) => board.id !== id));
        toast.success("Board deleted successfully");
      } catch (error) {
        console.error("Error deleting board:", error);
        toast.error("Failed to delete board");
      }
    }
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: () => "Name",
      cell: ({ getValue, row }) =>
        editingBoard?.id === row.original.id ? (
          <EditableCell
            initialValue={editingBoard.name}
            onSave={(value) => updateEditingBoard("name", value)}
          />
        ) : (
          getValue()
        ),
    }),
    columnHelper.accessor("key", {
      header: () => "Key",
      cell: ({ getValue, row }) =>
        editingBoard?.id === row.original.id ? (
          <EditableCell
            initialValue={editingBoard.key}
            onSave={(value) => updateEditingBoard("key", value)}
          />
        ) : (
          <button
            className="cursor-pointer text-blue-500"
            onClick={() => navigate(`/action-jackson/boards/${getValue()}`)}
          >
            {getValue()}
          </button>
        ),
    }),
    columnHelper.accessor("description", {
      header: () => "Description",
      cell: ({ getValue, row }) =>
        editingBoard?.id === row.original.id ? (
          <EditableCell
            initialValue={editingBoard.description}
            onSave={(value) => updateEditingBoard("description", value)}
          />
        ) : (
          getValue()
        ),
    }),
    columnHelper.accessor("site", {
      header: () => "Site",
      cell: ({ getValue, row }) => {
        const value = getValue();
        if (editingBoard?.id === row.original.id) {
          return (
            <select
              className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-base text-sm sm:leading-6"
              value={editingBoard.site}
              onChange={(e) => updateEditingBoard("site", e.target.value)}
            >
              <option value="">Select Site</option>
              {operations
                .sort((a, b) => a.OperationName.localeCompare(b.OperationName))
                .map((operation) => (
                  <option key={operation.id} value={operation.id}>
                    {operation.OperationName}
                  </option>
                ))}
            </select>
          );
        }
        return operations.find((op) => op.id === value)?.OperationName || "N/A";
      },
    }),
    columnHelper.accessor("department", {
      header: () => "Department",
      cell: ({ getValue, row }) => {
        const value = getValue();
        if (editingBoard?.id === row.original.id) {
          return (
            <select
              className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-base text-sm sm:leading-6"
              value={editingBoard.department}
              onChange={(e) => updateEditingBoard("department", e.target.value)}
            >
              <option value="">Select Department</option>
              {departments
                .sort((a, b) =>
                  a.DepartmentName.localeCompare(b.DepartmentName),
                )
                .map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.DepartmentName}
                  </option>
                ))}
            </select>
          );
        }
        return (
          departments.find((dep) => dep.id === value)?.DepartmentName || "N/A"
        );
      },
    }),
    columnHelper.accessor("actions", {
      header: () => "Actions",
      cell: ({ row }) => (
        <div className="flex space-x-2">
          {editingBoard?.id === row.original.id ? (
            <div
              variant="solid"
              onClick={handleSaveBoard}
              className="text-green-600 hover:text-green-700 cursor-pointer mr-4"
            >
              Save
            </div>
          ) : (
            <div
              variant="solid"
              onClick={() => handleEditBoard(row.original.id)}
              className="text-yellow-600 hover:text-yellow-700 cursor-pointer mr-4"
            >
              Edit
            </div>
          )}
          <div
            variant="solid"
            onClick={() => handleDeleteBoard(row.original.id)}
            className="text-red-600 hover:text-red-700 cursor-pointer"
          >
            Delete
          </div>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data: boardsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (selectedBoardKey) {
    return (
      <ActionLogsBoard
        boardKey={selectedBoardKey}
        onBack={() => {
          setSelectedBoardKey(null);
          navigate("/action-jackson");
        }}
      />
    );
  }

  return (
    <PageWrapper name="Project Board">
      <div className="flex justify-between">
        <h2 className="sm:text-6xl text-4xl  text-black break-words">
          Action Jackson Boards
        </h2>
        <Button
          variant="solid"
          icon="HeroPencil"
          className="max-w-fit my-2"
          onClick={() => setIsCreateBoardModalOpen(true)}
        >
          Create
        </Button>
      </div>
      <Container breakpoint={null} className="h-full w-full overflow-auto">
        <Table>
          <THead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} className="text-left">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {isLoading ? (
              <Tr>
                <Td colSpan={columns.length} className="text-center py-4">
                  <Loader className="w-12 h-12" />
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            )}
          </TBody>
        </Table>
      </Container>
      {isCreateBoardModalOpen && (
        <BoardCreatePartial
          isOpen={isCreateBoardModalOpen}
          setIsOpen={setIsCreateBoardModalOpen}
          boardsData={boardsData}
          setBoardsData={setBoardsData}
        />
      )}
    </PageWrapper>
  );
};

export default BoardsDashboardPage;

import React, { forwardRef } from "react";
import classNames from "classnames";

const Container = forwardRef((props, ref) => {
  const { children, className, breakpoint, ...rest } = props;

  return (
    <div
      ref={ref}
      data-component-name="Container"
      className={classNames("mx-auto p-4", breakpoint, className)}
      {...rest}
    >
      {children}
    </div>
  );
});
Container.defaultProps = {
  breakpoint: "container",
  className: undefined,
};
Container.displayName = "Container";

export default Container;

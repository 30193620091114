import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import allImage from "../images/all.png";
import supervisorImage from "../images/supervisor.png";
import plsImage from "../images/pls.png";
import managerImage from "../images/manager.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const images = [
  {
    id: "all",
    name: "ALL",
    description: "",
    subtitle: "Below you can find all of the MO@T Elements",
    src: allImage,
  },
  {
    id: "supervisor",
    name: "Supervisor",
    description: `...`,
    subtitle: "Key information for Thiess Supervisors",
    src: supervisorImage,
  },
  {
    id: "pls",
    name: "Planner, Staff or Superintendent",
    description: `...`,
    subtitle: "Essential details for Planners, Staff, and Superintendents",
    src: plsImage,
  },
  {
    id: "manager",
    name: "Manager",
    description: `...`,
    subtitle: "Critical information for Thiess Managers",
    src: managerImage,
  },
];

const tabs = [
  {
    id: "all",
    name: "ALL",
    description: "",
    subtitle: "Below you can find all of the MO@T Elements",
  },
  {
    id: "supervisor",
    name: "Supervisor",
    description: `As a supervisor you lead your crew to safely and productively deliver the plan each shift and then set future shifts up for success. 
To achieve this you will use a number of MOS elements throughout each shift. These can be seen in the “typical shift” diagram below. You can explore these by clicking on them in the MOS Elements section below.
It all starts with a Shift Plan that has all the work and targets with the resources you need get this done. Doing a Handover helps you work out where things are up to and talk through how you will deliver the plan, your site might do a Preshift Alignment to agree any last minute changes to the plan so that you are confident that you can commit your team to deliver it. You then describe the plan to your team in the Prestart and send them off to work with a clear understanding of what they need to do. During the shift you should regularly spend Time in the Field to do Short Interval Control, this enables you to check that the work is going smoothly and to act proactively to fix any issues that might stop you achieving the plan. Towards the end of shift you will write a Shift Report and prepare to do a smooth Handover with the oncoming shift. You might also discuss how well you met your commitments in an End of Shift Debrief, to find ways to improve tomorrow.`,
    subtitle: "Key information for Thiess Supervisors",
  },
  {
    id: "pls",
    name: "Planner, Staff or Superintendent",
    description: `As a planner, staff member or superintendent you are here to support operational teams to successfully deliver on the commitments the site’s leaders have made. 
To achieve this you will use a number of MOS elements throughout each planning week. These can be seen in the diagram below. You can explore these by clicking on them in the MOS Elements Explorer section. 
As a leader in your department, you should understand all the elements that you are involved in. If people report to you, you should also understand the elements that they are involved in so that you can improve the MOS maturity in your area of responsibility.
Before the start of the planning week, planners will create a draft Weekly Plan/Schedule, they will discuss this with operational teams in the Weekly Pre-Planner meeting, get feedback, make changes and then present it at the Weekly Planning Meeting aiming to get commitment from all teams to execute the plan as agreed. They then publish the Weekly Plan/Schedule. 
Each day, as work progresses and reality changes, short term planners will work with the operations teams to adapt the plan so that the shift plans that supervisors get are always accurate and useful to them. The team should commit to these plans in Daily Commitment Meetings.
Performance against the plan is reviewed using Performance Reporting tools in Daily Department & Daily Site Review meetings. If these meetings are done well, the team will find variances and develop actions (recorded in the Action Log) to remove those variances and improve results in the future. 
At the end of the planning week Performance Reporting tools will be used to do Performance Analysis that will be used by the leaders of the departments in Weekly Department & Weekly Site Review meetings to improve the team's results.`,
    subtitle: "Essential details for Planners, Staff, and Superintendents",
  },
  {
    id: "manager",
    name: "Manager",
    description: `As a manager, whether you are operational or not, you are here to build a department that plays its part in the site, safely and productively meeting its commitments to the client and the business. 
To achieve this you will use a number of MOS elements throughout each month, week and day. These can be seen in the diagram below. You can explore these by clicking on them in the MOS Elements Explorer section below. 
As the leader, you should understand all of the Elements that are occurring in your department and have plans to improve their maturity.
Depending on the department you lead you may play a role in the development of the Quarterly/Monthly Plan and participate in Monthly Planning Meetings. You may be involved in Weekly Planning & Scheduling through to Weekly Reviews and in the Daily Review Meetings that help identify variances and develop tactical actions to improve.
As a manager you will be expected to attend and contribute to the Weekly Site Review (IN) Meetings and the Monthly Review (ON) Meetings, where you and other Senior Leaders, seek to understand how you are progressing against your commitments then find ways to remove roadblocks and manage risks so you can systematically learn to improve the site’s performance.`,
    subtitle: "Critical information for Thiess Managers",
  },
];

export default function Panel({ statusArr, onFormSelect }) {
  const location = useLocation();
  const steps = [
    {
      id: "1",
      name: "Quarterly/Monthly Plan",
      slug: "quarterlyMonthlyPlan",
      status: statusArr.quarterlyMonthlyPlan,
      filters: ["manager", "pls"],
    },
    {
      id: "1a",
      name: "Monthly Planning Meeting",
      slug: "monthlyPlanningMeeting",
      status: statusArr.monthlyPlanningMeeting,
      filters: ["manager", "pls"],
    },
    {
      id: "2",
      name: "Weekly Plan/Schedule",
      slug: "weeklyPlanSchedule",
      status: statusArr.weeklyPlanSchedule,
      filters: ["manager", "pls"],
    },
    {
      id: "2a",
      name: "Weekly Pre-Planning",
      slug: "weeklyPrePlanning",
      status: statusArr.weeklyPrePlanning,
      filters: ["pls"],
    },
    {
      id: "2b",
      name: "Weekly Site Planning Meeting",
      slug: "weeklySitePlanningMeeting",
      status: statusArr.weeklySitePlanningMeeting,
      filters: ["pls"],
    },
    {
      id: "2c",
      name: "Weekly Commitment Meeting",
      slug: "weeklyCommitmentMeeting",
      status: statusArr.weeklyCommitmentMeeting,
      filters: ["pls"],
    },
    {
      id: "3",
      name: "Shift Plan",
      slug: "shiftPlan",
      status: statusArr.shiftPlan,
      filters: ["pls", "supervisor"],
    },
    {
      id: "3a",
      name: "Daily Commitment Meeting",
      slug: "dailyCommitmentMeeting",
      status: statusArr.dailyCommitmentMeeting,
      filters: ["pls", "supervisor"],
    },
    {
      id: "3b",
      name: "Shift Handover",
      slug: "shiftHandover",
      status: statusArr.shiftHandover,
      filters: ["supervisor"],
    },
    {
      id: "3c",
      name: "Pre-Shift Alignment",
      slug: "preShiftAlignment",
      status: statusArr.preShiftAlignment,
      filters: ["pls", "supervisor"],
    },
    {
      id: "4",
      name: "Shift Report",
      slug: "shiftReport",
      status: statusArr.shiftReport,
      filters: ["supervisor"],
    },
    {
      id: "4a",
      name: "Pre-Start Meeting",
      slug: "preStart",
      status: statusArr.preStart,
      filters: ["supervisor"],
    },
    {
      id: "4b",
      name: "Short Interval Control",
      slug: "shortIntervalControl",
      status: statusArr.shortIntervalControl,
      filters: ["supervisor"],
    },
    {
      id: "4d",
      name: "Shift Debrief",
      slug: "shiftDebrief",
      status: statusArr.shiftDebrief,
      filters: ["supervisor", "pls"],
    },
    {
      id: "5",
      name: "Performance Reports",
      slug: "performanceReports",
      status: statusArr.performanceReports,
      filters: ["pls"],
    },
    {
      id: "5a",
      name: "Department Daily Review",
      slug: "departmentDailyReview",
      status: statusArr.departmentDailyReview,
      filters: ["pls"],
    },
    {
      id: "5b",
      name: "Daily Site Review",
      slug: "dailySiteReview",
      status: statusArr.dailySiteReview,
      filters: ["manager", "pls"],
    },
    {
      id: "5c",
      name: "Weekly Department Review",
      slug: "weeklyDepartmentReview",
      status: statusArr.weeklyDepartmentReview,
      filters: ["manager", "pls"],
    },
    {
      id: "5d",
      name: "Weekly Site Review",
      slug: "weeklySiteReview",
      status: statusArr.weeklySiteReview,
      filters: ["manager", "pls"],
    },
    {
      id: "5e",
      name: "Performance Analysis",
      slug: "performanceAnalysis",
      status: statusArr.performanceAnalysis,
      filters: ["manager", "pls"],
    },
    {
      id: "6",
      name: "Management Reports",
      slug: "managementReports",
      status: statusArr.managementReports,
      filters: ["manager", "pls"],
    },
    {
      id: "6a",
      name: "Monthly Review",
      slug: "monthlyReview",
      status: statusArr.monthlyReview,
      filters: ["manager"],
    },
    {
      id: "6b",
      name: "Quarterly Forecast / Review",
      slug: "quarterlyForecastReview",
      status: statusArr.quarterlyForecastReview,
      filters: ["manager"],
    },
    {
      id: "7",
      name: "Action Log",
      slug: "actionLog",
      status: statusArr.actionLog,
      filters: ["manager", "pls"],
    },
    {
      id: "8",
      name: "Business Improvement & Capital Projects",
      slug: "businessImprovementCapitalProjects",
      status: statusArr.businessImprovementCapitalProjects,
      filters: ["manager", "pls"],
    },
  ];

  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [expandedItems, setExpandedItems] = useState({});
  const [isPanelHidden, setIsPanelHidden] = useState(false);

  const filteredSteps = steps.filter((step) => {
    const matchesSearch = step.name
      .toLowerCase()
      .includes(search.toLowerCase());
    const matchesFilter =
      activeTab === "all" || step.filters.includes(activeTab);
    return matchesSearch && matchesFilter;
  });

  const handleTabClick = (tabId) => {
    if (activeTab === tabId) {
      setActiveTab("");
      setIsPanelHidden(!isPanelHidden);
    } else {
      scrollToSection(tabId);
    }
  };

  useEffect(() => {
    if (location.state?.scrollToSection) {
      const sectionId = location.state.scrollToSection;
      const element = document.getElementById(`section-${sectionId}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setActiveTab(sectionId);
      }
    }
  }, [location]);

  function PanelView({ steps }) {
    return (
      <div
        role="list"
        className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 overflow-auto shadow-md"
      >
        {steps.map((step, stepIdx) => (
          <div key={step.slug} className="relative md:flex md:flex-1">
            <a
              href={`/forms/${step.slug}`}
              onClick={(e) => {
                e.preventDefault();
                onFormSelect(step.slug);
              }}
            >
              {step.status === "complete" ? (
                <div className="group flex cursor-pointer w-full items-center">
                  <span className="flex items-center px-6 py-4 sm:text-base text-sm font-medium">
                    <span className="flex sm:h-10 sm:w-10 h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-thiess-blue-600 group-hover:bg-thiess-blue-800">
                      <CheckIcon
                        className="sm:h-6 sm:w-6 h-3 w-3 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 sm:text-base text-sm font-medium text-black">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.status === "current" ? (
                <div
                  className="flex cursor-pointer items-center px-6 py-4 sm:text-base text-sm font-medium"
                  aria-current="step"
                >
                  <span className="flex sm:h-10 sm:w-10 h-5 w-5 flex-shrink-0 items-center justify-center rounded-full border-2 border-thiess-blue-600">
                    <span className="text-thiess-blue-600">{step.id}</span>
                  </span>
                  <span className="ml-4 sm:text-base text-sm font-medium text-thiess-blue-600">
                    {step.name}
                  </span>
                </div>
              ) : (
                <div className="group cursor-pointer flex items-center">
                  <span className="flex items-center px-6 py-4 sm:text-base text-sm font-medium">
                    <span className="flex sm:h-10 sm:w-10 h-5 w-5 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-black">
                        {step.id}
                      </span>
                    </span>
                    <span className="ml-4 sm:text-base text-sm font-medium text-gray-500 group-hover:text-black">
                      {step.name}
                    </span>
                  </span>
                </div>
              )}

              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </a>
          </div>
        ))}
      </div>
    );
  }

  const toggleExpand = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const truncateText = (text, maxLength = 200) => {
    if (text.length <= maxLength) return text;
    const truncated = text
      .slice(0, maxLength)
      .split(" ")
      .slice(0, -1)
      .join(" ");
    return truncated + "...";
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(`section-${sectionId}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveTab(sectionId);
      setIsPanelHidden(false);
    }
  };

  return (
    <div aria-label="Progress" className="w-full space-y-4">
      <div>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center mx-auto"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.id}
              id={`section-${tab.id}`}
              onClick={() => handleTabClick(tab.id)}
              className={classNames(
                activeTab === tab.id
                  ? "bg-white hover:bg-gray-50 focus-visible:outline-thiess-blue-200 text-black"
                  : "bg-thiess-blue-600 hover:bg-thiess-blue-500 focus-visible:outline-thiess-blue-600 text-white",
                "rounded-md shadow-lg py-2 sm:text-lg text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 relative group",
              )}
            >
              <span className="tooltip-text invisible group-focus:visible opacity-0 group-focus:opacity-100 transition-opacity duration-300 bg-blue-200 text-black p-2 rounded-full absolute left-1/2 transform -translate-x-1/2 -top-full w-48 text-center text-xs z-10">
                Click again to hide/show the panel
              </span>
              {tab.name}
            </button>
          ))}
        </div>

        {!isPanelHidden && (
          <>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-8 bg-white shadow-lg rounded-xl px-4 py-4">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  className={classNames(
                    activeTab === tab.id ? "block" : "hidden",
                    "col-span-1 md:col-span-2",
                  )}
                >
                  <h6 className="text-black sm:text-2xl text-lg font-semibold mb-4 leading-tight">
                    {tab.name}
                  </h6>
                  <h7 className="text-thiess-blue-600 sm:text-xl text-base mb-4 leading-snug">
                    {tab.subtitle}
                  </h7>
                  <div className="text-gray-600 leading-relaxed sm:text-base text-sm">
                    {expandedItems[tab.id]
                      ? tab.description.split("\n").map((line, index) => (
                          <p key={index} className="mb-2">
                            {line}
                          </p>
                        ))
                      : truncateText(tab.description)
                          .split("\n")
                          .map((line, index) => (
                            <p key={index} className="mb-2">
                              {line}
                            </p>
                          ))}
                    {tab.description.length > 200 && (
                      <button
                        onClick={() => toggleExpand(tab.id)}
                        className="text-thiess-blue-600 hover:underline mt-2 font-medium"
                      >
                        {expandedItems[tab.id] ? "Read less" : "Read more"}
                      </button>
                    )}
                  </div>
                </div>
              ))}

              {images.map((image) => (
                <div
                  key={image.id}
                  className={classNames(
                    activeTab === image.id ? "block" : "hidden",
                    "col-span-1 my-4 md:my-auto",
                  )}
                >
                  <div className="w-full h-64 md:h-80 overflow-hidden rounded-lg shadow-lg">
                    <img
                      src={image.src}
                      alt={image.name}
                      className="w-full h-full object-cover object-center transition-transform duration-300 hover:scale-105"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-white rounded-2xl my-4 shadow-md">
              <h6 className="text-black sm:text-2xl text-lg font-semibold mx-4 leading-tight py-6">
                Select the MO@T element you want to see or assess
              </h6>
              {filteredSteps.length === 0 ? (
                <p className="text-gray-500 sm:text-base text-sm text-center mt-4">
                  No forms found.
                </p>
              ) : (
                <div>
                  <div className="grid gap-4 grid-cols-2 lg:grid-cols-4 py-4 rounded-2xl px-4">
                    {filteredSteps.map((step) => (
                      <PanelView key={step.slug} steps={[step]} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function useNavigateToSection() {
  const navigate = useNavigate();

  return (pageRoute, sectionId) => {
    navigate(pageRoute, { state: { scrollToSection: sectionId } });
  };
}

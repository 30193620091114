import React from "react";
import classNames from "classnames";
import themeConfig from "../../fyr-theme/config/theme.config";
import Icon from "../../fyr-theme/components/icon/Icon";

const NewProjectButtonPartial = (props) => {
  return (
    <button
      type="button"
      className={classNames(
        "group flex w-full cursor-pointer items-center justify-center rounded-xl border border-dashed border-zinc-500 p-4 hover:border-zinc-300 hover:dark:border-zinc-800",
        themeConfig.transition,
      )}
      {...props}
    >
      <Icon
        icon="HeroPlus"
        size="text-2xl"
        className={classNames(
          "text-zinc-500 group-hover:text-zinc-300 group-hover:dark:text-zinc-800",
          themeConfig.transition,
        )}
      />
    </button>
  );
};

export default NewProjectButtonPartial;

import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { createTask, updateTask, deleteTask } from "../../graphql/mutations";
import { listTasks, listBoards, listCoachNames } from "../../graphql/queries";
import Card, {
  CardBody,
  CardHeader,
  CardHeaderChild,
  CardTitle,
} from "../../fyr-theme/components/ui/Card";
import Button from "../../fyr-theme/components/ui/Button";
import Input from "../../fyr-theme/components/form/Input";
import SelectReact from "../../fyr-theme/components/form/SelectReact";
import Badge from "../../fyr-theme/components/ui/Badge";
import Icon from "../../fyr-theme/components/icon/Icon";
import Toggle from "../../tailwind-ui-components/Toggle";
import toast from "react-hot-toast";
import { fetchAllPaginatedResults } from "../../utils/pagination";

const QuickActions = () => {
  const [recentTasks, setRecentTasks] = useState([]);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const [boards, setBoards] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [showDoneTasks, setShowDoneTasks] = useState(false);
  const [coachNames, setCoachNames] = useState({});
  const [user, setUser] = useState(null);
  const [coachUser, setCoachUser] = useState(null);
  const [userHasInfo, setUserHasInfo] = useState(false);

  const statusOptions = [
    { label: "Backlog", value: "BACKLOG", icon: "HeroQueueList" },
    { label: "To Do", value: "TODO", icon: "HeroClipboard" },
    { label: "Pending", value: "PENDING", icon: "HeroReceiptRefund" },
    { label: "Run", value: "RUN", icon: "HeroPlay" },
    { label: "Done", value: "DONE", icon: "HeroCheckCircle" },
  ];

  useEffect(() => {
    const checkUser = async () => {
      setLoading(true);
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        setUser(authUser);
        await getCoachNameByUserId(authUser.username);
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkUser();
    fetchBoards();
    fetchCoaches();
    fetchAllCoachNames();
  }, []);

  useEffect(() => {
    fetchRecentTasks();
  }, [showDoneTasks]);

  const getCoachNameByUserId = async (userId) => {
    try {
      const result = await fetchAllPaginatedResults(listCoachNames, {
        filter: { userId: { eq: userId } },
      });
      if (result.length > 0) {
        setCoachUser(result[0]);
        setUserHasInfo(true);
      } else {
        setCoachUser(null);
        setUserHasInfo(false);
      }
    } catch (error) {
      console.error("Error fetching coach name: ", error);
      setUserHasInfo(false);
    }
  };

  const fetchAllCoachNames = async () => {
    try {
      const result = await fetchAllPaginatedResults(listCoachNames);
      const coachNameMap = {};
      result.forEach((coach) => {
        coachNameMap[coach.id] = coach.CoachName;
      });
      setCoachNames(coachNameMap);
    } catch (error) {
      console.error("Error fetching coach names: ", error);
    }
  };

  const fetchRecentTasks = async () => {
    setLoading(true);
    try {
      const taskData = await fetchAllPaginatedResults(listTasks, {
        limit: 100,
        sort: { direction: "DESC", field: "createdAt" },
      });

      const allTasks = taskData;
      const filteredTasks = showDoneTasks
        ? allTasks.filter((task) => task.status === "DONE")
        : allTasks.filter((task) => task.status !== "DONE");

      setRecentTasks(filteredTasks.slice(0, 3));
    } catch (error) {
      console.error("Error fetching recent tasks:", error);
      toast.error("Failed to fetch recent tasks");
    }
    setLoading(false);
  };

  const fetchBoards = async () => {
    try {
      const boardData = await fetchAllPaginatedResults(listBoards);
      setBoards(boardData);
    } catch (error) {
      console.error("Error fetching boards:", error);
      toast.error("Failed to fetch boards");
    }
  };

  const fetchCoaches = async () => {
    try {
      const coachData = await fetchAllPaginatedResults(listCoachNames);
      setCoaches(coachData);
    } catch (error) {
      console.error("Error fetching coaches:", error);
      toast.error("Failed to fetch coaches");
    }
  };

  const createNewTask = async () => {
    if (!newTaskTitle.trim() || !selectedBoard) {
      toast.error("Please enter a task title and select a board");
      return;
    }
    setLoading(true);
    try {
      const creatorId = coachUser
        ? coachUser.id
        : user
          ? user.username
          : "UNKNOWN";

      const newTask = {
        title: newTaskTitle,
        status: "BACKLOG",
        boardTasksId: selectedBoard.value,
        creator: creatorId,
      };
      const result = await API.graphql(
        graphqlOperation(createTask, { input: newTask }),
      );
      const createdTask = result.data.createTask;

      setRecentTasks((prevTasks) => {
        if (!showDoneTasks) {
          return [createdTask, ...prevTasks.slice(0, 2)];
        }
        return prevTasks;
      });

      setNewTaskTitle("");
      setSelectedBoard(null);
      toast.success("Task created successfully");
    } catch (error) {
      console.error("Error creating new task:", error);
      toast.error("Failed to create task");
    }
    setLoading(false);
  };

  const updateTaskStatus = async (taskId, newStatus) => {
    setLoading(true);
    try {
      const updatedTask = await API.graphql(
        graphqlOperation(updateTask, {
          input: { id: taskId, status: newStatus },
        }),
      );

      const updatedTaskData = updatedTask.data.updateTask;

      setRecentTasks((prevTasks) => {
        if (newStatus === "DONE" && !showDoneTasks) {
          return prevTasks.filter((task) => task.id !== taskId);
        } else if (newStatus !== "DONE" && showDoneTasks) {
          return prevTasks.filter((task) => task.id !== taskId);
        } else {
          return prevTasks.map((task) =>
            task.id === taskId ? updatedTaskData : task,
          );
        }
      });

      toast.success("Task status updated");
    } catch (error) {
      console.error("Error updating task status:", error);
      toast.error("Failed to update task status");
    }
    setLoading(false);
  };

  const reassignTask = async (taskId, newAssigneeId) => {
    setLoading(true);
    try {
      const updatedTask = await API.graphql(
        graphqlOperation(updateTask, {
          input: { id: taskId, assignedTo: newAssigneeId },
        }),
      );
      setRecentTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? updatedTask.data.updateTask : task,
        ),
      );
      toast.success("Task reassigned successfully");
    } catch (error) {
      console.error("Error reassigning task:", error);
      toast.error("Failed to reassign task");
    }
    setLoading(false);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "2px solid #e2e8f0",
      borderRadius: "0.375rem",
      "&:hover": {
        borderColor: "#cbd5e0",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4299e1" : "transparent",
      color: state.isSelected ? "white" : "inherit",
      "&:hover": {
        backgroundColor: state.isSelected ? "#4299e1" : "#e2e8f0",
      },
    }),
  };

  return (
    <Card className="shadow-lg">
      <CardHeader>
        <CardHeaderChild>
          <CardTitle>Quick Actions</CardTitle>
        </CardHeaderChild>
        <CardHeaderChild>
          <div className="flex items-center space-x-2">
            <span>Show Done Tasks</span>
            <Toggle enabled={showDoneTasks} setEnabled={setShowDoneTasks} />
          </div>
        </CardHeaderChild>
      </CardHeader>
      <CardBody>
        <div className="space-y-4">
          <div className="space-y-2">
            <Input
              type="text"
              value={newTaskTitle}
              onChange={(e) => setNewTaskTitle(e.target.value)}
              placeholder="New task title"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <SelectReact
              options={boards.map((board) => ({
                label: board.name,
                value: board.id,
              }))}
              value={selectedBoard}
              onChange={setSelectedBoard}
              placeholder="Select Board"
              styles={customStyles}
            />
            <Button
              variant="solid"
              color="blue"
              className="w-full"
              onClick={createNewTask}
              disabled={loading}
            >
              Create Task
            </Button>
          </div>

          <div className="space-y-2">
            <h3 className="font-semibold text-lg">
              Recent {showDoneTasks ? "Done" : "Active"} Tasks
            </h3>
            {recentTasks.map((task) => (
              <Card
                key={task.id}
                className="!bg-zinc-100 dark:!bg-zinc-800 shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <CardBody>
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
                    <div>
                      <p className="font-medium text-lg">{task.title}</p>
                      <Badge color="blue" className="mt-1">
                        <Icon
                          icon={
                            statusOptions.find((s) => s.value === task.status)
                              ?.icon || "HeroQuestionMarkCircle"
                          }
                          className="mr-1"
                        />
                        {task.status}
                      </Badge>
                      <p className="text-sm text-gray-500 mt-1">
                        Created by: {coachNames[task.creator] || "Unknown"}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 w-full sm:w-auto">
                      <SelectReact
                        options={statusOptions}
                        value={{ label: task.status, value: task.status }}
                        onChange={(option) =>
                          updateTaskStatus(task.id, option.value)
                        }
                        isDisabled={loading}
                        styles={customStyles}
                        formatOptionLabel={({ label, icon }) => (
                          <div className="flex items-center">
                            <Icon icon={icon} className="mr-2" />
                            <span>{label}</span>
                          </div>
                        )}
                      />
                      <SelectReact
                        options={coaches.map((coach) => ({
                          label: coach.CoachName,
                          value: coach.id,
                        }))}
                        value={
                          task.assignedTo
                            ? {
                                label: coaches.find(
                                  (c) => c.id === task.assignedTo,
                                )?.CoachName,
                                value: task.assignedTo,
                              }
                            : null
                        }
                        onChange={(option) =>
                          reassignTask(task.id, option.value)
                        }
                        placeholder="Assign"
                        isDisabled={loading}
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default QuickActions;

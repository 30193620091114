import React, { useEffect } from "react";
import { useState } from "react";
import {
  listOperationNames,
  listCoachNames,
  listDepartmentNames,
} from "./graphql/queries";
import { API } from "aws-amplify";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import PreStartUI from "./ReportsUI/PreStartReportUI";
import ShiftHandoverUI from "./ReportsUI/ShiftHandoverReportUI";
import PreShiftAlignmentUI from "./ReportsUI/PreShiftAlignmentReportUI";
import QuarterlyMonthlyPlanUI from "./ReportsUI/QuarterlyMonthlyPlanReportUI";
import MonthlyPlanningMeetingUI from "./ReportsUI/MonthlyPlanningMeetingReportUI";
import DailyCommitmentMeetingUI from "./ReportsUI/DailyCommitmentMeetingReportUI";
import ShiftplanUI from "./ReportsUI/ShiftPlanReportUI";
import { getAllForms } from "./graphql/queries";
import Moskit from "./Moskit";
import { useNavigate, useLocation } from "react-router-dom";
import WeeklyPlanScheduleUI from "./ReportsUI/WeeklyPlanScheduleReportUI";
import WeeklyPrePlanningUI from "./ReportsUI/WeeklyPrePlanningReportUI";
import WeeklySitePlanningMeetingUI from "./ReportsUI/WeeklySitePlanningMeetingReportUI";
import WeeklyCommitmentMeetingUI from "./ReportsUI/WeeklyCommitmentMeetingReportUI";
import ShortIntervalControlUI from "./ReportsUI/ShortIntervalControlReportUI";
import ShiftReportUI from "./ReportsUI/ShiftReportReportUI";
import ShiftDebriefUI from "./ReportsUI/ShiftDebriefReportUI";
import PerformanceReportsUI from "./ReportsUI/PerformanceReportsReportUI";
import DepartmentDailyReviewUI from "./ReportsUI/DepartmentDailyReviewReportUI";
import DailySiteReviewUI from "./ReportsUI/DailySiteReviewReportUI";
import PerformanceAnalysisUI from "./ReportsUI/PerformanceAnalysisReportUI";
import WeeklyDepartmentReviewUI from "./ReportsUI/WeeklyDepartmentReviewReportUI";
import WeeklySiteReviewUI from "./ReportsUI/WeeklySiteReviewReportUI";
import ManagementReportsUI from "./ReportsUI/ManagementReportsReportUI";
import MonthlyReviewUI from "./ReportsUI/MonthlyReviewReportUI";
import QuarterlyForecastReviewUI from "./ReportsUI/QuarterlyForecastReviewReportUI";
import ActionLogUI from "./ReportsUI/ActionLogReportUI";
import BusinessImprovementCapitalProjectsUI from "./ReportsUI/BusinessImprovementCapitalProjectsReportUI";
import SelectionPanel from "./tailwind-ui-components/SelectionPanel";
import { fetchAllPaginatedResults } from "./utils/pagination";

function Reports({ user }) {
  const [statusArr, setStatusArr] = useState({
    preStart: "current",
    shiftHandover: "current",
    preShiftAlignment: "current",
    quarterlyMonthlyPlan: "complete",
    monthlyPlanningMeeting: "current",
    weeklyPlanSchedule: "current",
    weeklyPrePlanning: "current",
    weeklySitePlanningMeeting: "current",
    weeklyCommitmentMeeting: "current",
    dailyCommitmentMeeting: "current",
    shiftPlan: "current",
    shortIntervalControl: "current",
    shiftReport: "current",
    shiftDebrief: "current",
    performanceReports: "current",
    departmentDailyReview: "current",
    dailySiteReview: "current",
    performanceAnalysis: "current",
    weeklyDepartmentReview: "current",
    weeklySiteReview: "current",
    managementReports: "current",
    monthlyReview: "current",
    quarterlyForecastReview: "current",
    actionLog: "current",
    businessImprovementCapitalProjects: "current",
  });
  const [selectedForm, setSelectedForm] = useState("quarterlyMonthlyPlan");
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCrew, setSelectedCrew] = useState(null);
  const [operations, setOperations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [crews, setCrews] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [preStartWeights, setPreStartWeights] = useState(null);
  const [shiftHandoverWeights, setShiftHandoverWeights] = useState(null);
  const [preShiftAlignmentWeights, setPreShiftAlignmentWeights] =
    useState(null);
  const [quarterlyMonthlyPlanWeights, setQuarterlyMonthlyPlanWeights] =
    useState(null);
  const [monthlyPlanningMeetingWeights, setMonthlyPlanningMeetingWeights] =
    useState(null);
  const [weeklyPlanScheduleWeights, setWeeklyPlanScheduleWeights] =
    useState(null);
  const [weeklyPrePlanningWeights, setWeeklyPrePlanningWeights] =
    useState(null);
  const [
    weeklySitePlanningMeetingWeights,
    setWeeklySitePlanningMeetingWeights,
  ] = useState(null);
  const [weeklyCommitmentMeetingWeights, setWeeklyCommitmentMeetingWeights] =
    useState(null);
  const [dailyCommitmentMeetingWeights, setDailyCommitmentMeetingWeights] =
    useState(null);
  const [shiftplanWeights, setShiftplanWeights] = useState(null);
  const [shortIntervalControlWeights, setShortIntervalControlWeights] =
    useState(null);
  const [shiftReportWeights, setShiftReportWeights] = useState(null);
  const [shiftDebriefWeights, setShiftDebriefWeights] = useState(null);
  const [performanceReportsWeights, setPerformanceReportsWeights] =
    useState(null);
  const [departmentDailyReviewWeights, setDepartmentDailyReviewWeights] =
    useState(null);
  const [dailySiteReviewWeights, setDailySiteReviewWeights] = useState(null);
  const [performanceAnalysisWeights, setPerformanceAnalysisWeights] =
    useState(null);
  const [weeklyDepartmentReviewWeights, setWeeklyDepartmentReviewWeights] =
    useState(null);
  const [weeklySiteReviewWeights, setWeeklySiteReviewWeights] = useState(null);
  const [managementReportsWeights, setManagementReportsWeights] =
    useState(null);
  const [monthlyReviewWeights, setMonthlyReviewWeights] = useState(null);
  const [quarterlyForecastReviewWeights, setQuarterlyForecastReviewWeights] =
    useState(null);
  const [actionLogWeights, setActionLogWeights] = useState(null);
  const [
    businessImprovementCapitalProjectsWeights,
    setBusinessImprovementCapitalProjectsWeights,
  ] = useState(null);
  const [preStartQuestionsText, setPreStartQuestionsText] = useState({});
  const [shiftHandoverQuestionsText, setShiftHandoverQuestionsText] =
    useState(null);
  const [preShiftAlignmentQuestionsText, setPreShiftAlignmentQuestionsText] =
    useState({});
  const [
    quarterlyMonthlyPlanQuestionsText,
    setQuarterlyMonthlyPlanQuestionsText,
  ] = useState({});
  const [
    monthlyPlanningMeetingQuestionsText,
    setMonthlyPlanningMeetingQuestionsText,
  ] = useState({});
  const [weeklyPlanScheduleQuestionsText, setWeeklyPlanScheduleQuestionsText] =
    useState({});
  const [weeklyPrePlanningQuestionsText, setWeeklyPrePlanningQuestionsText] =
    useState({});
  const [
    weeklySitePlanningMeetingQuestionsText,
    setWeeklySitePlanningMeetingQuestionsText,
  ] = useState({});
  const [
    weeklyCommitmentMeetingQuestionsText,
    setWeeklyCommitmentMeetingQuestionsText,
  ] = useState({});
  const [
    dailyCommitmentMeetingQuestionsText,
    setDailyCommitmentMeetingQuestionsText,
  ] = useState({});
  const [shiftplanQuestionsText, setShiftplanQuestionsText] = useState({});
  const [
    shortIntervalControlQuestionsText,
    setShortIntervalControlQuestionsText,
  ] = useState({});
  const [shiftReportQuestionsText, setShiftReportQuestionsText] = useState({});
  const [shiftDebriefQuestionsText, setShiftDebriefQuestionsText] = useState(
    {},
  );
  const [performanceReportsQuestionsText, setPerformanceReportsQuestionsText] =
    useState({});
  const [
    departmentDailyReviewQuestionsText,
    setDepartmentDailyReviewQuestionsText,
  ] = useState({});
  const [dailySiteReviewQuestionsText, setDailySiteReviewQuestionsText] =
    useState({});
  const [
    performanceAnalysisQuestionsText,
    setPerformanceAnalysisQuestionsText,
  ] = useState({});
  const [
    weeklyDepartmentReviewQuestionsText,
    setWeeklyDepartmentReviewQuestionsText,
  ] = useState({});
  const [weeklySiteReviewQuestionsText, setWeeklySiteReviewQuestionsText] =
    useState({});
  const [managementReportsQuestionsText, setManagementReportsQuestionsText] =
    useState({});
  const [monthlyReviewQuestionsText, setMonthlyReviewQuestionsText] = useState(
    {},
  );
  const [
    quarterlyForecastReviewQuestionsText,
    setQuarterlyForecastReviewQuestionsText,
  ] = useState({});
  const [actionLogQuestionsText, setActionLogQuestionsText] = useState({});
  const [
    businessImprovementCapitalProjectsQuestionsText,
    setBusinessImprovementCapitalProjectsQuestionsText,
  ] = useState({});
  const [coaches, setCoaches] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const operationsPromise = fetchAllPaginatedResults(listOperationNames);
      const coachesPromise = fetchAllPaginatedResults(listCoachNames);
      const departmentsPromise = fetchAllPaginatedResults(listDepartmentNames);
      const InfoPromise = API.graphql({
        query: getAllForms,
        variables: { id: "aa703c84-de86-4898-bcb3-0d7bd68f0886" },
      });
      const [
        operationsResponse,
        coachesResponse,
        departmentsResponse,
        InfoResponse,
      ] = await Promise.all([
        operationsPromise,
        coachesPromise,
        departmentsPromise,
        InfoPromise,
      ]);
      setQuarterlyMonthlyPlanWeights(
        InfoResponse.data.getAllForms.Field0.weights,
      );
      setMonthlyPlanningMeetingWeights(
        InfoResponse.data.getAllForms.Field1.weights,
      );
      setWeeklyPlanScheduleWeights(
        InfoResponse.data.getAllForms.Field2.weights,
      );
      setWeeklyPrePlanningWeights(InfoResponse.data.getAllForms.Field3.weights);
      setWeeklySitePlanningMeetingWeights(
        InfoResponse.data.getAllForms.Field4.weights,
      );
      setWeeklyCommitmentMeetingWeights(
        InfoResponse.data.getAllForms.Field5.weights,
      );
      setDailyCommitmentMeetingWeights(
        InfoResponse.data.getAllForms.Field6.weights,
      );
      setShiftplanWeights(InfoResponse.data.getAllForms.Field7.weights);
      setShiftHandoverWeights(InfoResponse.data.getAllForms.Field8.weights);
      setPreStartWeights(InfoResponse.data.getAllForms.Field9.weights);
      setShortIntervalControlWeights(
        InfoResponse.data.getAllForms.Field10.weights,
      );
      setShiftReportWeights(InfoResponse.data.getAllForms.Field11.weights);
      setShiftDebriefWeights(InfoResponse.data.getAllForms.Field12.weights);
      setPerformanceReportsWeights(
        InfoResponse.data.getAllForms.Field13.weights,
      );
      setDepartmentDailyReviewWeights(
        InfoResponse.data.getAllForms.Field14.weights,
      );
      setDailySiteReviewWeights(InfoResponse.data.getAllForms.Field15.weights);
      setPerformanceAnalysisWeights(
        InfoResponse.data.getAllForms.Field16.weights,
      );
      setWeeklyDepartmentReviewWeights(
        InfoResponse.data.getAllForms.Field17.weights,
      );
      setWeeklySiteReviewWeights(InfoResponse.data.getAllForms.Field18.weights);
      setManagementReportsWeights(
        InfoResponse.data.getAllForms.Field19.weights,
      );
      setMonthlyReviewWeights(InfoResponse.data.getAllForms.Field20.weights);
      setQuarterlyForecastReviewWeights(
        InfoResponse.data.getAllForms.Field21.weights,
      );
      setActionLogWeights(InfoResponse.data.getAllForms.Field22.weights);
      setBusinessImprovementCapitalProjectsWeights(
        InfoResponse.data.getAllForms.Field23.weights,
      );
      setPreShiftAlignmentWeights(
        InfoResponse.data.getAllForms.Field24.weights,
      );

      setQuarterlyMonthlyPlanQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field0.questionsText),
      );
      setMonthlyPlanningMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field1.questionsText),
      );
      setWeeklyPlanScheduleQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field2.questionsText),
      );
      setWeeklyPrePlanningQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field3.questionsText),
      );
      setWeeklySitePlanningMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field4.questionsText),
      );
      setWeeklyCommitmentMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field5.questionsText),
      );
      setDailyCommitmentMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field6.questionsText),
      );
      setShiftplanQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field7.questionsText),
      );
      setShiftHandoverQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field8.questionsText),
      );
      setPreStartQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field9.questionsText),
      );
      setShortIntervalControlQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field10.questionsText),
      );
      setShiftReportQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field11.questionsText),
      );
      setShiftDebriefQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field12.questionsText),
      );
      setPerformanceReportsQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field13.questionsText),
      );
      setDepartmentDailyReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field14.questionsText),
      );
      setDailySiteReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field15.questionsText),
      );
      setPerformanceAnalysisQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field16.questionsText),
      );
      setWeeklyDepartmentReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field17.questionsText),
      );
      setWeeklySiteReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field18.questionsText),
      );
      setManagementReportsQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field19.questionsText),
      );
      setMonthlyReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field20.questionsText),
      );
      setQuarterlyForecastReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field21.questionsText),
      );
      setActionLogQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field22.questionsText),
      );
      setBusinessImprovementCapitalProjectsQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field23.questionsText),
      );
      setPreShiftAlignmentQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field24.questionsText),
      );
      setDepartments(departmentsResponse);
      setOperations(operationsResponse);
      setCoaches(coachesResponse);
    };
    fetchData();
  }, [isLoading]);

  useEffect(() => {
    // Set selectedForm based on URL path
    const path = location.pathname.replace("/reports/", "");
    const validPaths = [
      "shiftHandover",
      "preStart",
      "preShiftAlignment",
      "quarterlyMonthlyPlan",
      "monthlyPlanningMeeting",
      "weeklyPlanSchedule",
      "weeklyPrePlanning",
      "weeklySitePlanningMeeting",
      "weeklyCommitmentMeeting",
      "dailyCommitmentMeeting",
      "shiftPlan",
      "shortIntervalControl",
      "shiftReport",
      "shiftDebrief",
      "performanceReports",
      "departmentDailyReview",
      "dailySiteReview",
      "performanceAnalysis",
      "weeklyDepartmentReview",
      "weeklySiteReview",
      "managementReports",
      "monthlyReview",
      "quarterlyForecastReview",
      "actionLog",
      "businessImprovementCapitalProjects",
    ];
    if (validPaths.includes(path)) {
      setSelectedForm(path);
      const updatedStatus = Object.keys(statusArr).reduce((acc, key) => {
        acc[key] = key === path ? "complete" : "current";
        if (path !== "quarterlyMonthlyPlan" && key === "quarterlyMonthlyPlan") {
          acc[key] = "current";
        }
        return acc;
      }, {});
      setStatusArr(updatedStatus);
    } else {
      handleFormSelection("quarterlyMonthlyPlan");
      navigate("/reports/quarterlyMonthlyPlan", { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleFormSelection = (slug) => {
    setSelectedForm(slug);
    const updatedStatus = Object.keys(statusArr).reduce((acc, key) => {
      acc[key] = key === slug ? "complete" : "current";
      return acc;
    }, {});
    setStatusArr(updatedStatus);
    navigate(`/reports/${slug}`);
  };

  return (
    <>
      <div className="scroll-smooth">
        <h2 className="sm:text-6xl text-4xl mx-auto  mb-5 text-black break-words">
          MO@T Reports
        </h2>
        <p className="text-black sm:text-base text-sm mx-auto my-5">
          Choose your site, department and date range to see the MO@T maturity
          for your team, you can then explore each element in more detail by
          clicking on the coloured dot for each element of the MO@T.
        </p>
        <div className="mb-2">
          <SelectionPanel
            onCoachSelect={setSelectedCoach}
            onOperationSelect={setSelectedOperation}
            onDepartmentSelect={setSelectedDepartment}
            onCrewSelect={setSelectedCrew}
            onStartDateSelect={setStartDate}
            onEndDateSelect={setEndDate}
            startDate={startDate}
            endDate={endDate}
            operations={operations}
            departments={departments}
            crews={crews}
            isLoading={isLoading}
          />
        </div>
        <Moskit
          selectedCoach={selectedCoach}
          selectedOperation={selectedOperation}
          selectedDepartment={selectedDepartment}
          selectedCrew={selectedCrew}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setOperations={setOperations}
          setDepartments={setDepartments}
          setCrews={setCrews}
          operations={operations}
          departments={departments}
          crews={crews}
        />

        <div className="scroll-smooth py-10">
          <div id="reports">
            {selectedForm === "shiftHandover" && (
              <ShiftHandoverUI
                questionsText={shiftHandoverQuestionsText}
                weights={shiftHandoverWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "preStart" && (
              <PreStartUI
                questionsText={preStartQuestionsText}
                weights={preStartWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "preShiftAlignment" && (
              <PreShiftAlignmentUI
                questionsText={preShiftAlignmentQuestionsText}
                weights={preShiftAlignmentWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "quarterlyMonthlyPlan" && (
              <QuarterlyMonthlyPlanUI
                questionsText={quarterlyMonthlyPlanQuestionsText}
                weights={quarterlyMonthlyPlanWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "monthlyPlanningMeeting" && (
              <MonthlyPlanningMeetingUI
                questionsText={monthlyPlanningMeetingQuestionsText}
                weights={monthlyPlanningMeetingWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "weeklyPlanSchedule" && (
              <WeeklyPlanScheduleUI
                questionsText={weeklyPlanScheduleQuestionsText}
                weights={weeklyPlanScheduleWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "weeklyPrePlanning" && (
              <WeeklyPrePlanningUI
                questionsText={weeklyPrePlanningQuestionsText}
                weights={weeklyPrePlanningWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "weeklySitePlanningMeeting" && (
              <WeeklySitePlanningMeetingUI
                questionsText={weeklySitePlanningMeetingQuestionsText}
                weights={weeklySitePlanningMeetingWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "weeklyCommitmentMeeting" && (
              <WeeklyCommitmentMeetingUI
                questionsText={weeklyCommitmentMeetingQuestionsText}
                weights={weeklyCommitmentMeetingWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "dailyCommitmentMeeting" && (
              <DailyCommitmentMeetingUI
                questionsText={dailyCommitmentMeetingQuestionsText}
                weights={dailyCommitmentMeetingWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "shiftPlan" && (
              <ShiftplanUI
                questionsText={shiftplanQuestionsText}
                weights={shiftplanWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "shortIntervalControl" && (
              <ShortIntervalControlUI
                questionsText={shortIntervalControlQuestionsText}
                weights={shortIntervalControlWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "shiftReport" && (
              <ShiftReportUI
                questionsText={shiftReportQuestionsText}
                weights={shiftReportWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "shiftDebrief" && (
              <ShiftDebriefUI
                questionsText={shiftDebriefQuestionsText}
                weights={shiftDebriefWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "performanceReports" && (
              <PerformanceReportsUI
                questionsText={performanceReportsQuestionsText}
                weights={performanceReportsWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "departmentDailyReview" && (
              <DepartmentDailyReviewUI
                questionsText={departmentDailyReviewQuestionsText}
                weights={departmentDailyReviewWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "dailySiteReview" && (
              <DailySiteReviewUI
                questionsText={dailySiteReviewQuestionsText}
                weights={dailySiteReviewWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "performanceAnalysis" && (
              <PerformanceAnalysisUI
                questionsText={performanceAnalysisQuestionsText}
                weights={performanceAnalysisWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "weeklyDepartmentReview" && (
              <WeeklyDepartmentReviewUI
                questionsText={weeklyDepartmentReviewQuestionsText}
                weights={weeklyDepartmentReviewWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "weeklySiteReview" && (
              <WeeklySiteReviewUI
                questionsText={weeklySiteReviewQuestionsText}
                weights={weeklySiteReviewWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "managementReports" && (
              <ManagementReportsUI
                questionsText={managementReportsQuestionsText}
                weights={managementReportsWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "monthlyReview" && (
              <MonthlyReviewUI
                questionsText={monthlyReviewQuestionsText}
                weights={monthlyReviewWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "quarterlyForecastReview" && (
              <QuarterlyForecastReviewUI
                questionsText={quarterlyForecastReviewQuestionsText}
                weights={quarterlyForecastReviewWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "actionLog" && (
              <ActionLogUI
                questionsText={actionLogQuestionsText}
                weights={actionLogWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
            {selectedForm === "businessImprovementCapitalProjects" && (
              <BusinessImprovementCapitalProjectsUI
                questionsText={businessImprovementCapitalProjectsQuestionsText}
                weights={businessImprovementCapitalProjectsWeights}
                selectedOperation={selectedOperation}
                selectedDepartment={selectedDepartment}
                selectedCrew={selectedCrew}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;

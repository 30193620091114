import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import BarChartHierarchy from "./BarChartHierarchy.js";

const BarchartHierarchyWrapper = (props) => {
  const targetRef = useRef(null);

  useEffect(() => {
    const bh = BarChartHierarchy(targetRef.current, props.isLarge);
    bh.update(props.data);
  });
  return (
    <div className="flex-row">
      <div id="breadcrumbs"></div>
      <div className="tooltip z-10  absolute opacity-0 pointer-events-none bg-gray-200 border border-gray-400 px-2 rounded shadow-lg"></div>
      <div
        ref={targetRef}
        id="barchart"
        className="mx-6 overflow-x-scroll"
      ></div>
    </div>
  );
};

BarchartHierarchyWrapper.propTypes = {
  data: PropTypes.object.isRequired,
};

const BarchartHierarchyWrapperPure = React.memo(BarchartHierarchyWrapper);

export default BarchartHierarchyWrapperPure;

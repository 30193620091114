import React, { cloneElement, useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { Manager, Popper, Reference } from "react-popper";
import Icon from "../icon/Icon";
import Portal from "../layouts/Portal/Portal";
import themeConfig from "../../config/theme.config";

const getComponentName = (child) => {
  return (
    child?.props["data-component-name"] ||
    child?.type?.displayName ||
    child?.type
  );
};

const Tooltip = (props) => {
  const {
    children,
    className,
    text,
    placement,
    borderWidth,
    rounded,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const referenceRef = useRef(null);
  const setReferenceRef = useCallback((node, ref) => {
    referenceRef.current = node;
    return ref(node);
  }, []);

  const popperRef = useRef(null);
  const setPopperRef = useCallback((node, ref) => {
    popperRef.current = node;
    return ref(node);
  }, []);

  const REFERENCE_PROPS = {
    onMouseEnter: () => setIsOpen(true),
    onMouseLeave: () => setIsOpen(false),
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          ["string", "undefined"].includes(typeof children) ? (
            <span
              data-component-name="Tooltip/Reference"
              ref={(node) => setReferenceRef(node, ref)}
              className="cursor-pointer"
              {...REFERENCE_PROPS}
            >
              {children || (
                <Icon
                  icon="HeroInformationCircle"
                  className={classNames("inline-flex", className)}
                />
              )}
            </span>
          ) : (
            cloneElement(children, {
              "data-component-name": `${getComponentName(
                children,
              )} is cloned with Tooltip`,
              // @ts-ignore
              ref: (node) => setReferenceRef(node, ref),
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
              className: classNames("cursor-pointer", children.props.className),
              ...REFERENCE_PROPS,
            })
          )
        }
      </Reference>
      {isOpen && text !== "" && (
        <Portal>
          <Popper placement={placement}>
            {({ ref, style }) => (
              <div
                data-component-name="Tooltip/Popper"
                // @ts-ignore
                ref={(node) => setPopperRef(node, ref)}
                style={style}
                className={classNames(
                  "z-[9999] m-2 px-2 py-1",
                  "max-w-xs",
                  "border-zinc-500/10 shadow-lg backdrop-blur-sm",
                  [`${borderWidth}`],
                  [`${rounded}`],
                  className,
                )}
                {...rest}
              >
                {text}
              </div>
            )}
          </Popper>
        </Portal>
      )}
    </Manager>
  );
};
Tooltip.defaultProps = {
  children: undefined,
  className: undefined,
  placement: "top",
  borderWidth: "border",
  rounded: themeConfig.rounded,
};
Tooltip.displayName = "Tooltip";

export default Tooltip;

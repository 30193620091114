import React, { useState, useEffect } from "react";
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterChild,
} from "../../fyr-theme/components/ui/Modal";
import Button from "../../fyr-theme/components/ui/Button";
import { useFormik } from "formik";
import {
  listBoards,
  listOperationNames,
  listDepartmentNames,
} from "../../graphql/queries";
import { createBoard } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import toast from "react-hot-toast";
import classNames from "classnames";
import { fetchAllPaginatedResults } from "../../utils/pagination";

const BoardCreatePartial = (props) => {
  const { isOpen, setIsOpen, boardsData, setBoardsData } = props;
  const [operations, setOperations] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchOperations();
      fetchDepartments();
    }
  }, [isOpen]);

  const fetchOperations = async () => {
    try {
      const operationData = fetchAllPaginatedResults(listOperationNames);
      setOperations(operationData);
    } catch (error) {
      console.error("Error fetching operations:", error);
      toast.error("Failed to fetch operations");
    }
  };

  const fetchDepartments = async () => {
    try {
      const departmentData = fetchAllPaginatedResults(listDepartmentNames);
      setDepartments(departmentData);
    } catch (error) {
      console.error("Error fetching departments:", error);
      toast.error("Failed to fetch departments");
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      key: "",
      description: "",
      site: "",
      department: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const newBoard = await API.graphql(
          graphqlOperation(createBoard, { input: values }),
        );
        console.log(newBoard);
        const updatedBoardsData = await fetchAllPaginatedResults(listBoards);
        setBoardsData(updatedBoardsData);
        resetForm();
        setIsOpen(false);
        toast.success("Board created successfully");
      } catch (error) {
        console.error("Error creating board:", error);
        toast.error("Failed to create board");
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setIsOpen(false);
  };

  const inputClasses = classNames(
    "w-full appearance-none outline-0",
    "text-black dark:text-white",
    "disabled:!opacity-25",
    "transition-all duration-300 ease-in-out",
    "border-2 border-zinc-100 dark:border-zinc-800",
    "bg-zinc-100 dark:bg-zinc-800",
    "hover:border-thiess-blue-500",
    "dark:hover:border-thiess-blue-500",
    "disabled:!border-zinc-500",
    "focus:border-zinc-300 dark:focus:border-zinc-800",
    "focus:bg-transparent dark:focus:bg-transparent",
    "px-1.5 py-1.5 text-base",
    "rounded-lg",
  );

  const selectClasses = classNames(
    "w-full",
    "text-black dark:text-white",
    "transition-all duration-300 ease-in-out",
    "rounded-lg",
    "border-2 border-zinc-100 dark:border-zinc-800",
    "bg-zinc-100 dark:bg-zinc-800",
    "hover:border-thiess-blue-500",
    "dark:hover:border-thiess-blue-500",
    "!min-h-[2.5rem]",
    "py-0.5 px-1.5",
  );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isCentered={true}
      isScrollable={true}
    >
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader setIsOpen={setIsOpen}>Create a new Board</ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-y-2">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Title *
              </label>
              <input
                type="text"
                required
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Enter Board Name"
                autoComplete="off"
                className={inputClasses}
              />
            </div>
            <div>
              <label
                htmlFor="key"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Board Key *
              </label>
              <input
                type="text"
                required
                id="key"
                name="key"
                onChange={formik.handleChange}
                value={formik.values.key}
                placeholder="Enter Board Key"
                autoComplete="off"
                className={inputClasses}
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Enter a unique key for the board
              </p>
            </div>
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Description
              </label>
              <input
                type="text"
                id="description"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Enter Board Description"
                autoComplete="off"
                className={inputClasses}
              />
            </div>
            <div>
              <label
                htmlFor="site"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Site
              </label>
              <select
                id="site"
                name="site"
                onChange={formik.handleChange}
                value={formik.values.site}
                className={selectClasses}
              >
                <option value="">Select Site</option>
                {operations.map((op) => (
                  <option key={op.id} value={op.id}>
                    {op.OperationName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700 dark:text-gray-200"
              >
                Department
              </label>
              <select
                id="department"
                name="department"
                onChange={formik.handleChange}
                value={formik.values.department}
                className={selectClasses}
              >
                <option value="">Select Department</option>
                {departments.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.DepartmentName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="[&&]:justify-end">
          <ModalFooterChild>
            <Button color="red" onClick={handleClose} type="button">
              Cancel
            </Button>
            <Button
              variant="solid"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Creating..." : "Create"}
            </Button>
          </ModalFooterChild>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default BoardCreatePartial;

import Modal from "./Modal";
import React from "react";

export default function Question({
  id,
  question,
  name,
  onChange,
  value,
  disabled,
  viewState,
  comment,
  onCommentChange,
  weight,
}) {
  const options = [
    {
      label: "Missing",
      value: 0,
    },
    {
      label: "Poor",
      value: 1,
    },
    {
      label: "OK",
      value: 2,
    },
    {
      label: "Good",
      value: 3,
    },
  ];
  const renderOptions = () => {
    return options.map((option, index) => (
      <React.Fragment key={id}>
        <input
          type="radio"
          id={`${id}-${option.label.toLowerCase()}`}
          name={name}
          value={option.value}
          onChange={onChange}
          required
          className={option.colorClass}
        />
        <label
          htmlFor={`${id}-${option.label.toLowerCase()}`}
          style={{
            width: `${100 / options.length}%`,
            left: `${(index * 100) / options.length}%`,
          }}
          data-debt-amount={
            option.label === "Missing" ? "Missing / Very Poor" : option.label
          }
        />
      </React.Fragment>
    ));
  };

  return (
    <fieldset className="mt-8 grid grid-cols-7">
      <legend className="mb-4 text-md  leading-6 text-black font-semibold">
        {question || weight ? <>{question}</> : "Loading Question..."}
      </legend>
      <div className="col-span-6">
        {viewState ? (
          <div className="relative pb-2">
            <div className="relative mb-8">
              <div id="form-wrapper">
                <div
                  id="question-slider"
                  className={
                    value !== "" && value !== "-1"
                      ? options[parseInt(value)].label.toLowerCase()
                      : ""
                  }
                >
                  {renderOptions()}
                  <div
                    className={
                      value !== "" && value !== "-1"
                        ? options[parseInt(value)].label.toLowerCase()
                        : ""
                    }
                    id="question-pos"
                    style={
                      value !== "" && value !== "-1"
                        ? {
                            left: `${(((parseInt(value) + 1) * 100) / 4 - 12.5) % 100}%`,
                          }
                        : { display: "none" }
                    }
                  />
                </div>
              </div>

              {/* <label
                for={id}
                className="block mb-2 sm:text-lg text-sm font-medium text-black sr-only"
              >
                Please Select an option
              </label>
              <input
                id={id}
                name={name}
                type="range"
                value={value === "" ? "-1" : value}
                disabled={disabled}
                min="-1"
                max="3"
                step="1"
                className={`w-full h-3 appearance-none rounded-lg cursor-pointer range-lg ${value === "0" ? "bg-red-600" : value === "1" ? "bg-orange-400" : value === "2" ? "bg-yellow-400" : value === "3" ? "bg-green-600" : "bg-gray-200"}`}
                onChange={onChange}
              /> */}
            </div>
            {/* <span className="sm:text-lg text-sm text-black absolute start-0 bottom-0 overflow-auto whitespace-normal"></span>
            <span className="sm:text-lg text-sm text-black absolute start-1/4 -translate-x-1/2 rtl:translate-x-1/2 bottom-0 overflow-auto whitespace-normal">
              Missing
            </span>
            <span className="sm:text-lg text-sm text-black absolute start-2/4 -translate-x-1/3 rtl:translate-x-1/2 bottom-0 overflow-auto whitespace-normal">
              Poor
            </span>
            <span className="sm:text-lg text-sm text-black absolute start-3/4 -translate-x-2/3 rtl:translate-x-1/2 bottom-0 overflow-auto whitespace-normal">
              OK
            </span>
            <span className="sm:text-lg text-sm text-black absolute end-0 bottom-0 overflow-auto whitespace-normal">
              Good
            </span> */}
          </div>
        ) : (
          <div className="mt-2">
            <select
              id={id}
              name={name}
              value={value}
              disabled={disabled}
              className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-base text-sm"
              required
              onChange={onChange}
            >
              <optgroup label="Options">
                <option key="Select-Option" value="">
                  -- Please select from below --
                </option>
                {options.map((option, index) => (
                  <option key={index} value={option.score}>
                    {option.label}
                  </option>
                ))}
              </optgroup>
            </select>
          </div>
        )}
      </div>
      <Modal
        id={`${id}-comment`}
        name={name}
        comment={comment}
        disabled={disabled}
        onCommentChange={onCommentChange}
        question={question}
      />
    </fieldset>
  );
}

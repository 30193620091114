import React from "react";
import classNames from "classnames";

const BoardPartial = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div
      data-component-name="BoardPart"
      className={classNames(
        "inline-flex min-h-full min-w-full gap-4",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default BoardPartial;

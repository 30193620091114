import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Droppable } from "@hello-pangea/dnd";
import ColumnCardWrapperPartial from "./ColumnCardWrapper.partial";
import Button from "../../fyr-theme/components/ui/Button";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../fyr-theme/components/ui/Dropdown";
import Badge from "../../fyr-theme/components/ui/Badge";
import Icon from "../../fyr-theme/components/icon/Icon";
import Card, { CardBody } from "../../fyr-theme/components/ui/Card";
import TaskEditPartial from "./TaskEdit.partial";
import NewProjectButtonPartial from "./NewProjectButton.partial";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getCoachName } from "../../graphql/queries";
import CryptoJS from "crypto-js";

const getGravatarUrl = (email) => {
  const hash = email
    ? CryptoJS.MD5(email.trim().toLowerCase()).toString()
    : "00000000000000000000000000000000";
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const ColumnsPartial = ({
  tasksData,
  columnsData,
  setTasksData,
  onUpdateTask,
  onDeleteTask,
  currentUser,
  setIsCreateTaskModalOpen,
  setCreateColumnKey,
}) => {
  const [editingTask, setEditingTask] = useState(null);
  const [avatarUrls, setAvatarUrls] = useState({});
  const [coachDetails, setCoachDetails] = useState({});

  const getProfilePictureUrl = async (userId) => {
    try {
      const url = await Storage.get(`${userId}/profilepicture`, {
        level: "public",
      });
      const response = await fetch(url);
      if (response.ok) {
        return url;
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
    }
    return null;
  };

  const fetchAvatarUrlsAndCoachDetails = async () => {
    const urls = {};
    const details = {};
    const uniqueUserIds = new Set();

    for (const columnKey in tasksData) {
      for (const task of tasksData[columnKey]) {
        if (task.assignedTo) {
          uniqueUserIds.add(task.assignedTo);
        }
      }
    }

    await Promise.all(
      Array.from(uniqueUserIds).map(async (userId) => {
        try {
          const coachNameData = await API.graphql(
            graphqlOperation(getCoachName, { id: userId }),
          );
          const coachName = coachNameData.data.getCoachName;
          details[userId] = {
            name: coachName.CoachName,
            email: coachName.userEmail,
          };

          const profilePicUrl = await getProfilePictureUrl(coachName.id);
          console.log(userId, profilePicUrl);

          urls[userId] = profilePicUrl || getGravatarUrl(coachName.userEmail);
        } catch (error) {
          console.error(`Error fetching details for user ${userId}:`, error);
          urls[userId] = getGravatarUrl("");
          details[userId] = { name: "Unknown User", email: "" };
        }
      }),
    );

    setAvatarUrls(urls);
    setCoachDetails(details);
  };

  useEffect(() => {
    fetchAvatarUrlsAndCoachDetails();
  }, [tasksData]);

  return (
    <>
      {Object.keys(columnsData).map((columnKey) => {
        const columnData = columnsData[columnKey];
        const tasksInColumn = tasksData[columnKey] || [];
        return (
          <div
            key={columnKey}
            data-component-name="ColumnsPart"
            className="flex flex-col rounded-2xl border border-zinc-300/25 p-4 pb-0 dark:border-zinc-800/50"
          >
            <div className={classNames(`w-[20rem] xl:w-[22rem] 2xl:w-[28rem]`)}>
              <div className="mb-4 flex basis-full items-center">
                <div className="flex grow items-center gap-2">
                  <Icon icon={columnData.icon} size="text-2xl" />
                  <span className="text-2xl ">{columnData.title}</span>
                  <Badge
                    variant="outline"
                    className="border-transparent px-2"
                    rounded="rounded-full"
                  >
                    {tasksInColumn.length}
                  </Badge>
                </div>
                <div>
                  <Dropdown>
                    <DropdownToggle hasIcon={false}>
                      <Button icon="HeroEllipsisVertical" />
                    </DropdownToggle>
                    <DropdownMenu placement="bottom-end">
                      <div className="px-4 sm:text-lg text-sm ">
                        Item actions
                      </div>
                      <DropdownItem icon="HeroArchiveBox">
                        Archive All
                      </DropdownItem>
                      <DropdownItem icon="HeroTrash">Delete All</DropdownItem>
                      <div className="border-t-2 border-zinc-500/25 px-4 py-2 sm:text-lg text-sm  dark:border-zinc-500/50">
                        Column actions
                      </div>
                      <DropdownItem icon="HeroSquaresPlus">
                        Set column limit
                      </DropdownItem>
                      <DropdownItem icon="HeroPencil">
                        Edit details
                      </DropdownItem>
                      <DropdownItem icon="HeroEyeSlash">
                        Hide from view
                      </DropdownItem>
                      <DropdownItem icon="HeroTrash">Delete</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>

              <Droppable droppableId={columnKey}>
                {(providedDroppable, snapshotDroppable) => (
                  <div
                    data-component-name="ColumnsPart/Droppable"
                    className={classNames("rounded-xl", {
                      "border border-dashed border-blue-500 bg-blue-500/10":
                        snapshotDroppable.isDraggingOver,
                    })}
                    {...providedDroppable.droppableProps}
                    ref={providedDroppable.innerRef}
                  >
                    <div className="mb-6">
                      <Card className="!bg-zinc-900/5 dark:!bg-zinc-900/50">
                        <CardBody>
                          <NewProjectButtonPartial
                            onClick={() => {
                              setIsCreateTaskModalOpen(true);
                              setCreateColumnKey(columnKey);
                            }}
                          />
                        </CardBody>
                      </Card>
                    </div>
                    <ColumnCardWrapperPartial
                      columnKey={columnKey}
                      columnsData={columnsData}
                      tasksData={tasksData}
                      setTasksData={setTasksData}
                      onEditTask={(task) => setEditingTask(task)}
                      avatarUrls={avatarUrls}
                      coachDetails={coachDetails}
                    />
                    <div className="py-2">{providedDroppable.placeholder}</div>
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        );
      })}
      {editingTask && (
        <TaskEditPartial
          task={editingTask}
          isOpen={!!editingTask}
          setIsOpen={() => setEditingTask(null)}
          onTaskUpdated={(updatedTask) => {
            onUpdateTask(updatedTask);
            setEditingTask(null);
          }}
          onTaskDeleted={(taskId) => {
            onDeleteTask(taskId);
            setEditingTask(null);
          }}
          currentUser={currentUser}
          avatarUrls={avatarUrls}
          coachDetails={coachDetails}
        />
      )}
    </>
  );
};

export default ColumnsPartial;

import DARK_MODE from "../constants/darkMode.constant";

const themeConfig = {
  projectTitle: "Fyr",
  projectName: "React TypeScript Tailwind Admin & AI Chat Template",
  language: "en",
  theme: DARK_MODE.SYSTEM,
  themeColor: "blue",
  themeColorShade: "500",
  rounded: "rounded-lg",
  borderWidth: "border-2",
  transition: "transition-all duration-300 ease-in-out",
  fontSize: 13,
};

export default themeConfig;

import React, { forwardRef } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import useRoundedSize from "../../hooks/useRoundedSize";
import themeConfig from "../../config/theme.config";

export const CardTitle = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames("flex items-center text-2xl ", className);

  return (
    <div data-component-name="Card/CardTitle" className={classes} {...rest}>
      {children}
    </div>
  );
};
CardTitle.defaultProps = {
  className: undefined,
};

export const CardHeaderChild = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames("flex flex-wrap items-center gap-4", className);

  return (
    <div
      data-component-name="Card/CardHeaderChild"
      className={classes}
      {...rest}
    >
      {children}
    </div>
  );
};
CardHeaderChild.defaultProps = {
  children: undefined,
  className: undefined,
};

export const CardHeader = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(
    "flex flex-wrap items-center justify-between gap-4 px-4 pb-4 [&:first-child]:pt-4",
    className,
  );

  return (
    <div data-component-name="Card/CardHeader" className={classes} {...rest}>
      {children}
    </div>
  );
};
CardHeader.defaultProps = {
  className: undefined,
};

export const CardBody = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames("grow px-4 pb-4 [&:first-child]:pt-4", className);

  return (
    <div data-component-name="Card/CardBody" className={classes} {...rest}>
      {children}
    </div>
  );
};
CardBody.defaultProps = {
  className: undefined,
};

export const CardBodyCollapse = (props) => {
  const { children, className, isOpen, ...rest } = props;

  const classes = classNames(
    "px-4 grow overflow-hidden [&:first-child]:pt-4 pb-4",
    className,
  );

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          data-component-name="Card/CardBodyCollapse"
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: 0.8,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
          className={classes}
          {...rest}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
CardBodyCollapse.defaultProps = {
  className: undefined,
};

export const CardFooterChild = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames("flex flex-wrap items-center gap-4", className);

  return (
    <div
      data-component-name="Card/CardFooterChild"
      className={classes}
      {...rest}
    >
      {children}
    </div>
  );
};
CardFooterChild.defaultProps = {
  children: undefined,
  className: undefined,
};

export const CardFooter = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(
    "flex flex-wrap items-center justify-between gap-4 px-4 pb-4 [&:first-child]:pt-4",
    className,
  );

  return (
    <div data-component-name="Card/CardFooter" className={classes} {...rest}>
      {children}
    </div>
  );
};
CardFooter.defaultProps = {
  className: undefined,
};

const Card = forwardRef((props, ref) => {
  const { children, className, rounded, ...rest } = props;

  const { roundedCustom } = useRoundedSize(themeConfig.rounded);

  const cardClasses = classNames(
    "flex flex-col bg-white dark:bg-zinc-900",
    { [`${rounded}`]: rounded !== "auto" },
    { [`${roundedCustom(1)}`]: rounded === "auto" },
    className,
  );

  return (
    <div ref={ref} data-component-name="Card" className={cardClasses} {...rest}>
      {children}
    </div>
  );
});
Card.defaultProps = {
  className: undefined,
  rounded: "auto",
};

export default Card;

import React, { forwardRef } from "react";
import classNames from "classnames";
import themeConfig from "../../config/theme.config";

const Input = forwardRef((props, ref) => {
  const {
    borderWidth,
    className,
    color,
    colorIntensity,
    name,
    rounded,
    dimension,
    variant,
    isValid,
    isTouched,
    invalidFeedback,
    ...rest
  } = props;

  const inputVariants = {
    solid: {
      general: classNames(
        // Default
        [`${borderWidth} border-zinc-100 dark:border-zinc-800`],
        "bg-zinc-100 dark:bg-zinc-800",
        // Hover
        [`hover:border-${color}-${colorIntensity}`],
        [`dark:hover:border-${color}-${colorIntensity}`],
        "disabled:!border-zinc-500",
        // Focus
        "focus:border-zinc-300 dark:focus:border-zinc-800",
        "focus:bg-transparent dark:focus:bg-transparent",
      ),
      validation: classNames({
        "!border-red-500 ring-4 ring-red-500/30":
          !isValid && isTouched && invalidFeedback,
        "!border-green-500 focus:ring-4 focus:ring-green-500/30":
          !isValid && isTouched && !invalidFeedback,
      }),
    },
  };
  const inputVariantClasses = inputVariants[variant].general;
  const inputValidationsClasses = inputVariants[variant].validation;

  /**
   * Padding & Font Size & Icon Margin
   */
  const inputDimension = {
    xs: {
      general: classNames("px-1.5", "py-0.5", "text-xs"),
    },
    sm: {
      general: classNames("px-1.5", "py-1", "text-sm"),
    },
    default: {
      general: classNames("px-1.5", "py-1.5", "text-base"),
    },
    lg: {
      general: classNames("px-1.5", "py-2", "text-lg"),
    },
    xl: {
      general: classNames("px-1.5", "py-2.5", "text-xl"),
    },
  };
  const inputDimensionClasses = inputDimension[dimension].general;

  const classes = classNames(
    "w-full appearance-none outline-0",
    "text-black dark:text-white",
    "disabled:!opacity-25",
    themeConfig.transition,
    inputVariantClasses,
    inputDimensionClasses,
    rounded,
    inputValidationsClasses,
    className,
  );

  return (
    <input
      ref={ref}
      data-component-name="Input"
      className={classes}
      name={name}
      {...rest}
    />
  );
});
Input.defaultProps = {
  borderWidth: themeConfig.borderWidth,
  className: undefined,
  color: themeConfig.themeColor,
  colorIntensity: themeConfig.themeColorShade,
  rounded: themeConfig.rounded,
  dimension: "default",
  type: "text",
  value: "",
  variant: "solid",
};
Input.displayName = "Input";

export default Input;

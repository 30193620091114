import React from "react";
import classNames from "classnames";
import Button from "./Button";

const CloseButton = (props) => {
  const { className, setIsOpen, ...rest } = props;

  const classes = classNames(className);

  return (
    <Button
      data-component-name="CloseButton"
      icon="HeroXMark"
      color="red"
      className={classes}
      onClick={() => (setIsOpen ? setIsOpen(false) : undefined)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};
CloseButton.defaultProps = {
  className: undefined,
};
CloseButton.displayName = "CloseButton";

export default CloseButton;

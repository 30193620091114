import React, { useCallback, useMemo, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, Slate, withReact } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import classNames from "classnames";
import {
  BlockButton,
  Element,
  Leaf,
  MarkButton,
  toggleMark,
  Toolbar,
  ToolbarChild,
} from "./helper/richtext.helper";
import Button from "./ui/Button";
import themeConfig from "../config/theme.config";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const RichText = (props) => {
  const {
    borderWidth,
    className,
    color,
    colorIntensity,
    invalidFeedback,
    isTouched,
    isValid,
    isValidMessage,
    rounded,
    validFeedback,
    variant,
    value,
    handleChange,
    placeholder,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const richTextVariants = {
    solid: {
      general: classNames(
        // Default
        [`${borderWidth}`],
        {
          "border-zinc-100 dark:border-zinc-800": !isFocused,
          "bg-zinc-100 dark:bg-zinc-800": !isFocused,
        },
        // Hover
        {
          [`hover:border-${color}-${colorIntensity}`]: !isFocused,
          [`dark:hover:border-${color}-${colorIntensity}`]: !isFocused,
        },
        // Focus
        {
          "border-zinc-300 dark:border-zinc-800": isFocused,
          "bg-transparent dark:bg-transparent": isFocused,
        },
      ),
      validation: classNames({
        "!border-red-500 ring-4 ring-red-500/30":
          !isValid && isTouched && invalidFeedback,
        "!border-green-500 focus:ring-4 focus:ring-green-500/30":
          !isValid && isTouched && !invalidFeedback,
      }),
    },
  };
  const richTextVariantClasses = richTextVariants[variant].general;

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderElement = useCallback((props) => <Element {...props} />, []);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  // console.log(JSON.stringify(state), JSON.parse(JSON.stringify(state)));

  return (
    <div
      data-component-name="RichText"
      className={classNames(
        "w-full appearance-none overflow-hidden outline-0",
        themeConfig.transition,
        rounded,
        richTextVariantClasses,
        className,
      )}
      {...rest}
    >
      <style>{`
			[data-slate-editor] :is(ol, ul) {list-style-position: inside;} 
			[data-slate-editor] ul li {list-style-type: disc;} 
			[data-slate-editor] ol li {list-style-type: numeric;} 
			[data-slate-editor] blockquote {font-style: italic; font-size: 110%; opacity: 0.9; border-left: 2px solid; padding-left: 0.5rem}
			`}</style>
      <Slate
        editor={editor}
        initialValue={!value.length ? initialValue : value}
        onChange={handleChange}
      >
        <Toolbar>
          <ToolbarChild className="flex-wrap">
            <MarkButton format="bold" icon="DuoBold" />
            <MarkButton format="italic" icon="DuoItalic" />
            <MarkButton format="underline" icon="DuoUnderline" />
            <MarkButton format="code" icon="DuoCode1" />
            <BlockButton format="heading-one" icon="DuoH1" />
            <BlockButton format="heading-two" icon="DuoH2" />
            <BlockButton format="block-quote" icon="DuoQuote1" />
            <BlockButton format="numbered-list" icon="DuoBulletList" />
            <BlockButton format="bulleted-list" icon="DuoBulletList" />
            <BlockButton format="left" icon="DuoAlignLeft" />
            <BlockButton format="center" icon="DuoAlignCenter" />
            <BlockButton format="right" icon="DuoAlignRight" />
            <BlockButton format="justify" icon="DuoAlignJustify" />
          </ToolbarChild>
          <ToolbarChild>
            <Button
              icon="DuoReply"
              onMouseDown={() => {
                editor.undo();
              }}
              isDisable={!editor.history.undos.length}
            />
            <Button
              icon="DuoRedo"
              onMouseDown={() => {
                editor.redo();
              }}
              isDisable={!editor.history.redos.length}
            />
          </ToolbarChild>
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          spellCheck
          onKeyDown={(event) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const hotkey in HOTKEYS) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="p-4 outline-0"
        />
      </Slate>
    </div>
  );
};
RichText.defaultProps = {
  borderWidth: themeConfig.borderWidth,
  className: undefined,
  color: themeConfig.themeColor,
  colorIntensity: themeConfig.themeColorShade,
  invalidFeedback: undefined,
  isTouched: false,
  isValid: false,
  isValidMessage: true,
  rounded: themeConfig.rounded,
  validFeedback: undefined,
  variant: "solid",
  placeholder: "Enter some rich text…",
};

export default RichText;

import DatePicker from "react-datepicker";
import { View } from "@aws-amplify/ui-react";
import { useState } from "react";

// New Child Component for handling user selections
const SelectionPanel = ({
  onOperationSelect,
  onDepartmentSelect,
  onCrewSelect,
  onStartDateSelect,
  onEndDateSelect,
  startDate,
  endDate,
  operations,
  departments,
  crews,
  isLoading,
}) => {
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCrew, setSelectedCrew] = useState(null);

  const resetSelections = () => {
    setSelectedOperation(null);
    setSelectedDepartment(null);
    setSelectedCrew(null);
    onOperationSelect(null);
    onCrewSelect(null);
    onDepartmentSelect(null);
    onStartDateSelect(null);
    onEndDateSelect(null);
  };

  return (
    <>
      <h6 className="sm:text-2xl text-lg mx-auto  mb-5 text-black break-words">
        Overall MO@T Maturity
      </h6>
      <View className=" flex-col items-center justify-center mx-auto">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-6 mb-5">
          <div className="rounded-sm">
            <select
              disabled={isLoading}
              value={selectedOperation ? selectedOperation.value : ""}
              onChange={(e) => {
                const selected = operations.find(
                  (o) => o.id === e.target.value,
                );
                setSelectedOperation(
                  selected
                    ? { value: selected.id, label: selected.OperationName }
                    : null,
                );
                onOperationSelect(e.target.value || null);
              }}
              className="bg-white border border-gray-300 text-black sm:text-lg text-sm rounded-lg focus:ring-thiess-blue-500 focus:border-thiess-blue-500 w-full p-2.5"
            >
              <optgroup label="Operations">
                <option value="">Select Operation</option>
                {operations &&
                  operations
                    .sort((a, b) =>
                      a.OperationName.localeCompare(b.OperationName),
                    )
                    .map((o) => (
                      <option key={o.id} value={o.id}>
                        {o.OperationName}
                      </option>
                    ))}
              </optgroup>
            </select>
          </div>
          <div className="rounded-sm">
            <select
              disabled={isLoading}
              value={selectedDepartment ? selectedDepartment.value : ""}
              onChange={(e) => {
                const selected = departments.find(
                  (d) => d.id === e.target.value,
                );
                setSelectedDepartment(
                  selected
                    ? { value: selected.id, label: selected.DepartmentName }
                    : null,
                );
                onDepartmentSelect(e.target.value || null);
              }}
              className="bg-white border border-gray-300 text-black sm:text-lg text-sm rounded-lg focus:ring-thiess-blue-500 focus:border-thiess-blue-500 w-full p-2.5"
            >
              <optgroup label="Departments">
                <option value="">Select Department</option>
                {departments &&
                  departments
                    .sort((a, b) =>
                      a.DepartmentName.localeCompare(b.DepartmentName),
                    )
                    .map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.DepartmentName}
                      </option>
                    ))}
              </optgroup>
            </select>
          </div>
          <div className="rounded-sm">
            <select
              disabled={isLoading}
              value={selectedCrew ? selectedCrew.value : ""}
              onChange={(e) => {
                const selected = crews.find((c) => c.id === e.target.value);
                setSelectedCrew(
                  selected
                    ? { value: selected.id, label: selected.CrewName }
                    : null,
                );
                onCrewSelect(e.target.value || null);
              }}
              className="bg-white border border-gray-300 text-black sm:text-lg text-sm rounded-lg focus:ring-thiess-blue-500 focus:border-thiess-blue-500 w-full p-2.5"
            >
              <optgroup label="Crews">
                <option value="">Select Crew</option>
                {crews &&
                  crews
                    .sort((a, b) => a.CrewName.localeCompare(b.CrewName))
                    .map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.CrewName}
                      </option>
                    ))}
              </optgroup>
            </select>
          </div>

          <div className="rounded-sm">
            <DatePicker
              disabled={isLoading}
              selected={startDate}
              onChange={(date) => {
                onStartDateSelect(date);
              }}
              selectsStart
              maxDate={endDate}
              placeholderText="Start Date"
              className="bg-white border border-gray-300 text-black sm:text-lg text-sm rounded-lg focus:ring-thiess-blue-500 focus:border-thiess-blue-500 w-full p-2.5"
            />
          </div>
          <div className="rounded-sm">
            <DatePicker
              disabled={isLoading}
              selected={endDate}
              onChange={(date) => {
                onEndDateSelect(date);
              }}
              selectsEnd
              minDate={startDate}
              placeholderText="End Date"
              className="bg-white border border-gray-300 text-black sm:text-lg text-sm rounded-lg focus:ring-thiess-blue-500 focus:border-thiess-blue-500 w-full p-2.5"
            />
          </div>
          <div className="rounded-sm">
            <button
              className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
              onClick={resetSelections}
              disabled={isLoading}
            >
              Reset All
            </button>
          </div>
        </div>
      </View>
    </>
  );
};

export default SelectionPanel;

import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listCoachNames,
  listOperationNames,
  listDepartmentNames,
  listCrewNames,
} from "../graphql/queries";
import { createCoachName, updateCoachName } from "../graphql/mutations";
import ProfilePictureUpload from "./ProfilePictureUpload";
import { Auth } from "aws-amplify";
import Modal from "react-modal";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Toggle from "./ToggleDisabled";
import ActualToggle from "./Toggle";
import { fetchAllPaginatedResults } from "../utils/pagination";

Modal.setAppElement("#root");

export default function UpdateProfileForm({ combinedUser, setCombinedUser }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attributesToVerify, setAttributesToVerify] = useState([]);
  const [code, setCode] = useState("");
  const [pictureUrl, setPictureUrl] = useState(combinedUser.pictureUrl || "");
  const [name, setName] = useState(combinedUser.name || "");
  const [familyName, setFamilyName] = useState(combinedUser.family_name || "");
  const [email, setEmail] = useState(combinedUser.email || "");
  const [userEmail, setUserEmail] = useState(combinedUser.email || "");
  const [phone, setPhone] = useState(combinedUser.phone_number || "");
  const [originalEmail, setOriginalEmail] = useState(email);
  const [originalPhone, setOriginalPhone] = useState(phone);
  const [altEmail, setAltEmail] = useState(combinedUser.altEmail || "");
  const [coach, setCoach] = useState(combinedUser.coach || false);

  useEffect(() => {
    setOriginalEmail(email);
    setOriginalPhone(phone);
  }, [email, phone]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        name,
        family_name: familyName,
        email,
        phone_number: phone,
      });

      // Check if a user with the same userId already exists
      const existingUsers = await fetchAllPaginatedResults(listCoachNames, {
        filter: { userId: { eq: combinedUser.username } },
      });

      const existingUsersByName = await fetchAllPaginatedResults(
        listCoachNames,
        {
          filter: { CoachName: { eq: name + " " + familyName } },
        },
      );

      if (
        existingUsers.length > 0 ||
        (existingUsersByName.length > 0 && existingUsers[0]?.temp)
      ) {
        // Update coachName object
        await API.graphql(
          graphqlOperation(updateCoachName, {
            input: {
              id: existingUsers[0].id,
              userId: user.username,
              CoachName: name + " " + familyName,
              CrewName: selectedCrew,
              coach,
              admin: existingUsers[0]?.admin || false,
              guest: existingUsers[0]?.guest || false,
              altEmail: altEmail || existingUsers[0].altEmail,
              userEmail: userEmail || existingUsers[0].userEmail,
              AltCrewName: selectedCrew,
              OperationName: selectedOperation,
              DepartmentName: selectedDepartment,
              temp: existingUsers[0]?.temp ? false : false,
            },
          }),
        );

        // Update the combinedUser state after updating the user attributes
        setCombinedUser((prevState) => ({
          ...prevState,
          name,
          family_name: familyName,
          email: email,
          phone_number: phone,
          CoachName: name + " " + familyName,
          CrewName: selectedCrew,
          coach,
          admin: existingUsers[0].admin || false,
          guest: existingUsers[0].guest || false,
          altEmail: altEmail || existingUsers[0].altEmail,
          userEmail: userEmail || existingUsers[0].userEmail,
          AltCrewName: selectedCrew,
          OperationName: selectedOperation,
          DepartmentName: selectedDepartment,
          temp: existingUsers[0]?.temp ? false : false,
        }));

        toast.success("Coach details saved successfully");
      } else {
        // Create coachName object
        await API.graphql(
          graphqlOperation(createCoachName, {
            input: {
              userId: user.username,
              CoachName: name + " " + familyName,
              CrewName: selectedCrew,
              coach, // Add picture URL here
              admin: false,
              guest: false,
              altEmail,
              userEmail,
              AltCrewName: selectedCrew,
              OperationName: selectedOperation,
              DepartmentName: selectedDepartment,
              temp: false,
            },
          }),
        );

        // Update the combinedUser state after updating the user attributes
        setCombinedUser((prevState) => ({
          ...prevState,
          name,
          family_name: familyName,
          email: email,
          phone_number: phone,
          CoachName: name + " " + familyName,
          CrewName: selectedCrew,
          coach, // Update the state with picture URL
          admin: false,
          guest: false,
          altEmail,
          userEmail,
          AltCrewName: selectedCrew,
          OperationName: selectedOperation,
          DepartmentName: selectedDepartment,
          temp: false,
        }));

        toast.success("Coach details saved successfully");
      }

      let attributesToUpdate = [];
      if (originalEmail !== email) {
        attributesToUpdate.push("email");
      }
      if (originalPhone !== phone) {
        attributesToUpdate.push("phone_number");
      }
      if (attributesToUpdate.length > 0) {
        setAttributesToVerify(attributesToUpdate);
        setIsModalOpen(true);
      } else {
        toast.success("Profile details updated successfully");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message || "An error occurred while updating profile");
    }
    setIsSubmitting(false);
  };

  const handleSubmitModal = async (event) => {
    event.preventDefault();
    for (const attribute of attributesToVerify) {
      try {
        const result = await Auth.verifyCurrentUserAttributeSubmit(
          attribute,
          code,
        );
        console.log(result);
      } catch (err) {
        console.error(err);
        toast.error(`Error verifying ${attribute}: ${err.message}`);
        return;
      }
    }
    toast.success("Attributes successfully verified!");
    setIsModalOpen(false);
  };

  const resetStateValues = () => {
    setName(combinedUser.name || "");
    setFamilyName(combinedUser.family_name || "");
    setEmail(combinedUser.email || "");
    setPhone(combinedUser.phone_number || "");
    setAltEmail(combinedUser.altEmail || "");
    setUserEmail(combinedUser.email || "");
    setCoach(combinedUser.coach || false);
    setSelectedOperation(combinedUser.OperationName || "");
    setSelectedDepartment(combinedUser.DepartmentName || "");
    setSelectedCrew(combinedUser.AltCrewName || "");
  };

  useEffect(() => {
    setAltEmail(combinedUser.altEmail || "");
    setUserEmail(combinedUser.email || "");
    setCoach(combinedUser.coach || false);
    setSelectedOperation(combinedUser.OperationName || "");
    setSelectedDepartment(combinedUser.DepartmentName || "");
    setSelectedCrew(combinedUser.AltCrewName || "");
  }, [combinedUser]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchOperations = async () => {
    try {
      const operationData = await fetchAllPaginatedResults(listOperationNames);
      setOperations(operationData);
    } catch (err) {
      console.error("Error fetching operations:", err);
    }
  };

  const fetchDepartments = async () => {
    try {
      const departmentData =
        await fetchAllPaginatedResults(listDepartmentNames);
      setDepartments(departmentData);
    } catch (err) {
      console.error("Error fetching departments:", err);
    }
  };

  const fetchCrews = async () => {
    try {
      const crewData = await fetchAllPaginatedResults(listCrewNames);
      setCrews(crewData);
    } catch (err) {
      console.error("Error fetching crews:", err);
    }
  };

  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(
    combinedUser.OperationName || "",
  );
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(
    combinedUser.DepartmentName || "",
  );
  const [crews, setCrews] = useState([]);
  const [selectedCrew, setSelectedCrew] = useState(
    combinedUser.AltCrewName || "",
  );
  useEffect(() => {
    fetchOperations();
    fetchDepartments();
    fetchCrews();
  }, []);

  return (
    <>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 py-10">
        <div className="px-4 sm:px-0">
          <h2 className="sm:text-6xl text-4xl  leading-7 text-black">
            Profile
          </h2>
          <p className="mt-1 sm:text-lg text-sm leading-6 text-gray-600">
            This information will be used in all forms so make sure it is
            accurate.
          </p>
        </div>

        <form
          className="bg-white shadow-sm ring-1 ring-black/5 sm:rounded-xl md:col-span-2"
          onSubmit={handleSubmit}
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block sm:text-lg text-sm font-medium leading-6 text-black"
                >
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-lg text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block sm:text-lg text-sm font-medium leading-6 text-black"
                >
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    required
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-lg text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block sm:text-lg text-sm font-medium leading-6 text-black"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) =>
                      setEmail(e.target.value) && setUserEmail(e.target.value)
                    }
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-lg text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2 flex flex-row items-center">
                <label
                  htmlFor="email-verified"
                  className="block sm:text-lg text-sm font-medium leading-6 text-black mr-2"
                >
                  Email Verified
                </label>
                <Toggle enabled={combinedUser.email_verified} />
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block sm:text-lg text-sm font-medium leading-6 text-black"
                >
                  Phone
                </label>
                <div className="mt-2">
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="alternative-email"
                  className="block sm:text-lg text-sm font-medium leading-6 text-black"
                >
                  Alternative email
                </label>
                <div className="mt-2">
                  <input
                    id="altEmail"
                    name="altEmail"
                    type="email"
                    value={altEmail}
                    onChange={(e) => setAltEmail(e.target.value)}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-lg text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <select
                  value={selectedOperation}
                  onChange={(e) => setSelectedOperation(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-lg text-sm sm:leading-6"
                >
                  <option value="">Select Operation</option>
                  {operations
                    .sort((a, b) =>
                      a.OperationName.localeCompare(b.OperationName),
                    )
                    .map((op) => (
                      <option key={op.id} value={op.id}>
                        {op.OperationName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="sm:col-span-3">
                <select
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-lg text-sm sm:leading-6"
                >
                  <option value="">Select Department</option>
                  {departments
                    .sort((a, b) =>
                      a.DepartmentName.localeCompare(b.DepartmentName),
                    )
                    .map((dept) => (
                      <option key={dept.id} value={dept.id}>
                        {dept.DepartmentName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="sm:col-span-3">
                <select
                  value={selectedCrew}
                  onChange={(e) => setSelectedCrew(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:max-w-xs sm:text-lg text-sm sm:leading-6"
                >
                  <option value="">Select Crew</option>
                  {crews.map((crew) => (
                    <option key={crew.id} value={crew.id}>
                      {crew.CrewName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sm:col-span-3 flex flex-row py-2">
                <label
                  htmlFor="email-verified"
                  className="block sm:text-lg text-sm font-medium leading-6 text-black mr-2"
                >
                  Is Coach?
                </label>
                <ActualToggle enabled={coach} setEnabled={setCoach} />
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="Image Upload"
                  className="block py-2 text-md font-medium leading-6 text-black"
                >
                  Update Profile Image:
                </label>
                {console.log(combinedUser)}
                <ProfilePictureUpload
                  onImageUpload={(fileUrl) => setPictureUrl(fileUrl)}
                  userId={combinedUser.id}
                />
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-black/10 px-4 py-4 sm:px-8">
              <button
                type="reset"
                className="sm:text-lg text-sm  leading-6 text-black"
                disabled={isSubmitting}
                onClick={(event) => {
                  event.preventDefault();
                  resetStateValues();
                }}
              >
                Reset
              </button>
              <button
                disabled={isSubmitting}
                type="submit"
                className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
              >
                {isSubmitting ? (
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-200 animate-spin fill-thiess-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </form>
        {attributesToVerify.length > 0 && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Verify Attributes"
          >
            <h2>Verify Attributes</h2>
            <form onSubmit={handleSubmitModal}>
              {attributesToVerify.map((attribute) => (
                <div key={attribute}>
                  <label htmlFor="verificationCode">
                    Enter verification code for {attribute}:
                  </label>
                  <input
                    type="text"
                    id="verificationCode"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button type="submit">Verify</button>
                </div>
              ))}
            </form>
            <button onClick={closeModal}>Close</button>
          </Modal>
        )}
      </div>
    </>
  );
}

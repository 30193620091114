import React from "react";
import ReactSelect from "react-select";
import classNames from "classnames";
import themeConfig from "../../config/theme.config";
import useColorIntensity from "../../hooks/useColorIntensity";
import useRoundedSize from "../../hooks/useRoundedSize";

const SelectReact = (props) => {
  const {
    borderWidth,
    className,
    color,
    colorIntensity,
    isValidMessage,
    name,
    rounded,
    dimension,
    validFeedback,
    variant,
    isValid,
    isTouched,
    invalidFeedback,
    disabled,
    menuIsOpen,
    ...rest
  } = props;

  const { textColor } = useColorIntensity(colorIntensity);

  const selectVariant = {
    solid: {
      control: classNames(
        // Default
        [`${borderWidth} border-zinc-100 dark:border-zinc-800`],
        "bg-zinc-100 dark:bg-zinc-800",
        "w-full",
        "text-black dark:text-white",
        themeConfig.transition,
        [`${rounded}`],
        { "!border-zinc-500 !opacity-25": disabled },
        // Hover
        [`hover:border-${color}-${colorIntensity}`],
        [`dark:hover:border-${color}-${colorIntensity}`],
      ),
      controlFocus: classNames(
        {
          "!border-zinc-300 dark:!border-zinc-800": isValid,
        },
        "!bg-transparent dark:!bg-transparent",
      ),
      validation: classNames({
        "!border-red-500 ring-4 ring-red-500/30":
          !isValid && isTouched && invalidFeedback,
        "!border-green-500": !isValid && isTouched && !invalidFeedback,
      }),
      validationFocus: classNames({
        "!ring-4 !ring-green-500/30": !isValid && isTouched && !invalidFeedback,
      }),
    },
  };
  const selectControlVariantClasses = selectVariant[variant].control;
  const selectControlFocusVariantClasses = selectVariant[variant].controlFocus;
  const selectControlValidationsClasses = selectVariant[variant].validation;
  const selectControlValidationFocusClasses =
    selectVariant[variant].validationFocus;

  const selectDimensions = {
    sm: { control: classNames("!min-h-[2rem] text-sm") },
    default: { control: classNames("!min-h-[2.5rem]") },
    lg: { control: classNames("!min-h-[3rem] text-lg") },
    xl: { control: classNames("!min-h-[3.25rem] text-xl") },
  };
  const selectDimensionClasses = selectDimensions[dimension].control;

  const { roundedCustom } = useRoundedSize(rounded);

  return (
    <ReactSelect
      inputId={rest.id || name}
      data-component-name="Select"
      unstyled
      menuIsOpen={menuIsOpen}
      classNames={{
        control: (state) =>
          classNames(
            "py-0.5 px-1.5",
            selectControlVariantClasses,
            {
              [`${selectControlFocusVariantClasses}`]: state.isFocused,
            },
            selectControlValidationsClasses,
            {
              [`${selectControlValidationFocusClasses}`]: state.isFocused,
            },
            selectDimensionClasses,
            themeConfig.transition,
            className,
          ),
        option: (state) =>
          classNames("px-1.5 py-1", themeConfig.transition, {
            [`bg-${color}-${colorIntensity}`]: state.isFocused,
            [`${textColor}`]: state.isFocused,
            "opacity-50": state?.data?.isDisabled,
          }),
        menu: () =>
          classNames("bg-white dark:bg-black overflow-hidden shadow-lg", [
            `${rounded}`,
          ]),
        group: () =>
          classNames("border-zinc-500/25", "[&:not(:last-child)]:border-b"),
        groupHeading: () => classNames("", "px-1.5", "pt-1.5", "pb-0.5"),
        placeholder: () => classNames("text-black/50", "dark:text-white/50"),
        indicatorSeparator: () => classNames("rounded", "!bg-zinc-500/50"),
        multiValue: (state) =>
          classNames(
            `bg-${color}-${colorIntensity}`,
            "m-0.5",
            "ltr:pl-1 rtl:pr-1",
            [`${textColor}`],
            [`${roundedCustom(-2)}`],
            {
              "ltr:pr-1 rtl:pl-1": state?.data?.isFixed,
            },
          ),
        multiValueRemove: (state) =>
          classNames(
            "hover:bg-red-500",
            [`${roundedCustom(-2)}`],
            themeConfig.transition,
            {
              "!hidden": state?.data?.isFixed,
              "opacity-50 pointer-events-none": state?.data?.isDisabled,
            },
          ),
      }}
      isDisabled={disabled || rest?.isDisabled}
      {...rest}
    />
  );
};
SelectReact.defaultProps = {
  borderWidth: themeConfig.borderWidth,
  className: undefined,
  color: themeConfig.themeColor,
  colorIntensity: themeConfig.themeColorShade,
  rounded: themeConfig.rounded,
  dimension: "default",
  variant: "solid",
  disabled: false,
};
SelectReact.displayName = "Select";

export default SelectReact;

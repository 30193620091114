import React from "react";
import "@aws-amplify/ui-react/styles.css";
import { Link, useLocation } from "react-router-dom";
import thiess from "../assets/thiess.svg";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import {
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";

const ActionJacksonIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 351.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
      fill="#D1D5DB"
      stroke="none"
    >
      <path
        d="M2507 2380 c-101 -32 -166 -153 -137 -255 51 -181 298 -210 390 -45
50 91 20 209 -68 273 -33 24 -54 31 -100 34 -31 1 -70 -2 -85 -7z"
      />
      <path
        d="M1228 2319 c-21 -12 -24 -49 -6 -67 9 -9 131 -12 489 -12 421 0 479
2 493 16 21 20 20 30 -4 54 -19 19 -33 20 -487 20 -299 0 -474 -4 -485 -11z"
      />
      <path
        d="M1803 2037 c-12 -7 -30 -23 -41 -37 -26 -34 -181 -320 -188 -347 -21
-81 75 -164 162 -141 42 11 63 37 137 168 l64 115 111 3 c82 2 112 -1 112 -9
0 -11 -53 -106 -267 -479 l-78 -135 -241 -6 c-229 -5 -242 -6 -272 -28 -61
-43 -78 -110 -42 -168 37 -61 48 -63 370 -63 343 0 323 -6 397 121 25 44 49
82 53 84 3 2 47 -28 96 -67 49 -39 98 -77 109 -84 24 -17 19 -37 -58 -221 -30
-73 -58 -148 -61 -167 -20 -106 103 -185 194 -123 30 19 45 50 129 257 102
253 115 307 78 348 -10 11 -77 67 -148 123 -71 57 -128 106 -127 109 14 32
165 280 170 280 4 0 31 -38 61 -83 96 -147 138 -157 293 -70 49 28 116 65 149
83 94 51 123 77 134 117 25 91 -45 174 -134 160 -19 -3 -81 -31 -137 -61 -55
-31 -104 -56 -107 -56 -4 0 -35 44 -71 98 l-65 97 -57 7 c-92 12 -175 63 -219
136 -13 20 -28 40 -33 44 -19 13 -449 9 -473 -5z"
      />
      <path
        d="M408 1889 c-24 -13 -23 -56 1 -69 30 -15 953 -14 969 2 15 15 15 51
0 66 -16 16 -944 17 -970 1z"
      />
      <path
        d="M696 1394 c-19 -18 -21 -45 -4 -62 9 -9 132 -12 493 -12 466 0 481 1
492 20 15 23 8 47 -18 60 -11 6 -201 10 -483 10 -409 0 -466 -2 -480 -16z"
      />
      <path
        d="M347 835 c-19 -19 -10 -54 15 -64 32 -11 819 -14 849 -3 32 13 43 37
29 63 -10 18 -26 19 -445 19 -370 0 -436 -2 -448 -15z"
      />
    </g>
  </svg>
);

const MOTElementsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 393.000000 386.000000"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,386.000000) scale(0.100000,-0.100000)"
      fill="#D1D5DB"
      stroke="none"
    >
      <path
        d="M1845 3336 c-233 -66 -380 -202 -425 -394 -7 -31 -11 -97 -9 -160 4
-105 3 -108 -26 -151 -28 -42 -30 -50 -30 -145 0 -117 17 -164 72 -199 22 -15
47 -47 73 -97 129 -243 348 -410 540 -410 194 0 423 172 541 407 24 49 48 81
71 96 54 36 79 100 79 200 0 84 -11 125 -40 161 -13 15 -15 41 -13 145 4 143
-6 197 -52 285 -43 83 -105 127 -208 146 -26 5 -71 25 -100 44 -88 57 -166 79
-303 82 -82 3 -136 -1 -170 -10z m550 -488 c24 -77 66 -137 149 -215 68 -64
74 -74 80 -119 9 -77 -4 -115 -49 -145 -31 -21 -51 -49 -90 -126 -68 -135
-184 -255 -300 -310 -67 -31 -89 -37 -146 -37 -57 0 -78 5 -140 36 -129 64
-240 183 -310 331 -27 59 -76 117 -99 117 -17 0 -32 62 -28 120 4 55 8 63 57
114 29 31 66 85 83 122 l31 66 36 -6 c187 -32 337 -27 491 15 56 15 183 67
216 88 1 1 9 -23 19 -51z"
      />
      <path
        d="M1591 1659 c-41 -91 -55 -173 -48 -282 7 -109 14 -137 34 -137 7 0
30 -9 51 -19 72 -37 112 -102 112 -183 0 -67 -27 -126 -75 -165 -105 -87 -258
-54 -319 68 -15 31 -26 69 -26 93 0 58 41 140 84 168 33 22 35 25 28 63 -10
56 -9 261 2 314 8 41 7 43 -10 37 -11 -4 -77 -26 -149 -48 -152 -46 -213 -77
-262 -131 -75 -83 -107 -196 -123 -426 -10 -165 -5 -191 52 -234 254 -194
1180 -263 1826 -137 210 41 334 92 397 161 l38 42 -7 136 c-11 236 -53 378
-135 464 -58 62 -123 90 -385 165 -16 4 -18 2 -12 -14 4 -10 11 -49 16 -87 4
-38 10 -70 12 -72 2 -1 21 -12 44 -23 85 -43 117 -101 169 -308 31 -122 29
-160 -8 -215 -36 -53 -82 -79 -142 -79 -59 0 -85 19 -85 63 0 32 33 57 77 57
24 0 37 7 49 25 15 24 14 32 -19 155 -19 72 -42 142 -52 156 -21 32 -78 57
-115 49 -53 -12 -79 -53 -116 -189 -19 -70 -34 -135 -34 -145 0 -30 28 -51 66
-51 37 0 74 -29 74 -58 0 -10 -7 -28 -16 -40 -12 -18 -25 -22 -66 -22 -98 0
-167 62 -175 158 -5 48 52 285 83 343 16 32 101 109 120 109 21 0 18 86 -6
161 -19 62 -63 139 -79 139 -4 0 -84 -93 -177 -207 -93 -114 -177 -216 -187
-226 -13 -13 -30 -17 -60 -15 -40 3 -44 6 -209 208 -228 278 -201 257 -237
179z"
      />
      <path
        d="M1495 1116 c-71 -31 -71 -127 1 -161 29 -14 37 -14 68 -1 77 32 77
130 1 162 -40 16 -33 16 -70 0z"
      />
    </g>
  </svg>
);

const GitlabIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_628_510)">
      <path
        d="M22.65 14.39L12 22.13L1.35 14.39C1.20722 14.285 1.10132 14.1376 1.04743 13.9687C0.993549 13.7999 0.994447 13.6183 1.05 13.45L2.27 9.67002L4.71 2.16002C4.73367 2.09883 4.77134 2.04403 4.82 2.00002C4.89924 1.92764 5.00268 1.88751 5.11 1.88751C5.21732 1.88751 5.32076 1.92764 5.4 2.00002C5.45138 2.04969 5.48924 2.11164 5.51 2.18002L7.95 9.67002H16.05L18.49 2.16002C18.5137 2.09883 18.5513 2.04403 18.6 2.00002C18.6792 1.92764 18.7827 1.88751 18.89 1.88751C18.9973 1.88751 19.1008 1.92764 19.18 2.00002C19.2314 2.04969 19.2692 2.11164 19.29 2.18002L21.73 9.69002L23 13.45C23.0505 13.6235 23.0438 13.8087 22.9807 13.978C22.9177 14.1473 22.8017 14.2918 22.65 14.39V14.39Z"
        stroke="#D1D5DB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_628_510">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const navigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  {
    name: "MO@T Elements",
    href: "/forms",
    icon: MOTElementsIcon,
    current: false,
  },
  {
    name: "MO@T Reports",
    href: "/reports",
    icon: ChartPieIcon,
    current: false,
  },
  {
    name: "Action Jackson",
    href: "/action-jackson",
    icon: ActionJacksonIcon,
    current: false,
  },
  {
    name: "Answer Fox",
    href: "/answer-fox",
    icon: GitlabIcon,
    current: false,
  },
];

const navigation2 = [
  { name: "Manage Users", href: "/manage", icon: UsersIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  signOut,
  combinedUser,
  profilePictureURL,
}) {
  const location = useLocation();

  return (
    <div className="bg-black">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 bg-black px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={thiess}
                      alt="Opskit Logo"
                    />
                  </div>
                  <div className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => {
                            const isCurrent =
                              item.href ===
                              `/${location.pathname.split("/")[1]}`;
                            return (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    isCurrent
                                      ? "bg-gray-700 text-white"
                                      : "text-gray-200 hover:text-white hover:bg-gray-700",
                                    "group flex gap-x-4 rounded-md p-2 sm:text-lg text-sm leading-6  no-underline", // Adjusted gap-x value
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      isCurrent
                                        ? "text-white"
                                        : "text-gray-200 group-hover:text-white",
                                      "h-6 w-6 shrink-0",
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                      <li className="-mx-6 mt-auto">
                        <li>
                          <ul className="-mx-2 mt-2 space-y-1">
                            {navigation2.map((item) => {
                              const isCurrent =
                                item.href ===
                                `/${location.pathname.split("/")[1]}`;
                              return (
                                <li key={item.name}>
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      isCurrent
                                        ? "bg-gray-700 text-white"
                                        : "text-gray-200 hover:text-white hover:bg-gray-700",
                                      "flex items-center gap-x-5 px-6 py-3 sm:text-lg text-sm  leading-6", // Adjusted gap-x value
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        isCurrent
                                          ? "text-white"
                                          : "text-gray-200 group-hover:text-white",
                                        "h-6 w-6 shrink-0",
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      </li>
                    </ul>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black px-6">
          <div className="flex h-16 shrink-0 items-center">
            <img className="h-8 w-auto" src={thiess} alt="Opskit Logo" />
          </div>
          <div className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => {
                    const isCurrent =
                      item.href === `/${location.pathname.split("/")[1]}`;
                    return (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            isCurrent
                              ? "bg-gray-700 text-white"
                              : "text-gray-200 hover:text-white hover:bg-gray-700",
                            "group flex gap-x-4 rounded-md p-2 sm:text-lg text-sm leading-6 ", // Adjusted gap-x value
                          )}
                        >
                          <item.icon
                            className={classNames(
                              isCurrent
                                ? "text-white"
                                : "text-gray-200 group-hover:text-white",
                              "h-6 w-6 shrink-0",
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>

              <li className="mt-auto">
                <ul className="-mx-2 space-y-1">
                  {navigation2.map((item) => {
                    const isCurrent =
                      item.href === `/${location.pathname.split("/")[1]}`;
                    return (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            isCurrent
                              ? "bg-gray-700 text-white"
                              : "text-gray-200 hover:text-white hover:bg-gray-700",
                            "group flex gap-x-4 rounded-md p-2 sm:text-lg text-sm leading-6 ", // Adjusted gap-x value
                          )}
                        >
                          <item.icon
                            className={classNames(
                              isCurrent
                                ? "text-white"
                                : "text-gray-200 group-hover:text-white",
                              "h-6 w-6 shrink-0",
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
// import { motion } from "framer-motion";
import { Tabs } from "./tailwind-ui-components/Tabs";
import Hero from "./tailwind-ui-components/Hero";
import Features from "./tailwind-ui-components/Features";

// H1 - text-6xl
// H2 - text-5xl
// H3 - text-4xl
// H4 - text-3xl
// H5 - text-2xl
// H6 - text-xl
// P - text-base

export default function Home({
  className,
  showRadialGradient = true,
  ...props
}) {
  return (
    <>
      <Hero />
      <Features />
      <Tabs />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  createCrewName,
  deleteCrewName,
  updateCrewName,
} from "./graphql/mutations";
import { listCrewNames } from "./graphql/queries";
import "./App.css";
import toast from "react-hot-toast";
import PageWrapper from "./fyr-theme/components/layouts/PageWrapper/PageWrapper.jsx";
import Container from "./fyr-theme/components/layouts/Container/Container.jsx";
import Table, {
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "./fyr-theme/components/ui/Table.jsx";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { fetchAllPaginatedResults } from "./utils/pagination";

const normalizeName = (name) => {
  return name.trim().toLowerCase().replace(/\s+/g, "");
};

export default function ManageCrews() {
  const [allCrews, setAllCrews] = useState([]);
  const [newCrewName, setNewCrewName] = useState("");
  const [editingCrew, setEditingCrew] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [crewsPerPage] = useState(10);

  useEffect(() => {
    fetchAllCrews();
  }, []);

  const fetchAllCrews = async () => {
    try {
      const crewData = await fetchAllPaginatedResults(listCrewNames);
      setAllCrews(crewData);
    } catch (err) {
      console.error("Error fetching crews:", err);
      toast.error("Failed to fetch crews");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCrew = async () => {
    const normalizedName = normalizeName(newCrewName);
    if (!normalizedName) {
      toast.error("Crew name cannot be empty");
      return;
    }
    try {
      const existingCrew = allCrews.find(
        (crew) => normalizeName(crew.CrewName) === normalizedName,
      );
      if (existingCrew) {
        toast.error("A crew with the same name already exists.");
        return;
      }
      const input = {
        CrewName: newCrewName,
      };
      const result = await API.graphql(
        graphqlOperation(createCrewName, { input }),
      );
      setAllCrews([...allCrews, result.data.createCrewName]);
      setNewCrewName("");
      toast.success("Crew added successfully");
    } catch (err) {
      console.error("Error adding crew:", err);
      toast.error("Failed to add crew");
    }
  };

  const handleDeleteCrew = async (crewId) => {
    if (window.confirm("Are you sure you want to delete this crew?")) {
      try {
        const input = { id: crewId };
        await API.graphql(graphqlOperation(deleteCrewName, { input }));
        setAllCrews(allCrews.filter((crew) => crew.id !== crewId));
        toast.success("Crew deleted successfully");
      } catch (err) {
        console.error("Error deleting crew:", err);
        toast.error("Error deleting crew");
      }
    }
  };

  const handleEditCrew = (crew) => {
    setEditingCrew(crew);
  };

  const handleUpdateCrew = async () => {
    if (!editingCrew) return;
    const normalizedName = normalizeName(editingCrew.CrewName);
    if (!normalizedName) {
      toast.error("Crew name cannot be empty");
      return;
    }
    try {
      const existingCrew = allCrews.find(
        (crew) =>
          crew.id !== editingCrew.id &&
          normalizeName(crew.CrewName) === normalizedName,
      );
      if (existingCrew) {
        toast.error("A crew with the same name already exists.");
        return;
      }
      const input = {
        id: editingCrew.id,
        departmentNameCrewNamesId: editingCrew.departmentNameCrewNamesId,
        CrewName: editingCrew.CrewName,
      };
      const result = await API.graphql(
        graphqlOperation(updateCrewName, { input }),
      );
      setAllCrews(
        allCrews.map((crew) =>
          crew.id === editingCrew.id ? result.data.updateCrewName : crew,
        ),
      );
      setEditingCrew(null);
      toast.success("Crew updated successfully");
    } catch (err) {
      console.error("Error updating crew:", err);
      toast.error("Failed to update crew");
    }
  };

  const sortedCrews = allCrews
  ? [...allCrews].sort((a, b) => {
      const nameA = a?.CrewName ?? '';
      const nameB = b?.CrewName ?? '';
      return nameA.localeCompare(nameB);
    })
  : [];


  // Pagination logic
  const indexOfLastCrew = currentPage * crewsPerPage;
  const indexOfFirstCrew = indexOfLastCrew - crewsPerPage;
  const currentCrews = sortedCrews.slice(indexOfFirstCrew, indexOfLastCrew);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allCrews.length / crewsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <PageWrapper name="Manage Crews">
      <Container
        breakpoint={null}
        className="h-full w-full overflow-auto mb-12"
      >
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h3 className="sm:text-5xl text-3xl leading-6 text-black">Crews</h3>
            <p className="mt-2 sm:text-lg text-sm text-gray-700">
              A list of all the crews.
            </p>
          </div>
          <div className="sm:flex sm:items-center mb-4">
            <input
              type="text"
              value={newCrewName}
              onChange={(e) => setNewCrewName(e.target.value)}
              placeholder="New crew name"
              className="block w-64 rounded-md border-0 mx-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-base text-sm sm:leading-6"
            />
            <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-thiess-blue-600 px-3 py-2 text-center sm:text-lg text-sm text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
                onClick={handleAddCrew}
              >
                Add Crew
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div
            role="status"
            className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse md:p-6 "
          >
            <div className="flex items-center justify-between">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
              <div>
                <div className="h-2.5 bg-gray-300 rounded-full w-full mb-2.5"></div>
                <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
              </div>
              <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <Table>
              <THead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Actions</Th>
                </Tr>
              </THead>
              <TBody>
                {currentCrews
                  .sort((a, b) => a.CrewName.localeCompare(b.CrewName))
                  .map((crew) => (
                    <Tr key={crew.id}>
                      <Td>
                        {editingCrew && editingCrew.id === crew.id ? (
                          <input
                            type="text"
                            value={editingCrew.CrewName}
                            onChange={(e) =>
                              setEditingCrew({
                                ...editingCrew,
                                CrewName: e.target.value,
                              })
                            }
                            onBlur={handleUpdateCrew}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                handleUpdateCrew();
                              }
                            }}
                            className="block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-thiess-blue-600 sm:text-base text-sm sm:leading-6"
                          />
                        ) : (
                          crew.CrewName
                        )}
                      </Td>
                      <Td>
                        <div className="flex justify-between">
                          {editingCrew && editingCrew.id === crew.id ? (
                            <div
                              onClick={handleUpdateCrew}
                              className="cursor-pointer text-green-600 hover:text-green-700"
                            >
                              Save
                            </div>
                          ) : (
                            <div
                              onClick={() => handleEditCrew(crew)}
                              className="cursor-pointer text-yellow-600 hover:text-yellow-700"
                            >
                              Edit
                            </div>
                          )}
                          <div
                            onClick={() => handleDeleteCrew(crew.id)}
                            className="cursor-pointer text-red-600 hover:text-red-700"
                          >
                            Delete
                          </div>
                        </div>
                      </Td>
                    </Tr>
                  ))}
              </TBody>
            </Table>

            {/* Pagination */}
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
              <div className="flex flex-1 justify-between sm:hidden">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </button>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={
                    currentPage === Math.ceil(allCrews.length / crewsPerPage)
                  }
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">{indexOfFirstCrew + 1}</span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {Math.min(indexOfLastCrew, allCrews.length)}
                    </span>{" "}
                    of <span className="font-medium">{allCrews.length}</span>{" "}
                    results
                  </p>
                </div>
                <div>
                  <div
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    <button
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    {pageNumbers.map((number) => (
                      <button
                        key={number}
                        onClick={() => paginate(number)}
                        className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                          currentPage === number
                            ? "z-10 bg-thiess-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
                            : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                        }`}
                      >
                        {number}
                      </button>
                    ))}
                    <button
                      onClick={() => paginate(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(allCrews.length / crewsPerPage)
                      }
                      className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </PageWrapper>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { invokeChatBot } from "./graphql/mutations";

const Chat = ({ user }) => {
  const [messages, setMessages] = useState(
    () => JSON.parse(localStorage.getItem("messages")) || [],
  );
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [showBackground, setShowBackground] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    const savedMessages = localStorage.getItem("messages");
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  const sendMessage = async () => {
    if (input.trim() === "") return;

    setShowBackground(false);
    setLoading(true);

    const newMessage = { user: "Me", text: input };
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];
      localStorage.setItem("messages", JSON.stringify(updatedMessages)); // Save to local storage
      return updatedMessages;
    });

    try {
      const response = await API.graphql(
        graphqlOperation(invokeChatBot, { message: input }),
      );
      setInput("");
      console.log("Response:", response);

      let aiMessage;

      if (
        response.data &&
        response.data.invokeChatBot &&
        response.data.invokeChatBot.message
      ) {
        aiMessage = {
          user: "AI",
          text: response.data.invokeChatBot.message,
        };
      } else if (response.errors && response.errors.length > 0) {
        const error = response.errors[0];
        const errorMessage = error.message;

        const jsonMatch = errorMessage.match(/\{[\s\S]*\}/);
        if (jsonMatch) {
          try {
            const cleanedJson = jsonMatch[0]
              .replace(/\\'/g, "'")
              .replace(/\\"/g, '"')
              .replace(/\s+/g, " ")
              .trim()
              .replace(/\\n/g, "")
              .replace(/\\t/g, "")
              .replace(/ +/g, " ");

            const parsedError = JSON.parse(cleanedJson);
            if (parsedError && parsedError.message) {
              aiMessage = { user: "AI", text: parsedError.message };
            } else {
              aiMessage = {
                user: "AI",
                text: "Error: Unexpected response structure",
              };
            }
          } catch (parseError) {
            console.error("Error parsing JSON from error message:", parseError);
            aiMessage = { user: "AI", text: `Error: ${errorMessage}` };
          }
        } else {
          aiMessage = { user: "AI", text: `Error: ${errorMessage}` };
        }
      } else {
        aiMessage = {
          user: "AI",
          text: "Error: Unexpected response format",
        };
      }

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, aiMessage];
        localStorage.setItem("messages", JSON.stringify(updatedMessages)); // Save to local storage
        return updatedMessages;
      });
    } catch (errorObj) {
      let aiMessage;

      console.error("Error sending message:", errorObj);

      if (errorObj && errorObj.errors && errorObj.errors.length > 0) {
        const error = errorObj.errors[0];
        const errorMessage = error.message;

        const jsonMatch = errorMessage.match(/\{[\s\S]*\}/);
        if (jsonMatch) {
          try {
            const cleanedJson = jsonMatch[0]
              .replace(/\\'/g, "'")
              .replace(/\\"/g, '"')
              .replace(/\s+/g, " ")
              .trim()
              .replace(/\\n/g, "")
              .replace(/\\t/g, "")
              .replace(/ +/g, " ");

            const parsedError = JSON.parse(cleanedJson);
            if (parsedError && parsedError.message) {
              aiMessage = { user: "AI", text: parsedError.message };
            } else {
              aiMessage = {
                user: "AI",
                text: "Error: Unexpected response structure",
              };
            }
          } catch (parseError) {
            console.error("Error parsing JSON from error message:", parseError);
            aiMessage = { user: "AI", text: `Error: ${errorMessage}` };
          }
        } else {
          aiMessage = { user: "AI", text: `Error: ${errorMessage}` };
        }
      } else {
        aiMessage = {
          user: "AI",
          text: "Error: Unexpected error format",
        };
      }

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, aiMessage];
        localStorage.setItem("messages", JSON.stringify(updatedMessages)); // Save to local storage
        return updatedMessages;
      });
    }

    setLoading(false);
    setInput("");
  };

  return (
    <div className="flex flex-col h-[86vh]">
      <div className="flex-grow overflow-auto px-4 py-2">
        {showBackground && messages.length === 0 && (
          <div>
            <div className="flex flex-col items-center justify-center">
              <svg
                width="68"
                height="68"
                viewBox="0 0 68 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_883_93)">
                  <rect
                    x="2"
                    y="1"
                    width="64"
                    height="64"
                    rx="32"
                    fill="#0063B0"
                  />
                  <path
                    d="M48.1999 36.1866L33.9999 46.5066L19.7999 36.1866C19.6096 36.0466 19.4684 35.85 19.3965 35.6249C19.3247 35.3998 19.3259 35.1577 19.3999 34.9333L21.0266 29.8933L24.2799 19.8799C24.3115 19.7984 24.3617 19.7253 24.4266 19.6666C24.5323 19.5701 24.6702 19.5166 24.8133 19.5166C24.9564 19.5166 25.0943 19.5701 25.1999 19.6666C25.2685 19.7328 25.3189 19.8154 25.3466 19.9066L28.5999 29.8933H39.3999L42.6533 19.8799C42.6848 19.7984 42.7351 19.7253 42.8 19.6666C42.9056 19.5701 43.0435 19.5166 43.1866 19.5166C43.3297 19.5166 43.4676 19.5701 43.5733 19.6666C43.6418 19.7328 43.6923 19.8154 43.72 19.9066L46.9733 29.9199L48.6666 34.9333C48.734 35.1646 48.725 35.4115 48.6409 35.6372C48.5568 35.863 48.4022 36.0557 48.1999 36.1866V36.1866Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_883_93"
                    x="0"
                    y="0"
                    width="68"
                    height="68"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_883_93"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_883_93"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>

              <h5 className="sm:text-3xl text-xl  mt-4">
                {user ? (user.name ? " Hi, " + user.name + "!" : "") : ""}
              </h5>
              <h5 className="sm:text-3xl text-xl">Ask me a question</h5>
            </div>

            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex flex-col items-center w-full mt-4 space-y-4">
                <div className="flex flex-wrap justify-center gap-4 w-full max-w-7xl">
                  {[
                    "What is MO@T Reports?",
                    "How can I enhance my work with MO@T app",
                    "What is a Maturity Trend?",
                  ].map((text, index) => (
                    <button
                      key={index}
                      onClick={() => setInput(text)}
                      className="sm:text-lg text-sm px-6 py-3 w-full sm:w-auto border border-thiess-blue-500 text-black rounded-md hover:bg-thiess-blue-500 hover:text-white transition text-center"
                    >
                      {text}
                    </button>
                  ))}
                </div>
                <div className="flex flex-wrap justify-center gap-4 w-full max-w-7xl">
                  {[
                    "Explain a Maturity Trend to me",
                    "How can I enhance my work with MO@T app",
                    "How can I use MO@T as a diagnostic tool?",
                  ].map((text, index) => (
                    <button
                      key={index}
                      onClick={() => setInput(text)}
                      className="sm:text-lg text-sm px-6 py-3 w-full sm:w-auto border border-thiess-blue-500 text-black rounded-md hover:bg-thiess-blue-500 hover:text-white transition text-center"
                    >
                      {text}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {messages.map((msg, index) => (
          <div key={index} className={`flex justify-start mb-4`}>
            <div className={`flex items-start space-x-2 flex-row`}>
              {msg.user === "AI" ? (
                <svg
                  className="w-8 h-8 mt-1"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="22" fill="#0063B0" />
                  <g clipPath="url(#clip0_621_10535)">
                    <path
                      d="M30.8751 23.9917L22.0001 30.4417L13.1251 23.9917C13.0061 23.9042 12.9179 23.7813 12.873 23.6406C12.8281 23.4999 12.8288 23.3486 12.8751 23.2083L13.8918 20.0583L15.9251 13.8C15.9448 13.749 15.9762 13.7033 16.0168 13.6667C16.0828 13.6063 16.169 13.5729 16.2585 13.5729C16.3479 13.5729 16.4341 13.6063 16.5001 13.6667C16.5429 13.7081 16.5745 13.7597 16.5918 13.8167L18.6251 20.0583H25.3751L27.4085 13.8C27.4282 13.749 27.4596 13.7033 27.5001 13.6667C27.5662 13.6063 27.6524 13.5729 27.7418 13.5729C27.8312 13.5729 27.9174 13.6063 27.9835 13.6667C28.0263 13.7081 28.0578 13.7597 28.0751 13.8167L30.1085 20.075L31.1668 23.2083C31.2089 23.3529 31.2033 23.5072 31.1507 23.6483C31.0982 23.7894 31.0015 23.9098 30.8751 23.9917V23.9917Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_621_10535">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(12 12)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg
                  className="w-8 h-8 mt-1"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="43"
                    height="43"
                    rx="21.5"
                    fill="#FCFCFD"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="43"
                    height="43"
                    rx="21.5"
                    stroke="#EAEEF6"
                  />
                  <rect
                    width="20"
                    height="20"
                    transform="translate(12 12)"
                    fill="white"
                  />
                  <path
                    d="M22 21C23.6569 21 25 19.6569 25 18C25 16.3431 23.6569 15 22 15C20.3431 15 19 16.3431 19 18C19 19.6569 20.3431 21 22 21Z"
                    fill="#0063B0"
                  />
                  <path
                    d="M15 30C15 26.134 18.134 23 22 23C25.866 23 29 26.134 29 30H15Z"
                    fill="#0063B0"
                  />
                </svg>
              )}
              <div
                className={`rounded-lg shadow-lg p-3 max-w-[70%] ${msg.user === "Me" ? "bg-thiess-blue-100" : "bg-white"}`}
              >
                <p>{msg.text}</p>
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-4 pb-32">
        <div className="max-w-4xl mx-auto">
          <div className="py-6">
            <div className="flex mx-2 sm:mx-6 my-2 items-center rounded-xl shadow-lg">
              <input
                type="text"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  setShowBackground(
                    e.target.value === "" && messages.length === 0,
                  );
                }}
                onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                className="flex-grow border rounded-xl shadow-lg"
                placeholder="Question to Answer Fox"
                disabled={loading}
              />
              <button
                onClick={sendMessage}
                className="mx-2 sm:text-lg text-sm"
                disabled={loading}
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_590_8910)">
                      <path
                        d="M18.3333 1.66663L9.16663 10.8333"
                        stroke="#718096"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.3333 1.66663L12.5 18.3333L9.16663 10.8333L1.66663 7.49996L18.3333 1.66663Z"
                        stroke="#718096"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_590_8910">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

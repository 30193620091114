import React, {
  Children,
  cloneElement,
  forwardRef,
  useId,
  useRef,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import useEventListener from "../../hooks/useEventListener";
import Portal from "../layouts/Portal/Portal";
import CloseButton from "./CloseButton";
import themeConfig from "../../config/theme.config";

const defaultProps = {
  position: "right",
};

const checkComp = (componentName, child) => {
  return [componentName].includes(
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    child?.type?.displayName,
  );
};

export const OffCanvasHeader = (props) => {
  const { children, className, titleId, setIsOpen, ...rest } = props;

  const classes = classNames(
    "flex items-center justify-between px-4 pb-4 text-2xl  [&:first-child]:pt-4",
  );

  return (
    <div
      data-component-name="OffCanvas/OffCanvasHeader"
      className={classNames(classes, className)}
      {...rest}
    >
      <div id={titleId} className="flex items-center">
        {children}
      </div>
      <div className="flex items-center">
        <CloseButton
          setIsOpen={() => (setIsOpen ? setIsOpen(false) : undefined)}
        />
      </div>
    </div>
  );
};
OffCanvasHeader.defaultProps = {
  className: undefined,
  setIsOpen: undefined,
  titleId: undefined,
};
OffCanvasHeader.displayName = "OffCanvasHeader";

export const OffCanvasBody = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(
    "grow px-4 pb-4 [&:first-child]:pt-4",
    "overflow-y-auto",
  );

  return (
    <div
      data-component-name="OffCanvas/OffCanvasBody"
      className={classNames(classes, className)}
      {...rest}
    >
      {children}
    </div>
  );
};
OffCanvasBody.defaultProps = {
  className: undefined,
};
OffCanvasBody.displayName = "OffCanvasBody";

export const OffCanvasFooterChild = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames("flex items-center gap-4");

  return (
    <div
      data-component-name="OffCanvas/OffCanvasFooterChild"
      className={classNames(classes, className)}
      {...rest}
    >
      {children}
    </div>
  );
};
OffCanvasFooterChild.defaultProps = {
  children: undefined,
  className: undefined,
};
OffCanvasFooterChild.displayName = "OffCanvasFooterChild";

export const OffCanvasFooter = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(
    "flex items-center justify-between px-4 pb-4 [&:first-child]:pt-4",
  );

  return (
    <div
      data-component-name="OffCanvas/OffCanvasFooter"
      className={classNames(classes, className)}
      {...rest}
    >
      {children}
    </div>
  );
};
OffCanvasFooter.defaultProps = {
  className: undefined,
};
OffCanvasFooter.displayName = "OffCanvasFooter";

/**
 * BackDrop
 * @constructor
 */
const BackDrop = () => {
  const animationProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { ease: "easeInOut", duration: 0.3 },
  };
  return (
    <motion.div
      data-component-name="OffCanvas/BackDrop"
      {...animationProps}
      className="fixed left-0 top-0 z-[1050] h-screen w-screen backdrop-blur-sm"
    />
  );
};

/**
 * Content
 * @constructor
 */
const Content = (props) => {
  const { children, className, ...rest } = props;

  const classes = classNames(
    "pointer-events-auto relative flex h-full w-full flex-col overflow-hidden bg-white/75 dark:bg-zinc-950/95 backdrop-blur-md",
    className,
  );
  return (
    <div data-component-name="OffCanvas/Content" className={classes} {...rest}>
      {children}
    </div>
  );
};
Content.defaultProps = {
  className: undefined,
};

/**
 * Dialog
 * @constructor
 */
const Dialog = forwardRef((props, ref) => {
  const { children, position, className, ...rest } = props;

  const classes = classNames(
    "pointer-events-none fixed w-full h-full shadow-2xl",
    {
      "max-w-[30rem]": ["right", "left"].includes(position),
      "left-0": position === "left",
      "right-0": position === "right",
      "max-h-[30rem]": ["top", "bottom"].includes(position),
      "top-0": position === "top",
      "bottom-0": position === "bottom",
    },
    className,
  );

  return (
    <div
      data-component-name="OffCanvas/Dialog"
      ref={ref}
      className={classes}
      {...rest}
    >
      {children}
    </div>
  );
});
Dialog.defaultProps = {
  position: defaultProps.position,
  className: undefined,
};

const OffCanvas = (props) => {
  const {
    children,
    isOpen,
    setIsOpen,
    isStaticBackdrop,
    isAnimation,
    position,
    dialogClassName,
    contentClassName,
    ...rest
  } = props;
  const refOffCanvas = useRef(null);
  const ref = useRef(null);

  const titleId = useId();

  // Backdrop close function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const closeOffCanvas = (event) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !isStaticBackdrop
    ) {
      setIsOpen(false);
    }
  };
  useEventListener("mousedown", closeOffCanvas);
  useEventListener("touchstart", closeOffCanvas); // Touchscreen

  // Backdrop static function
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const offCanvasStatic = (event) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      isStaticBackdrop
    ) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      refOffCanvas.current.classList.add("!scale-105");
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
      setTimeout(
        () => refOffCanvas.current.classList.remove("!scale-105"),
        300,
      );
    }
  };
  useEventListener("mousedown", offCanvasStatic);
  useEventListener("touchstart", offCanvasStatic); // Touchscreen

  // Keypress close function
  const escFunction = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };
  useEventListener("keydown", escFunction);

  const animationProps = isAnimation
    ? {
        animate: { opacity: 1, x: "0%", y: "0%" },
        transition: { ease: "easeInOut", duration: 0.3 },
      }
    : null;

  const animationPositionProps = isAnimation
    ? {
        top: {
          initial: { opacity: 0, y: "-50%" },
          exit: { opacity: 0, y: "-50%" },
        },
        right: {
          initial: { opacity: 0, x: "50%" },
          exit: { opacity: 0, x: "50%" },
        },
        bottom: {
          initial: { opacity: 0, y: "50%" },
          exit: { opacity: 0, y: "50%" },
        },
        left: {
          initial: { opacity: 0, x: "-50%" },
          exit: { opacity: 0, x: "-50%" },
        },
      }
    : null;

  const classes = classNames(
    "fixed left-0 top-0 z-[1055] block h-full w-full overflow-y-auto overflow-x-hidden",
    { [`${themeConfig.transition}`]: isStaticBackdrop },
  );

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              data-component-name="OffCanvas"
              ref={refOffCanvas}
              key="offCanvas"
              className={classes}
              role="dialog"
              tabIndex={-1}
              aria-labelledby={titleId}
              aria-hidden="true"
              {...animationProps}
              {...animationPositionProps?.[position || "right"]}
              {...rest}
            >
              <Dialog ref={ref} position={position} className={dialogClassName}>
                <Content className={contentClassName}>
                  {Children.map(
                    children,
                    (child) =>
                      (checkComp("OffCanvasHeader", child) &&
                        cloneElement(child, {
                          // @ts-ignore
                          setIsOpen,
                          titleId,
                        })) ||
                      child,
                  )}
                </Content>
              </Dialog>
            </motion.div>
            <BackDrop />
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};
OffCanvas.defaultProps = {
  isAnimation: true,
  isStaticBackdrop: false,
  position: defaultProps.position,
  dialogClassName: undefined,
  contentClassName: undefined,
};

export default OffCanvas;

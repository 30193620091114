import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/20/solid";

export default function Modal({
  id,
  name,
  comment,
  disabled,
  onCommentChange,
  question,
}) {
  const [showModal, setShowModal] = useState(false);
  const cancelButtonRef = useRef(null);
  return (
    <div className="col-span-1">
      <button type="button" onClick={() => setShowModal(true)}>
        <ChatBubbleOvalLeftEllipsisIcon
          data-tip
          data-for={`commentTooltip-${id}-${name}`}
          className={`h-10 w-10 cursor-pointer ${comment ? "text-green-600 hover:text-green-500" : "text-gray-400 hover:text-gray-300"}`}
        />
      </button>

      {showModal ? (
        <Transition.Root show={showModal} as={Fragment}>
          <Dialog
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setShowModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-1/2 my-6 mx-auto max-w-3xl">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <textarea
                          id={`${id}-comment`}
                          name={name}
                          rows={10}
                          value={comment}
                          disabled={disabled}
                          placeholder={`Write your comments for the question: "${question}" here ...`}
                          onChange={onCommentChange}
                          className="w-full h-full "
                        />
                      </div>
                      {/*footer*/}
                      <div className="flex items-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          className="bg-emerald-500 text-white active:bg-emerald-600  uppercase text-sm px-2 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModal(false)}
                          ref={cancelButtonRef}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { createShiftHandover, createFormScore } from "../graphql/mutations";
import toast from "react-hot-toast";

import CompanyInfoSection from "../tailwind-ui-components/CompanyInfoSection";
import QuestionSection from "../tailwind-ui-components/QuestionSection";
import SummarySection from "../tailwind-ui-components/SummarySection";
import UpdateProfileNotice from "../tailwind-ui-components/UpdateProfileNotice";
import ContentAccordion from "../tailwind-ui-components/ContentAccordion";
import { v4 as uuidv4 } from "uuid";
import FormTabSteps from "../tailwind-ui-components/FormTabSteps";

export default function ShiftHandoverUI({
  user,
  weights,
  viewState,
  setViewState,
  coachees,
  selectedCoachee,
  setSelectedCoachee,
  operations,
  departments,
  crews,
  questionsText,
  formName,
}) {
  const [errors, setErrors] = useState({});
  const [toggleState, setToggleState] = useState(false);
  const handleView = () => {
    setViewState(!viewState);
  };
  const [formattedWeights, setFormattedWeights] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (weights) {
      const newFormattedWeights = {
        q1: weights[0],
        q2: weights[1],
        q3: weights[2],
        q4: weights[3],
        q5: weights[4],
        q6: weights[5],
        q7: weights[6],
        q8: weights[7],
        q9: weights[8],
        q10: weights[9],
        q11: weights[10],
      };
      let newTotal = 0;
      for (let i = 0; i < weights.length; i++) {
        newTotal += Number(weights[i]);
      }

      // Update state variables
      setFormattedWeights(newFormattedWeights);
      setTotal(newTotal);
    }
  }, [weights]); // Run this effect whenever `weights` changes

  const [questions, setQuestions] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: "",
  });

  const sections = [
    {
      id: "Behaviours",
      title: "Behaviours",
      description: "Behaviours observed during the assessment.",
      prevSection: "Company",
      nextSection: "Tools",
      questionIds: ["q1", "q4", "q6", "q7", "q9", "q10"],
    },
    {
      id: "Tools",
      title: "Tools / Systems",
      description: "Tools / Systems that are currently being used.",
      prevSection: "Behaviours",
      nextSection: "Psych",
      questionIds: ["q2", "q3", "q5", "q8"],
    },
    {
      id: "Psych",
      title: "Psych Safety",
      description:
        "Psychological safety is the belief that you won’t be punished when you make a mistake. It’s the belief that you can ask a question without being ridiculed. It’s the belief that you can offer a new idea without being shot down.",
      prevSection: "Tools",
      nextSection: "Summary",
      questionIds: ["q11"],
    },
  ];

  const [comments, setComments] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
    q5: "",
    q6: "",
    q7: "",
    q8: "",
    q9: "",
    q10: "",
    q11: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    operation: "",
    department: "",
    FirstName: user.name ? user.name : "",
    LastName: user.family_name ? user.family_name : "",
    date: "",
    CommentsNotes: "",
    CoachingNotes: "",
    coachee: "",
    crew: "",
  });

  const validateForm = () => {
    let newErrors = {};

    if (!formData.operation || !formData.operation.trim()) {
      newErrors.operation = "Operation is required";
    }

    if (!formData.department || !formData.department.trim()) {
      newErrors.department = "Department is required";
    }

    if (!formData.date || !formData.date.trim()) {
      newErrors.date = "Date is required";
    } else {
      const selectedDate = new Date(formData.date);
      const validDate = new Date("2023-01-01");
      const oneDayAhead = new Date();
      oneDayAhead.setDate(oneDayAhead.getDate() + 1);

      if (selectedDate < validDate) {
        newErrors.date = "Date is older than 2023";
      } else if (selectedDate > oneDayAhead) {
        newErrors.date =
          "Date cannot be more than one day ahead of the current time";
      }
    }

    // Validating all questions
    Object.keys(questions).forEach((key) => {
      if (!questions[key].trim() || questions[key] === "-1") {
        newErrors[key] = "This field is required";
      }
    });

    setErrors(newErrors);

    // Return the error object
    return newErrors;
  };

  const handleToggle = (value) => {
    setToggleState(value);
  };

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setQuestions((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  const handleCommentChange = (e) => {
    const { name, value } = e.target;
    setComments((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleDateChange = (e) => {
    let { name, value } = e.target;
    value = new Date(value).toISOString();
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  useEffect(() => {
    if (user.name && user.family_name) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        FirstName: user.name,
        LastName: user.family_name,
      }));
    }
  }, [user]);

  const handleSubmitReset = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const newErrors = validateForm();

    // Check for the specific date error
    if (newErrors.date === "Date is older than 2023") {
      toast.error("Error: The date must be on or after January 1, 2023");
      setIsSubmitting(false);
      return;
    }

    // If there are any other errors
    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      const result = await API.graphql({
        query: createShiftHandover,
        variables: {
          input: {
            Operation: formData.operation,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            Department: formData.department,
            FormDate: formData.date,
            MosElement: toggleState ? "Yes" : "No",
            Crew: formData.crew,
            Q1: questions.q1,
            Q2: questions.q2,
            Q3: questions.q3,
            Q4: questions.q4,
            Q5: questions.q5,
            Q6: questions.q6,
            Q7: questions.q7,
            Q8: questions.q8,
            Q9: questions.q9,
            Q10: questions.q10,
            Q11: questions.q11,
            C1: comments.q1,
            C2: comments.q2,
            C3: comments.q3,
            C4: comments.q4,
            C5: comments.q5,
            C6: comments.q6,
            C7: comments.q7,
            C8: comments.q8,
            C9: comments.q9,
            C10: comments.q10,
            C11: comments.q11,
            CommentsNotes: formData.CommentsNotes,
            CoachingNotes: formData.CoachingNotes,
            CoachName: formData.coachee,
          },
        },
      });

      function transformStringToValue(str) {
        str = str.toLowerCase();
        switch (str) {
          case "nothing":
            return 0;
          case "0":
            return 0;
          case "poor":
            return 1;
          case "1":
            return 1;
          case "ok":
            return 2;
          case "2":
            return 2;
          case "good":
            return 3;
          case "3":
            return 3;
          default:
            return 0; // For 'Missing' or any unrecognized string
        }
      }

      function calculateWeightedAverage(weights, questions) {
        let sumOfWeightedQuestions = 0;
        let sumOfWeights = weights.reduce((a, b) => a + b, 0);
        for (let i = 0; i < weights.length; i++) {
          let question = transformStringToValue(questions[`q${i + 1}`]);
          sumOfWeightedQuestions += question * weights[i];
        }
        let result = sumOfWeightedQuestions / sumOfWeights;
        return result.toFixed(1);
      }

      let averageScore = calculateWeightedAverage(weights, questions);

      const FormScore = await API.graphql({
        query: createFormScore,
        variables: {
          input: {
            // Error with amplify autogenerating id: https://github.com/aws-amplify/amplify-cli/issues/4502
            id: uuidv4(),
            FormName: formName,
            WeightedScore: averageScore,
            OperationName: formData.operation,
            DepartmentName: formData.department,
            CrewName: formData.crew,
            CoachName: formData.coachee,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            FormDate: formData.date,
          },
        },
      });
      console.log("Score Form Input Result: ", FormScore);
      console.log("Result: ", result);
      toast.success("Successfully submitted Form!");
      setIsSubmitting(false);
      resetStateValues();
    } catch (error) {
      console.error("Error submitting form: ", error);
      toast.error("Error submitting Form! " + error.errors[0].message);
      setIsSubmitting(false);
      resetStateValues();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const newErrors = validateForm();

    // Check for the specific date error
    if (newErrors.date === "Date is older than 2023") {
      toast.error("Error: The date must be on or after January 1, 2023");
      setIsSubmitting(false);
      return;
    }

    // If there are any other errors
    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      const result = await API.graphql({
        query: createShiftHandover,
        variables: {
          input: {
            Operation: formData.operation,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            Department: formData.department,
            FormDate: formData.date,
            MosElement: toggleState ? "Yes" : "No",
            Crew: formData.crew,
            Q1: questions.q1,
            Q2: questions.q2,
            Q3: questions.q3,
            Q4: questions.q4,
            Q5: questions.q5,
            Q6: questions.q6,
            Q7: questions.q7,
            Q8: questions.q8,
            Q9: questions.q9,
            Q10: questions.q10,
            Q11: questions.q11,
            C1: comments.q1,
            C2: comments.q2,
            C3: comments.q3,
            C4: comments.q4,
            C5: comments.q5,
            C6: comments.q6,
            C7: comments.q7,
            C8: comments.q8,
            C9: comments.q9,
            C10: comments.q10,
            C11: comments.q11,
            CommentsNotes: formData.CommentsNotes,
            CoachingNotes: formData.CoachingNotes,
            CoachName: formData.coachee,
          },
        },
      });

      function transformStringToValue(str) {
        str = str.toLowerCase();
        switch (str) {
          case "nothing":
            return 0;
          case "0":
            return 0;
          case "poor":
            return 1;
          case "1":
            return 1;
          case "ok":
            return 2;
          case "2":
            return 2;
          case "good":
            return 3;
          case "3":
            return 3;
          default:
            return 0; // For 'Missing' or any unrecognized string
        }
      }

      function calculateWeightedAverage(weights, questions) {
        let sumOfWeightedQuestions = 0;
        let sumOfWeights = weights.reduce((a, b) => a + b, 0);
        for (let i = 0; i < weights.length; i++) {
          let question = transformStringToValue(questions[`q${i + 1}`]);
          sumOfWeightedQuestions += question * weights[i];
        }
        let result = sumOfWeightedQuestions / sumOfWeights;
        return result.toFixed(1);
      }

      let averageScore = calculateWeightedAverage(weights, questions);

      const FormScore = await API.graphql({
        query: createFormScore,
        variables: {
          input: {
            // Error with amplify autogenerating id: https://github.com/aws-amplify/amplify-cli/issues/4502
            id: uuidv4(),
            FormName: formName,
            WeightedScore: averageScore,
            OperationName: formData.operation,
            DepartmentName: formData.department,
            CrewName: formData.crew,
            CoachName: formData.coachee,
            FirstName: formData.FirstName,
            LastName: formData.LastName,
            FormDate: formData.date,
          },
        },
      });
      console.log("Score Form Input Result: ", FormScore);
      console.log("Result: ", result);
      toast.success("Successfully submitted Form!");
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error submitting form: ", error);
      toast.error("Error submitting Form! " + error.errors[0].message);
      setIsSubmitting(false);
    }
  };
  const resetStateValues = () => {
    setFormData({
      operation: formData.operation,
      department: formData.department,
      FirstName: user.name ? user.name : "",
      LastName: user.family_name ? user.family_name : "",
      CommentsNotes: "",
      CoachingNotes: "",
      coachee: formData.coachee,
      crew: formData.crew,
    });
    setQuestions({
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
      q10: "",
      q11: "",
    });
    setComments({
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
      q10: "",
      q11: "",
    });
    setErrors({});
  };

  //   const contentBody = `
  // #### Objectives:
  // - A good handover transfers understanding of where the off-going crew is up to on the plan to the incoming crew so that the next shift can be well coordinated. The incoming supervisor gets the shift plan from Dispatch when they get to site.
  // - The incoming & off-going supervisors meet to review progress against KPIs, the status of tasks, identify hazards and understand priorities. With this understanding they may need to adapt the shift plan or escalate issues to the Superintendent

  // #### Good handovers follow a consistent process
  // - Review the previous actuals and the plan for your shift.
  // - Do pit inspection and discuss progress and your plan.
  // - Adjust your plan for the shift in consultation with your leader.

  // Dispatch is responsible for setting up the incoming Supervisor for a successful shift by keeping planned and actuals up to date with what’s happening in each production area. The incoming Shift Supervisor should clarify, ask questions and make their own notes during the handover.
  // `;

  //   const tableObject = {
  //     "Current Reality - Last shift plan vs actual KPIs, face positions, hazards, machines, pit standards, safety concerns, any incomplete work.":
  //       "Work Management System, Fleet Management System, Inspections / OCE Report",
  //     "Detailed shift plan showing the tasks, inspections, targets, machines, supplies, locations and people required to deliver a successful shift.":
  //       "Work Management System - (Powerpoint / infomine), Fleet Management System",
  //     "Pit designs, planned volumes, sequences, geology, geotech etc.":
  //       "Images of designs / mud maps",
  //     "Expected shutdowns, outages, rebuilds, weather, water, unavailable locations etc.":
  //       "Maintenance plan (work management system) (Infomine)",
  //     "Understand adjacent team plans to help “play as a team”.":
  //       "Work management system",
  //   };
  const keyQuestion = `KEY QUESTION: How do I need to adapt my shift Handover, based on the
  previous shift performance and the reality in the workplace so I can
  set my crew up for success.`;
  const outcome = `OUTCOME: Smooth handover of understanding & ownership that will
  deliver the shift Handover.  `;
  const [parsedContent, setParsedContent] = useState({});
  const [data, setData] = useState(null);
  const splitContent = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = Array.from(doc.body.childNodes);

    let tableObject, contentBody;
    let contentStartIndex, contentEndIndex;
    let tableStartIndex = null;
    let tableHeading = "";

    elements.forEach((element, index) => {
      if (
        /^H[1-6]$/i.test(element.nodeName) &&
        element.textContent.includes("Description")
      ) {
        contentStartIndex = index + 1;
      } else if (/^H[1-6]$/i.test(element.nodeName)) {
        tableHeading = element.textContent;
        tableStartIndex = index;
      }
    });

    if (tableStartIndex !== null) {
      tableObject = elements
        .slice(tableStartIndex + 1)
        .map((element) => element.outerHTML)
        .join("");
      contentEndIndex = tableStartIndex - 1;
    }

    contentBody = elements
      .slice(contentStartIndex, contentEndIndex + 1)
      .map((element) => {
        if (element.nodeName === "UL") {
          element.classList.add("list-disc");
          element.classList.add("pl-8");
        } else if (element.nodeName === "OL") {
          element.classList.add("list-decimal");
          element.classList.add("pl-8");
        }
        return element.outerHTML;
      })
      .join("");

    return {
      tableHeading,
      tableObject,
      contentBody: `<div>${contentBody}</div>`,
    };
  };
  useEffect(() => {
    fetch(
      "https://opskit.app/wp-json/wp/v2/posts?slug=thiess-3b-shift-handover",
      {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEyLCJuYW1lIjoib3Brc2tpdF9jb3JzIiwiaWF0IjoxNzExNTE1ODE4LCJleHAiOjE4NjkxOTU4MTh9.V15EPNXDoR7UeKGa-rAVXtXXokG3bzy9gQsqnm7wVkY",
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        if (data && data[0] && data[0].content) {
          setParsedContent(splitContent(data[0].content.rendered));
        }
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  let contentBody = parsedContent.contentBody;
  if (contentBody) {
    contentBody = contentBody.replace(/\[vc_.*?\]/g, "");
  }
  let tableObject = parsedContent.tableObject;
  if (tableObject) {
    tableObject = tableObject.replace(/\[vc_.*?\]/g, "");
  }
  let tableHeading = parsedContent.tableHeading;
  if (tableHeading) {
    tableHeading = tableHeading.replace(/\[vc_.*?\]/g, "");
  }
  const [showForm, setShowForm] = useState(false);
  const [currentQuestionSection, setCurrentQuestionSection] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  const nextSection = () => {
    setCurrentSection(currentSection + 1);
  };

  const prevSection = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
    }
  };
  return (
    <>
      <h5 className="sm:text-3xl text-xl mx-auto  mb-5 text-black break-words">
        3b. Shift Handover
      </h5>
      <ContentAccordion
        directoryName={formName}
        contentTitle="What is a Shift Handover?"
        contentBody={contentBody}
        videoUrl="https://www.youtube.com/embed/84fuJJgVPP0?si=_rinmaDP8dhCxWih"
        tableObject={tableObject}
        contentOpen="Click here to open the content & show info"
        Title="Shift Handover"
        keyQuestion={keyQuestion}
        outcome={outcome}
        showForm={showForm}
        setShowForm={setShowForm}
      />
      {showForm && (
        <div>
          <h6 className="text-black font-semibold sm:text-2xl text-lg mx-auto  my-5">
            Maturity Assessment
          </h6>
          <p className="text-black sm:text-base text-sm mx-auto my-5">
            Use this form to understand and assess the maturity of this element
            as you observe it on the site
          </p>
          <FormTabSteps
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            currentQuestionSection={currentQuestionSection}
            setCurrentQuestionSection={setCurrentQuestionSection}
          />
          <div className="space-y-10 divide-y divide-black/10">
            {user.name &&
            user.family_name &&
            user.userHasInfo &&
            !user.guest &&
            !user.temp ? (
              <form onSubmit={handleSubmit}>
                {/* <PersonalInfoSection
              user={user}
              viewState={viewState}
              handleView={handleView}
            /> */}
                {currentSection === 0 && (
                  <CompanyInfoSection
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleDateChange={handleDateChange}
                    handleToggle={handleToggle}
                    isSubmitting={isSubmitting}
                    errors={errors}
                    operations={operations}
                    departments={departments}
                    crews={crews}
                    toggleState={toggleState}
                    coachees={coachees}
                    nextSection={nextSection}
                    prevSection={prevSection}
                  />
                )}
                {currentSection === 1 && (
                  <QuestionSection
                    questions={questions}
                    questionsText={questionsText}
                    comments={comments}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    handleQuestionChange={handleQuestionChange}
                    handleCommentChange={handleCommentChange}
                    sections={sections}
                    viewState={viewState}
                    weights={formattedWeights}
                    total={total}
                    nextSection={nextSection}
                    prevSection={prevSection}
                    currentQuestionSection={currentQuestionSection}
                    setCurrentQuestionSection={setCurrentQuestionSection}
                  />
                )}
                {currentSection === 2 && (
                  <SummarySection
                    handleSubmitReset={handleSubmitReset}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    isSubmitting={isSubmitting}
                    resetStateValues={resetStateValues}
                    directoryName={formName}
                    nextSection={nextSection}
                    prevSection={prevSection}
                  />
                )}
              </form>
            ) : user.userHasInfo && user.guest ? (
              <div className="text-center text-2xl">
                You are not allowed to view this page, ask your coach or admin
                to elevate your permissions.
              </div>
            ) : (
              <UpdateProfileNotice />
            )}
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";

const SvgReply = (props) => {
  return (
    <svg viewBox="0 0 24 24" className="svg-icon" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M21.445 17.791c0-.82.176-4.058-2.378-6.626C17.348 9.437 14.777 9.137 11.7 9V4.693a.75.75 0 00-1.254-.555l-7.14 6.477a.75.75 0 00-.006 1.106l7.14 6.612a.75.75 0 001.26-.55V13.5c1.953.054 3.344.178 4.608.58 1.82.58 3.227 2.222 4.22 4.928a.75.75 0 00.704.492h.218c0-.493-.005-1.274-.005-1.709z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default SvgReply;

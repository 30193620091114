import React, { useEffect } from "react";
import { useState } from "react";
import {
  listOperationNames,
  listCoachNames,
  listDepartmentNames,
  listCrewNames,
} from "./graphql/queries";
import { API } from "aws-amplify";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import PreStartUI from "./FormsUI/PreStartUI";
import ShiftHandoverUI from "./FormsUI/ShiftHandoverUI";
import PreShiftAlignmentUI from "./FormsUI/PreShiftAlignmentUI";
import QuarterlyMonthlyPlanUI from "./FormsUI/QuarterlyMonthlyPlanUI";
import MonthlyPlanningMeetingUI from "./FormsUI/MonthlyPlanningMeetingUI";
import DailyCommitmentMeetingUI from "./FormsUI/DailyCommitmentMeetingUI";
import ShiftplanUI from "./FormsUI/ShiftPlanUI";
import { getAllForms } from "./graphql/queries";
import Panel from "./tailwind-ui-components/Panel";
import { useNavigate, useLocation } from "react-router-dom";
import WeeklyPlanScheduleUI from "./FormsUI/WeeklyPlanScheduleUI";
import WeeklyPrePlanningUI from "./FormsUI/WeeklyPrePlanningUI";
import WeeklySitePlanningMeetingUI from "./FormsUI/WeeklySitePlanningMeetingUI";
import WeeklyCommitmentMeetingUI from "./FormsUI/WeeklyCommitmentMeetingUI";
import ShortIntervalControlUI from "./FormsUI/ShortIntervalControlUI";
import ShiftReportUI from "./FormsUI/ShiftReportUI";
import ShiftDebriefUI from "./FormsUI/ShiftDebriefUI";
import PerformanceReportsUI from "./FormsUI/PerformanceReportsUI";
import DepartmentDailyReviewUI from "./FormsUI/DepartmentDailyReviewUI";
import DailySiteReviewUI from "./FormsUI/DailySiteReviewUI";
import PerformanceAnalysisUI from "./FormsUI/PerformanceAnalysisUI";
import WeeklyDepartmentReviewUI from "./FormsUI/WeeklyDepartmentReviewUI";
import WeeklySiteReviewUI from "./FormsUI/WeeklySiteReviewUI";
import ManagementReportsUI from "./FormsUI/ManagementReportsUI";
import MonthlyReviewUI from "./FormsUI/MonthlyReviewUI";
import QuarterlyForecastReviewUI from "./FormsUI/QuarterlyForecastReviewUI";
import ActionLogUI from "./FormsUI/ActionLogUI";
import BusinessImprovementCapitalProjectsUI from "./FormsUI/BusinessImprovementCapitalProjectsUI";
import { fetchAllPaginatedResults } from "./utils/pagination";
function Forms({ user }) {
  const [statusArr, setStatusArr] = useState({
    preStart: "current",
    shiftHandover: "current",
    preShiftAlignment: "current",
    quarterlyMonthlyPlan: "complete",
    monthlyPlanningMeeting: "current",
    weeklyPlanSchedule: "current",
    weeklyPrePlanning: "current",
    weeklySitePlanningMeeting: "current",
    weeklyCommitmentMeeting: "current",
    dailyCommitmentMeeting: "current",
    shiftPlan: "current",
    shortIntervalControl: "current",
    shiftReport: "current",
    shiftDebrief: "current",
    performanceReports: "current",
    departmentDailyReview: "current",
    dailySiteReview: "current",
    performanceAnalysis: "current",
    weeklyDepartmentReview: "current",
    weeklySiteReview: "current",
    managementReports: "current",
    monthlyReview: "current",
    quarterlyForecastReview: "current",
    actionLog: "current",
    businessImprovementCapitalProjects: "current",
  });
  const [selectedForm, setSelectedForm] = useState("quarterlyMonthlyPlan");
  const [preStartWeights, setPreStartWeights] = useState(null);
  const [shiftHandoverWeights, setShiftHandoverWeights] = useState(null);
  const [preShiftAlignmentWeights, setPreShiftAlignmentWeights] =
    useState(null);
  const [quarterlyMonthlyPlanWeights, setQuarterlyMonthlyPlanWeights] =
    useState(null);
  const [monthlyPlanningMeetingWeights, setMonthlyPlanningMeetingWeights] =
    useState(null);
  const [weeklyPlanScheduleWeights, setWeeklyPlanScheduleWeights] =
    useState(null);
  const [weeklyPrePlanningWeights, setWeeklyPrePlanningWeights] =
    useState(null);
  const [
    weeklySitePlanningMeetingWeights,
    setWeeklySitePlanningMeetingWeights,
  ] = useState(null);
  const [weeklyCommitmentMeetingWeights, setWeeklyCommitmentMeetingWeights] =
    useState(null);
  const [dailyCommitmentMeetingWeights, setDailyCommitmentMeetingWeights] =
    useState(null);
  const [shiftplanWeights, setShiftplanWeights] = useState(null);
  const [shortIntervalControlWeights, setShortIntervalControlWeights] =
    useState(null);
  const [shiftReportWeights, setShiftReportWeights] = useState(null);
  const [shiftDebriefWeights, setShiftDebriefWeights] = useState(null);
  const [performanceReportsWeights, setPerformanceReportsWeights] =
    useState(null);
  const [departmentDailyReviewWeights, setDepartmentDailyReviewWeights] =
    useState(null);
  const [dailySiteReviewWeights, setDailySiteReviewWeights] = useState(null);
  const [performanceAnalysisWeights, setPerformanceAnalysisWeights] =
    useState(null);
  const [weeklyDepartmentReviewWeights, setWeeklyDepartmentReviewWeights] =
    useState(null);
  const [weeklySiteReviewWeights, setWeeklySiteReviewWeights] = useState(null);
  const [managementReportsWeights, setManagementReportsWeights] =
    useState(null);
  const [monthlyReviewWeights, setMonthlyReviewWeights] = useState(null);
  const [quarterlyForecastReviewWeights, setQuarterlyForecastReviewWeights] =
    useState(null);
  const [actionLogWeights, setActionLogWeights] = useState(null);
  const [
    businessImprovementCapitalProjectsWeights,
    setBusinessImprovementCapitalProjectsWeights,
  ] = useState(null);
  const [preStartQuestionsText, setPreStartQuestionsText] = useState({});
  const [shiftHandoverQuestionsText, setShiftHandoverQuestionsText] = useState(
    {},
  );
  const [preShiftAlignmentQuestionsText, setPreShiftAlignmentQuestionsText] =
    useState({});
  const [
    quarterlyMonthlyPlanQuestionsText,
    setQuarterlyMonthlyPlanQuestionsText,
  ] = useState({});
  const [
    monthlyPlanningMeetingQuestionsText,
    setMonthlyPlanningMeetingQuestionsText,
  ] = useState({});
  const [weeklyPlanScheduleQuestionsText, setWeeklyPlanScheduleQuestionsText] =
    useState({});
  const [weeklyPrePlanningQuestionsText, setWeeklyPrePlanningQuestionsText] =
    useState({});
  const [
    weeklySitePlanningMeetingQuestionsText,
    setWeeklySitePlanningMeetingQuestionsText,
  ] = useState({});
  const [
    weeklyCommitmentMeetingQuestionsText,
    setWeeklyCommitmentMeetingQuestionsText,
  ] = useState({});
  const [
    dailyCommitmentMeetingQuestionsText,
    setDailyCommitmentMeetingQuestionsText,
  ] = useState({});
  const [shiftplanQuestionsText, setShiftplanQuestionsText] = useState({});
  const [
    shortIntervalControlQuestionsText,
    setShortIntervalControlQuestionsText,
  ] = useState({});
  const [shiftReportQuestionsText, setShiftReportQuestionsText] = useState({});
  const [shiftDebriefQuestionsText, setShiftDebriefQuestionsText] = useState(
    {},
  );
  const [performanceReportsQuestionsText, setPerformanceReportsQuestionsText] =
    useState({});
  const [
    departmentDailyReviewQuestionsText,
    setDepartmentDailyReviewQuestionsText,
  ] = useState({});
  const [dailySiteReviewQuestionsText, setDailySiteReviewQuestionsText] =
    useState({});
  const [
    performanceAnalysisQuestionsText,
    setPerformanceAnalysisQuestionsText,
  ] = useState({});
  const [
    weeklyDepartmentReviewQuestionsText,
    setWeeklyDepartmentReviewQuestionsText,
  ] = useState({});
  const [weeklySiteReviewQuestionsText, setWeeklySiteReviewQuestionsText] =
    useState({});
  const [managementReportsQuestionsText, setManagementReportsQuestionsText] =
    useState({});
  const [monthlyReviewQuestionsText, setMonthlyReviewQuestionsText] = useState(
    {},
  );
  const [
    quarterlyForecastReviewQuestionsText,
    setQuarterlyForecastReviewQuestionsText,
  ] = useState({});
  const [actionLogQuestionsText, setActionLogQuestionsText] = useState({});
  const [
    businessImprovementCapitalProjectsQuestionsText,
    setBusinessImprovementCapitalProjectsQuestionsText,
  ] = useState({});
  const [operations, setOperations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [coachees, setCoachees] = useState([]);
  const [crews, setCrews] = useState([]);
  const [selectedCoachee, setSelectedCoachee] = useState(coachees[0]);
  const [viewState, setViewState] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      const operationsPromise = fetchAllPaginatedResults(listOperationNames);
      const coacheesPromise = fetchAllPaginatedResults(listCoachNames);
      const departmentsPromise = fetchAllPaginatedResults(listDepartmentNames);
      const crewsPromise = fetchAllPaginatedResults(listCrewNames);
      const InfoPromise = API.graphql({
        query: getAllForms,
        variables: { id: "aa703c84-de86-4898-bcb3-0d7bd68f0886" },
      });
      const [
        operationsResponse,
        coacheesResponse,
        departmentsResponse,
        crewsRepsonse,
        InfoResponse,
      ] = await Promise.all([
        operationsPromise,
        coacheesPromise,
        departmentsPromise,
        crewsPromise,
        InfoPromise,
      ]);
      setQuarterlyMonthlyPlanWeights(
        InfoResponse.data.getAllForms.Field0.weights,
      );
      setMonthlyPlanningMeetingWeights(
        InfoResponse.data.getAllForms.Field1.weights,
      );
      setWeeklyPlanScheduleWeights(
        InfoResponse.data.getAllForms.Field2.weights,
      );
      setWeeklyPrePlanningWeights(InfoResponse.data.getAllForms.Field3.weights);
      setWeeklySitePlanningMeetingWeights(
        InfoResponse.data.getAllForms.Field4.weights,
      );
      setWeeklyCommitmentMeetingWeights(
        InfoResponse.data.getAllForms.Field5.weights,
      );
      setDailyCommitmentMeetingWeights(
        InfoResponse.data.getAllForms.Field6.weights,
      );
      setShiftplanWeights(InfoResponse.data.getAllForms.Field7.weights);
      setShiftHandoverWeights(InfoResponse.data.getAllForms.Field8.weights);
      setPreStartWeights(InfoResponse.data.getAllForms.Field9.weights);
      setShortIntervalControlWeights(
        InfoResponse.data.getAllForms.Field10.weights,
      );
      setShiftReportWeights(InfoResponse.data.getAllForms.Field11.weights);
      setShiftDebriefWeights(InfoResponse.data.getAllForms.Field12.weights);
      setPerformanceReportsWeights(
        InfoResponse.data.getAllForms.Field13.weights,
      );
      setDepartmentDailyReviewWeights(
        InfoResponse.data.getAllForms.Field14.weights,
      );
      setDailySiteReviewWeights(InfoResponse.data.getAllForms.Field15.weights);
      setPerformanceAnalysisWeights(
        InfoResponse.data.getAllForms.Field16.weights,
      );
      setWeeklyDepartmentReviewWeights(
        InfoResponse.data.getAllForms.Field17.weights,
      );
      setWeeklySiteReviewWeights(InfoResponse.data.getAllForms.Field18.weights);
      setManagementReportsWeights(
        InfoResponse.data.getAllForms.Field19.weights,
      );
      setMonthlyReviewWeights(InfoResponse.data.getAllForms.Field20.weights);
      setQuarterlyForecastReviewWeights(
        InfoResponse.data.getAllForms.Field21.weights,
      );
      setActionLogWeights(InfoResponse.data.getAllForms.Field22.weights);
      setBusinessImprovementCapitalProjectsWeights(
        InfoResponse.data.getAllForms.Field23.weights,
      );
      setPreShiftAlignmentWeights(
        InfoResponse.data.getAllForms.Field24.weights,
      );

      setQuarterlyMonthlyPlanQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field0.questionsText),
      );
      setMonthlyPlanningMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field1.questionsText),
      );
      setWeeklyPlanScheduleQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field2.questionsText),
      );
      setWeeklyPrePlanningQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field3.questionsText),
      );
      setWeeklySitePlanningMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field4.questionsText),
      );
      setWeeklyCommitmentMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field5.questionsText),
      );
      setDailyCommitmentMeetingQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field6.questionsText),
      );
      setShiftplanQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field7.questionsText),
      );
      setShiftHandoverQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field8.questionsText),
      );
      setPreStartQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field9.questionsText),
      );
      setShortIntervalControlQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field10.questionsText),
      );
      setShiftReportQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field11.questionsText),
      );
      setShiftDebriefQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field12.questionsText),
      );
      setPerformanceReportsQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field13.questionsText),
      );
      setDepartmentDailyReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field14.questionsText),
      );
      setDailySiteReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field15.questionsText),
      );
      setPerformanceAnalysisQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field16.questionsText),
      );
      setWeeklyDepartmentReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field17.questionsText),
      );
      setWeeklySiteReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field18.questionsText),
      );
      setManagementReportsQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field19.questionsText),
      );
      setMonthlyReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field20.questionsText),
      );
      setQuarterlyForecastReviewQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field21.questionsText),
      );
      setActionLogQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field22.questionsText),
      );
      setBusinessImprovementCapitalProjectsQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field23.questionsText),
      );
      setPreShiftAlignmentQuestionsText(
        JSON.parse(InfoResponse.data.getAllForms.Field24.questionsText),
      );
      setDepartments(departmentsResponse);
      setOperations(operationsResponse);
      setCoachees(coacheesResponse);
      setCrews(crewsRepsonse);
    };
    fetchData();
  }, [viewState]);

  useEffect(() => {
    // Set selectedForm based on URL path
    const path = location.pathname.replace("/forms/", "");
    const validPaths = [
      "shiftHandover",
      "preStart",
      "preShiftAlignment",
      "quarterlyMonthlyPlan",
      "monthlyPlanningMeeting",
      "weeklyPlanSchedule",
      "weeklyPrePlanning",
      "weeklySitePlanningMeeting",
      "weeklyCommitmentMeeting",
      "dailyCommitmentMeeting",
      "shiftPlan",
      "shortIntervalControl",
      "shiftReport",
      "shiftDebrief",
      "performanceReports",
      "departmentDailyReview",
      "dailySiteReview",
      "performanceAnalysis",
      "weeklyDepartmentReview",
      "weeklySiteReview",
      "managementReports",
      "monthlyReview",
      "quarterlyForecastReview",
      "actionLog",
      "businessImprovementCapitalProjects",
    ];
    if (validPaths.includes(path)) {
      setSelectedForm(path);
      const updatedStatus = Object.keys(statusArr).reduce((acc, key) => {
        acc[key] = key === path ? "complete" : "current";
        if (path !== "quarterlyMonthlyPlan" && key === "quarterlyMonthlyPlan") {
          acc[key] = "current";
        }
        return acc;
      }, {});
      setStatusArr(updatedStatus);
    } else {
      handleFormSelection("quarterlyMonthlyPlan");
      navigate("/forms/quarterlyMonthlyPlan", { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleFormSelection = (slug) => {
    setSelectedForm(slug);
    const updatedStatus = Object.keys(statusArr).reduce((acc, key) => {
      acc[key] = key === slug ? "complete" : "current";
      return acc;
    }, {});
    setStatusArr(updatedStatus);
    navigate(`/forms/${slug}`);
  };

  const [staticTextExpanded, setStaticTextExpanded] = useState(false);

  const toggleStaticText = () => {
    setStaticTextExpanded((prev) => !prev);
  };

  const staticText = `Operational teams perform better when the MO@T elements below are used throughout a site.`;

  const truncateText = (text, maxLength = 200) => {
    if (text.length <= maxLength) return text;
    const truncated = text
      .slice(0, maxLength)
      .split(" ")
      .slice(0, -1)
      .join(" ");
    return truncated + "...";
  };

  return (
    <div className="scroll-smooth pb-10">
      <h2 className="sm:text-6xl text-4xl mx-auto  mb-5 text-black break-words">
        MO@T Elements
      </h2>
      <p className="text-black sm:text-base text-sm mx-auto my-5 text-justify">
        {staticTextExpanded ? (
          <>
            <p>
              <b>
                Operational teams perform better when the MO@T elements below
                are used throughout a site.
              </b>
            </p>
            <p>
              These elements help because they ensure the right inputs and
              conversations are had so that plans align everyday work with
              reality and the expectations of leaders. Having a good plan then
              makes it possible for the team to compare their results with the
              plan and systematically learn from their successes and failures.
            </p>
            <p>
              <b>
                Click on the elements below to understand more about them, see
                what good looks like and complete an assessment or coaching
                session.
              </b>
            </p>
          </>
        ) : (
          <p>
            <b>{truncateText(staticText, 200)}</b>
          </p>
        )}
        <button
          onClick={toggleStaticText}
          className="text-thiess-blue-600 hover:underline mt-2 sm:text-base text-sm"
        >
          {staticTextExpanded ? "Read less" : "Read more"}
        </button>
      </p>
      <Panel statusArr={statusArr} onFormSelect={handleFormSelection} />
      <div className="scroll-smooth py-10">
        <div>
          {selectedForm === "shiftHandover" && (
            <ShiftHandoverUI
              user={user}
              formName="shiftHandover"
              questionsText={shiftHandoverQuestionsText}
              weights={shiftHandoverWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "preStart" && (
            <PreStartUI
              user={user}
              formName="preStart"
              questionsText={preStartQuestionsText}
              weights={preStartWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "preShiftAlignment" && (
            <PreShiftAlignmentUI
              user={user}
              formName="preShiftAlignment"
              questionsText={preShiftAlignmentQuestionsText}
              weights={preShiftAlignmentWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "quarterlyMonthlyPlan" && (
            <QuarterlyMonthlyPlanUI
              user={user}
              formName="quarterlyMonthlyPlan"
              questionsText={quarterlyMonthlyPlanQuestionsText}
              weights={quarterlyMonthlyPlanWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "monthlyPlanningMeeting" && (
            <MonthlyPlanningMeetingUI
              user={user}
              formName="monthlyPlanningMeeting"
              questionsText={monthlyPlanningMeetingQuestionsText}
              weights={monthlyPlanningMeetingWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "weeklyPlanSchedule" && (
            <WeeklyPlanScheduleUI
              user={user}
              formName="weeklyPlanSchedule"
              questionsText={weeklyPlanScheduleQuestionsText}
              weights={weeklyPlanScheduleWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "weeklyPrePlanning" && (
            <WeeklyPrePlanningUI
              user={user}
              formName="weeklyPrePlanning"
              questionsText={weeklyPrePlanningQuestionsText}
              weights={weeklyPrePlanningWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "weeklySitePlanningMeeting" && (
            <WeeklySitePlanningMeetingUI
              user={user}
              formName="weeklySitePlanningMeeting"
              questionsText={weeklySitePlanningMeetingQuestionsText}
              weights={weeklySitePlanningMeetingWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "weeklyCommitmentMeeting" && (
            <WeeklyCommitmentMeetingUI
              user={user}
              formName="weeklyCommitmentMeeting"
              questionsText={weeklyCommitmentMeetingQuestionsText}
              weights={weeklyCommitmentMeetingWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "dailyCommitmentMeeting" && (
            <DailyCommitmentMeetingUI
              user={user}
              formName="dailyCommitmentMeeting"
              questionsText={dailyCommitmentMeetingQuestionsText}
              weights={dailyCommitmentMeetingWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "shiftPlan" && (
            <ShiftplanUI
              user={user}
              formName="shiftPlan"
              questionsText={shiftplanQuestionsText}
              weights={shiftplanWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "shortIntervalControl" && (
            <ShortIntervalControlUI
              user={user}
              formName="shortIntervalControl"
              questionsText={shortIntervalControlQuestionsText}
              weights={shortIntervalControlWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "shiftReport" && (
            <ShiftReportUI
              user={user}
              formName="shiftReport"
              questionsText={shiftReportQuestionsText}
              weights={shiftReportWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "shiftDebrief" && (
            <ShiftDebriefUI
              user={user}
              formName="shiftDebrief"
              questionsText={shiftDebriefQuestionsText}
              weights={shiftDebriefWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "performanceReports" && (
            <PerformanceReportsUI
              user={user}
              formName="performanceReports"
              questionsText={performanceReportsQuestionsText}
              weights={performanceReportsWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "departmentDailyReview" && (
            <DepartmentDailyReviewUI
              user={user}
              formName="departmentDailyReview"
              questionsText={departmentDailyReviewQuestionsText}
              weights={departmentDailyReviewWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "dailySiteReview" && (
            <DailySiteReviewUI
              user={user}
              formName="dailySiteReview"
              questionsText={dailySiteReviewQuestionsText}
              weights={dailySiteReviewWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "performanceAnalysis" && (
            <PerformanceAnalysisUI
              user={user}
              formName="performanceAnalysis"
              questionsText={performanceAnalysisQuestionsText}
              weights={performanceAnalysisWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "weeklyDepartmentReview" && (
            <WeeklyDepartmentReviewUI
              user={user}
              formName="weeklyDepartmentReview"
              questionsText={weeklyDepartmentReviewQuestionsText}
              weights={weeklyDepartmentReviewWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "weeklySiteReview" && (
            <WeeklySiteReviewUI
              user={user}
              formName="weeklySiteReview"
              questionsText={weeklySiteReviewQuestionsText}
              weights={weeklySiteReviewWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "managementReports" && (
            <ManagementReportsUI
              user={user}
              formName="managementReports"
              questionsText={managementReportsQuestionsText}
              weights={managementReportsWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "monthlyReview" && (
            <MonthlyReviewUI
              user={user}
              formName="monthlyReview"
              questionsText={monthlyReviewQuestionsText}
              weights={monthlyReviewWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "quarterlyForecastReview" && (
            <QuarterlyForecastReviewUI
              user={user}
              formName="quarterlyForecastReview"
              questionsText={quarterlyForecastReviewQuestionsText}
              weights={quarterlyForecastReviewWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "actionLog" && (
            <ActionLogUI
              user={user}
              formName="actionLog"
              questionsText={actionLogQuestionsText}
              weights={actionLogWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
          {selectedForm === "businessImprovementCapitalProjects" && (
            <BusinessImprovementCapitalProjectsUI
              user={user}
              formName="businessImprovementCapitalProjects"
              questionsText={businessImprovementCapitalProjectsQuestionsText}
              weights={businessImprovementCapitalProjectsWeights}
              viewState={viewState}
              setViewState={setViewState}
              coachees={coachees}
              selectedCoachee={selectedCoachee}
              setSelectedCoachee={setSelectedCoachee}
              operations={operations}
              departments={departments}
              crews={crews}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Forms;

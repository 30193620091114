import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./index.css";
import { API, Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { listCoachNames } from "./graphql/queries";
import Manage from "./Manage";
import "./styles.css";
import { Sidebar, Navbar } from "./tailwind-ui-components";
import { Loader } from "@aws-amplify/ui-react";
import ProfileUpdate from "./ProfileUpdate";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { Storage } from "aws-amplify";
import CryptoJS from "crypto-js";
import Home from "./Home";
import { Toaster } from "react-hot-toast";
import Moskit from "./Moskit";
import Forms from "./Forms";
import ActionLogsBoard from "./ActionLogs/ActionLogsBoardPage/ActionLogsBoard.page";
import BoardsDashboardPage from "./ActionLogs/BoardsDashboardPage/BoardsDashboard.page";
import Reports from "./Reports";
import Login from "./Login";
import Chat from "./Chat";
import ChatPopup from "./tailwind-ui-components/ChatPopup";
import ForgotPassword from "./ForgotPassword";
import { fetchAllPaginatedResults } from "./utils/pagination";
Amplify.configure(config);
dayjs.extend(localizedFormat);

const ProtectedRoutes = ({
  user,
  combinedUser,
  setCombinedUser,
  signOut,
  sidebarOpen,
  setSidebarOpen,
  profilePictureURL,
}) => {
  const location = useLocation();

  if (combinedUser?.deleted) {
    return (
      <div className="flex items-center justify-center h-screen text-center">
        Account Deleted, Ask your admin to restore your account!
      </div>
    );
  }

  return (
    <>
      <ChatPopup user={combinedUser} location={location.pathname} />
      <Toaster />
      <div className="bg-black">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          signOut={signOut}
          combinedUser={combinedUser}
          profilePictureURL={profilePictureURL}
        />
        <Navbar
          setSidebarOpen={setSidebarOpen}
          signOut={signOut}
          profilePictureURL={profilePictureURL}
        />
        <main className="lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8 bg-gray-50 rounded-t-3xl pt-14">
            <Routes>
              <Route path="/forms" element={<Forms user={combinedUser} />} />
              <Route
                path="/forms/:slug"
                element={<Forms user={combinedUser} />}
              />
              <Route
                path="/reports"
                element={<Reports user={combinedUser} />}
              />
              <Route
                path="/reports/:slug"
                element={<Reports user={combinedUser} />}
              />
              <Route path="/manage" element={<Manage user={combinedUser} />} />
              <Route
                path="/manage/:slug"
                element={<Manage user={combinedUser} />}
              />
              <Route
                path="/profile"
                element={
                  <ProfileUpdate
                    combinedUser={combinedUser}
                    setCombinedUser={setCombinedUser}
                  />
                }
              />
              <Route path="/mos" element={<Moskit />} />
              <Route
                path="/action-jackson"
                element={<BoardsDashboardPage user={combinedUser} />}
              />
              <Route
                path="/action-jackson/boards/:boardId"
                element={<ActionLogsBoard user={combinedUser} />}
              />
              <Route path="/" element={<Home />} />
              <Route
                path="/answer-fox"
                element={<Chat user={combinedUser} />}
              />
            </Routes>
          </div>
        </main>
      </div>
    </>
  );
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [coachUser, setCoachUser] = useState(null);
  const [userHasInfo, setUserHasInfo] = useState(false);
  const [combinedUser, setCombinedUser] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);

  useEffect(() => {
    const checkUser = async () => {
      setLoading(true);
      try {
        const authUser = await Auth.currentAuthenticatedUser();
        setUser(authUser);
        await getCoachNameByUserId(authUser.username);
      } catch (error) {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    checkUser();
  }, []);

  const getCoachNameByUserId = async (userId) => {
    try {
      const result = await fetchAllPaginatedResults(listCoachNames, {
        filter: { userId: { eq: userId } },
      });
      if (result.length > 0) {
        setCoachUser(result[0]);
        setUserHasInfo(true);
      } else {
        setCoachUser(null);
        setUserHasInfo(false);
      }
    } catch (error) {
      console.error("Error fetching coach name: ", error);
      setUserHasInfo(false);
    }
  };

  useEffect(() => {
    if (user) {
      const userInfo = user.attributes;
      userInfo.username = user.username;
      const combinedUserDetails = { ...userInfo, ...coachUser, userHasInfo };
      setCombinedUser(combinedUserDetails);
    }
  }, [userHasInfo, coachUser, user]);

  useEffect(() => {
    const email = combinedUser
      ? combinedUser?.email
      : user
        ? user?.attributes?.email
        : null;
    const hash = email
      ? CryptoJS.MD5(email.trim().toLowerCase()).toString()
      : "00000000000000000000000000000000";
    const defaultProfilePictureURL = `https://www.gravatar.com/avatar/${hash}?d=identicon`;

    const fetchProfilePicture = async () => {
      try {
        const url = await Storage.get(`${combinedUser.id}/profilepicture`, {
          level: "public",
        });
        const response = await fetch(url);
        if (response.ok) {
          setProfilePictureURL(url);
        } else {
          setProfilePictureURL(defaultProfilePictureURL);
        }
      } catch (error) {
        setProfilePictureURL(defaultProfilePictureURL);
      }
    };

    fetchProfilePicture();
  }, [combinedUser]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen text-center">
        <Loader className="w-56 h-56" />
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login setUser={setUser} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="*"
          element={
            user ? (
              <ProtectedRoutes
                user={user}
                combinedUser={combinedUser}
                setCombinedUser={setCombinedUser}
                signOut={() => {
                  Auth.signOut();
                  setUser(null);
                }}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                profilePictureURL={profilePictureURL}
              />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;

import * as d3 from "d3";

export default function BarChartHierarchy(target, isLarge = true) {
  const BarChartHierarchy = {};
  let breadcrumbStack = [];
  let margin;
  isLarge
    ? (margin = { top: 30, right: 30, bottom: 10, left: 300 })
    : (margin = { top: 40, right: 25, bottom: 5, left: 200 });

  let width;
  isLarge ? (width = 800) : (width = 600);

  let height = 0;
  const tooltip = d3.select(".tooltip");
  function transformValueToString(value) {
    switch (value) {
      case 0:
        return "Nothing";
      case 1:
        return "Poor";
      case 2:
        return "OK";
      case 3:
        return "Good";
      default:
        return "Nothing";
    }
  }
  function countDeepestChildrenComments(node) {
    if (!node.children || node.children.length === 0) {
      if (node.data.comment !== "No Comment") {
        return 1;
      } else {
        return 0;
      }
    }
    return node.children.reduce(
      (total, child) => total + countDeepestChildrenComments(child),
      0,
    );
  }
  function countDeepestChildren(node) {
    if (!node.children || node.children.length === 0) {
      return 1;
    }
    return node.children.reduce(
      (total, child) => total + countDeepestChildren(child),
      0,
    );
  }

  function showTooltip(event, d) {
    tooltip
      .style("opacity", 0.95)
      .style("font", "15px Arial, sans-serif")
      .html(
        `${
          event.children
            ? `Current Bar: ${event.data.name} <br/> Parent Bar: ${event.parent.data.name} <br/> Value: ${event.value} ${event.data.weight === undefined ? "" : `<br/>Weight: ${event.data.weight}`}<br/> Click to view answers, you have ${countDeepestChildrenComments(event)} comments & ${countDeepestChildren(event)} answers`
            : event.parent
              ? `Question: ${event.parent.parent.data.name} <br/> Name: ${event.data.name}<br/>Review: ${transformValueToString(event.value)} <br/> Comment: ${event.data.comment}`
              : ""
        }`,
      );
    d3.select(this).on("mousemove", moveTooltip);
  }

  function moveTooltip(event) {
    tooltip
      .style("left", d3.event.pageX + 5 + "px")
      .style("top", d3.event.pageY + 5 + "px");
  }

  function hideTooltip() {
    tooltip.style("opacity", 0);
    d3.select(this).on("mousemove", null); // remove the mousemove event listener
  }

  function colorBasedOnValue(value) {
    if (value >= 0 && value < 0.5) {
      return "red";
    } else if (value >= 0.5 && value < 1.5) {
      return "orange";
    } else if (value >= 1.5 && value <= 2.5) {
      return "yellow";
    } else if (value >= 2.5 && value <= 3) {
      return "green";
    } else {
      return "white"; // For 'Missing' or any unrecognized num
    }
  }
  function valueRange(value) {
    if (value === 0) {
      return "(Nothing)";
    } else if (value > 0 && value <= 1) {
      return "(Poor)";
    } else if (value > 1 && value <= 2) {
      return "(OK)";
    } else if (value > 2 && value <= 3) {
      return "(Good)";
    } else {
      return "(Nothing)";
    }
  }

  const x = d3.scaleLinear().range([margin.left, width - margin.right]);
  const xAxis = (g) =>
    g
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${margin.top})`)
      .style(
        "font",
        isLarge ? "15px Arial, sans-serif" : "13px Arial, sans-serif",
      )
      .call(
        d3
          .axisTop(x)
          .ticks(4)
          .tickFormat(function (d) {
            return d + " " + valueRange(d);
          }),
      )
      .call((g) => (g.selection ? g.selection() : g).select(".domain").remove())
      .selectAll(".tick") // select all ticks
      .each(function () {
        // for each tick
        d3.select(this) // select the current tick
          .append("line") // append a line to the current tick
          .attr("stroke", "currentColor")
          .attr("stroke-dasharray", "2,2") // make the line dashed
          .attr("y2", height - margin.top); // set the y2 attribute to extend the line to the bottom of the graph
      });

  const yAxis = (g) =>
    g
      .attr("class", "y-axis")
      .attr("transform", `translate(${margin.left + 0.5},0)`)
      .call((g) =>
        g
          .append("line")
          .attr("stroke", "currentColor")
          .attr("y1", margin.top)
          .attr("y2", height - margin.bottom),
      );

  let barStep;
  isLarge ? (barStep = 60) : (barStep = 40);
  let barPadding;
  isLarge ? (barPadding = 15 / barStep) : (barPadding = 10 / barStep);
  const duration = 750;

  // Create a breadcrumbs style object
  let breadstyles = {
    padding: "0px 5px",
    margin: "0px 5px",
    backgroundColor: "#f9f9f9",
    color: "blue",
    fontFamily: "Arial, sans-serif",
    fontSize: isLarge ? "25px" : "17px",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const breadcrumbDisplay = (d) => {
    let labelstring = "";
    let breadcrumbs = [];

    // Add the current node's name to the breadcrumbs
    let currentName = d.data.name.replace(/[^\w\s]/gi, "");
    breadcrumbs.unshift(
      currentName.length > 10
        ? currentName.substring(0, 10) + "..."
        : currentName,
    );

    // If the current node has a parent, add the parent's name to the breadcrumbs
    if (d.parent && d.parent.data) {
      let parentName = d.parent.data.name.replace(/[^\w\s]/gi, "");
      breadcrumbs.unshift(
        parentName.length > 10
          ? parentName.substring(0, 10) + "..."
          : parentName,
      );
    }

    // If the parent node has a parent (i.e., the root), add the root's name to the breadcrumbs
    if (d.parent && d.parent.parent && d.parent.parent.data) {
      let rootName = d.parent.parent.data.name.replace(/[^\w\s]/gi, "");
      breadcrumbs.unshift(
        rootName.length > 10 ? rootName.substring(0, 10) + "..." : rootName,
      );
    }

    // Join the breadcrumbs with " -> "
    labelstring = breadcrumbs.join(" / ");

    return labelstring;
  };

  BarChartHierarchy.update = (data) => {
    // Create a new breadcrumb container for this instance
    let breadcrumbContainer = document.createElement("div");
    breadcrumbContainer.id = "breadcrumbs-" + data.id; // Assuming each data item has a unique id
    document.getElementById("barchart").appendChild(breadcrumbContainer);

    // Apply the styles to the breadcrumb container
    for (let style in breadstyles) {
      breadcrumbContainer.style[style] = breadstyles[style];
    }
    let root = d3
      .hierarchy(data)
      .sum((d) => d.value)
      .eachAfter(function (node) {
        if (node.children) {
          let sum = 0;
          let count = 0;

          function recurse(node) {
            if (node.children) {
              node.children.forEach(recurse);
            } else {
              sum += node.value;
              count++;
            }
          }

          recurse(node);
          node.value = Number((sum / count).toFixed(1));
        }
      })
      .each(function (node) {
        if (node.parent) {
          node.children && node.children.sort((a, b) => a.value - b.value);
        }
      });

    let max = 1;
    root.each((d) => d.children && (max = Math.max(max, d.children.length)));
    height = max * barStep + margin.top + margin.bottom;
    // Generate breadcrumbs for each bar
    root.each(function (d) {
      const breadcrumb = document.createElement("div");
      breadcrumb.id = "breadcrumb-" + d.data.id; // Assuming each data item has a unique id
      breadcrumb.textContent = breadcrumbDisplay(d); // Use the existing breadcrumbDisplay function to generate the breadcrumb text
      breadcrumbContainer.appendChild(breadcrumb);
    });

    // const color = d3.scaleOrdinal( [true, false], ["orange", "#aaa"]);

    const svg = d3
      .select(target)
      .selectAll("svg")
      .data(["John"])
      .join("svg")
      .attr("width", width)
      .attr("height", height);

    x.domain([0, 3.4]);
    let totalValue = 0;
    let numChildren = 0;

    root.children.forEach((child) => {
      totalValue += child.value;
      numChildren++;
    });

    // Calculate the mean value for the children of the current node
    let meanValue = totalValue / numChildren;

    // Append a line at the mean value
    svg
      .append("line")
      .attr("x1", x(meanValue)) // position the line at the mean value on the x-axis
      .attr("x2", x(meanValue)) // position the line at the mean value on the x-axis
      .attr("y1", isLarge ? margin.top + 30 : margin.top + 15) // start the line at the top of the chart
      .attr("y2", height) // end the line at the bottom of the chart
      .attr("stroke", "#000") // color the line red
      .attr("stroke-dasharray", "15,15"); // make the line dashed

    // Add a label for the average line
    svg
      .append("text")
      .attr("x", x(meanValue))
      .attr("y", isLarge ? margin.top + 15 : margin.top + 8) // position the label slightly above the line
      .attr("fill", "#000")
      .attr("font-weight", "bold")
      .attr("text-anchor", "middle")
      .text(`Score: ${meanValue.toFixed(1)}`);
    svg
      .append("rect")
      .attr("class", "background")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .attr("width", width)
      .attr("height", height)
      .attr("cursor", (d) => (!d.children ? "zoom-out" : null))
      .on("click", (d) => up(svg, d));

    svg
      .selectAll("g.x-axis")
      .data((d) => d)
      .join("g")
      .call(xAxis);

    svg
      .selectAll("g.y-axis")
      .data((d) => d)
      .join("g")
      .call(yAxis);

    down(svg, root);

    // Creates a set of bars for the given data node, at the specified index.
    function bar(svg, down, d, selector) {
      const g = svg
        .insert("g", selector)
        .attr("class", "enter")
        .attr("transform", `translate(0,${margin.top + barStep * barPadding})`)
        .attr("text-anchor", "end")
        .style(
          "font",
          isLarge
            ? "bold 15px Arial, sans-serif"
            : "bold 12px Arial, sans-serif",
        );

      const bar = g
        .selectAll("g")
        .data(d.children)
        .join("g")
        .attr("cursor", (d) => (!d.children ? null : "zoom-in"))
        .on("click", (d) => down(svg, d));

      bar
        .append("foreignObject")
        .attr("x", 0)
        .attr("y", -5)
        .attr("width", margin.left)
        .attr("height", barStep)
        .append("xhtml:p")
        .attr(
          "style",
          "word-wrap: break-word; text-overflow: none; overflow: hidden; height: 2.4em; line-height: 1.2em;",
        )
        .text((d) => d.data.name);

      bar
        .append("rect")
        .attr("x", x(0))
        .attr("width", (d) => x(d.value) - x(0))
        .attr("height", barStep * (1 - barPadding))
        .attr("fill", (d) => colorBasedOnValue(d.value));

      bar
        .on("mouseover", function (event, d) {
          showTooltip.call(this, event, d);
        })
        .on("mouseout", function (event, d) {
          hideTooltip.call(this, event, d);
        });

      bar
        .append("rect")
        .attr("x", x(0))
        .attr("width", (d) => x(d.value) - x(0))
        .attr("height", barStep * (1 - barPadding))
        .attr("fill", (d) => colorBasedOnValue(d.value));

      bar
        .append("text")
        .attr("x", (d) => x(d.value) + 65)
        .attr("y", (barStep * (1 - barPadding)) / 2)
        .attr("dy", ".35em");
      return g;
    }

    function down(svg, d) {
      if (!d.children || d3.active(svg.node())) return;

      // Rebind the current node to the background.
      svg.select(".background").datum(d);

      // Define two sequenced transitions.
      const transition1 = svg.transition().duration(duration);
      const transition2 = transition1.transition();

      // Mark any currently-displayed bars as exiting.
      const exit = svg.selectAll(".enter").attr("class", "exit");

      // Entering nodes immediately obscure the clicked-on bar, so hide it.
      exit.selectAll("rect").attr("fill-opacity", (p) => (p === d ? 0 : null));

      // Transition exiting bars to fade out.
      exit.transition(transition1).attr("fill-opacity", 0).remove();

      // Enter the new bars for the clicked-on data.
      // Per above, entering bars are immediately visible.
      const enter = bar(svg, down, d, ".y-axis").attr("fill-opacity", 0);

      // Have the text fade-in, even though the bars are visible.
      enter.transition(transition1).attr("fill-opacity", 1);

      // Transition entering bars to their new y-position.
      enter
        .selectAll("g")
        .attr("transform", stack(d.index))
        .transition(transition1)
        .attr("transform", stagger());

      // Update the x-axis.
      svg.selectAll(".x-axis").transition(transition2).call(xAxis);

      // Transition entering bars to the new x-scale.
      enter
        .selectAll("g")
        .transition(transition2)
        .attr("transform", (d, i) => `translate(0,${barStep * i})`);

      // Color the bars as parents; they will fade to children if appropriate.
      enter
        .selectAll("rect")
        .attr("fill", colorBasedOnValue(d.value))
        .attr("fill-opacity", 1)
        .transition(transition2)
        .attr("fill", (d) => colorBasedOnValue(d.value))
        .attr("width", (d) => x(d.value) - x(0));

      breadcrumbStack.push(d);
      updateBreadcrumbs(d, svg);
    }

    function up(svg, d) {
      if (!d.parent || !svg.selectAll(".exit").empty()) return;

      // Rebind the current node to the background.
      svg.select(".background").datum(d.parent);

      // Define two sequenced transitions.
      const transition1 = svg.transition().duration(duration);
      const transition2 = transition1.transition();

      // Mark any currently-displayed bars as exiting.
      const exit = svg.selectAll(".enter").attr("class", "exit");

      // Update the x-axis.
      svg.selectAll(".x-axis").transition(transition1).call(xAxis);

      // Transition exiting bars to the new x-scale.
      exit.selectAll("g").transition(transition1).attr("transform", stagger());

      // Transition exiting bars to the parent’s position.
      exit
        .selectAll("g")
        .transition(transition2)
        .attr("transform", stack(d.index));

      // Transition exiting rects to the new scale and fade to parent color.
      exit
        .selectAll("rect")
        .transition(transition1)
        .attr("width", (d) => x(d.value) - x(0))
        .attr("fill", colorBasedOnValue(d.value));

      // Transition exiting text to fade out.
      // Remove exiting nodes.
      exit.transition(transition2).attr("fill-opacity", 0).remove();

      // Enter the new bars for the clicked-on data's parent.
      const enter = bar(svg, down, d.parent, ".exit").attr("fill-opacity", 0);

      enter
        .selectAll("g")
        .attr("transform", (d, i) => `translate(0,${barStep * i})`);

      // Transition entering bars to fade in over the full duration.
      enter.transition(transition2).attr("fill-opacity", 1);

      // Color the bars as appropriate.
      // Exiting nodes will obscure the parent bar, so hide it.
      // Transition entering rects to the new x-scale.
      // When the entering parent rect is done, make it visible!
      enter
        .selectAll("rect")
        .attr("fill", (d) => colorBasedOnValue(d.value))
        .attr("fill-opacity", (p) => (p === d ? 0 : null))
        .transition(transition2)
        .attr("width", (d) => x(d.value) - x(0))
        .on("end", function (p) {
          d3.select(this).attr("fill-opacity", 1);
        });

      breadcrumbStack.pop();
      // Update breadcrumbs based on the current top of the stack
      if (breadcrumbStack.length > 0) {
        updateBreadcrumbs(breadcrumbStack[breadcrumbStack.length - 1], svg);
      }
    }

    function stack(i) {
      let value = 0;
      return (d) => {
        let translateX = x(value) - x(0);
        let translateY = barStep * i;
        translateX = translateX.toFixed(1);
        translateY = translateY.toFixed(1);

        if (isNaN(translateX)) {
          translateX = 0;
        }

        if (isNaN(translateY)) {
          translateY = 0;
        }

        const t = `translate(${translateX},${translateY})`;
        value += d.value.toFixed(1);
        return t;
      };
    }

    function stagger() {
      let value = 0;
      return (d, i) => {
        let translateX = x(value) - x(0);
        let translateY = barStep * i;
        translateX = translateX.toFixed(1);
        translateY = translateY.toFixed(1);

        if (isNaN(translateX)) {
          translateX = 0;
        }

        if (isNaN(translateY)) {
          translateY = 0;
        }

        const t = `translate(${translateX},${translateY})`;
        value += d.value.toFixed(1);
        return t;
      };
    }

    function updateBreadcrumbs(d, svg) {
      // Clear existing breadcrumbs
      while (breadcrumbContainer.firstChild) {
        breadcrumbContainer.removeChild(breadcrumbContainer.firstChild);
      }

      // Create breadcrumb for the current node
      let breadcrumb = document.createElement("span");
      breadcrumb.textContent = breadcrumbDisplay(d);
      breadcrumb.addEventListener("click", () => {
        // Pop nodes from the stack until we reach the clicked node
        while (
          breadcrumbStack.length > 0 &&
          breadcrumbStack[breadcrumbStack.length - 1] !== d
        ) {
          breadcrumbStack.pop();
        }
        up(svg, d);
      });

      // Add the breadcrumb to the container
      breadcrumbContainer.appendChild(breadcrumb);
    }
  };

  return BarChartHierarchy;
}

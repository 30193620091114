import * as React from "react";
const SvgCustomGithub = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 457.27 445.14"
    width="1em"
    height="1em"
    className="svg-icon"
    {...props}
  >
    <path
      fill="currentColor"
      d="M457.27 228.63C457.27 102.36 354.9 0 228.63 0S0 102.36 0 228.63C0 328.85 64.49 414 154.24 444.87c5.47-3.95 11.77-8.82 11.77-17.02 0-12.57-.27-44.92-.27-44.92s-9.92 1.69-24.09 1.69c-35.69 0-46.97-22.65-52.34-35.32-6.99-16.51-16.05-24.26-25.68-30.46-5.91-3.81-7.27-8.29-.43-9.58 31.59-5.92 39.66 35.63 60.76 42.25 15.06 4.72 34.42 2.68 44.05-3.52 1.27-12.69 10.47-23.66 18.13-29.44-53.66-5.13-85.47-23.66-101.97-53.45l-1.77-3.34-4.15-9.46-1.22-3.39c-5.17-15.12-7.45-32.44-7.45-51.78 0-34.34 10.76-47.3 25.21-65.61-11.05-39.15 3.97-65.91 3.97-65.91s23.23-4.79 67.13 26.48c23.79-10.14 87.24-10.99 117.26-2.25 18.41-12.11 52.12-29.3 65.71-24.48 3.68 5.89 11.61 23.08 4.82 60.82 4.61 8.28 28.61 25.91 28.7 75.77-.39 18.39-2.31 33.91-5.88 47.04l-1.98 6.72s-1.13 3.17-2.37 6.19l-1.45 3.34c-15.92 34.77-48.57 47.75-101.33 53.15 17.1 10.7 22 24.13 22 60.47s-.49 41.22-.37 49.58c.1 7.39 6.01 12.7 11.32 16.72 90.13-30.66 154.97-116.01 154.97-216.5Z"
      className="CustomGithub_svg__cls-1"
    />
  </svg>
);
export default SvgCustomGithub;

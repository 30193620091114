import React, { useMemo, useState, useEffect } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
} from "../../fyr-theme/components/ui/OffCanvas";
import Badge from "../../fyr-theme/components/ui/Badge";
import Icon from "../../fyr-theme/components/icon/Icon";
import RichText from "../../fyr-theme/components/RichText";
import Avatar from "../../fyr-theme/components/Avatar";
import Button from "../../fyr-theme/components/ui/Button";
import SelectReact from "../../fyr-theme/components/form/SelectReact";
import FieldWrap from "../../fyr-theme/components/form/FieldWrap";
import Input from "../../fyr-theme/components/form/Input";
import Card, {
  CardBody,
  CardHeader,
  CardHeaderChild,
} from "../../fyr-theme/components/ui/Card";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  updateTask,
  deleteTask,
  createComment,
  updateComment,
  deleteComment,
} from "../../graphql/mutations";
import {
  listCoachNames,
  getTask,
  getCoachName,
  listComments,
} from "../../graphql/queries";
import toast from "react-hot-toast";
import CryptoJS from "crypto-js";
import { fetchAllPaginatedResults } from "../../utils/pagination";

const getGravatarUrl = (email) => {
  const hash = email
    ? CryptoJS.MD5(email.trim().toLowerCase()).toString()
    : "00000000000000000000000000000000";
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const getPlainTextFromSlateData = (data) => {
  if (typeof data === "string") {
    try {
      data = JSON.parse(data);
    } catch (e) {
      return "";
    }
  }

  if (!data || !Array.isArray(data)) return "";

  return data
    .map((node) => {
      if (node.text) return node.text;
      if (node.children) return getPlainTextFromSlateData(node.children);
      return "";
    })
    .join("");
};

const CommentText = ({ text, isEditing, onChange, onSave }) => {
  const [value, setValue] = useState(JSON.parse(text));

  if (isEditing) {
    return (
      <div>
        <RichText
          value={value}
          handleChange={(event) => {
            setValue(event);
            onChange(event);
          }}
          placeholder="Edit comment"
        />
        <Button
          color="emerald"
          variant="outline"
          onClick={() => onSave(JSON.stringify(value))}
          className="mt-2"
        >
          Save
        </Button>
      </div>
    );
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: JSON.parse(text)[0].children[0].text }}
    />
  );
};

const TaskEditPartial = (props) => {
  const { task, isOpen, setIsOpen, onTaskUpdated, onTaskDeleted, currentUser } =
    props;
  const [coaches, setCoaches] = useState([]);
  const [editDescStatus, setEditDescStatus] = useState(false);
  const [editResponsibleStatus, setEditResponsibleStatus] = useState(false);
  const [editLabelStatus, setEditLabelStatus] = useState(false);
  const [comments, setComments] = useState([]);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const initialValue = [{ type: "paragraph", children: [{ text: "" }] }];

  const statusOptions = [
    { label: "Backlog", value: "BACKLOG" },
    { label: "To Do", value: "TODO" },
    { label: "Pending", value: "PENDING" },
    { label: "Run", value: "RUN" },
    { label: "Done", value: "DONE" },
  ];
  const [coachDetails, setCoachDetails] = useState({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    status: Yup.object().required("Status is required"),
  });
  const [avatarUrls, setAvatarUrls] = useState({});

  const formik = useFormik({
    initialValues: {
      id: task.id,
      title: task.title,
      description: task.description
        ? JSON.parse(task.description)
        : initialValue,
      status: statusOptions.find((option) => option.value === task.status),
      label: task.label,
      assignedTo: task.assignedTo,
      creator: task.creator,
      newComment: initialValue,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const input = {
          id: values.id,
          title: values.title,
          description: JSON.stringify(values.description),
          status: values.status.value,
          label: values.label,
          assignedTo: values.assignedTo,
        };
        const result = await API.graphql(
          graphqlOperation(updateTask, { input }),
        );
        onTaskUpdated(result.data.updateTask);
        toast.success("Task updated successfully");
      } catch (error) {
        console.error("Error updating task:", error);
        toast.error("Failed to update task");
      }
    },
  });

  useEffect(() => {
    fetchCoaches();
    fetchTaskDetails();
  }, [task.id]);

  useEffect(() => {
    coaches.forEach((coach) => fetchAndUpdateAvatarUrl(coach.id));
  }, [coaches]);

  const fetchCoaches = async () => {
    try {
      const coachData = await fetchAllPaginatedResults(listCoachNames);
      const coachItems = coachData;
      const coachDetailsMap = {};
      coachItems.forEach((coach) => {
        coachDetailsMap[coach.id] = {
          name: coach.CoachName,
          email: coach.userEmail,
        };
      });
      setCoachDetails(coachDetailsMap);
      setCoaches(coachItems);
    } catch (error) {
      console.error("Error fetching coaches:", error);
      toast.error("Failed to fetch coaches");
    }
  };

  const fetchTaskDetails = async () => {
    try {
      const taskData = await API.graphql(
        graphqlOperation(getTask, { id: task.id }),
      );
      const fetchedTask = taskData.data.getTask;

      // Fetch comments separately
      const commentsData = await fetchAllPaginatedResults(listComments, {
        taskCommentsId: { eq: task.id },
      });
      setComments(commentsData);

      formik.setValues({
        ...formik.values,
        title: fetchedTask.title,
        description: fetchedTask.description
          ? JSON.parse(fetchedTask.description)
          : initialValue,
        status: statusOptions.find(
          (option) => option.value === fetchedTask.status,
        ),
        label: fetchedTask.label,
        assignedTo: fetchedTask.assignedTo || null,
        creator: fetchedTask.creator || null,
      });

      if (fetchedTask.creator) {
        await fetchAndUpdateAvatarUrl(fetchedTask.creator);
      }
      if (fetchedTask.assignedTo) {
        await fetchAndUpdateAvatarUrl(fetchedTask.assignedTo);
      }

      for (const comment of commentsData) {
        await fetchAndUpdateAvatarUrl(comment.user);
      }
    } catch (error) {
      console.error("Error fetching task details:", error);
      toast.error("Failed to fetch task details");
    }
  };

  const fetchAndUpdateAvatarUrl = async (userId) => {
    if (!avatarUrls[userId]) {
      try {
        const url = await getProfilePictureUrl(userId);
        setAvatarUrls((prev) => ({ ...prev, [userId]: url }));
      } catch (error) {
        console.error(`Error fetching avatar for user ${userId}:`, error);
      }
    }
  };

  const getProfilePictureUrl = async (userId) => {
    try {
      const url = await Storage.get(`${userId}/profilepicture`, {
        level: "public",
      });
      const response = await fetch(url);
      if (response.ok) {
        return url;
      }
    } catch (error) {
      console.error("Error fetching profile picture:", error);
    }
    return getGravatarUrl(coachDetails[userId]?.email || "");
  };

  const handleDeleteTask = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this task? All related comments will also be deleted.",
      )
    ) {
      try {
        // First, delete all comments associated with the task
        const commentsToDelete = await fetchAllPaginatedResults(listComments, {
          filter: { taskCommentsId: { eq: task.id } },
        });

        for (const comment of commentsToDelete) {
          await API.graphql(
            graphqlOperation(deleteComment, { input: { id: comment.id } }),
          );
        }

        // Then delete the task
        await API.graphql(
          graphqlOperation(deleteTask, { input: { id: task.id } }),
        );

        onTaskDeleted(task.id);
        setIsOpen(false);
        toast.success("Task and related comments deleted successfully");
      } catch (error) {
        console.error("Error deleting task:", error);
        toast.error("Failed to delete task");
      }
    }
  };

  const handleAddComment = async () => {
    if (formik.values.newComment[0].children[0].text.trim() === "") {
      toast.error("Comment cannot be empty");
      return;
    }

    const optimisticComment = {
      id: "temp-id",
      text: JSON.stringify(formik.values.newComment),
      user: currentUser.id,
      createdAt: new Date().toISOString(),
    };

    setComments((prevComments) => [...prevComments, optimisticComment]);

    try {
      const input = {
        text: JSON.stringify(formik.values.newComment),
        taskCommentsId: task.id,
        user: currentUser.id,
      };
      const result = await API.graphql(
        graphqlOperation(createComment, { input }),
      );

      if (result.data && result.data.createComment) {
        const newComment = result.data.createComment;
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === "temp-id" ? newComment : comment,
          ),
        );
        formik.setFieldValue("newComment", initialValue);
        toast.success("Comment added successfully");

        await fetchAndUpdateAvatarUrl(currentUser.id);
      } else {
        throw new Error("Failed to add comment");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error("Failed to add comment");
      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== "temp-id"),
      );
    }
  };

  const handleEditComment = async (commentId, newText) => {
    try {
      const input = {
        id: commentId,
        text: newText,
      };
      const result = await API.graphql(
        graphqlOperation(updateComment, { input }),
      );

      if (result.data && result.data.updateComment) {
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.id === commentId ? result.data.updateComment : comment,
          ),
        );
        setEditingCommentId(null);
        toast.success("Comment updated successfully");
      } else {
        throw new Error("Failed to update comment");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      toast.error("Failed to update comment");
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      try {
        await API.graphql(
          graphqlOperation(deleteComment, { input: { id: commentId } }),
        );
        setComments((prevComments) =>
          prevComments.filter((comment) => comment.id !== commentId),
        );
        toast.success("Comment deleted successfully");
      } catch (error) {
        console.error("Error deleting comment:", error);
        toast.error("Failed to delete comment");
      }
    }
  };

  const editor = useMemo(() => withReact(createEditor()), []);

  return (
    <OffCanvas
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      dialogClassName="max-md:max-w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl"
    >
      <OffCanvasHeader>
        <div className="flex gap-4">
          <span>{formik.values.title}</span>
          <span className="text-zinc-500">#{formik.values.id}</span>
        </div>
      </OffCanvasHeader>
      <OffCanvasBody>
        <div className="flex flex-col gap-4">
          <Badge
            color="emerald"
            variant="outline"
            className="flex gap-2 border-transparent"
          >
            <Icon icon="HeroPlayCircle" />
            {formik.values.status.label}
          </Badge>
          <div>
            <h3 className="text-lg font-semibold mb-2">Title</h3>
            <Input
              type="text"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              placeholder="Enter Task Title ..."
              autoComplete="off"
            />
            {formik.touched.title && formik.errors.title && (
              <div className="text-red-500">{formik.errors.title}</div>
            )}
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Description</h3>
            {editDescStatus ? (
              <RichText
                id="description"
                value={formik.values.description}
                handleChange={(event) => {
                  formik.setFieldValue("description", event);
                }}
              />
            ) : (
              <>
                {formik.values.description ? (
                  <div className="text-sm text-gray-600">
                    {getPlainTextFromSlateData(formik.values.description)}
                  </div>
                ) : (
                  <div className="text-sm text-gray-400 italic">
                    No description
                  </div>
                )}
              </>
            )}
            <Button
              className="mt-2"
              onClick={() => setEditDescStatus(!editDescStatus)}
            >
              {editDescStatus ? "Save" : "Edit"}
            </Button>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Status</h3>
            <SelectReact
              options={statusOptions}
              name="status"
              value={formik.values.status}
              onChange={(option) => formik.setFieldValue("status", option)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.status && formik.errors.status && (
              <div className="text-red-500">{formik.errors.status}</div>
            )}
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Assigned To</h3>
            {editResponsibleStatus ? (
              <SelectReact
                options={coaches.map((coach) => ({
                  label: coachDetails[coach.id]?.name || coach.id,
                  value: coach.id,
                }))}
                id="options"
                name="options"
                defaultMenuIsOpen={true}
                value={
                  formik.values.assignedTo
                    ? {
                        label:
                          coachDetails[formik.values.assignedTo]?.name ||
                          formik.values.assignedTo,
                        value: formik.values.assignedTo,
                      }
                    : null
                }
                onChange={(option) => {
                  formik.setFieldValue(
                    "assignedTo",
                    option ? option.value : null,
                  );
                  setEditResponsibleStatus(false);
                }}
              />
            ) : (
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setEditResponsibleStatus(true)}
              >
                {formik.values.assignedTo ? (
                  <>
                    <Avatar
                      src={avatarUrls[formik.values.assignedTo]}
                      name={
                        coachDetails[formik.values.assignedTo]?.name ||
                        formik.values.assignedTo
                      }
                      className="w-8"
                    />
                    <span className="text-[--primary-color]">
                      {coachDetails[formik.values.assignedTo]?.name ||
                        formik.values.assignedTo}
                    </span>
                  </>
                ) : (
                  <span className="text-gray-400">Not assigned</span>
                )}
              </div>
            )}
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Creator</h3>
            {formik.values.creator ? (
              <div className="flex items-center gap-2">
                <Avatar
                  src={avatarUrls[formik.values.creator]}
                  name={
                    coachDetails[formik.values.creator]?.name ||
                    formik.values.creator
                  }
                  className="w-8"
                />
                <span>
                  {coachDetails[formik.values.creator]?.name ||
                    formik.values.creator}
                </span>
              </div>
            ) : (
              <span className="text-gray-400">Unknown</span>
            )}
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Label</h3>
            {editLabelStatus ? (
              <FieldWrap
                lastSuffix={
                  <Button
                    className="ms-2"
                    variant="solid"
                    rounded="rounded"
                    icon="HeroArrowTopRightOnSquare"
                    size="sm"
                    onClick={() => {
                      setEditLabelStatus(false);
                    }}
                  />
                }
              >
                <Input
                  type="text"
                  name="label"
                  onChange={(event) =>
                    formik.setFieldValue("label", event.target.value)
                  }
                  value={formik.values.label}
                  autoComplete="off"
                />
              </FieldWrap>
            ) : (
              <div
                className="cursor-pointer"
                onClick={() => setEditLabelStatus(true)}
              >
                {formik.values.label ? (
                  <Badge color="red" colorIntensity="500" rounded="rounded-lg">
                    {formik.values.label}
                  </Badge>
                ) : (
                  <span>No label</span>
                )}
              </div>
            )}
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Comments</h3>
            {comments.map((comment) => (
              <div key={comment.id} className="mb-4">
                <Card className="shadow-lg">
                  <CardHeader>
                    <CardHeaderChild>
                      <Avatar
                        src={avatarUrls[comment.user]}
                        name={coachDetails[comment.user]?.name || comment.user}
                        className="w-8"
                      />
                      <span>
                        {coachDetails[comment.user]?.name || comment.user}
                      </span>
                      <span className="text-zinc-500">
                        {dayjs(comment.createdAt).format("LLL")}
                      </span>
                    </CardHeaderChild>
                    {comment.user === currentUser.id && (
                      <CardHeaderChild>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() =>
                            setEditingCommentId(
                              comment.id === editingCommentId
                                ? null
                                : comment.id,
                            )
                          }
                        >
                          {editingCommentId === comment.id ? "Cancel" : "Edit"}
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          color="red"
                          onClick={() => handleDeleteComment(comment.id)}
                        >
                          Delete
                        </Button>
                      </CardHeaderChild>
                    )}
                  </CardHeader>
                  <CardBody>
                    <CommentText
                      text={comment.text}
                      isEditing={editingCommentId === comment.id}
                      onChange={(newText) => {
                        setComments((prevComments) =>
                          prevComments.map((c) =>
                            c.id === comment.id
                              ? { ...c, text: JSON.stringify(newText) }
                              : c,
                          ),
                        );
                      }}
                      onSave={(newText) =>
                        handleEditComment(comment.id, newText)
                      }
                    />
                  </CardBody>
                </Card>
              </div>
            ))}
            <div>
              <RichText
                id="newComment"
                value={formik.values.newComment}
                handleChange={(event) => {
                  formik.setFieldValue("newComment", event);
                }}
                placeholder="Leave a comment"
              />
              <Button
                color="emerald"
                variant="outline"
                onClick={handleAddComment}
                className="mt-2"
              >
                Add Comment
              </Button>
            </div>
          </div>

          <div className="flex gap-4">
            <Button
              icon="HeroCheckCircle"
              color="emerald"
              variant="solid"
              onClick={formik.handleSubmit}
            >
              Update Task
            </Button>
            <Button
              icon="HeroTrash"
              color="red"
              variant="solid"
              onClick={handleDeleteTask}
            >
              Delete Task
            </Button>
          </div>
        </div>
      </OffCanvasBody>
    </OffCanvas>
  );
};

export default TaskEditPartial;

import { useState, useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { Storage } from "aws-amplify";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileValidateSize,
);

export default function ProfilePictureUpload({ onImageUpload, userId }) {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pond = useRef(null);

  const handleInit = () => {
    console.log("FilePond instance has initialised", pond.current);
  };

  const handleProcessFile = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
  ) => {
    const fileName = `${userId}/profilepicture`; // Use the directoryName state to create a path

    setIsLoading(true); // Start loading

    Storage.put(fileName, file, {
      level: "public", // Set the access level to private
      progressCallback(progressEvent) {
        progress(true, progressEvent.loaded, progressEvent.total);
      },
      contentType: file.type,
    })
      .then((storageResponse) => {
        load(storageResponse.key); // Calls the load method with the file key if upload is successful
        onImageUpload(storageResponse.key); // Notify the parent component
      })
      .catch((err) => {
        error(err.message);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });

    return {
      abort: () => {
        abort();
        setIsLoading(false); // Stop loading if aborted
      },
    };
  };

  return (
    <div className="relative">
      <FilePond
        ref={pond}
        files={files}
        allowReorder={true}
        acceptedFileTypes={[
          "image/jpg",
          "image/jpeg",
          "image/png",
          "image/gif",
        ]}
        instantUpload={true}
        allowMultiple={false}
        allowImagePreview={true}
        allowFileTypeValidation={true}
        allowImageExifOrientation={true}
        allowFileSizeValidation={true}
        maxFileSize="10MB"
        labelMaxFileSizeExceeded="File is too large"
        oninit={() => handleInit()}
        onupdatefiles={setFiles}
        server={{ process: handleProcessFile }}
        labelIdle='Drag & Drop your picture or <span class="filepond--label-action">Browse</span>'
        imagePreviewHeight={170}
        imageCropAspectRatio="1:1"
        imageResizeTargetWidth={200}
        imageResizeTargetHeight={200}
        stylePanelLayout="compact circle"
        styleLoadIndicatorPosition="center middle"
        styleButtonRemoveItemPosition="center middle"
        className="w-44 mx-auto bg-gray-200 rounded-full"
      />
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75 rounded-full">
          <div className="w-5 h-5 border-4 border-blue-500 border-t-transparent border-t-4 border-t-blue-600 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
}

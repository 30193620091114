function useColorIntensity(shade) {
  const colorShades = [
    "50",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "950",
  ];

  const index = typeof shade === "undefined" ? 5 : colorShades.indexOf(shade);

  const textColor = index <= 4 ? "text-black" : "text-white";

  const calculateIntensity = (process) => {
    if (process === "tint") {
      return colorShades[index - 1];
    }
    if (process === "shade") {
      return colorShades[index + 1];
    }
    return shade;
  };

  const tintColorIntensity = calculateIntensity("tint");
  const shadeColorIntensity = calculateIntensity("shade");

  return { textColor, tintColorIntensity, shadeColorIntensity };
}

export default useColorIntensity;

import React, { cloneElement, forwardRef, useRef } from "react";
import classNames from "classnames";
import useDomRect from "../../hooks/useDomRect";

const FieldWrap = forwardRef((props, ref) => {
  const {
    children,
    className,
    firstSuffix,
    lastSuffix,
    isValidMessage,
    isValid,
    isTouched,
    invalidFeedback,
    validFeedback,
    ...rest
  } = props;

  const sharedClasses = classNames(
    "absolute top-[2px] bottom-[2px] flex justify-center items-center px-1 rounded",
  );

  const divFirstRef = useRef(null);
  const [domFirstRect] = useDomRect(divFirstRef);

  const divLastRef = useRef(null);
  const [domLastRect] = useDomRect(divLastRef);

  // TODO: use useDir hook of theme to know whether current selected i18 language is LTR or not
  const isLTR = true;

  return (
    <div
      ref={ref}
      data-component-name="FieldWrap"
      className={classNames("relative", className)}
      {...rest}
    >
      {firstSuffix && (
        <div
          ref={divFirstRef}
          className={classNames(sharedClasses, "start-px")}
        >
          {firstSuffix}
        </div>
      )}
      {cloneElement(children, {
        isValid,
        isTouched,
        invalidFeedback,
        style: {
          paddingLeft:
            (firstSuffix && isLTR && domFirstRect?.width) ||
            (lastSuffix && !isLTR && domLastRect?.width),
          paddingRight:
            (firstSuffix && !isLTR && domFirstRect?.width) ||
            (lastSuffix && isLTR && domLastRect?.width),
        },
      })}
      {lastSuffix && (
        <div ref={divLastRef} className={classNames(sharedClasses, "end-px")}>
          {lastSuffix}
        </div>
      )}
    </div>
  );
});
FieldWrap.defaultProps = {
  className: undefined,
  firstSuffix: undefined,
  lastSuffix: undefined,
};

export default FieldWrap;

import thiessHero from "../assets/thiess-hero.png";
import { useNavigateToSection } from "./Panel";

export default function Hero() {
  const navigateToSection = useNavigateToSection();

  return (
    <div>
      <div className="relative isolate overflow-hidden pt-14">
        <img
          src={thiessHero}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover rounded-2xl filter shadow"
        />
        <div className="mx-auto py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="sm:text-7xl text-5xl  tracking-tight text-white">
              MO@T App
            </h1>
            <h1 className="sm:text-7xl text-5xl  tracking-tight text-white">
              (Managing operations @ THIESS)
            </h1>
            <p className="mt-6 sm:text-lg text-sm leading-8 text-white">
              This tool kit will help you get into the rhythms and routines
              needed to help you systematically improve your results.
            </p>
            <p className="mt-6 sm:text-lg text-sm font-semibold leading-8 text-white">
              Click on your button below to explore the MO@T for your role.
            </p>
            <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-6">
              <button
                onClick={() => navigateToSection("/forms", "supervisor")}
                className="rounded-md bg-thiess-blue-700 px-3.5 py-2.5 sm:text-lg text-sm text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-400"
              >
                Supervisor
              </button>
              <button
                onClick={() => navigateToSection("/forms", "pls")}
                className="rounded-md bg-thiess-blue-700 px-3.5 py-2.5 sm:text-lg text-sm text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-400"
              >
                Planner, Staff or Superintendent
              </button>
              <button
                onClick={() => navigateToSection("/forms", "manager")}
                className="rounded-md bg-thiess-blue-700 px-3.5 py-2.5 sm:text-lg text-sm text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-400"
              >
                Manager
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import InteractiveMap from "./tailwind-ui-components/InteractiveMap";

function Moskit({
  selectedCoach,
  selectedOperation,
  selectedDepartment,
  selectedCrew,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setOperations,
  setDepartments,
  setCrews,
  operations,
  departments,
  crews,
}) {
  return (
    <>
      <InteractiveMap
        selectedCoach={selectedCoach}
        selectedOperation={selectedOperation}
        selectedDepartment={selectedDepartment}
        selectedCrew={selectedCrew}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setOperations={setOperations}
        setDepartments={setDepartments}
        setCrews={setCrews}
        operations={operations}
        departments={departments}
        crews={crews}
      />
    </>
  );
}

export default Moskit;

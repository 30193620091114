export default function UpdateProfileNotice() {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h4 className="sm:text-4xl text-2xl  leading-7 text-black">
          Update Your Profile before filling the form
        </h4>
      </div>
      <div className="flex items-center justify-center">
        <button>
          <a
            className="rounded-md bg-thiess-blue-600 px-3 py-2 sm:text-lg text-sm  text-white shadow-sm hover:bg-thiess-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-thiess-blue-600"
            href="/profile"
          >
            Update Profile
          </a>
        </button>
      </div>
    </div>
  );
}

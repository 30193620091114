import React from "react";
import classNames from "classnames";
import Tooltip from "../ui/Tooltip";

const Label = (props) => {
  const { children, className, description, ...rest } = props;
  return (
    <label
      data-component-name="Label"
      className={classNames(
        "mb-2 inline-block w-full cursor-pointer text-base",
        className,
      )}
      {...rest}
    >
      {children}
      {description && (
        <Tooltip className="ms-2 align-baseline" text={description} />
      )}
    </label>
  );
};
Label.defaultProps = {
  className: undefined,
  description: undefined,
};

export default Label;

import React from "react";
import { Draggable } from "@hello-pangea/dnd";
import classNames from "classnames";
import ColumnCardPartial from "./ColumnCard.partial";
import Card from "../../fyr-theme/components/ui/Card";

const ColumnCardWrapperPartial = ({
  columnKey,
  columnsData,
  tasksData,
  setTasksData,
  onEditTask,
  avatarUrls,
  coachDetails,
}) => {
  const cardsInTheColumn = tasksData[columnKey] || [];

  return (
    <>
      {cardsInTheColumn.map((task, index) => (
        <Draggable key={task.id} draggableId={task.id} index={index}>
          {(providedDraggable, snapshotDraggable) => (
            <Card
              data-component-name="ColumnCardWrapperPart"
              className={classNames(
                "shadow-sm [&:not(:nth-last-child(2))]:mb-4",
                {
                  "border border-blue-500": snapshotDraggable.isDragging,
                },
              )}
              ref={providedDraggable.innerRef}
              {...providedDraggable.draggableProps}
              {...providedDraggable.dragHandleProps}
              style={providedDraggable.draggableProps.style}
            >
              <ColumnCardPartial
                columnKey={columnKey}
                columnsData={columnsData}
                task={task}
                tasksData={tasksData}
                setTasksData={setTasksData}
                onEditTask={onEditTask}
                avatarUrls={avatarUrls}
                coachDetails={coachDetails}
              />
            </Card>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default ColumnCardWrapperPartial;

import React, { useMemo } from "react";
import { Editable, Slate, withReact } from "slate-react";
import { createEditor } from "slate";
import {
  CardBody,
  CardHeader,
  CardHeaderChild,
  CardTitle,
} from "../../fyr-theme/components/ui/Card";
import Badge from "../../fyr-theme/components/ui/Badge";
import Avatar from "../../fyr-theme/components/Avatar";
import Icon from "../../fyr-theme/components/icon/Icon";

// Function to extract plain text from Slate's data structure
const getPlainTextFromSlateData = (data) => {
  if (typeof data === 'string') {
    try {
      data = JSON.parse(data);
    } catch (e) {
      return 'No description';
    }
  }

  if (!data || !Array.isArray(data) || data.length === 0) return 'No description';
  
  const text = data.map(node => {
    if (node.text) return node.text;
    if (node.children) return getPlainTextFromSlateData(node.children);
    return '';
  }).join('');

  return text.trim() === '' ? 'No description' : text;
};

const ColumnCardPartial = ({
  columnKey,
  columnsData,
  task,
  tasksData,
  setTasksData,
  onEditTask,
  avatarUrls,
  coachDetails,
}) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const columnData = columnsData[columnKey];
  const initialValue = [{ type: "paragraph", children: [{ text: "" }] }];

  return (
    <>
      <CardHeader onClick={() => onEditTask(task)}>
        <CardHeaderChild>
          <CardTitle className="text-xl">
            <div role="presentation">
              <div>{task.title}</div>
              {task.subtitle && (
                <div className="sm:text-lg text-sm text-zinc-500">
                  {task.subtitle}
                </div>
              )}
            </div>
          </CardTitle>
        </CardHeaderChild>
        <CardHeaderChild>
          {task.assignedTo && (
            <Avatar
              src={avatarUrls[task.assignedTo]}
              className="!w-8"
              name={coachDetails[task.assignedTo]?.name || "Unknown User"}
            />
          )}
        </CardHeaderChild>
      </CardHeader>
      <CardBody onClick={() => onEditTask(task)}>
        <div className="flex flex-wrap gap-4">
          <div className="flex gap-2">
            {!!task?.attachments?.length && (
              <Badge
                variant="outline"
                rounded="rounded-full"
                className="border-transparent"
              >
                <small className="flex items-center gap-2">
                  <Icon icon="HeroPaperClip" />
                  {task.attachments.length}
                </small>
              </Badge>
            )}
            {!!task?.items?.length && (
              <Badge
                variant="outline"
                rounded="rounded-full"
                className="border-transparent"
              >
                <small className="flex items-center gap-2">
                  <Icon icon="HeroCheck" />
                  {task.items.length}
                </small>
              </Badge>
            )}
            {task.label && (
              <Badge
                color="emerald"
                variant="outline"
                rounded="rounded-full"
                className="border-transparent"
              >
                <small className="">{task.label}</small>
              </Badge>
            )}
          </div>
          {task?.img && (
            <img
              src={task?.img}
              alt=""
              className="aspect-[16/9] w-14 basis-full rounded-lg object-cover"
            />
          )}
          <div className="basis-full">
            <div className="text-sm text-gray-600">
              {getPlainTextFromSlateData(task.description)}
            </div>
          </div>
        </div>
      </CardBody>
    </>
  );
};

export default ColumnCardPartial;

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  PresentationChartLineIcon,
  PresentationChartBarIcon,
  DocumentDuplicateIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";

export default function Stats({ scatterTrendData }) {
  let lastTrendItem = null;
  let beforeLastTrendItem = null;
  let maxTrendItem = null;
  let lastCountItem = null;
  let beforeLastCountItem = null;
  let maxCountItem = null;

  if (Array.isArray(scatterTrendData) && scatterTrendData.length > 0) {
    const trendObject = scatterTrendData[0];
    const countObject = scatterTrendData[1];
    if (Array.isArray(trendObject.data) && trendObject.data.length > 0) {
      lastTrendItem = trendObject.data[trendObject.data.length - 1];
      beforeLastTrendItem = trendObject.data[trendObject.data.length - 2];
      const yValues = trendObject.data.map((item) => item.y);
      const max = Math.max(...yValues);
      maxTrendItem = trendObject.data.find((item) => item.y === max);
    }

    if (Array.isArray(countObject.data) && countObject.data.length > 0) {
      lastCountItem = countObject.data[countObject.data.length - 1];
      beforeLastCountItem = countObject.data[countObject.data.length - 2];
      const yValues = countObject.data.map((item) => item.y);
      const max = Math.max(...yValues);
      maxCountItem = countObject.data.find((item) => item.y === max);
    }
  }

  let maturityTrendChange = "(No value)";
  let maxTrendChange = "(No value)";
  let maturityCountChange = "(No value)";
  let maxCountChange = "(No value)";

  if (lastTrendItem && beforeLastTrendItem) {
    maturityTrendChange = (
      ((lastTrendItem.y - beforeLastTrendItem.y) / beforeLastTrendItem.y) *
      100
    ).toFixed(1);
    maturityTrendChange += "% (From last month)";
  }

  if (maxTrendItem && lastTrendItem) {
    maxTrendChange = (
      ((maxTrendItem.y - lastTrendItem.y) / lastTrendItem.y) *
      100
    ).toFixed(1);
    maxTrendChange += "% (From latest month)";
  }

  if (lastCountItem && beforeLastCountItem) {
    maturityCountChange = (
      ((lastCountItem.y - beforeLastCountItem.y) / beforeLastCountItem.y) *
      100
    ).toFixed(0);
    maturityCountChange += "% (From last month)";
  }

  if (maxCountItem && lastCountItem) {
    maxCountChange = (
      ((maxCountItem.y - lastCountItem.y) / lastCountItem.y) *
      100
    ).toFixed(0);
    maxCountChange += "% (From latest month)";
  }

  const stats = [
    {
      id: 1,
      name: "Overall Maturity of your site",
      stat: lastTrendItem ? lastTrendItem.y.toFixed(1) : "(No value)",
      href: "/reports",
      icon: PresentationChartBarIcon,
      change: maturityTrendChange,
      changeType:
        maturityTrendChange !== "(No value)" &&
        parseFloat(maturityTrendChange) >= 0
          ? "increase"
          : "decrease",
    },
    {
      id: 2,
      name: "Best Maturity of your site",
      stat: maxTrendItem ? maxTrendItem.y.toFixed(1) : "(No value)",
      href: "/reports",
      icon: PresentationChartLineIcon,
      change: maxTrendChange,
      changeType:
        maxTrendChange !== "(No value)" && parseFloat(maxTrendChange) >= 0
          ? "increase"
          : "decrease",
    },
    {
      id: 3,
      name: "MO@T Engagement assessments / month",
      stat: lastCountItem ? lastCountItem.y.toFixed(0) : "(No value)",
      href: "/forms",
      icon: DocumentDuplicateIcon,
      change: maturityCountChange,
      changeType:
        maturityCountChange !== "(No value)" &&
        parseFloat(maturityCountChange) >= 0
          ? "increase"
          : "decrease",
    },
    {
      id: 4,
      name: "Best MO@T Engagement assessments / month",
      stat: maxCountItem ? maxCountItem.y.toFixed(0) : "(No value)",
      href: "/forms",
      icon: ClipboardDocumentCheckIcon,
      change: maxCountChange,
      changeType:
        maxCountChange !== "(No value)" && parseFloat(maxCountChange) >= 0
          ? "increase"
          : "decrease",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="pr-6 rounded-2xl shadow-m col-span-12 xl:col-span-6 xl:my-0 my-6">
      <dl className="grid grid-cols-1 gap-8 sm:grid-cols-2 xl:grid-cols-2 justify-between">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative bg-white shadow rounded-lg overflow-hidden"
          >
            <div className="p-6">
              <dt>
                <div className="flex items-center space-x-3">
                  {/* <item.icon className="h-6 w-6 text-gray-700" /> */}
                  <p className="sm:text-base text-sm text-black">{item.name}</p>
                </div>
              </dt>
              <dd className="mt-4 flex items-baseline">
                <h6 className="sm:text-2xl text-lg  text-black">{item.stat}</h6>
                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : "text-red-600",
                    "ml-2 flex items-baseline sm:text-sm text-xs ",
                  )}
                >
                  {item.changeType === "increase" ? (
                    <ArrowUpIcon
                      className="h-4 w-4 text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="h-4 w-4 text-red-500"
                      aria-hidden="true"
                    />
                  )}
                  <span className="sr-only">
                    {item.changeType === "increase" ? "Increased" : "Decreased"}{" "}
                    by
                  </span>
                  {item.change}
                </p>
              </dd>
            </div>
          </div>
        ))}
      </dl>
    </div>
  );
}

import React from "react";
import classNames from "classnames";

const PageWrapper = (props) => {
  const { children, className, title, name, ...rest } = props;

  return (
    <main
      data-component-name="PageWrapper"
      className={classNames("flex shrink-0 grow flex-col", className)}
      {...rest}
    >
      {children}
    </main>
  );
};
PageWrapper.defaultProps = {
  className: undefined,
  title: undefined,
  name: undefined,
};

export default PageWrapper;

import React from "react";
import Table from "../../fyr-theme/components/ui/Table";

const TabularBoard = ({ columnsData, tasksData }) => {
  const allTasks = Object.values(tasksData).flat();

  const columns = [
    { Header: "Title", accessor: "title" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Assigned To",
      accessor: "assignedTo.CoachName",
      Cell: ({ value }) => value || "Unassigned",
    },
    { Header: "Label", accessor: "label" },
  ];

  return <Table columns={columns} data={allTasks} />;
};

export default TabularBoard;

import {
  AcademicCapIcon,
  ShieldCheckIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";

const incentives = [
  {
    name: "Diagnostic and Teaching Tool",
    imageSrc: <AcademicCapIcon className="h-12 w-12 mx-auto" />,
    description:
      "The MO@T app offers operational insights and guides users on planning, execution, and improvement best practices.",
  },
  {
    name: "Consistency Driver",
    imageSrc: <ShieldCheckIcon className="h-12 w-12 mx-auto" />,
    description:
      "The MO@T ensures consistency in Thiess operations, driving uniformity in planning, execution, and improvement.",
  },
  {
    name: "Predictable Delivery Enablement",
    imageSrc: <TruckIcon className="h-12 w-12 mx-auto" />,
    description:
      "Teams can ensure predictable work delivery across all Thiess sites globally, leading to more reliable outcomes and improved results.",
  },
];

export default function Features() {
  return (
    <div className="">
      <div className="mx-auto py-16 sm:px-2 sm:py-24 lg:px-4">
        <div className="mx-auto px-4 lg:max-w-none">
          <div className="text-center">
            <h2 className="sm:text-6xl text-4xl  tracking-tight text-black">
              About MO@T App
            </h2>
            <p className="mt-8 max-w-2xl mx-auto text-gray-700 sm:text-lg text-sm">
              The MO@T app ensures consistency in Thiess operations through
              diagnostic and teaching features, enhancing results with continual
              best practice adoption.
            </p>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <div
                key={incentive.name}
                className="sm:flex lg:block mx-auto text-center"
              >
                <div className="sm:flex-shrink-0">{incentive.imageSrc}</div>
                <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                  <h6 className="text-black sm:text-2xl text-lg font-medium">
                    {incentive.name}
                  </h6>
                  <p className="mt-2 sm:text-lg text-sm max-w-xs text-gray-700">
                    {incentive.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
